import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  // Create,
  TextInput,
  SimpleForm,
  required,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  //FileInput,
  SelectInput,
  PasswordInput,
  // useNotify,
  Toolbar,
  SaveButton
} from 'react-admin';

import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  Button,
  InputAdornment,
  Card,
  CardContent,
  Modal
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useForm } from 'react-final-form';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SecondaryCustomer from '../projects/SecondaryCustomer';
var generator = require('generate-password');

const UpdateProject = props => {
  const { project, open, close, updateProject } = props;
  const [Tabvalue, setTabValue] = React.useState(0);
  const [secondaryClientDetails, setSecondaryClientDetails] = React.useState(props?.project?.secondary_clients);
  const [secondOpen, setSecondopen] = React.useState();
  const [address, setAddress] = React.useState({})
  const classes = useStyles();
  //const notify = useNotify();

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };
  const handleSecondaryOpen = () => {
    setSecondopen(true);
  };

  const handleSecondaryClose = () => {
    setSecondopen(false);
  };
  const editproject = (data) => {
    data.project_map_address = address?.project;
    data.client_map_address = address?.client;
    data.secondary_clients = secondaryClientDetails || [];
    updateProject(data);
  }

  if (!project) return null;
  let managers = project.managers && project.managers.length > 0 ? project.managers.map(manager => manager.id) : [];
  let contractors = project.contractors && project.contractors.length > 0 ? project.contractors.map(contractor => contractor.id) : [];
  if (contractors && contractors.length > 0) {
    contractors = contractors.filter(contractor => contractor !== null);
  }
console.log(project);
  let initialValues = {
    id: project.id,
    title: project.title,
    description: (project.description) ? project.description : '',
    project_address_id: project.address.id,
    street: project.address.street,
    suburb: project.address.suburb,
    zip: project.address.zip,
    area: project.area,
    amount: project.amount,
    facade: { url: project.facade, title: `${project.title}` },
    image: { url: project.client.avatar, title: `${project.client._client.name.first_name} ${project.client._client.name.last_name}` },
    managers: managers,
    client_id: project.client._id,
    client_nameId: project.client._client.name.id,
    client_Info_Id: project.client._client.id,
    first_name: project.client._client.name.first_name,
    last_name: project.client._client.name.last_name,
    email: project.client.email,
    contact: project.client._client.contact,
    client_address_id: project.client._client.address.id,
    client_street: project.client._client.address.street,
    client_suburb: project.client._client.address.suburb,
    client_zip: project.client._client.address.zip,
    client_status: project.client.status,
    contractors: contractors,
    project_type: project.type,
    project_status: project.project_status,
    project_map_address: project?.address?.map_address,
    client_map_address: project?.client?._client?.address?.map_address
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="edit-project"
        aria-describedby="edit-project"
      >
        <Paper square className={classes.paper}>
          <Tabs
            value={Tabvalue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}

          >
            <Tab label="General" classes={{ root: classes.tab, selected: classes.selectedTab }} />
            <Tab label="Client" classes={{ root: classes.tab, selected: classes.selectedTab }} />
            <Tab label="Facade" classes={{ root: classes.tab, selected: classes.selectedTab }} />

          </Tabs>
        </Paper>

        <SimpleForm save={editproject} toolbar={<CustomToolbar />} initialValues={initialValues}>
          {/* <Grid container spacing={1}> */}
            <TabContainer id={0} active={Tabvalue === 0}>
              <>
                <TextInput source="title" fullWidth={true} variant="outlined" validate={required()} />
                <TextInput source="description" fullWidth={true} variant="outlined" multiline />
                <AddressField
                  {...props}
                  address={address}
                  setAddress={setAddress}
                />
                <EstimateField />
                <ReferenceArrayInput
                  reference="managers"
                  source="managers"
                  label="Project Managers"
                  fullWidth={true}
                  variant="outlined"
                  resource="managers"
                  filter={{ all: true, status: 1 }}
                >
                  <SelectArrayInput optionText="fullname" optionValue="id" options={{ fullWidth: true }}>
                    <ChipField />
                  </SelectArrayInput>
                </ReferenceArrayInput>
                <ReferenceArrayInput
                  reference="contractors"
                  source="contractors"
                  label="Sub Contractors"
                  fullWidth={true}
                  variant="outlined"
                  resource="contractors"
                  filter={{ all: true, status: 1 }}
                >
                  <SelectArrayInput optionText="fullname" optionValue="id" options={{ fullWidth: true }}>
                    <ChipField />
                  </SelectArrayInput>
                </ReferenceArrayInput>
                <SelectInput
                  source="project_type"
                  label="Project Type"
                  choices={[
                    { id: 'construction', name: 'Construction' },
                    { id: 'pre_contract', name: 'Pre Contract' },
                    { id: 'design_approval', name: 'Design Approval' },
                  ]}
                  variant="outlined"
                  fullWidth={true}
                />
                {/* <Grid item lg={12} md={12} sm={12} xs={12}> */}
                  <SelectInput
                    source="project_status"
                    label="Project Status"
                    defaultValue={1}
                    choices={[
                      { id: 1, name: 'Ongoing' },
                      { id: 2, name: 'Completed' },
                    ]}
                    variant="outlined"
                    fullWidth={true}
                  />
                {/* </Grid> */}
              </>
            </TabContainer>

            <TabContainer id={1} active={Tabvalue === 1}>
              <>
                <ImageInput
                  source="image"
                  label="Client Image"
                  accept="image/*"
                  placeholder={<p>Drop here</p>}
                >
                  <ImageField source="url" title="title" />
                </ImageInput>
                <FormFields
                  {...props}
                  address={address}
                  setAddress={setAddress}
                  gridStyle={classes.main}
                />
                <PasswordField classes={classes} />
                <SelectInput
                  source="client_status"
                  label="Status"
                  choices={[
                    { id: 1, name: 'Enabled' },
                    { id: 0, name: 'Disabled' },
                  ]}
                  fullWidth={true}
                  variant="outlined"
                />
                <Grid container justifyContent="center" spacing={1} className={classes.clientTab}>
                  <ClientSectionTitle label="Secondary Clients Details" classProp={classes.title} />
                  <SecondaryClinet
                    classProp={classes.modalStyle}
                    buttonStyle={classes.addButton}
                    secondOpen={secondOpen}
                    setSecondopen={setSecondopen}
                    handleSecondaryOpen={handleSecondaryOpen}
                    handleSecondaryClose={handleSecondaryClose}
                    setSecondaryClientDetails={setSecondaryClientDetails}
                    cardStyle={classes.card}
                    textStyle={classes.cardText}
                    project={project}
                  />
                </Grid>
              </>
            </TabContainer>

            <TabContainer id={2} active={Tabvalue === 2}>
              <>
                <ImageInput
                  source="facade"
                  label="Facade"
                  accept="image/*"
                  placeholder={<p>Upload Project Facade</p>}
                >
                  <ImageField source="url" title="title" />
                </ImageInput>
              </>
            </TabContainer>
          {/* </Grid> */}
        </SimpleForm>
      </Dialog>
    </div>
  );
}
const ClientSectionTitle = ({ label, classProp }) => {
  const style = {
    width: '100%',
    textAlign: 'center',
    background: '#00000047',
    color: '#fff'
  }
  return (
    <div style={style}>
      <Typography variant="h6" style={{ color: '#fff' }} gutterBottom className={classProp}>
        {label}
      </Typography>
    </div>
  );
};
function TabContainer(props) {
  const classes = useStyles();
  return (
    <div
      className={clsx({
        [classes.tabcontainerInActive]: !props.active,
      })}
    >
      {props.children}
    </div>
  );
}
const cardAction = {
  main: {
    postion: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: 5,
    top: 5,
    position: 'absolute',
  },
  Edit: {
    margin: 5,
    fontSize: 20,
    color: '#0074ff',
    cursor: 'pointer'
  },
  delete: {
    margin: 5,
    fontSize: 20,
    color: '#f00',
    cursor: 'pointer'
  },
}

const SecondaryClinet = ({
  classProp,
  buttonStyle,
  setSecondaryClientDetails,
  secondOpen,
  handleSecondaryClose,
  handleSecondaryOpen,
  cardStyle,
  textStyle,
  project
}) => {
  const [editOpen, setEditOpen] = React.useState({ open: false, data: {}, key: 0 });
  const [clientInfo, setClientInfo] = React.useState(project?.secondary_clients || []);
  React.useEffect(() => {
    setSecondaryClientDetails(project?.secondary_clients || []);
  }, [])
  const saveSecondaryClient = (client) => {
    setClientInfo(prev => [...prev, client]);
    setSecondaryClientDetails(prev => [...prev, client])
    handleSecondaryClose();
  }
  const editSecondaryClient = (client) => {
    let data = [...clientInfo];
    data[editOpen.key] = client;
    setSecondaryClientDetails(data);
    setClientInfo(data)
    handleEditClose();
  }
  const remove = (index) => {
    const details = clientInfo.filter((item, i) => i !== index);
    setSecondaryClientDetails(details);
    setClientInfo(details)
  }
  const handleEditClose = () => {
    setEditOpen({ open: false, data: {}, key: 0 })
  }

  const tabStyle = {
    textAlign: 'center',
    marginTop: 35,
    width: '100%'
  }

  return (
    <>
      {clientInfo?.length > 0 && (
        <>
          {clientInfo?.map((data, index) => (
            <Grid item md={6} xs={12}>
              <Card className={cardStyle}>
                <CardContent>
                  <Typography className={textStyle} gutterBottom>
                    {`Name: ${data.first_name} ${data.last_name}`}
                  </Typography>
                  <Typography className={textStyle} gutterBottom>
                    {`Email: ${data.email}`}
                  </Typography>
                  <Typography className={textStyle} gutterBottom>
                    {`Contact: ${data.contact}`}
                  </Typography>
                  {data.map_address ?
                    <Typography className={textStyle} gutterBottom>
                      {`Address: ${data.map_address}`}
                    </Typography>
                    :
                    <Typography className={textStyle} gutterBottom>
                      {`Address: ${data.street}  ${data.suburb}  ${data.zip}`}
                    </Typography>
                  }
                </CardContent>
                <div style={cardAction.main}>
                  <EditIcon style={cardAction.Edit} onClick={() => setEditOpen({ open: true, data: data, key: index })} />
                  <DeleteIcon style={cardAction.delete} onClick={() => remove(index)} />
                  <Modal
                    open={editOpen.open}
                    onClose={handleEditClose}
                    className={classProp}
                  >
                    <SecondaryCustomer save={editSecondaryClient} record={editOpen.data} key={index} />
                  </Modal>
                </div>
              </Card>
            </Grid>
          ))}
        </>
      )}
      <div style={tabStyle}>
        <Button size="small" variant="contained" onClick={handleSecondaryOpen} className={buttonStyle}>
          Add Client
        </Button>
        <Modal
          open={secondOpen}
          onClose={handleSecondaryClose}
          className={classProp}
        >
          <SecondaryCustomer save={saveSecondaryClient} record={{}} />
        </Modal>
      </div>
    </>
  );
};
const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.invalid} />
  </Toolbar>
);
const AddressField = (props) => {
  const { project, setAddress } = props;
  const projectAddressRef = React.useRef(null);
  const [project_address, setProjectAddress] = React.useState(project?.address?.map_address || null);
  let projectAddressAuto;
  React.useEffect(() => {
    setAddress(prevState => ({ ...prevState, project: project?.address?.map_address || null }));
    hadDetailsLoad(setProjectAddress, projectAddressRef);
  }, []);
  function hadDetailsLoad(updateProjectAddress, projectAddressRef) {
    projectAddressAuto = new window.google.maps.places.Autocomplete(
      projectAddressRef.current,
      { types: ["address"], componentRestrictions: { country: "aus" } }
    );
    projectAddressAuto.setFields(["address_components", "formatted_address"]);
    projectAddressAuto.addListener("place_changed", () =>
      handleAddressSelect(updateProjectAddress)
    );
  }

  function handleAddressSelect(updateProjectAddress) {
    const addressObject = projectAddressAuto.getPlace();
    const s = addressObject.formatted_address;
    updateProjectAddress(s);
    setAddress(prevState => ({ ...prevState, project: s }));
    setProjectAddress(s);
  }
  return (
    <>
      {project.address.street ?
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <TextInput
              source="street"
              fullWidth={true}
              variant="outlined"
              label="Street"
              multiline
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              source="suburb"
              label="Suburb"
              fullWidth={true}
              variant="outlined"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              source="zip"
              fullWidth={true}
              label="Postal Code"
              variant="outlined"
              validate={required()}
            />
          </Grid>
        </Grid>
        :
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <TextInput
              value={project_address || null}
              source="project_map_address"
              fullWidth={true}
              variant="outlined"
              label="Proposed location Address"
              placeholder=""
              multiline
              inputRef={projectAddressRef}
              onChange={(e) => {
                setProjectAddress(e.target.value);
                setAddress(prevState => ({ ...prevState, project: e.target.value }));
              }}
              validate={required()}
            />
          </Grid>
        </Grid>
      }
    </>
  );
};

const EstimateField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="area"
          validate={required()}
          variant="outlined"
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                square metre
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="amount"
          validate={required()}
          variant="outlined"
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                AUD
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

const FormFields = (props) => {



  const { project, setAddress } = props;
  const clientAddressRef = React.useRef(null);
  const [client_address, setClientAddress] = React.useState(project?.client?._client?.address?.map_address || null);
  let clientAddressAuto;
  React.useEffect(() => {
    setAddress(prevState => ({ ...prevState, client: project?.client?._client?.address?.map_address || null }));
    hadDetailsLoad(setClientAddress, clientAddressRef);
  }, []);
  function hadDetailsLoad(updateClientAddress, clientAddressRef) {
    clientAddressAuto = new window.google.maps.places.Autocomplete(
      clientAddressRef.current,
      { types: ["address"], componentRestrictions: { country: "aus" } }
    );
    clientAddressAuto.setFields(["address_components", "formatted_address"]);
    clientAddressAuto.addListener("place_changed", () =>
      handleAddressSelect(updateClientAddress)
    );
  }

  function handleAddressSelect(updateClientAddress) {
    const addressObject = clientAddressAuto.getPlace();
    const s = addressObject.formatted_address;
    setAddress(prevState => ({ ...prevState, client: s }));
    updateClientAddress(s);
    setClientAddress(s);
  }

  return (
    <Grid container spacing={1} className={props.gridStyle}>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="first_name"
          validate={required()}
          fullWidth={true}
          label="First Name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="last_name"
          validate={required()}
          fullWidth={true}
          label="Last Name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="email"
          fullWidth={true}
          label="Email Address"
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="contact"
          validate={required()}
          fullWidth={true}
          label="Contact Number"
          variant="outlined"
        />
      </Grid>

      {project.client._client.address.street ? <>
        <Grid item xs={12} sm={6}>
          <TextInput
            source="client_street"
            validate={required()}
            multiline
            fullWidth={true}
            label="Street"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInput
            source="client_suburb"
            validate={required()}
            fullWidth={true}
            label="Suburb"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInput
            source="client_zip"
            validate={required()}
            fullWidth={true}
            label="Postal code"
            variant="outlined"
          />
        </Grid></> :

        <Grid item xs={12} sm={8}>
          <TextInput
            value={client_address || null}
            source="client_map_address"
            fullWidth={true}
            variant="outlined"
            label="Address"
            placeholder=""
            multiline
            inputRef={clientAddressRef}
            onChange={(e) => {
              setAddress(prevState => ({ ...prevState, client: e.target.value }));
              setClientAddress(e.target.value);
            }}
            validate={required()}
          />
        </Grid>
      }
    </Grid>
  );
}

const PasswordField = ({ classes }) => {
  const form = useForm();
  const changePassword = () => {
    form.change('client_password', generator.generate(
      {
        length: 15,
        numbers: true
      }
    ));
  }
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12} sm={9}>
        <Typography className={classes.passwordTitle}>Update password</Typography>
        <PasswordInput
          fullWidth={true}
          source="client_password"
          label="Password"
          variant="outlined"
          size="small"
        // validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button variant="outlined"
          color="primary"
          className={classes.passwordButton}
          onClick={changePassword}
        >
          Generate Password
        </Button>
      </Grid>
    </Grid>
  );
};

export default UpdateProject;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 20,
    boxShadow: 'none',
  },
  tab: {
    width: 140,
    background: '#f3f3f3',
    fontSize: 14,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#fff',
  },
  tabcontainerInActive: {
    display: "none"
  },
  passwordTitle: {
    fontSize: 12,
    textTransform: 'capitalize',
    paddingBottom: 10,
    color: '#FF9800',
  },
  passwordButton: {
    margin: 10,
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '100%',
    display: 'block'
  },
  addButton: {
    background: '#363957',
    color: '#fff',
    '&:hover': {
      background: '#484b5f',
    },
    marginBottom: 20,
  },
  card: {
    margin: '10px 25px',
    position: 'relative',
  },
  cardText: {
    fontSize: 14,
    color: '#525457',
  },
  clientTab: {
    border: '1px dashed #ddd'
  },
}));
