import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
  Grid, Typography,
} from '@material-ui/core';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  useDataProvider,
  useRedirect,
  Loading,
  TextInput
}
  from 'react-admin';
import Pdf from "react-to-pdf";
import { Document, Page, Text, View, Image, StyleSheet, Font, } from '@react-pdf/renderer';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import { useSelector } from 'react-redux';
const ref = React.createRef();
Font.register({
  family: 'newfont', fonts: [
    { src: process.env.PUBLIC_URL + '/ARIAL.ttf' }, // font-style: normal, font-weight: normal
    // { src: process.env.PUBLIC_URL + '/TimesNewRoman.ttf',},
  ]
});

function MaterialList(props) {
  const { value, projectId, RevId, Prev_revId } = props;
  // const projectId = '606d49b0f0ceed01607f6280'
  const dataProvider = useDataProvider();
  const [grand, setGrand] = useState();
  const [data, setData] = useState([]);
  const [rows, setRows] = React.useState(false);
  const [sitecost, setSitecost] = React.useState(false);
  const [estateinclusion, setEstateinclusion] = React.useState(false);
  const [authority, setAuthority] = React.useState(false);
  const [note, setNote] = React.useState(false);
  const [process_flow, setProcess_flow] = React.useState(false);
  const [luxuryspec, setLuxuryspec] = React.useState(false);
  const [auth_coun_fee, setAuth_coun_fee] = React.useState(false);
  const [userdetails, setUserdetails] = useState();
  const [Notes, setNotes] = useState();
  const [TAC, setTAC] = useState();
  const [Upgrades_cost, setUpgrades_cost] = useState();
  const [Upgrades, setUpgrades] = useState();
  const [project, setProject] = useState();
  const [grandtotal, setGrandtotal] = useState();
  const [costarray, setCostarray] = useState();
  const [displayFiles, setDisplayFiles] = useState();
  const [totalcost, setTotalcost] = useState();
  const [construction_period, setConstruction_period] = useState();
  const [authoritycost, setAuthoritycost] = useState();
  const [estateinclusion_cost, setEstateinclusion_cost] = useState();
  const [site_cost, setSite_cost] = useState();
  const [rev, setRev] = useState();
  const [PdfList, setPdfList] = React.useState(false);
  const [additional_details, setAdditional_details] = useState();
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const Single_element_return = (item, mainCatId, estimate) => {
    if (item) {
      let mainIndex = estimate.findIndex(x => x.subcatId.id === item._id);
      if (mainIndex >= 0) {
        return estimate[mainIndex]
      }
    }
    return false
  }
  const Luxury_single_element_return = (item, mainCatId, estimate) => {
    if (item) {
      let mainIndex = estimate.findIndex(x => x.subRef === item._id);
      if (mainIndex >= 0) {
        return estimate[mainIndex]
      }
    }
    return false
  }
  const Sitecost_single_element_return = (item, mainCatId, estimate) => {
    if (item) {
      let mainIndex = estimate.findIndex(x => x.subRef.id === item._id);
      if (mainIndex >= 0) {
        return estimate[mainIndex]
      }
    }
    return false
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  useEffect(() => {
    dataProvider.getOne('projects', { id: projectId }).then(({ data }) => {
      setProject(data)
      // setClientinfo(data.client)
      // setSecondary_Client(data.secondary_clients)
      setLoading(false);

    })
      .catch(error => {
        setLoading(false);
      });
    // dataProvider.getProjectDetailInstance(projectId).then(({ data }) => {
    //   setProject(data.project)
    //   setLoading(false);
    // })
    //   .catch(error => {
    //     setLoading(false);
    //   });
    dataProvider.getList('tender-user', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setUserdetails(data[0] ? data[0] : [])
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getvariations('tenderbasicorginal', { filter: { project: projectId, revId: RevId, Prev_revId: Prev_revId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setRows(data.tenderbasic);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });

    dataProvider.getvariations('tender-sitecost', { filter: { project: projectId, revId: RevId, Prev_revId: Prev_revId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      const NewCategorylist = []
      data && data.tender_sitecost && data.tender_sitecost.length > 0 && data.tender_sitecost.map((projectMaterial, index1) => {
        const subArr = []
        projectMaterial.children && projectMaterial.children.length > 0 && projectMaterial.children.map((item, index2) => {
          const val = Sitecost_single_element_return(item, projectMaterial?._id, data.tender_sitecost_saved)
          if (val) {
            subArr.push(val)
          }
        })
        if (subArr && subArr.length > 0) {
          NewCategorylist.push({ children: subArr, basecategory: projectMaterial.basecategory, created_at: projectMaterial.created_at, _id: projectMaterial._id, name: projectMaterial.name })
        }
      })
      setSitecost(NewCategorylist)
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getvariations('tender-estateinclusion', { filter: { project: projectId, revId: RevId, Prev_revId: Prev_revId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setEstateinclusion(data?.EstateInclusion ? data?.EstateInclusion : [])
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getvariations('tender-authority-condition', { filter: { project: projectId, revId: RevId, Prev_revId: Prev_revId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {

      setAuthority(data?.tender_authority_saved)
      setNote(data?.tender_authority_note_saved)
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getvariations('tender-luxuryspec', { filter: { project: projectId, revId: RevId, Prev_revId: Prev_revId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      const NewCategorylist = []
      data.tender_luxury && data.tender_luxury.length > 0 && data.tender_luxury.map((projectMaterial, index1) => {
        const subArr = []
        projectMaterial.children && projectMaterial.children.length > 0 && projectMaterial.children.map((item, index2) => {
          const val = Luxury_single_element_return(item, projectMaterial?._id, data.tender_luxury_saved)
          if (val) {
            subArr.push(val)
          }
        })
        if (subArr && subArr.length > 0) {
          NewCategorylist.push({ children: subArr, basecategory: projectMaterial.basecategory, created_at: projectMaterial.created_at, _id: projectMaterial._id, name: projectMaterial.name })
        }
      })
      setLuxuryspec(NewCategorylist);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('councile-authority', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setAuth_coun_fee(data);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('process-flow', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setProcess_flow(data);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('notes', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setNotes(data);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('terms-conditions', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setTAC(data);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('upgrade', { filter: { project: projectId, revId: RevId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setUpgrades(data);
      var price = 0
      data && data.length > 0 && data.map((item) => {
        price = price + item.price
      })
      setUpgrades_cost(price)
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getvariations('project-estimate-list', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      console.log(data)
      var grandtotal = 0
      // data && data.category_saved.length > 0 && data.category_saved.map((item) => {
      //   console.log(item)
      //   grandtotal = grandtotal + (item?.unitcost * item?.quantity)
      // })

      const NewCategorylist = []
      data.category && data.category.length > 0 && data.category.map((projectMaterial, index1) => {
        const subArr = []
        var sub_total = 0
        projectMaterial.children && projectMaterial.children.length > 0 && projectMaterial.children.map((item, index2) => {
          const val = Single_element_return(item, projectMaterial?._id, data.category_saved)
          if (val) {
            subArr.push(val)
            sub_total = sub_total + (val.unitcost * val.quantity)
          }
        })
        if (subArr && subArr.length > 0) {
          if (projectMaterial.name !== 'AUTHORITYCONDITION') {
            grandtotal = grandtotal + sub_total
          }
          NewCategorylist.push({ children: subArr, MaincategorySum: sub_total, basecategory: projectMaterial.basecategory, created_at: projectMaterial.created_at, _id: projectMaterial._id, name: projectMaterial.name })
        }
      })
      if (data && data.discount.length > 0 && data.sitebaseprice.length > 0) {
        if (data?.discount[0]?.percentage === true) {
          setGrandtotal((grandtotal - data?.sitebaseprice[0]?.cost) * (100 - data.discount[0].discount) / 100)
        }
        else {
          setGrandtotal(grandtotal - data?.sitebaseprice[0]?.cost - data.discount[0].discount)
        }
      }
      else {
        if (data.sitebaseprice.length > 0) {
          setGrandtotal(grandtotal - data?.sitebaseprice[0]?.cost)
        }
        else {
          setGrandtotal(grandtotal)
        }
      }
      setCostarray(NewCategorylist.filter((projectMaterial) => {
        if (projectMaterial.name === 'SITECOST' || projectMaterial.name === 'AUTHORITYCONDITION' || projectMaterial.name === 'POOL' || projectMaterial.name === 'GRANNY') {
          return projectMaterial
        }
      }))

      setSite_cost((NewCategorylist.find(vendor => vendor.name === 'SITECOST')).MaincategorySum)
      setAuthoritycost((NewCategorylist.find(vendor => vendor.name === 'AUTHORITYCONDITION')).MaincategorySum)
      setEstateinclusion_cost((NewCategorylist.find(vendor => vendor.name === 'ESTATEINCLUSION')).MaincategorySum)
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('additional-details', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setAdditional_details(data[0] ? data[0] : [])
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getProjectFiles(projectId).then(({ data }) => {
      let files = data.filter((file) => {
        return (
          file.category === 'tender'
        )
      })
      setDisplayFiles(files)
      setLoading(false)
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getList('final', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setRev(data)
      setConstruction_period(data[data.length - 1].construction_period)
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  }, []);

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={{
        paddingBottom: 40,
      }}>
        <View fixed style={{ marginBottom: 10, marginTop: -20 }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.jpg'} style={{ height: 140 }} />
        </View>
        <View style={{ alignItems: 'center', marginTop: 25 }}>
          <Image style={{ height: 120, width: 200 }} src={process.env.PUBLIC_URL + '/website nexahomes  logo.1.jpg'} />
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 35, marginTop: 15 }}>{'TENDER'}</Text>
          <Text
            style={{
              fontSize: 15,
              marginLeft: 110,
              marginTop: 35,
              fontWeight: 'bolder',
              fontFamily: 'Helvetica',
              alignSelf: 'flex-start'
            }} >
            {"Presented To,"}
          </Text>
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 22, marginTop: 15 }}>{project?.client?.first_name && project?.client?.last_name ? (project?.client?.first_name + ' ' + project?.client?.last_name) : ''}</Text>
          {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
            <>
              <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 20 }}>{'And'}</Text>
              <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 22, }}>{item?.first_name + ' ' + item?.last_name}</Text>
            </>
          ))
          }

          <Text style={{ fontWeight: 'bolder', fontFamily: 'Helvetica', fontSize: 15, marginTop: 20 }}>{'For the Construction of your New Homes'}</Text>
          <Text style={{ fontWeight: 'bolder', fontSize: 16, marginTop: 15 }}>{'At'}</Text>
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 20, marginTop: 15 }}>{`${project?.street} ${project?.suburb} ${project?.zip}`}</Text>
          <View style={{ marginTop: 10, alignItems: 'center' }}>

          </View>
          <View
            style={{
              border: '1.8px solid black',
              alignItems: 'center',
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
              paddingBottom: 10
            }}>
            <Text style={{ fontWeight: 'bolder', fontSize: 18, fontFamily: 'Helvetica-Bold' }}>{'Tender Reference Number'}</Text>
            <Text style={{ fontWeight: 'bolder', fontSize: 18, fontFamily: 'Helvetica-Bold' }}>{project?.title ? project.title : ''}</Text>
          </View>
          <Text style={{ fontSize: 15, fontFamily: 'Helvetica-Bold', marginTop: 20, alignSelf: 'flex-start', marginLeft: 25 }}>{'Revisions'}</Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View
              style={{
                width: 100,
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: '1.8px solid black',
                borderBottom: '1.8px solid black',
                borderLeft: '1.8px solid black'
              }}>
              <Text style={{ fontSize: 13, fontFamily: 'Helvetica-Bold' }}>{'Date'}</Text>
            </View>
            <View
              style={{
                width: 70,
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: '1.8px solid black',
                borderBottom: '1.8px solid black',
                borderLeft: '1.8px solid black'
              }}>
              <Text style={{ fontSize: 13, fontFamily: 'Helvetica-Bold' }}>{'Tender Rev.'}</Text>
            </View>
            <View
              style={{
                width: 70,
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: '1.8px solid black',
                borderBottom: '1.8px solid black',
                borderLeft: '1.8px solid black',
              }}>
              <Text style={{ fontSize: 13, fontFamily: 'Helvetica-Bold' }}>{'Drawing Rev.'}</Text>
            </View>
            <View
              style={{
                width: 180,
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: '1.8px solid black',
                borderBottom: '1.8px solid black',
                borderLeft: '1.8px solid black',
              }}>
              <Text style={{ fontSize: 13, fontFamily: 'Helvetica-Bold' }}>{'Description'}</Text>
            </View>
            <View
              style={{
                width: 140,
                justifyContent: 'center',
                alignItems: 'center',
                borderTop: '1.8px solid black',
                borderLeft: '1.8px solid black',
                borderBottom: '1.8px solid black',
                borderRight: '1.8px solid black',
              }}>
              <Text style={{ fontSize: 13, fontFamily: 'Helvetica-Bold' }}>{'Remarks'}</Text>
            </View>
          </View>
          {rev && rev.length > 0 && rev.map((item, index1) => {
            return (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View
                  style={{
                    width: 100,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: '1.8px solid black',
                    borderLeft: '1.8px solid black'
                  }}>
                  <Text style={{ fontSize: 12 }}>{moment(item?.prepared_date ? item.prepared_date : '').format("DD/MM/YYYY")}</Text>
                </View>
                <View
                  style={{
                    width: 70,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: '1.8px solid black',
                    borderLeft: '1.8px solid black'
                  }}>
                  <Text style={{ fontSize: 12 }}>{item?.rev_no ? item.rev_no : ''}</Text>
                </View>
                <View
                  style={{
                    width: 70,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: '1.8px solid black',
                    borderLeft: '1.8px solid black'
                  }}>
                  <Text style={{ fontSize: 12 }}>{item?.drg_rev ? item.drg_rev : ''}</Text>
                </View>
                <View
                  style={{
                    width: 180,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: '1.8px solid black',
                    borderLeft: '1.8px solid black'
                  }}>
                  <Text style={{ fontSize: 12 }}>{item?.description ? item.description : ''}</Text>
                </View>
                <View
                  style={{
                    width: 140,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom: '1.8px solid black',
                    borderLeft: '1.8px solid black',
                    borderRight: '1.8px solid black'
                  }}>
                  <Text style={{ fontSize: 12 }}>{item?.remarks ? item.remarks : ''}</Text>
                </View>
              </View>
            )
          }
          )}

        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
          <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, borderBottom: '1.8px solid black' }}>
            <Text gutterBottom style={{ fontSize: 20, textDecorationLine: 'underline', fontWeight: 'bolder', fontFamily: 'Helvetica-Bold' }} >
              {"Details"}
            </Text>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 40, paddingTop: 40 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <li style={{ flex: 3, marginTop: 5, marginBottom: 5 }} >
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Tender Prepared'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{userdetails?.acceptance_date ? userdetails?.acceptance_date : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Prepared by'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{userdetails?.preparedby ? userdetails?.preparedby : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Council'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{userdetails?.councile ? userdetails?.councile : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Developer Approval'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{userdetails?.developer_approval ? userdetails?.developer_approval : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Developer Contact'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{userdetails?.developer_condact ? userdetails?.developer_condact : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Land Registration'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{userdetails?.landregistration ? userdetails?.landregistration : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Building Contract'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{userdetails?.building_contract ? userdetails?.building_contract : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Development Application'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{userdetails?.development_application ? userdetails?.development_application : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Owner Details'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <View style={{ flex: 4 }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{project?.client?.name ? project?.client?.name : ''}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{`${project?.client?.street ? project?.client?.street : ''}, ${project?.client?.suburb ? project?.client?.suburb : ''}, ${project?.client?.zip ? ('NSW-' + project?.client?.zip) : ''}`}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{project?.client?.contact ? project?.client?.contact : ''}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{project?.client?.email ? project?.client?.email : ''}</Text>
                    {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                      <>
                        <Text style={{ fontFamily: 'Helvetica', fontSize: 12, }}>{item?.first_name + ' ' + item?.last_name}</Text>
                        <Text style={{ fontFamily: 'Helvetica', fontSize: 12, }}>{item?.map_address ? item?.map_address : ''}</Text>
                        <Text style={{ fontFamily: 'Helvetica', fontSize: 12, }}>{item?.contact ? item?.contact : ''}</Text>
                        <Text style={{ fontFamily: 'Helvetica', fontSize: 12, }}>{item?.email ? item?.email : ''}</Text>
                      </>
                    ))
                    }
                  </View>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'To Construct'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{project?.description ? project?.description : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 5 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Address'}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 4 }}>{`${project?.street ? project?.street : ''}, ${project?.client?.suburb ? project?.suburb : ''}, ${project?.zip ? ('NSW-' + project?.zip) : ''}`}</Text>
                </View>
              </li>
            </div>
          </ul>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 40,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, borderBottom: '1.8px solid black' }}>
            <Text gutterBottom style={{ fontSize: 20, textDecorationLine: 'underline', fontWeight: 'bolder', fontFamily: 'Helvetica-Bold' }} >
              {"General Specifications"}
            </Text>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 60, paddingTop: 40 }}>
            {rows && rows.length > 0 && rows.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ flex: 3, marginTop: 5, marginBottom: 5 }} >
                  <View gutterBottom style={{ display: 'flex', flexDirection: 'row' }} >
                    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20 }}>{'.'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{projectMaterial.name}</Text>
                  </View>
                </li>
              </div>
            ))}
          </ul>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, borderBottom: '1.8px solid black' }}>
            <Text gutterBottom style={{ fontSize: 20, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder', }} >
              {"Site Cost"}
            </Text>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 60, paddingTop: 30 }}>
            {sitecost && sitecost.length > 0 && sitecost.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ flex: 3, marginTop: 10, marginBottom: 10 }} >
                  <Text gutterBottom style={{ fontFamily: 'Helvetica-Bold', fontSize: 13 }} >
                    {projectMaterial.name}
                  </Text>
                  <ul style={{ listStyleType: "lower-roman", marginTop: 10 }}>
                    {projectMaterial.children && projectMaterial.children.length > 0 &&
                      projectMaterial.children.map((item, index2) => (
                        <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginLeft: 20, marginTop: 3, marginBottom: 3 }} >
                          <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20, flex: .1, marginRight: 3 }}>{'.'}</Text>
                          <View style={{ flex: 6 }}>
                            <Text style={{ fontWeight: 'bold', fontFamily: 'newfont', fontSize: 12, }}>{item.name}</Text>
                          </View>
                          <Text style={{ fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 1, marginLeft: 5 }}>{'included'}</Text>
                        </View>
                      ))}
                  </ul>
                </li>
              </div>
            ))}
            <View style={{ flex: 1, borderTop: '1px solid black', }}></View>
            <View style={{ alignSelf: 'flex-end', marginBottom: 20, paddingTop: 10 }}>
              <Text style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', fontSize: 15 }}>
                {`TOTAL SITE COST :${formatter.format(site_cost ? site_cost : 0)}`}
              </Text>
            </View>

          </ul>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, borderBottom: '1.8px solid black' }}>
            <Text gutterBottom style={{ fontSize: 20, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder' }} >
              {"Estate Inclusion"}
            </Text>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 60, paddingTop: 40 }}>
            {estateinclusion && estateinclusion.length > 0 && estateinclusion.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ flex: 3, marginTop: 5, marginBottom: 5 }} >
                  <View gutterBottom style={{ display: 'flex', flexDirection: 'row' }} >
                    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20 }}>{'.'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{projectMaterial.name}</Text>
                  </View>
                </li>
              </div>
            ))}
            <View style={{ flex: 1, borderTop: '1px solid black', marginTop: 10 }}></View>
            {Upgrades && Upgrades.length > 0 &&
              <>
                <View style={{ borderBottom: '1px solid black', paddingBottom: 20, marginTop: 40 }}>
                  <Text gutterBottom style={{ fontSize: 20, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder' }} >
                    {"Upgrades"}
                  </Text>
                </View>
                {Upgrades && Upgrades.length > 0 && Upgrades.map((projectMaterial, index1) => (
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10 }}>
                    <li style={{ flex: 3, marginTop: 5, marginBottom: 5 }} >
                      <View gutterBottom style={{ display: 'flex', flexDirection: 'row' }} >
                        <Text style={{ fontWeight: 'bold', fontSize: 12, width: 350 }}>{projectMaterial.name}</Text>
                        <Text style={{ fontWeight: 'bold', fontSize: 15, width: 50 }}>{'='}</Text>
                        <Text style={{ fontWeight: 'bold', fontSize: 12, width: 150 }}>{projectMaterial.price}</Text>
                      </View>
                    </li>
                  </div>
                ))}

                <View style={{ flex: 1, borderTop: '1px solid black', }}></View>
                <View style={{ alignSelf: 'flex-end', marginBottom: 20, paddingTop: 10 }}>
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', fontSize: 15 }}>
                    {`TOTAL UPGRADE :${formatter.format(Upgrades_cost ? Upgrades_cost : 0)}`}
                  </Text>
                </View>
              </>}

          </ul>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, borderBottom: '1.8px solid black' }}>
            <Text gutterBottom style={{ fontSize: 20, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder' }} >
              {"Authority Conditions"}
            </Text>
          </View>
          <View style={{ fontSize: 20, paddingLeft: 50, paddingRight: 60, paddingTop: 50 }}>
            {authority && authority.length > 0 && authority.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ marginTop: 5, marginBottom: 5 }} >
                  <View gutterBottom style={{ display: 'flex', flexDirection: 'row' }} >
                    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20 }}>{'.'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{projectMaterial.name}</Text>
                  </View>
                </li>
              </div>
            ))}
            {note && note.length > 0 && note.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ marginTop: 5, marginBottom: 5 }} >
                  <View gutterBottom style={{ display: 'flex', flexDirection: 'row', paddingRight: 35 }} >
                    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20 }}>{'.'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 10 }}>{'Notes :'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 5 }}>{projectMaterial.name}</Text>
                  </View>
                </li>
              </div>
            ))}
            <View style={{ flex: 1, borderTop: '1px solid black', marginTop: 10 }}></View>
            <View style={{ alignSelf: 'flex-end', marginBottom: 20, paddingTop: 10 }}>
              <Text style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', fontSize: 15 }}>
                {`TOTAL AUTHORITY COST :${formatter.format(authoritycost ? authoritycost : 0)}`}
              </Text>
            </View>
          </View>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, borderBottom: '1.8px solid black' }}>
            <Text gutterBottom style={{ fontSize: 20, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder', }} >
              {"Please Note"}
            </Text>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 60, paddingTop: 40 }}>
            {Notes && Notes.length > 0 && Notes.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ flex: 3, marginTop: 5, marginBottom: 5 }} >
                  <View gutterBottom style={{ display: 'flex', flexDirection: 'row', textAlign: 'justify' }} >
                    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20 }}>{'.'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{projectMaterial.name}</Text>
                  </View>
                </li>
              </div>
            ))}
          </ul>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
          <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
        </View>
        <View style={classes.section}>
          <View gutterBottom style={{ paddingLeft: 60, paddingRight: 60, paddingTop: 15 }} >
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{project?.client?.name ? project?.client?.name : ''}</Text>
              {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                <>
                  <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{' & '}</Text>
                  <Text style={{ fontFamily: 'Helvetica', fontSize: 12, }}>{item?.first_name + ' ' + item?.last_name}</Text>
                </>
              ))
              }
              {/* <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{userdetails?.name_2 ? '&' : ''}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{userdetails?.name_2 ? userdetails?.name_2 : ''}</Text> */}
            </View>
            <View style={{}}>
              {/* <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{project?.client?.name ? project?.client?.name : ''}</Text> */}
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{`${project?.client?.street ? project?.client?.street : ''}, ${project?.client?.suburb ? project?.client?.suburb : ''}, ${project?.client?.zip ? ('NSW-' + project?.client?.zip) : ''}`}</Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'phone:'}</Text>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{project?.client?.contact ? project?.client?.contact : ''}</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'Email:'}</Text>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{project?.client?.email ? project?.client?.email : ''}</Text>
              </View>
              {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                <>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'phone:'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{item?.contact ? item?.contact : ''}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'Email:'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{item?.email ? item?.email : ''}</Text>
                  </View>
                </>
              ))
              }
            </View>
            {/* <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'phone:'}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{userdetails?.phone ? userdetails?.phone : ''}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'Email:'}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{userdetails?.email ? userdetails?.email : ''}</Text>
            </View> */}
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{`Dear ${project?.client?.name ? project?.client?.name : ''}`}</Text>
              {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                <>
                  <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{userdetails?.name_2 ? ' & ' : ''}</Text>
                  <Text style={{ fontFamily: 'Helvetica', fontSize: 12, }}>{item?.first_name + ' ' + item?.last_name}</Text>
                </>))
              }
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 11.5, marginTop: 10 }}>
              {`We thank you for the opportunity of presenting this tender to construct your new single occupancy double storey Nexa Home at ${project?.street ? project?.street : ''}, ${project?.client?.suburb ? project?.suburb : ''}, ${project?.zip ? ('NSW-' + project?.zip) : ''}`}
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 10 }}>
              {`This tender supersedes all previous tenders and offers. It is based upon our recent site inspection, known council and statutory authority requirements and the disclosure of all related information about your land including restrictions and/or covenants.`}
            </Text>
            <View style={{ marginTop: 10, marginLeft: 10 }}>
              {costarray && costarray.length > 0 && costarray.map((projectMaterial, index) => {

                return (
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View
                      style={{
                        borderTop: index === 0 && '1.8px solid black',
                        borderBottom: '1.8px solid black',
                        borderLeft: '1.8px solid black',
                        width: 150,
                        paddingLeft: 5,
                        justifyContent: 'center'
                      }}>
                      <Text style={{ fontWeight: 'bold', fontSize: 11 }}>{projectMaterial?.name ? projectMaterial.name : ''}</Text>
                    </View>
                    <View
                      style={{
                        borderTop: index === 0 && '1.8px solid black',
                        borderBottom: '1.8px solid black',
                        borderLeft: '1.8px solid black',
                        borderRight: '1.8px solid black',
                        width: 150,
                        paddingRight: 5,
                        justifyContent: 'center',
                        alignItems: 'flex-end'
                      }}>
                      <Text style={{ fontWeight: 'bold', fontSize: 11, }}>{formatter.format(projectMaterial?.MaincategorySum ? projectMaterial?.MaincategorySum : 0)}</Text>
                    </View>
                  </View>
                )
              })
              }
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View
                  style={{
                    borderBottom: '1.8px solid black',
                    borderLeft: '1.8px solid black',
                    width: 150,
                    paddingLeft: 5,
                    justifyContent: 'center'
                  }}>
                  <Text style={{ fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: 11 }}>{'Total cost to Construct'}</Text>
                </View>
                <View
                  style={{
                    borderBottom: '1.8px solid black',
                    borderLeft: '1.8px solid black',
                    borderRight: '1.8px solid black',
                    width: 150,
                    paddingRight: 5,
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                  }}>
                  <Text style={{ fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: 11, }}>{formatter.format(grandtotal ? grandtotal + (Upgrades_cost ? Upgrades_cost : 0) : 0)}</Text>
                </View>
              </View>
            </View>
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5, }}>{'All our prices are GST inclusive'}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5, }}>{'Thev tender price will remain fixed for 180 days provided that,within 7 days from the original tender date you have paid an acceptance fee and signed the tender.Upon your acceptance of this tender, we will:'}</Text>
              <View style={{ marginLeft: 10, marginTop: 10 }}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold', fontSize: 11, }}>{'•	Prepare detailed plans for authority approval.'}</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold', fontSize: 11, }}>{'•	Prepare DA / CDC Documents'}</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold', fontSize: 11, }}>{'•	Obtain Developer approval (if applicable)During the DA / CDC approval process, we would expect that the following will occur: -'}</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold', fontSize: 11, }}>{'•	Electrical Layout, AC Zoning, Cabinetry deign and Internal colour selections are completed by you with our design team'}</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold', fontSize: 11, }}>{' •	Any Council Section 94 contributions, deposits/bonds required are to be paid by you (if applicable)'}</Text>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5, }}>
                  {' Upon CDC / Construction Certificate an Authority to Commence Construction Letter will need to be obtained by you from your lending institution, confirming that security documentation is in place and construction of your home may commence, alternatively, should you be financing without a lending institution, then you will be required to provide proof of your capacity to pay the contract value (this is required prior to commencing construction work on site)'}
                </Text>
              </View>
              <View style={{ marginTop: 5 }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5, }}>
                  {'Once again, we would like to thank you for the opportunity to present this tender for the construction of your new home and trust that it meets with your satisfaction. We take great pride in the homes we build, their quality and design, and we are confident that you too will be extremely proud of your new Nexa Home.'}
                </Text>
              </View>
              <View style={{ marginTop: 5 }}>
                <Text style={{ fontWeight: 'bold', fontSize: 12, }}>
                  {'Yours Sincerely,'}
                </Text>
                <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 35 }}>
                  {'Nesa Services Pty Ltd'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, borderBottom: '1.8px solid black' }}>
            <Text gutterBottom style={{ fontSize: 20, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder' }} >
              {"Luxury Specification"}
            </Text>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 70 }}>
            {luxuryspec && luxuryspec.length > 0 && luxuryspec.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ flex: 3, marginTop: 10, marginBottom: 10 }} >
                  <Text gutterBottom style={{ fontFamily: 'Helvetica-Bold', fontSize: 13 }} >
                    {projectMaterial.name}
                  </Text>
                  <ul style={{ listStyleType: "lower-roman", marginTop: 10 }}>
                    {projectMaterial.children && projectMaterial.children.length > 0 &&
                      projectMaterial.children.map((item, index2) => (
                        <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginLeft: 20, marginTop: 3, marginBottom: 3 }} >
                          <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20, marginRight: 3 }}>{'.'}</Text>
                          <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{item.name}</Text>
                        </View>
                      ))}
                  </ul>
                </li>
              </div>
            ))}
          </ul>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, paddingRight: 60 }}>
            <Text gutterBottom style={{ fontSize: 18, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder' }} >
              {"Councile/Authority Fees And Application Requirements"}
            </Text>
            <View style={{ flex: 1, borderTop: '1.5px solid black', marginTop: 7 }}></View>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 60, paddingTop: 20 }}>
            {auth_coun_fee && auth_coun_fee.length > 0 && auth_coun_fee.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ flex: 3, marginTop: 5, marginBottom: 5 }} >
                  <View gutterBottom style={{ display: 'flex', flexDirection: 'row' }} >
                    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20 }}>{'.'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{projectMaterial.name}</Text>
                  </View>
                </li>
              </div>
            ))}
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 8 }}>
              {'Nexa Homes considers safety in every aspect of your home, from design through to handover. During the construction phase, all components, including those that are at a height  of 3.0 meters or more, can be completed safely due to Nexa Homes strict policies, work methods and safety controls. However, once the finished house is handed over to the client, Nexa Homes can no longer ensure the safety of any person that needs to maintain any components (for example, but not limited to, light fittings, fixtures, antennas or gutter cleaning). The client must be aware that the maintenance of any components that are at a height of 3.0 meters or more, will require the implementation of adequate safety control measures and the service of a qualified person that is trained in safe work at heights.'}
            </Text>

          </ul>
        </View>
        <View style={classes.section}>
          <View style={{ textAlign: 'center', marginTop: 30, paddingLeft: 60, paddingRight: 60 }}>
            <Text gutterBottom
              style={{ fontSize: 18, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder', alignSelf: 'flex-start' }} >
              {"Process Flow"}
            </Text>
            <View style={{ flex: 1, borderTop: '1.5px solid black', marginTop: 7 }}></View>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 80, paddingRight: 50, paddingTop: 15 }}>
            {process_flow && process_flow.length > 0 && process_flow.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ flex: 3, marginTop: 3, marginBottom: 3 }} >
                  <View gutterBottom style={{ display: 'flex', flexDirection: 'row' }} >
                    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20 }}>{'.'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{projectMaterial.name}</Text>
                  </View>
                </li>
              </div>
            ))}
          </ul>
        </View>
        <View style={classes.section}>
          <View style={{ marginTop: 15, paddingLeft: 60, paddingRight: 60 }}>
            <Text gutterBottom
              style={{ fontSize: 18, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder', alignSelf: 'flex-start' }} >
              {"Construction Period"}
            </Text>
            <View style={{ flex: 1, borderTop: '1.5px solid black', marginTop: 7 }}></View>
          </View>

          <View style={{ paddingLeft: 60, paddingRight: 60 }}>
            <Text gutterBottom
              style={{ fontSize: 12, marginTop: 25, fontWeight: 'bolder' }} >
              {`${construction_period ? construction_period : ''} from the Construction Certificateand Commencement Letter from bank. Following events, as applicable, to be added to the above construction period.`}
            </Text>
            <View style={{ marginLeft: 10 }}>
              <Text gutterBottom
                style={{ fontSize: 12, textDecorationLine: 'underline', fontWeight: 'bolder', alignSelf: 'flex-start' }} >
                {"•	Rainy days (each rainy day is equivalent to 3 workingdays). "}
              </Text>
              <Text gutterBottom
                style={{ fontSize: 12, textDecorationLine: 'underline', fontWeight: 'bolder', alignSelf: 'flex-start' }} >
                {"•	Any work stoppage due to legislation."}
              </Text>
              <Text gutterBottom
                style={{ fontSize: 12, textDecorationLine: 'underline', fontWeight: 'bolder', alignSelf: 'flex-start' }} >
                {"•	Any work stoppage due to reasons solely attributable to client"}
              </Text>
            </View>
            <Text gutterBottom
              style={{ fontSize: 12, textDecorationLine: 'underline', fontWeight: 'bolder', alignSelf: 'flex-start' }} >
              {"Nexa Homes will claim Extension of Time if the progress payments are delayedfor more than 7business days."}
            </Text>
            <Text
              style={{ fontSize: 12, fontWeight: 'bolder', marginTop: 5 }} >
              {`•	Nexa Homes is of the understanding that there is no special building covenants pursuance to section 88B of the conveyancing act 1919 unless stated in this tender. The owner is to disclose all information regarding the site and any special conditions noted in the land contract, Any requirements as a result of the 88B instrument is to be client’s responsibility. The client acknowledges and agrees that no allowance has been made for any traffic report, road closure, traffic control, after hour’s delivery, special transport or additional persons and equipment required on certain roads. This is up to the local council, RTA and individual suppliers and can’t be determined until the DA or CC is received or when construction commences. If this is required, this will be an expense to the owner by way of variation to the contract.`}
            </Text>
          </View>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 50,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, borderBottom: '1.8px solid black' }}>
            <Text gutterBottom style={{ fontSize: 20, fontFamily: 'Helvetica-Bold', textDecorationLine: 'underline', fontWeight: 'bolder', }} >
              {"Terms And Conditions"}
            </Text>
          </View>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 60, paddingTop: 40, justifyContent: 'center' }}>
            {TAC && TAC.length > 0 && TAC.map((projectMaterial, index1) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <li style={{ flex: 3, marginTop: 5, marginBottom: 5 }} >
                  <View gutterBottom style={{ display: 'flex', flexDirection: 'row', textAlign: 'justify' }} >
                    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: -20 }}>{'.'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{projectMaterial.name}</Text>
                  </View>
                </li>
              </div>
            ))}
          </ul>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/construction stage 2.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 70,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.jpg'} style={{ height: 140 }} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, marginBottom: -45, marginLeft: 60 }}>{` Date:${moment(Date.now()).format("DD/MM/YYYY")}`}</Text>
          {/* <View  style={{ paddingLeft: 60, paddingRight: 60, paddingTop: 20, alignItems: 'flex-end' }} >
            <View style={{ display: 'flex' }}>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{userdetails?.name ? userdetails?.name : ''}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, alignSelf: 'center' }}>{userdetails?.name_2 ? '&' : ''}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{userdetails?.name_2 ? userdetails?.name_2 : ''}</Text>
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 12, width: 150 }}>{userdetails?.address ? userdetails?.address : ''}</Text>
            <View style={{ display: 'flex', width: 150 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>
                {'phone:'}{userdetails?.phone ? userdetails?.phone : ''}{userdetails?.phone_2 ? userdetails?.phone_2 : ''}
              </Text>
            </View>
            <View style={{ display: 'flex', width: 150 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>
                {userdetails?.email ? userdetails?.email : ''}{userdetails?.email_2 ? userdetails?.email_2 : ''}
              </Text>
            </View>
          </View> */}
          <View style={{ paddingLeft: 60, paddingRight: 30, paddingTop: 20, alignItems: 'flex-end' }}>
            <View style={{ display: 'flex', flexDirection: 'column', width: 150 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{project?.client?.name ? project?.client?.name : ''}</Text>
              {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                <>
                  <Text style={{ fontWeight: 'bold', fontSize: 11.5, marginLeft: 30 }}>{' & '}</Text>
                  <Text style={{ fontFamily: 'Helvetica', fontSize: 12, }}>{item?.first_name + ' ' + item?.last_name}</Text>
                </>
              ))
              }
              {/* <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{userdetails?.name_2 ? '&' : ''}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{userdetails?.name_2 ? userdetails?.name_2 : ''}</Text> */}
            </View>
            <View style={{ width: 150 }}>
              {/* <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{project?.client?.name ? project?.client?.name : ''}</Text> */}
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{`${project?.client?.street ? project?.client?.street : ''}, ${project?.client?.suburb ? project?.client?.suburb : ''}, ${project?.client?.zip ? ('NSW-' + project?.client?.zip) : ''}`}</Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'phone:'}</Text>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{project?.client?.contact ? project?.client?.contact : ''}</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{''}</Text> */}
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{`Email : ${project?.client?.email ? project?.client?.email : ''}`}</Text>
              </View>
              {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                <>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'phone:'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{item?.contact ? item?.contact : ''}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{`Email : ${item?.email ? item?.email : ''}`}</Text>
                  </View>
                </>
              ))
              }
            </View>
          </View>

          <View gutterBottom style={{ paddingLeft: 60, paddingRight: 60, paddingTop: 20, alignItems: 'flex-start' }} >
            <View style={{ display: 'flex', marginTop: 10 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{'Nesa Services Pty Ltd '}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, alignSelf: 'center' }}>{' 4.01/5 Celebration Drive'}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{'Bella Vista'}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{'NSW 2153'}</Text>
            </View>

            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 30 }}>{'LETTER OF AUTHORITY'}</Text>
            <View style={{ textAlign: 'justify' }}>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 30 }}>{'I/We hereby give M/s NesaServices Pty Ltd the authority to submit a Development Application, Construction Certificate Application and any other Applications required by the appropriate Council on our behalf.'}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 10 }}>{'I/We also give consent for authorised Council officers to enter the land to carry out inspections.'}</Text>
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>{`Building Address: ${project?.description}`}</Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 20 }}>{'Name : '}{project?.client?.name ? project?.client?.name : ''}</Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>{'Signed :'}</Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>{'Date :'}</Text>
            {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
              <View>
                <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 30 }}>{'Name : '}{item?.first_name && item?.last_name ? item?.first_name + item?.last_name : ''}</Text>
                <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>{'Signed :'}</Text>
                <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>{'Date :'}</Text>
              </View>
            ))
            }
            {/* {userdetails?.name_2 ?
              <View>
                <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 30 }}>{'Name :'}{userdetails?.name_2 ? userdetails?.name_2 : ''}</Text>
                <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>{'Signed :'}</Text>
                <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>{'Date :'}</Text>
              </View>
              :
              <View></View>} */}

          </View>

        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 25,
      }}>
        <View fixed style={{ marginBottom: 10, marginTop: 5 }}>
          <Image style={{ height: 90 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 60, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <ul style={{ fontSize: 20, paddingLeft: 60, paddingRight: 40, paddingTop: 20 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <li style={{ flex: 3, marginTop: 4, marginBottom: 4 }} >
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Given Names'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{project?.client?.name ? project?.client?.name : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Telephone (Mobile)'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{project?.client?.contact ? project?.client?.contact : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Email Address'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{project?.client?.email ? project?.client?.email : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Postal Address'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{`${project?.client?.street ? project?.client?.street : ''}, ${project?.client?.suburb ? project?.client?.suburb : ''}, ${project?.client?.zip ? ('NSW-' + project?.client?.zip) : ''}`}</Text>
                </View>
                {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                  <>
                    <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 5 }} >
                      <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Given Names'}</Text>
                      <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                      <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{item?.first_name && item?.last_name ? item?.first_name + item?.last_name : ''}</Text>
                    </View>
                    <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                      <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Telephone (Mobile)'}</Text>
                      <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                      <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{item?.contact ? item?.contact : ''}</Text>
                    </View>
                    <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                      <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Email Address'}</Text>
                      <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                      <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{item?.email ? item?.email : ''}</Text>
                    </View>
                    <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                      <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Postal Address'}</Text>
                      <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                      <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{item?.map_address ? item?.map_address : ''}</Text>
                    </View>
                  </>
                ))
                }

                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Electricity Supplier:'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.electricity_supplier ? additional_details.electricity_supplier : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 5 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Electricity Supplier Account Number'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.electricity_supplier_account_number ? additional_details.electricity_supplier_account_number : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 5 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Communication Supplier'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.communication_supplier ? additional_details.communication_supplier : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our D.P. Number is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.dp_number ? additional_details.dp_number : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our Solicitor is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.solicitor ? additional_details.solicitor : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our Solicitors Address is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.solicitors_address ? additional_details.solicitors_address : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our Solicitors Phone Number is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.solicitors_phone_number ? additional_details.solicitors_phone_number : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our Finance Source is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.finance_source ? additional_details.finance_source : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our Finance Source Address is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.finance_source_address ? additional_details.finance_source_address : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our Finance Source Contact is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.finance_source_contact ? additional_details.finance_source_contact : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our Finance Source Phone No. is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.finance_source_phone ? additional_details.finance_source_phone : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our Finance Type is'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.finance_type ? additional_details.finance_type : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'My/Our copy of Title Deeds'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.copy_of_title_deeds ? additional_details.copy_of_title_deeds : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Payment method'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.Electricity ? additional_details.Electricity : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Account Name'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.account_name ? additional_details.account_name : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'BSB'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.BSB ? additional_details.BSB : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 3, marginBottom: 3 }} >
                  <Text style={{ fontWeight: 'bolder', fontSize: 12, flex: 2 }}>{'Account Number'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.account_number ? additional_details.account_number : ''}</Text>
                </View>
                <View gutterBottom style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 3 }} >
                  <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, flex: 2 }}>{'Accepted By'}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: .4 }}>{' : '}</Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12, flex: 4 }}>{additional_details?.acceptedby ? additional_details.acceptedby : ''}</Text>
                </View>
              </li>
            </div>
          </ul>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      <Page size="A4" style={{
        paddingBottom: 30,
      }}>
        <View fixed style={{ marginBottom: 10 }}>
          <View fixed style={{ marginTop: 10, marginBottom: 10 }}>
            <Image style={{ height: 100 }} src={process.env.PUBLIC_URL + '/nexa_header1.jpg'} />
          </View>
          <Text gutterBottom style={{ fontSize: 12, marginLeft: 35, marginTop: 10, fontFamily: 'Helvetica-Bold' }} >
            {project?.description ? project.description : ''}
          </Text>
        </View>
        <View style={classes.section}>
          <View style={{ paddingLeft: 60, paddingRight: 60, paddingTop: 10, alignItems: 'flex-end' }}>
            <View style={{ display: 'flex', flexDirection: 'column', width: 150 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{project?.client?.name ? project?.client?.name : ''}</Text>
              {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                <>
                  <Text style={{ fontWeight: 'bold', fontSize: 11.5, marginLeft: 30 }}>{' & '}</Text>
                  <Text style={{ fontFamily: 'Helvetica', fontSize: 12, }}>{item?.first_name + ' ' + item?.last_name}</Text>
                </>
              ))
              }
            </View>
            <View style={{ width: 150 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{`${project?.client?.street ? project?.client?.street : ''}, ${project?.client?.suburb ? project?.client?.suburb : ''}, ${project?.client?.zip ? ('NSW-' + project?.client?.zip) : ''}`}</Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'phone:'}</Text>
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{project?.client?.contact ? project?.client?.contact : ''}</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {/* <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{''}</Text> */}
                <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{`Email:${project?.client?.email ? project?.client?.email : ''}`}</Text>
              </View>
              {project && project.secondary_clients && project.secondary_clients.length > 0 && project.secondary_clients.map((item) => (
                <>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{'phone:'}</Text>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{item?.contact ? item?.contact : ''}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 11.5 }}>{`Email:${item?.email ? item?.email : ''}`}</Text>
                  </View>
                </>
              ))
              }
            </View>
          </View>
          <View gutterBottom style={{ paddingLeft: 35, paddingRight: 25, paddingTop: 10, alignItems: 'flex-start' }} >
            <View style={{ display: 'flex', marginTop: 10 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{'Nesa Services Pty Ltd '}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, alignSelf: 'center' }}>{' 4.01/5 Celebration Drive'}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{'Bella Vista'}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{'NSW 2153'}</Text>
            </View>
            <View style={{ marginTop: 15, padding: 10, borderBottom: '1.8px solid black', borderTop: '1.8px solid black', alignSelf: 'center' }}>
              <Text style={{ fontWeight: 'bold', fontFamily: 'Helvetica-Bold', fontSize: 15 }}>
                {'ACCEPTANCE OF TENDER AND INSTRUCTION FOR PREPARATION OF DEVELOPMENT APPLICATION PLANS'}
              </Text>
            </View>
            <View>
              <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, marginTop: 10 }}>{`Construction of Dwelling at:${project?.description}`}</Text>
              <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, marginTop: 15 }}>{`Tender Date:${moment(userdetails?.prepared_date).format("DD/MM/YYYY")}`}</Text>
              <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12, marginTop: 15 }}>{`Tender Price: ${formatter.format(grandtotal ? grandtotal + (Upgrades_cost ? Upgrades_cost : 0) : 0)}`}</Text>
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>
              {`I/We hereby accept your tender for the construction of our new home at the above address. We have initialed and attached the tender and any plans to this acceptance.`}
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>
              {`I/We here by acknowledge that this tender price will remain fixed for a period of 180 days from the tender date `}
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>
              {`I/We hereby acknowledge that in the event that Nexa Homes has been unable to  commence  construction by above mentioned date, Nexa Homes reserves the right to serve a variation on you for $1,250 for every month, that Nexa Homes is delayed in commencing the building works on site.`}
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>
              {`You are hereby authorized to prepare all necessary documentation for submission to the Authorities for approval, including architectural working drawings, structural engineer design and  specifications`}
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>
              {`As consideration for the above I/We enclose a tender acceptance fee of $950/- and further 5% of this building tender value (minus $950/- above) which will be considered as due on the date of signing the HIA Contract, to the bank details provided in previous page. These fees form part of the contract price. I/We acknowledge that these fees do not cover Council Section 94 Contributions, damage deposits/bonds, which will be my/our responsibility to pay.`}
            </Text>
            <Text style={{ fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>
              {`Should I/We not proceed to construction for any reason, I/We acknowledge that the preparation, acceptance fee will not be refunded as these fees will have been expended to preparedocumentationforthesubmissionandapprovalofourDevelopmentApplication.I/Wealso acknowledge that if we do not proceed with our new Nexa Home that any additional costs incurredbyNexaHomes in providing the above items is payable to Nexa Homes.`}
            </Text>
            <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 11, marginTop: 15 }}>
              {`Accepted by:`}
            </Text>
            <View style={{ flexDirection: 'row', marginTop: 40 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 10, marginRight: 5 }}>
                {`Client Name`}
              </Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 5, marginRight: 5 }}>
                {`Signature`}
              </Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 5, marginRight: 5 }}>
                {`Date`}
              </Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 40, marginRight: 5 }}>
                {`Client Name`}
              </Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 5, marginRight: 5 }}>
                {`Signature`}
              </Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12, marginLeft: 5, marginRight: 10 }}>
                {`Date`}
              </Text>
            </View>
          </View>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/latter head nexa homes 1.1.1.jpg'} />
        </View>
      </Page>
      {displayFiles && displayFiles.length > 0 && displayFiles.map((item) => {
        return (
          <Page size="A4" style={{
            paddingBottom: 40,
            paddingTop: 40,
            paddingLeft: 10, paddingRight: 10
          }}>
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Image src={item?.file_location} />
            </View>
          </Page>
        )
      })}
    </Document >
  );
  if (!userdetails && rows.length < 1 && !rev) return null;
  if (loading) return <Loading loadingPrimary="Creating Project..." loadingSecondary="" />;
  return (
    <>
      <Button
        variant="outlined"
        startIcon={<PrintIcon />}
        style={{ marginTop: 10, marginBottom: 10 }}
        className={classes.deleteButton}
      >
        <PDFDownloadLink
          document={<MyDocument />}
          fileName="Tender.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
        </PDFDownloadLink>
      </Button>
      <PDFViewer style={{ width: '80vw', height: '160vh' }}>
        <MyDocument />
      </PDFViewer>

    </>
  )
}



const useStyles = makeStyles({
  tableContainer: {
    marginTop: 15,
    paddingBottom: 15,
    boxShadow: 'none',
    border: '1px solid #ebebeb',
    width: 'auto',
    marginLeft: 10,
  },
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
  table: {
    minWidth: 650,
  },
  deleteButton: {
    border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
  },
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  projectTitle: {
    background: '#282a3c',
    color: '#fff',
    padding: 10,
    fontSize: 15,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    paddingBottom: 40
  },
  section: {
    margin: 10,
    padding: 10,
    flex: 1,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 100,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});


export default MaterialList;
