import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper,Tab,Tabs } from '@material-ui/core';
// import { TextInput } from 'react-admin';

const useStyles = makeStyles((theme) =>({
  paper: {
    background: '#f3f3f3',
    marginTop:20,
    boxShadow:'none',
  },
  tab: {
    minWidth: 130,
    width: 130,
    background: '#f3f3f3',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#fff',
  },
  formField: {
    background: '#fff',
    paddingTop: 20,
  },
  noteField: {
    background: '#fff',
    padding: 10,
    border: '1px solid #ebe1e1',
  }
}));

function NotesTab(props) {
  const [Tabvalue, setTabValue] = useState(0);

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const classes = useStyles();

  return(
    <Paper square className={classes.paper}>
      <Tabs
        value={Tabvalue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        
      >
        <Tab label="All Notes" classes={{ root: classes.tab,selected: classes.selectedTab }}/>
        <Tab label="Sub Notes" classes={{ root: classes.tab,selected: classes.selectedTab }}/>

      </Tabs>

      {(Tabvalue === 0) ? (
        <>
          {/* <TextInput
            source="all_notes"
            disabled
            label=""
            variant="outlined"
            fullWidth={true}
            multiline
            helperText="All Notes Here"
            className={classes.formField}
          /> */}
          <NoteField source="all_notes" record={props.data} />
        </>
      ): (
        null
      )}

      {(Tabvalue === 1) ? (
      //   <TextInput
      //     source="sub_notes"
      //     disabled
      //     label=""
      //     variant="outlined"
      //     fullWidth={true}
      //     multiline
      //     helperText="Sub Notes"
      //     className={classes.formField}
      //  />
        <NoteField source="sub_notes" record={props.data} />
      ): (
        null
      )}

      
    </Paper>
  )
}

const NoteField = ({record,source}) => {
  const classes = useStyles();
  return(
    <div className={classes.noteField}>{record[source]}</div>
  )
};

export default NotesTab;
