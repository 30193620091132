import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  useDataProvider,
  useRedirect,
  Loading,
  TextInput
}
  from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { InputAdornment, Typography, Grid } from '@material-ui/core';


function MaterialEdit({ id }) {
  const [materialDetail, setMaterialDetail] = useState();
  const [loading, setLoading] = useState(true);
  const [unit, setUnit] = useState();
  const [qty, setQty] = useState();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const classes = useStyles();
  const onSave = (data) => {
    data = {cost:data?.cost,
    description:data?.description, 
    quantity:data?.quantity,
    total: data?.total,
    unit: data?.unit,
    projectId: materialDetail?.project };
    dataProvider.newupadte('projectvariation', { data, id: id })
      .then(({ data }) => {
        redirect(`/project-variations/${materialDetail.project}`);
      })
      .catch(error => {
      });
  }

  useEffect(() => {
    dataProvider.getList('unit', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setUnit(data);
      dataProvider.getOne('projectvariation', { id: id })
        .then(({ data }) => {
          setMaterialDetail(data);
          setLoading(false);
        })
        .catch(error => {
          // console.log(error);
          setLoading(false);
        });
    })
      .catch(error => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;
  if (!materialDetail) return null;

  return (
    <SimpleForm save={onSave}>
      <SectionHeading label="Edit Material" classProp={classes.heading} />
      <TextInput
        source="name"
        validate={required()}
        variant="outlined"
        label="Name"
        fullWidth={true}
        disabled={true}
        initialValue={materialDetail?.name?.name}
      />
      <TextInput
        source="description"
        validate={required()}
        variant="outlined"
        label="Description"
        fullWidth={true}
        initialValue={materialDetail.description}
      />
      <GridField value={materialDetail} unit={unit} />
    </SimpleForm>
  )
}
const GridField = (props) => {
  const [unit, setUnit] = useState(props.value.unit);
  const [units, setUnits] = useState(props.unit);
  const [cost, setCost] = useState(props.value.cost);
  const [qty, setQty] = useState(props.value.quantity);
  // useEffect(() => {
  //   dataProvider.getList('maincategory', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
  //     setMaincategory(data);
  //   })
  //     .catch(error => {
  //       setLoading(false);
  //     });
  // }, []);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <NumberInput
          source="cost"
          validate={required()}
          variant="outlined"
          onChange={(e) => setCost(e.target.value)}
          label="Cost"
          fullWidth={true}
          initialValue={props.value.cost}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                AUD
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          source="quantity"
          validate={required()}
          onChange={(e) => setQty(e.target.value)}
          variant="outlined"
          label="Quantity"
          fullWidth={true}
          initialValue={props.value.quantity}

        />
      </Grid>
      <Grid item xs={12} sm={2}>

        <SelectInput
          allowEmpty={true}
          emptyText="--Select unit--"
          variant="outlined"
          label="Unit"
          source="unit"
          fullWidth={true}
          validate={required()}
          initialValue={unit.id}
          choices={units}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumberInput
          disabled={true}
          source="total"
          initialValue={qty * cost ? qty * cost : null}
          editable={false}
          // defaultValue={total}
          validate={required()}
          variant="outlined"
          label="Total"
          fullWidth={true}

        />
      </Grid>
    </Grid>
  );
}
const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles({
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
});

export default MaterialEdit;
