import * as React from "react";
import { Edit, SimpleForm,TextInput,SelectInput  } from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 17,
      padding: 10,
      // fontWeight: 'bold',
      color:'#8e680d',
    }
  },
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const MaterialEdit = props => {
  const classes = useStyles();
  return (
  <Edit {...props}>
      <SimpleForm>
        <SectionHeading label="Edit Material" classes={classes} />
        <TextInput fullWidth={true} source="name" variant="outlined"/>
        <SelectInput
          source="status"
          choices={[
            { id: 1, name: 'Enabled' },
            { id: 0, name: 'Disabled' },
          ]}
          variant="outlined"
          fullWidth={true}
        />
      </SimpleForm>
  </Edit>
)};

export default MaterialEdit;