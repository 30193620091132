/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';
import { Grid, Typography, Button } from '@material-ui/core';
import Create from './Subchecklistcreate';
import Edit from './Subchecklistedit';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {
    FcCheckmark,
} from "react-icons/fc";

function Subchecklist() {
    const dataProvider = useDataProvider();
    const [flag, setFlag] = useState(false);
    const [rows, setRows] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    React.useEffect(() => {
        setLoading(true);
        dataProvider.getList('subchecklist', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
            setRows(data);
            setLoading(false);
        })
            .catch(error => {
                setLoading(false);
            });

    }, [flag]);

    const handleDelete = (data) => {
        dataProvider.delete('subchecklist', { id: data.id }).then(({ data }) => {
            setRows(data);
            setLoading(false);
        })
            .catch(error => {
                setLoading(false);
            });
    };
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={1} direction="row" alignItems="center" className={classes.main}>
                    <Grid item xs={12} sm={12}>
                        <Grid container direction="row" alignItems="center" className={classes.tableheading} >
                            <div className={classes.heading} >
                                <FcCheckmark />
                                <Typography variant="h6" gutterBottom>
                                    Checklist
                                </Typography>
                            </div>
                            <div>
                                <Create setRows={setRows} rows={rows} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left" >Question</TableCell>
                                <TableCell align="left" >Category</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.length > 0 &&
                                rows.map((item) => {
                                    console.log(item);
                                    return (
                                        <TableRow >
                                            <TableCell className={classes.table_cell} ></TableCell>
                                            <TableCell className={classes.table_cell}>{item.question}</TableCell>
                                            <TableCell className={classes.table_cell}>{item?.checklistcategory?.name}</TableCell>
                                            <TableCell className={classes.table_cell} style={{ flexDirection: 'row', display: 'flex', }}>
                                                <Edit setRows={setRows} rows={rows} data={{ id: item?.id, name: item?.question, checklistcategory: item?.checklistcategory?.id, type: item?.type, remarkable: item?.remarkable }} />
                                                <Button onClick={() => handleDelete(item)} className={classes.deleteBtn}>
                                                    <DeleteOutlineIcon style={{ fontSize: 17, marginRight: 2 }} />
                                                    Delete
                                                </Button>
                                            </TableCell>
                                            <TableCell className={classes.table_cell}></TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default Subchecklist;

const useStyles = makeStyles((theme) => ({
    container: {
        background: '#fff',
        margin: 'auto',
        marginTop: 15,
        maxHeight: 600,
    },
    main: {
        width: '100%',
    },
    root: {
        width: '100%',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        //float: 'right',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    heading: {
        padding: 14,
        '& h6': {
            fontSize: 15,
            padding: 10,
            fontWeight: 'bold',
            color: '#e7b02c',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    table_cell: {
        paddingTop: 6,
        paddingBottom: 6
    },
    loader_holder: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    loader: { color: '#e5aa13' },
    tableheading: {
        padding: '0px 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    editBtn: {
        color: '#e5aa13',
        fontWeight: 'bold',
    },
    deleteBtn: {
        color: 'red',
        fontWeight: 'bold',
    },
    memberItem: {
        background: '#ddd',
        display: 'inline-block',
        margin: 5,
        padding: '2px 10px',
        fontSize: '13px',
        borderRadius: '10px',
    },
    emptyClass: {
        padding: 10,
        textAlign: 'center',
        fontStyle: 'italic',
        paddingBottom: 30,
    }
}));