import * as React from "react";
import {
  List,
  Datagrid,
  DatagridBody,
  TextField,
  Filter,
  useNotify,
  useRefresh,
  useDataProvider
} from 'react-admin';
import {
  TextInput as RaTextInput,
  DateInput as RaDateInput,
  SelectInput as RaSelectInput,
  ReferenceArrayInput as RaReferenceArrayInput,
  AutocompleteArrayInput as RaAutocompleteArrayInput,
} from 'react-admin'

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TableRow,
  TableCell,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import ProgressBar from '../components/ProgressBar';
import { Link } from "react-router-dom";

const standardize = Component => props => <Component {...props} variant="outlined" />
const TextInput = standardize(RaTextInput);
const SelectInput = standardize(RaSelectInput);
const DateInput = standardize(RaDateInput);
const ReferenceArrayInput = standardize(RaReferenceArrayInput);
const AutocompleteArrayInput = standardize(RaAutocompleteArrayInput);

const ProjectField = ({ record = {} }) => <span>{record.project.title} {record.project.description}</span>;
ProjectField.defaultProps = { label: 'Project' };

const ProgressField = ({ record = {} }) => (
  <ProgressBar value={(record.progress) ? record.progress : 0} />
)
ProgressField.defaultProps = { label: 'Progress' };

const TextUrlField = ({ record = {}, rowClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.linkWrapper}>
      <Link
        className={record.isOverrun ? classes.linkWrapperOverdue : {}}
        to={`/project-schedule-chart/${record.project.id}?taskId=${record.id}`}
      >
        {record.title}
      </Link>
    </div>
  )
}
TextUrlField.defaultProps = { label: 'Task' };

const CompleteField = ({ record = {}, markComplete }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.formLabel}
      control={
        <Checkbox
          checked={record.completed}
          onChange={() => markComplete(record)}
          name={record.title}
          color="primary"
        />
      }
      label="Completed"
    />
  );
};

CompleteField.defaultProps = { label: 'Complete' };

const ScheduleList = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const markTaskComplete = (task) => {
    task.completed = !task.completed;
    dataProvider.update('task-complete', { id: task.id })
      .then(({ data }) => {
        notify(`${task.title} Marked Complete !`);
        refresh();
      })
      .catch(error => {
        notify('Error Occurred While Changing !', 'warning');
        refresh();
      })
  }

  return (
    <div className={classes.listWrapper}>
      <List {...props}
        exporter={false}
        filters={<TaskFilter />}
        bulkActionButtons={false}
        // sort={{ field: 'title', order: 'ASC' }}
        filterDefaultValues={{ date: getTodayDate() }}
      >
        <MyDatagrid >
          <TextUrlField label="Task" source="title" />
          <TextField label="Start Date" source="startDate" />
          <TextField label="End Date" source="endDate" />
          <ProjectField source="project" />
          <TextField label="Contractors" source="contractors" />
          <ProgressField source="progress" />
          <CompleteField source="status" markComplete={markTaskComplete} />
          {/* <TextField label="Task" source="title" /> */}
          {/* <EditButton source="Action"/> */}
        </MyDatagrid>
      </List>
    </div>
  );
}

const TaskFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Title" variant="outlined" source="title" alwaysOn />
    <SelectInput label ="Project type" source="type" allowEmpty emptyValue={null} choices={[
      { id: 'pre_contract', name: 'PRE CONTRACT' },
      { id: 'design_approval', name: 'DESIGN AND APPROVAL' },
      { id: 'construction', name: 'CONSTRUCTION' },
    ]} variant="outlined"  alwaysOn />
    <ReferenceArrayInput
      reference="user-projects"
      source="projects"
      label="Projects"
      variant="outlined"
      alwaysOn
      resource="user-projects"
      fullWidth={true}
    >
      <AutocompleteArrayInput allowEmpty={false} optionText={optionRenderer} optionValue="id">

      </AutocompleteArrayInput >
    </ReferenceArrayInput >
    <DateInput label="Start Date From" source="date" alwaysOn />
    <DateInput label="Start Date" source="start_date" />
    <DateInput label="End Date" source="end_date" />
    {/* <TextInput label="Title" source="title" alwaysOn defaultValue="Hello, World!" /> */}
  </Filter>
);
const optionRenderer = choice => `${choice.title} (${choice.description})`;

const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => {
  const classes = useStyles();
  return (
    <StyledTableRow key={id} >
      {/* first column: selection checkbox */}
      {/* <TableCell padding="none">
            <Checkbox
                disabled={record.selectable}
                checked={selected}
                onClick={() => onToggleItem(id)}
            />
      </TableCell> */}
      {/* data columns based on children */}
      {React.Children.map(children, field => (
        <TableCell className={classes.rowStyle} key={`${id}-${field.props.source}`} >
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}
    </StyledTableRow>
  )
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

const useStyles = makeStyles({
  rowStyle: {
    padding: '0px 5px',
    '& span': {
      fontSize: 13,
    },
  },
  formLabel: {
    // zIndex: 99,
  },
  listWrapper: {
    margin: '25px 10px',
  },
  linkWrapper: {
    '& a': {
      fontSize: 13,
      textDecoration: 'none',
      color: '#000', //634a08
      //fontWeight: 'bold',
    }
  },
  linkWrapperOverdue: {
    color: 'red !important',
  },
});

const getTodayDate = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
}

export default ScheduleList;
