import React,{useEffect} from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography
}
from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import clsx from 'clsx';
import { useForm } from 'react-final-form';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  fileHolder: {
    // display: 'inline-flex',
    background: '#f3f3f3',
    padding: 10,
    marginBottom: 10,
    marginRight: 10,
    '& a': {
      textDecoration: 'none',
      color: '#514646',
    },
    margin: 5,
    textAlign: 'center',
  },
  formLabel: {
    fontSize: 12,
  },
  fileIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 130,
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  fileTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    padding: '5px 0',
  },
  fileSubTitle: {
    fontSize: 12,
    color: '#322f2f',
  },
});

function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}
export default function FilePreview(props) {

  const classes = useStyles();
  const form = useForm();

  let sourceValue = get(props.record, props.source);
  if(!sourceValue){sourceValue = props.record.url};

  const [permission, setPermission] = React.useState();

  const updateFilePermission = (updatedPermission) => {

    if(form.getFieldState('files') && form.getFieldState('files').value) {
      let fileInfos = form.getFieldState('files').value;
      let permissionInfo = [];
      for (let key in updatedPermission) {
        if(updatedPermission.hasOwnProperty(key) && updatedPermission[key] === true) {
          permissionInfo.push(key)
        }
      }
      let selectedFileData = fileInfos.map(file => {
        if(file.rawFile && file.rawFile.name === updatedPermission.name) {
          file['permissions'] = permissionInfo;
          return file;
        } else if (file.name && file.name === updatedPermission.name) {
          file['permissions'] = permissionInfo;
          return file;
        } else {
          return file;
        }
      });
      form.change('files', selectedFileData);
    }

    if(form.getFieldState('uploads') && form.getFieldState('uploads').value) {
      let uploadInfos = form.getFieldState('uploads').value;
      let permissionInfo = [];
      for (let key in updatedPermission) {
        if(updatedPermission.hasOwnProperty(key) && updatedPermission[key] === true) {
          permissionInfo.push(key)
        }
      }
      let selectedUplaodData = uploadInfos.map(file => {
        if(file.name && file.name === updatedPermission.name) {
          file['permissions'] = permissionInfo;
          return file;
        } else {
          return file;
        }
      });
      form.change('uploads', selectedUplaodData);
    }


  }

  const handleChange = (event) => {
    let updatedPermission = { ...permission, [event.target.name]: event.target.checked };
    updateFilePermission(updatedPermission);
    setPermission(updatedPermission);
  };

  useEffect(() => {
    let permissionInfo = {
      name: (props.record.name) ? props.record.name : props.record.rawFile.name,
      sourceValue: sourceValue,
      owner: true,
      manager: true,
      contractor: (props.record.permissions && props.record.permissions.includes('contractor')),
      client: (props.record.permissions && props.record.permissions.includes('client')),
    }
    setPermission(permissionInfo);
    updateFilePermission(permissionInfo);
  },[props.record]);

  if(!permission) return null;
  return (
    <div className={classes.fileHolder} >
      {((props.record.rawFile && props.record.rawFile.type === "application/pdf") || (props.record.type === "application/pdf")) ? (
        <div className={classes.fileIcon}>
          <a href={(props.record.undefined) ? props.record.undefined : props.record.url} target="_blank">
            <PictureAsPdfIcon fontSize= "large" style={{color: 'red',width: 130,height: 100}} />
            <Typography className={classes.fileTitle}>{(props.record.rawFile) ? props.record.rawFile.name : props.record.name}</Typography>
            {(props.record.stat) && (
              <Typography className={classes.fileSubTitle}>{`Uploaded On ${props.record.stat}`}</Typography>
            )}
          </a>
        </div>
      ) : (
        <a href={(props.record.undefined) ? props.record.undefined : props.record.url} target="_blank">
          <img
            style={{
              width: 130,
              height: 100,
            }}
            src={permission.sourceValue}
            // className={classes.image}
            alt=""
          />
          {(props.record.name) && (
            <Typography className={classes.fileTitle}>{props.record.name}</Typography>
          )}
          {(props.record.stat) && (
            <Typography className={classes.fileSubTitle}>{`Uploaded On ${props.record.stat}`}</Typography>
          )}
        </a>
      )}
      
      <FormGroup style={{paddingTop: 20}}>
        {/* <FormControlLabel
          control={ <StyledCheckbox name="owner" checked={true} />}
          label="Owner"
          classes={{label: classes.formLabel}}
        />
        <FormControlLabel
          control={<StyledCheckbox name="manager"checked={true} />}
          //onChange={handleChange} checked={permission.manager}
          label="Manager"
          classes={{label: classes.formLabel}}
        /> */}
        <FormControlLabel
          control={<StyledCheckbox name="contractor" onChange={handleChange} checked={permission.contractor}/>}
          label="Sub Contractor"
          classes={{label: classes.formLabel}}
        />
        <FormControlLabel
          control={<StyledCheckbox name="client" onChange={handleChange} checked={permission.client} />}
          label="Client"
          classes={{label: classes.formLabel}}
        />
      </FormGroup>
    </div>
  )
};
