import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Typography,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,

} from '@material-ui/core';
import ScheduleHeader from '../components/ScheduleHeader';
import ProgressBar from '../components/ProgressBar';

function Scheduletask(props) {
  const { Schedules, title, isComplete } = props;
  const classes = useStyles();
  let tableHeaders = ['Task','Start Date','End Date'];
  if(isComplete) {tableHeaders.push('Progress','Complete')}

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Typography className={classes.tableheading}>{title}</Typography>
      <Table size="small">
        <ScheduleHeader 
          titles={tableHeaders}
        />
        <SchdeduleBody
          classes={classes}
          isComplete={isComplete}
          tasks={Schedules.tasks}
          onComplete={isComplete ? props.onComplete : undefined}
        />
      </Table>
    </TableContainer>
  )
}

const SchdeduleBody = ({tasks,classes,isComplete,onComplete}) => {
  return (
    <TableBody>
      {tasks.map((taskItem) =>(
        (taskItem) && (
          <StyledTableRow key={taskItem.id} >
            <TableCell component="th"
              scope="row"
              className={classes.rowStyle}
              style={{color: taskItem.isOverrun ? 'red' : ''}}
            >
              {taskItem.title}
            </TableCell>
            <TableCell className={classes.rowStyle}>{taskItem.startDate}</TableCell>
            <TableCell className={classes.rowStyle}>{taskItem.endDate}</TableCell>
            {(isComplete) && (
              <>
                <TableCell component="th" scope="row" className={classes.rowStyle}>
                  <ProgressBar value={(taskItem.progress) ? Math.round(taskItem.progress * 100) : 0} />
                  {/* {(taskItem.progress) ? `${Math.round(taskItem.progress * 100)}%` : '0%'} */}
                </TableCell>
                <TableCell padding="checkbox" className={classes.rowStyle}>
                  <FormControlLabel
                    className={classes.formLabel}
                    control={
                      <Checkbox
                        checked={(taskItem.status === 2)? true: false}
                        onChange={onComplete(taskItem,'task')}
                        name={taskItem.title}
                        color="primary"
                      />
                    }
                    label="Completed"
                  />
                </TableCell>
              </>
            )}
        </StyledTableRow>
      )
    ))}
  </TableBody>
)}

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: '10px',
  },
  tableheading: {
    background:'#f3f3f3',
    padding: 8,
    color: '#1b1809',
    fontSize: 14,
    margin: '10px 0',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    border: '1px solid #9f9150',
  },
  projectTitle: {
    fontSize: 13,
  },
  rowStyle: {
    padding: '10px 5px',
    fontSize: 13,
  },
  formLabel: {
    padding: '8px 10px',
    '& span': {
      fontSize: 13,
    }
  },
  badgeHolder: {
    '& span': {
      position: 'relative',
      transform: 'none',
      margin: '0 10px',
      background: '#c08c01',
      color: '#fff',
    }
  }, 
}));

export default Scheduletask;
