import * as React from "react";
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: '#282a3c',
    // height:'100rem',
    paddingTop: 20,
    
  },
});

const CustomSidebar = props => {
  const classes = useSidebarStyles();
  return (
    <Sidebar classes={classes} {...props} size={200} />
  );
};

export default CustomSidebar;