import React, { useEffect } from 'react';
import {
  ImageInput,
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axiosInstance from "../utils/axios";

var _ = require("lodash");

const { Form } = withTypes();

export default function ImageUpload(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState();
  const [initialValues, setInitialValues] = React.useState();

  const handleSubmit = (data) => {
   // console.log(data);
  };
  const handleChange = event => {
    setProgress();
  }
  const uploadFile = (record,source) => {
    if(props.id) {
      setLoading(true);
      let formData = new FormData();
      if(record.rawFile){
        formData.append("file", record.rawFile);
      }
      formData.append("userId", props.id);
      axiosInstance
        .post("/upload_file_progress", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: data => {
            setProgress(Math.round((100 * data.loaded) / data.total))
          },
        })
        .then(({data}) => {
          if(data.status && data.avatar) {
            setInitialValues({profile: {src: data.avatar}})
          }
          setLoading(false);
          
        })
        .catch(error => {
          setLoading(false);
        });
    }
  };

  const validate = (values) => {
    const errors = {};
    return errors;
  };

  useEffect(() => {
    setInitialValues({profile: props.src ? {src: props.src} : null})
  },[props.src]);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, pristine, values  }) => (
        <form onSubmit={handleSubmit} noValidate>
          <ImageInput
            source="profile"
            label="" accept="image/*"
            classes={{dropZone: classes.dropzoneContainer,removeButton: classes.removeButton}}
            placeholder="Choose Image"
            onChange={handleChange}
          >
            <ImagePreview source="src" pristine={pristine} submitting={submitting} progress={progress} uploadFile={uploadFile} />
            {/* <ImageField source="src" title="title" /> */}
          </ImageInput>
          {console.log(values)}
          {values && !values.profile && !pristine && !loading &&(
            <Button
             variant="contained"
             color="primary"
             onClick={() => uploadFile({},'')}
             style={{float: 'right'}}
            >
             Save
            </Button>
          )}
        </form>
      )}
    />
  )
}

const ImagePreview = ({record,source,className,progress,uploadFile,submitting,pristine}) => {
  const classes = useStyles();
  let sourceValue = _.get(record, source);
  let title = record?.rawFile?.name || '';

  // if(!sourceValue) {return null;}
  return (
    <>
      <div className={classNames(className, classes.imageWrapper)} >
        <img src={sourceValue} title={title} alt={title} className={classes.profileImage} />
      </div>
      {!progress && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => uploadFile(record,source)}
          disabled = {submitting || pristine}
        >
          Upload
        </Button>
      )}
      {(progress && progress < 100) && (
        <div className={classes.progressHolder} >
          <LinearProgressWithLabel value={progress} />
        </div>
      )}
      
    </>
  )
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


const useStyles = makeStyles((theme) =>({
  dropzoneContainer: {
    border: '2px dotted #000',
    padding: 5,
    background: '#095f7e',
    color: '#fff',
    borderRadius: 5,
    fontSize: 12,
    float: 'right',
    width: 100,
  },
  removeButton: {
    // position: absolute;
    // right: 0px;
    // top: 0px;
    position: 'relative',
    float:'right',
    width: '100%',
    textAlign: 'right',
  },
  imageWrapper: {
  },
  profileImage: {
    width: 150,
    heigth: 'auto'
  },
  progressHolder: {
    width: 200,float: 'right',
  }
}));
