import * as React from "react";

import {
  Edit, SimpleForm,TextInput,SelectInput,
  useNotify, useRefresh, useRedirect
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { Grid,Typography } from '@material-ui/core';

const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const PhaseEdit = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    console.log(data);
    notify(`Changes to Phase updated`);
    redirect('/phases');
    refresh();
  };

  return (
    <Edit onSuccess={onSuccess}  undoable={false} {...props}>
      <SimpleForm>
        <SectionHeading label="Edit Phase" classes={classes} />
        <TextInput fullWidth={true} source="title" />
        <SelectInput
          source="status"
          choices={[
            { id: 1, name: 'Enabled' },
            { id: 0, name: 'Disabled' },
          ]}
          fullWidth={true}
        />
      </SimpleForm>
    </Edit>
)};

export default PhaseEdit;