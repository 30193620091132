/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent

} from '@material-ui/core';
import {
  ListBase,
  useListContext,
  Pagination,
  usePermissions,
}from 'react-admin';
import { useHistory } from "react-router-dom";
import Page from '../components/Page';
import RefererCreate from './RefererCreate';
import CompanyList from './CompanyList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    fontSize: 16,
    padding: '10px 10px 5px',
    fontWeight: 'bold',
  },
}));

const ReferalList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { permissions } = usePermissions();
  useEffect(() => {
    if(permissions && permissions.role && permissions.role !== 'admin') {
      history.replace("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permissions]);

  return (
    <Page
      className={classes.root}
      title={'Nexa Referer'}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <ListBase
              perPage={10}
              {...props}
            >
              <ReferalListView />
            </ListBase>
            {/* <List {...props}>
              <>
                <Typography className={classes.title}>Referer's List</Typography>
                <Datagrid>
                  <TextField source="id" />
                  <TextField label="Company" source="_referal.company" />
                  <TextField label="Street" source="_referal.address.street" />
                </Datagrid>
              </>
            </List> */}
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <Box>
             <RefererCreate {...props}/>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};


const ReferalListView = ({ isSmall }) => {
  const { loaded } = useListContext();
  const classes = useStyles();
  return (
    <Box mt={3}>
      <Card>
        <CardContent>
          <Typography className={classes.title}>Referer's List</Typography>
          {loaded ? (
            <CompanyList/>
          ) : (
            <div>loading</div>
          )}
        </CardContent>
      </Card>
      <Pagination rowsPerPageOptions={[5, 10, 20, 40]}/>
    </Box>
  );
};

export default ReferalList;