import * as React from "react";

import { 
  required,SimpleForm,SelectInput,ImageInput,ImageField,ArrayInput, SimpleFormIterator,
  useNotify, useRefresh, useRedirect,useDataProvider,DateInput,TextInput,
} from 'react-admin';

import { Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { ColorInput } from 'react-admin-color-input';

const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  colorInput: {
    width: '100%',
  },
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const PopupCreate = props => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const createPopUp = (data)=> {
    dataProvider.createPopup(data).then(({ response }) => {  
      notify(`Popup Created`)
      redirect('/app-popups');
      refresh();
    })
    .catch(error => {
      console.log(error);
    })
  };

  return (
    <SimpleForm save={createPopUp}>
      <SectionHeading label="Create Popup" classes={classes} />
      <Grid container spacing={1} fullWidth={true}>
        <Grid item xs={12} sm={6}>
          <SelectInput
            source="project_type"
            label="Project Type"
            choices={[
              { id: 'construction', name: 'Construction' },
              { id: 'pre_contract', name: 'Pre Contract' },
              { id: 'design_approval', name: 'Design Approval' },
            ]}
            defaultValue={'pre_contract'}
            variant="outlined"
            fullWidth={true}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput
            source="user_type"
            label="User Type"
            choices={[
              { id: 'client', name: 'Client' },
              // { id: 'contractor', name: 'Contractor' },
              // { id: 'manager', name: 'Manager' },
            ]}
            defaultValue={'client'}
            variant="outlined"
            fullWidth={true}
            validate={required()}
          />
        </Grid>
      </Grid>
      <SpanField />
      <Grid container spacing={1} fullWidth={true}>
        <Grid item xs={12} sm={6}>
          <ImageInput
            source="ad_image"
            label="Ad Image"
            accept="image/*"
            placeholder={<p>Upload Ad Image Here</p>}
            validate={required()}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            source="buttonText"
            label="button Label"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <ColorField classes={classes}/>
      <ArrayInput source="terms">
        <SimpleFormIterator>
          <TextInput
            source="content"
            label="Term Content"
            fullWidth={true}
            variant="outlined"
            multiline={true}
            validate={required()}
            no
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

const ColorField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <ColorInput
          source="buttonbackground"
          fullWidth={true}
          variant="outlined"
          //picker="Sketch"
          label="button background Color"
          className={props.classes.colorInput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ColorInput
          source="buttonLabelColor"
          fullWidth={true}
          variant="outlined"
          //picker="Sketch"
          label="button Label Color"
          className={props.classes.colorInput}
        />
      </Grid>
    </Grid>
  )
}
const SpanField = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <DateInput
          source="start_date"
          label="Ad Start Date"
          fullWidth={true}
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          source="end_date"
          label="Ad End Date"
          //disabled
          fullWidth={true}
          variant="outlined"
          validate={required()}
        />
      </Grid>
    </Grid>
  );
};


export default PopupCreate;
