import React from 'react';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#dfab26',
  },
}))(LinearProgress);

const useStyles = makeStyles({
  bar: {
    backgroundColor: props => (props.value >= 90)
      ? '#4caf50'
      : '#dfab26',
  },
});


export default function CustomizedProgressBars(props) {
  const classes = useStyles(props);
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress
          variant="determinate"
          classes={{bar:classes.bar}}
          value={props.value}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}
