/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent

} from '@material-ui/core';
import {
  Datagrid,
  List,
  TextField,
  ListBase,
  useListContext,
  Pagination,
}from 'react-admin';
import Page from '../components/Page';
import EditReferal from './EditReferal';
import Profile from './Profile';
import Employers from './Employers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    fontSize: 16,
    padding: '10px 10px 5px',
    fontWeight: 'bold',
  },
}));

const ReferalEdit = (props) => {
  const classes = useStyles();
  return (
    <Page
      className={classes.root}
      title={'Nexa Referer'}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <Box mt={2}>
              <Profile {...props} />
            </Box>
            <Box mt={1}>
              <Employers/>
            </Box>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <Box>
             <EditReferal {...props}/>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};


export default ReferalEdit;