import React from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    useDataProvider,
    required,
    SelectInput,
    ReferenceInput,
    NumberInput,
    useNotify, useRefresh, useRedirect,
    SelectArrayInput,
} from 'react-admin';

import {Grid,Typography } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { ColorInput } from 'react-admin-color-input';
import TabField from './TabFields';

const useStyles = makeStyles((theme) =>({
  main: {
    width: '100%',
  },
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  colorInput: {
    width: '100%',
    marginTop: 4,
  },
}));

const TasksCreate = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  // const onSuccess = ({ data }) => {
    
  // };

  const createTask = (data)=> {
    dataProvider.createTask('tasks',data).then(({ response }) => {  
      notify(`Task Created`)
      redirect('/tasks');
      refresh();
    })
    .catch(error => {
      notify(`Error Occured ${error}`,'warning');
    })
  }
  
  return(          
    <SimpleForm save={createTask}>
      <SectionHeading label="Create Project Task" classes={classes} />
      <TextInput
        source="title"
        validate={required()}
        label="Title"
        variant="outlined"
        fullWidth={true}
        helperText="Title Of Phase"
      />
      <DaysField />
      <ExtraField classes={classes}/>
      <ReferenceInput source="phase" reference="phases" label="Phase" fullWidth={true} 
        variant="outlined" filter={{all:true}} >
        <SelectInput optionText="title" fullWidth={true} />
      </ReferenceInput>
      <SelectArrayInput
        label="Task Completes By"
        source="completedBy"
        fullWidth={true}
        variant="outlined"
        choices={[
          { id: 'contractor', name: 'Contractor' },
          { id: 'client', name: 'Client' },
          { id: 'manager', name: 'Manager' },
          // { id: 'admin', name: 'Admin' },
        ]} 
      />
      <TabField />
    </SimpleForm>
  );
}

const SectionHeading = ({label,classes}) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
    </Grid>
  )
}

const DaysField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="days"
          validate={required()}
          label="Days"
          variant="outlined"
          fullWidth={true}
          helperText="Expected Days Needed to Complete Phase"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="weightage"
          label="Weightage"
          variant="outlined"
          fullWidth={true}
        />
      </Grid>
    </Grid>
  );
};

const ExtraField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="reminder"
          choices={[
            { id: 1, name: '1 hrs' },
            { id: 2, name: '2 hrs' },
            { id: 4, name: '4 hrs' },
            { id: 16, name: '16 hrs' },
            { id: 24, name: '1 day' },
            { id: 48, name: '2 day' },
            { id: 72, name: '3 day' }
          ]}
          defaultValue={1}
          validate={required()}
          fullWidth={true}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ColorInput
          source="color"
          fullWidth={true}
          variant="outlined"
          //picker="Sketch"
          label="Display Color"
          className={props.classes.colorInput}
        />
        
      </Grid>
    </Grid>
  );
};

export default TasksCreate;
