import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  CreateButton,
  TextInput,
  Filter,
  ReferenceArrayField,

} from 'react-admin';
import ListIcon from '@material-ui/icons/List';
import { Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { Route,useLocation } from 'react-router-dom';
import ProgressBar from '../components/ProgressBar';

const queryString = require('query-string');

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

let projectTaskProps = {
  basePath: "/projecttask",
  hasCreate: false,
  hasEdit: false,
  hasList: true,
  hasShow: false,
  match: {path: "/projecttask", url: "/projecttask", isExact: true, params: {}},
  resource: "projecttask",
  task: null,
}

const ProjectTaskList = props => {
  console.log(props);
  // let query = useQuery();
  // console.log(query.get("project"));
  const parsedQuery = queryString.parse(props.location.search);
  // console.log(parsedQuery);

  const [selectedProject] = React.useState(
    parsedQuery && parsedQuery.project ?  parsedQuery.project : props.id ? props.id : null
  );

  const [selectedTask] = React.useState(
    parsedQuery && parsedQuery.task ?  parsedQuery.task : null
  );

  if(selectedProject) {
    return (
      <ProjectTasks project={selectedProject} task={selectedTask} {...props} {...projectTaskProps} />
    );
  } else {
    return null
  }
  
}

const ManagersField = (props) => {
  return props.record && props.record.assignees && props.record.assignees.length > 0 ? (
    <ReferenceArrayField {...props} label="Managers" reference="managers" source="assignees" link={false}>
      <SingleFieldList linkType={false}>
        <ChipField source="fullname" />
      </SingleFieldList>
    </ReferenceArrayField>
  ) : (
  <span style={{fontSize: 13}}>None Assigned</span>
  )
};
ManagersField.defaultProps = { label: 'Managers' };

const ContractorsField = (props) => {
  props.record.contractors = [...new Set(props?.record?.contractors)];
  return props.record && props.record.contractors && props.record.contractors.length > 0 ? (
    <ReferenceArrayField {...props} label="Contractors" reference="contractors" source="contractors">
      <SingleFieldList linkType={false}>
        <ChipField source="fullname" />
      </SingleFieldList>
    </ReferenceArrayField>
  ) : (
    <span style={{fontSize: 13}} >None Assigned</span>
  )
};
ContractorsField.defaultProps = { label: 'Contractors' };

const TitleField = ({ record = {} }) => {
  return (
    <span style={record.isOverrun ? {color: '#f10a0a',fontSize: 13} : {fontSize: 13}}>{record.title}</span>
  );
}
TitleField.defaultProps = { label: 'Title' };

const StartField = ({ record = {} }) => {
  return (
    <span style={record.isOverrun ? {color: '#f10a0a',fontSize: 13} : {fontSize: 13}}>{record.startReadable}</span>
  );
}
StartField.defaultProps = { label: 'Start' };

const FinishField = ({ record = {} }) => {
  return (
    <span style={record.isOverrun ? {color: '#f10a0a',fontSize: 13} : {fontSize: 13}}>{record.endReadable}</span>
  );
}
FinishField.defaultProps = { label: 'Finish' };

const ProgressField = ({ record = {} }) => (
  <ProgressBar value={(record.progress) ? record.progress : 0} />
)
ProgressField.defaultProps = { label: 'Progress' };

const ProjectTasks = (props) => {
  console.log(props);
  return (
   <List {...props}
      exporter={false}
      filter={{ project: props.project}}
      filters={<PostFilter />}
      bulkActionButtons={false}
      // actions={<SectionAction {...props} label="Suppliers" classes={classes} />}
    >
      <Datagrid rowClick={(id, basePath, record) => props.dialogOpen(id)}>
        <TitleField/>
        <TextField source="work_days" label="Duration" />
        <StartField />
        <FinishField />
        {props.role && props.role === 'admin' && (<ManagersField {...props} />)}
        
        <ContractorsField {...props} />
        <ProgressField source="progress" />
        <TextField source="confirmed" label="Confirmation" />
      </Datagrid>
    </List>
  );
}

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    {/* <TextInput label="Title" source="title" /> */}
  </Filter>
);

const useStyles = makeStyles({
  heading: {
    padding: '20px 16px',
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  createButton: {
    margin: 30,
  },
});

export default ProjectTaskList;
