import React, {useEffect,useState} from 'react';
import { useDataProvider } from 'react-admin';
import ScheduleTask from './ScheduleTask';
import ScheduleTodo from './ScheduleTodo';

function Allschedules(props) {

  const [comingSchedules, setComingSchedules] = useState();
  const dataProvider = useDataProvider();

  useEffect(() => {
    let filter = {};
    if(props.role && props.role !== 'admin') {
      filter.assigneeId = props.userId;
    }
    dataProvider.getUpComingtasks(filter).then(({ data }) => {
      // console.log(data);
      setComingSchedules(data);
    })
    .catch(error => {
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!comingSchedules) return null;

  return(
    <>
      <ScheduleTask
        Schedules={comingSchedules}
        title="Upcoming Tasks"
        isComplete={false}
      />
      
      {(props.role && props.role === "admin" && (
        <ScheduleTodo
          Schedules={comingSchedules}
          title="Upcoming To Do's"
          isComplete={false}
        />
      ))}

    </>
  )
}

export default Allschedules;
