import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Tooltip,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function FullCalender(props){

  const {updateTask, dialogOpen,isEdit } = props;
  let events = props.data;
  events = events.concat(
  {
    daysOfWeek: [0],
    rendering:"background",
    color: "#ef2516",
    overLap: true,
    allDay: true,
    title: 'Weekend',
    }
  )

  const handleEventChange = (changeInfo) => {
    updateTask(changeInfo.event.toPlainObject(),0);
  }

  const handleEventClick = (info)=> {
    if(info.event.toPlainObject().id) {
      dialogOpen(info.event.toPlainObject());
    }
  }

  const renderEventContent = (arg) => {
    return (
      <Eventtooltip arg={arg} />
    );
  }

  return (
    <div className="full-calender-container">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        contentHeight={'auto'}
        editable={isEdit}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        events={events}
        eventChange={handleEventChange}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
      />
    </div>
  )
}

function Eventtooltip( {arg} ) {
  const classes = useStyles(arg);
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
      title={
        <>
          <Typography color="inherit" variant="body1">{arg.event.title}</Typography>
          <div style={{display:'block'}}>
            {(arg.event.extendedProps.read_start_date && !arg.event.extendedProps.due_date) && (
            <p>{`Start Date: ${arg.event.extendedProps.read_start_date}`}</p>
            )}
            {(arg.event.extendedProps.calender_end_date) && (
              <p>{`End Date: ${arg.event.extendedProps.calender_end_date}`}</p>
              )}
            {(arg.event.extendedProps.calender_duration) && (
              <p>{`Duration: ${arg.event.extendedProps.calender_duration}`}</p>
            )}
            {(arg.event.extendedProps.due_date) && (
              <p>{`Due Date: ${arg.event.extendedProps.due_date}`}</p>
            )}
            {(arg.event.extendedProps.contractorNames && arg.event.extendedProps.contractorNames.length > 0) && (
              <ContractorsList contractors={arg.event.extendedProps.contractorNames} />
            )}
             {(arg.event.extendedProps.assigneesNames && arg.event.extendedProps.assigneesNames.length > 0) && (
              <AssigneesList assignees={arg.event.extendedProps.assigneesNames} />
            )}
          </div>
        </>
      }
      arrow
    >
      {renderInnerContent( arg )}
    </Tooltip>
  )
}

const ContractorsList = (props) => {
  let contractorList = props.contractors.map(name => {
    return name;
  });
  contractorList = contractorList.join(", ");
  return (
    <p>{`Sub Contractors: ${contractorList}`}</p>
  )
}

const AssigneesList = (props) => {
  let assigneesList = props.assignees.map(name => {
    return name;
  });
  assigneesList = assigneesList.join(", ");
  return (
    <p>{`Assignees: ${assigneesList}`}</p>
  )
}

function renderInnerContent( innerProps ) {
  return (
    <div className='fc-event-main-frame'>
      { innerProps.timeText &&
        <div className='fc-event-time'>{ innerProps.timeText }</div>
      }
      <div className='fc-event-title-container'>
        <div className='fc-event-title fc-sticky'>
          { innerProps.event.title || <>&nbsp;</> }
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    borderColor: props => props.backgroundColor,
    borderWidth: 1,
    borderStyle:'solid',
  },
  arrow: {
    "&::before": {
      borderColor: props => props.backgroundColor,
      borderWidth: 1,
      borderStyle:'solid',
      backgroundColor: "#fff",
      boxSizing: "border-box"
    }
  },
}));

