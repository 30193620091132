import React, {useEffect, useState} from 'react';
import { 
  useDataProvider,
  SimpleForm,FileInput,FileField,Loading
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Box, Typography,Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import GetApp from '@material-ui/icons/GetApp';
import { usePermissions  } from 'react-admin';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FolderIcon from '@material-ui/icons/Folder';
import { useHistory } from "react-router-dom";
import ProjectCertificates from './ProjectCertificates';

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: 10,
    marginBottom: 10,
    background: '#fff',
    padding: '10px 10px',
    border: '1px solid #f5f0f0',
  },
  addButton: {
    marginLeft: 20,
  },
  uploadForm: {
    border: '1px dashed orange',
    margin: '20px 0',
  },
  fileHolder: {
    //background: '#efefef',
   // justifyContent: 'center',
    alignItems: 'center',
  },
  holderBox: {
    // border: '1px solid #e9e9e9',
    background: '#fff',
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
      color: '#514646',
    },
    margin: 5,
    position: 'relative',
    paddingTop: 45,
  },
  fileDisplay: {
    textAlign: 'center',
    '& svg':{
      color: '#2b6b88',
      fontSize: '100px',
    }
  },
  fileTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    padding: '5px 0',
  },
  folderTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    padding: '5px 0',
    textTransform: 'uppercase',
  },
  fileSubTitle: {
    fontSize: 12,
    color: '#322f2f',
  },
  actionButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  buttonIcon: {
    padding: 5,
  },
  removeButton: {
    //color: '#ef5d5d',
  },
  downloadButton: {
    //color: '#597bc6',
  },
  folderHolder: {
    textAlign: 'center',
    background: '#ffff',
    border: '1px solid #f1f1f1',
    borderRadius: 10,
    cursor: 'pointer',
    padding: '10px 20px',
    '&:hover': {
      background: '#fff9ef',
    }
  },
}));

function Projectfiles({projectId,match,location}) {
  
  const parsedQuery = queryString.parse(location.search);
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState({general: [], client: [],contractor: [],tender:[]});
  const [openUpload, setOpenUpload] = useState(false);
  const { permissions } = usePermissions();
  const [dialogStat, setDialogStat] = useState({visible: false, file: null});
  const history = useHistory();

  const groupFiles = (data) => {

    if(data && data.length > 0) {

      let genFiles = [];
      let clientFiles = [];
      let contractorFiles = [];
      let tenderFiles = [];

      data&&data.length>0&&data.map(file => {
        if(file.category && file.category === 'client') {
          clientFiles.push(file);
        } else if (file.category && file.category === 'contractor') {
          contractorFiles.push(file);
        } else if (file.category && file.category === 'tender') {
          tenderFiles.push(file);
        } else {
          genFiles.push(file);
        }
      })
      setFiles({general: genFiles, client: clientFiles,contractor: contractorFiles,tender:tenderFiles});
    }  
  }

  useEffect(() => {
    dataProvider.getProjectFiles(projectId).then(({ data }) => {
      groupFiles(data);
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadFile = (data)=> {
    setLoading(true);
    console.log(data,parsedQuery.category)
    if(data.files && data.files !== undefined && data.files.length > 0) {
      if(parsedQuery && parsedQuery.category) {
        data.category = parsedQuery.category;
      } else {
        data.category = '';
      }
 
      dataProvider.uploadprojectImages(projectId, data).then(({data}) => {
        groupFiles(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      })
    }
  }

  const setCategory = (category) => {
    // setInitialValues({files : []});
    history.push(`/project-gallery/${projectId}?category=${category}`);
  };

  const downloadFile = (file) => {
    fetch(file.file_location, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  
  const openDialog = (fileInfo) => {
    setDialogStat({visible:true,file: fileInfo})
  }
  const closeDialog = () => {
    setDialogStat({visible: false, file: null});
  }

  const removeFile = (fileInfo) => {
    closeDialog();
    setLoading(true);
    dataProvider.delete('projectfiles',{id: fileInfo.id}).then(({data}) => {
      if(parsedQuery && parsedQuery.category === 'client') {
        let filesArray = files.client.filter(file => file.id !== fileInfo.id);
        setFiles((prevFiles) => {
          return {...prevFiles, client: filesArray}
        });

      } else if(parsedQuery && parsedQuery.category === 'contractor') {
        let filesArray = files.contractor.filter(file => file.id !== fileInfo.id);
        setFiles((prevFiles) => {
          return {...prevFiles, contractor: filesArray}
        });

      } else if(parsedQuery && parsedQuery.category === 'tender') {
        let filesArray = files.tender.filter(file => file.id !== fileInfo.id);
        setFiles((prevFiles) => {
          return {...prevFiles, tender: filesArray}
        });

      } else {
        let filesArray = files.general.filter(file => file.id !== fileInfo.id);
        setFiles((prevFiles) => {
          return {...prevFiles, general: filesArray}
        });
      }
      setLoading(false);
    })
    .catch(err => {
      console.log(err);
      setLoading(false);
    })
  }

  const classes = useStyles();

  if (loading) return <Loading loadingSecondary="" />;

  return (
    <>
      <Container>

        {parsedQuery && parsedQuery.category && parsedQuery.category !== 'certificate' && (
          <>
            <Grid container direction="row" alignItems="center" className={classes.heading}>
              <Typography variant="h6" gutterBottom>
                Project Gallery
              </Typography>
              {(permissions && (permissions.role ==="admin" || permissions.role ==="manager") && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.addButton}
                  onClick={()=> setOpenUpload((prevStatus) => !prevStatus)}
                >
                  Add New
                </Button>
              ))}
            </Grid>
            {(openUpload &&
              (
                <SimpleForm save={uploadFile} className={classes.uploadForm}>
                  <FileInput source="files" label="Related files" accept="" multiple={true}>
                    <FileField source="src" title="title" />
                  </FileInput>
                </SimpleForm>
              )
            )}
          </>
        )}
        <Box
          display="flex"
          flexWrap="wrap"
          className={classes.fileHolder}
        >
          {parsedQuery && parsedQuery.category === "general" && (
            <>
              {files.general.map((file) => (
                <Box
                  p={1}
                  m={2}
                  alignItems="center"
                  key={file._id}
                  className={classes.holderBox}
                  //onClick={() => downloadFile(file)}
                >
                  <div className={classes.actionButton}>
                    <IconButton
                      aria-label="download"
                      className={[classes.buttonIcon,classes.downloadButton]}
                      onClick={() => downloadFile(file)}
                    >
                      <GetApp />
                    </IconButton>
                    {(permissions && (permissions.role ==="admin") && (
                      <IconButton
                        aria-label="delete"
                        className={[classes.buttonIcon,classes.removeButton]}
                        onClick={() => openDialog(file)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ))}
                  </div>
                  <FilePreview file={file} />
                </Box>
              ))}
            </>
          )}

         {parsedQuery && parsedQuery.category === "client" && (
            <>
             {files.client.map((file) => (
                <Box
                  p={1}
                  m={2}
                  alignItems="center"
                  key={file._id}
                  className={classes.holderBox}
                  //onClick={() => downloadFile(file)}
                >
                  <div className={classes.actionButton}>
                    <IconButton
                      aria-label="download"
                      className={[classes.buttonIcon,classes.downloadButton]}
                      onClick={() => downloadFile(file)}
                    >
                      <GetApp />
                    </IconButton>
                    {(permissions && (permissions.role ==="admin") && (
                      <IconButton
                        aria-label="delete"
                        className={[classes.buttonIcon,classes.removeButton]}
                        onClick={() => openDialog(file)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ))}
                  </div>
                  <FilePreview file={file} />
                </Box>
              ))}
            </>
          )}

          {parsedQuery && parsedQuery.category === "contractor" && (
            <>
              {files.contractor.map((file) => (
                <Box
                  p={1}
                  m={2}
                  alignItems="center"
                  key={file._id}
                  className={classes.holderBox}
                  //onClick={() => downloadFile(file)}
                >
                  <div className={classes.actionButton}>
                    <IconButton
                      aria-label="download"
                      className={[classes.buttonIcon,classes.downloadButton]}
                      onClick={() => downloadFile(file)}
                    >
                      <GetApp />
                    </IconButton>
                    {(permissions && (permissions.role ==="admin") && (
                      <IconButton
                        aria-label="delete"
                        className={[classes.buttonIcon,classes.removeButton]}
                        onClick={() => openDialog(file)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ))}
                  </div>
                  <FilePreview file={file} />
                </Box>
              ))}
            </>
          )}
          {parsedQuery && parsedQuery.category === "tender" && (
            <>
              {files.tender.map((file) => (
                <Box
                  p={1}
                  m={2}
                  alignItems="center"
                  key={file._id}
                  className={classes.holderBox}
                  //onClick={() => downloadFile(file)}
                >
                  <div className={classes.actionButton}>
                    <IconButton
                      aria-label="download"
                      className={[classes.buttonIcon,classes.downloadButton]}
                      onClick={() => downloadFile(file)}
                    >
                      <GetApp />
                    </IconButton>
                    {(permissions && (permissions.role ==="admin") && (
                      <IconButton
                        aria-label="delete"
                        className={[classes.buttonIcon,classes.removeButton]}
                        onClick={() => openDialog(file)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ))}
                  </div>
                  <FilePreview file={file} />
                </Box>
              ))}
            </>
          )}
          {parsedQuery && parsedQuery.category === "certificate" && (
            <ProjectCertificates project={projectId} />
          )}

          {(!parsedQuery || (parsedQuery.category !== "contractor" && parsedQuery.category !== "client" && parsedQuery.category !== "tender" && parsedQuery.category !== "general" && parsedQuery.category !== "certificate")) && (           
            <>
              <Box
                display="flex"
                flexWrap="wrap"
                className={classes.fileHolder}
              >
                <Box
                  p={1} m={2}
                  alignItems="center"
                  className={classes.folderHolder}
                  onClick={() => setCategory('general')}
                >
                  <div className={classes.fileIcon}>
                    <FolderIcon fontSize= "large" style={{color: '#ffbb54',width: 100,height: 100}} />
                  </div>
                  <Typography className={classes.folderTitle}>General</Typography>
                </Box>

                {(permissions && (permissions.role ==="admin" || permissions.role ==="manager" || permissions.role ==="client")) && (
                  <Box
                    p={1} m={2}
                    alignItems="center"
                    className={classes.folderHolder}
                    onClick={() => setCategory('client')}
                  >
                    <div className={classes.fileIcon}>
                      <FolderIcon fontSize= "large" style={{color: '#ffbb54',width: 100,height: 100}} />
                    </div>

                    <Typography className={classes.folderTitle}>Client</Typography>
                  </Box>
                )}

                {(permissions && (permissions.role ==="admin" || permissions.role ==="manager" || permissions.role ==="contractor")) && (
                  <Box
                    p={1} m={2}
                    alignItems="center"
                    className={classes.folderHolder}
                    onClick={() => setCategory('contractor')}
                  >
                    <div className={classes.fileIcon}>
                      <FolderIcon fontSize= "large" style={{color: '#ffbb54',width: 100,height: 100}} />
                    </div>
                    <Typography className={classes.folderTitle}>Contractor</Typography>
                  </Box>
                )}
                {(permissions && (permissions.role ==="admin" || permissions.role ==="manager" || permissions.role ==="contractor")) && (
                  <Box
                    p={1} m={2}
                    alignItems="center"
                    className={classes.folderHolder}
                    onClick={() => setCategory('tender')}
                  >
                    <div className={classes.fileIcon}>
                      <FolderIcon fontSize= "large" style={{color: '#ffbb54',width: 100,height: 100}} />
                    </div>
                    <Typography className={classes.folderTitle}>Tender files</Typography>
                  </Box>
                )}
                <Box
                  p={1} m={2}
                  alignItems="center"
                  className={classes.folderHolder}
                  onClick={() => setCategory('certificate')}
                >
                  <div className={classes.fileIcon}>
                    <FolderIcon fontSize= "large" style={{color: '#ffbb54',width: 100,height: 100}} />
                  </div>
                  <Typography className={classes.folderTitle}>Certificates</Typography>
                </Box>

              </Box>

              {/* {files.general.map((file) => (
                <Box
                  p={1}
                  m={2}
                  alignItems="center"
                  key={file._id}
                  className={classes.holderBox}
                  //onClick={() => downloadFile(file)}
                >
                  <div className={classes.actionButton}>
                    <IconButton
                      aria-label="download"
                      className={[classes.buttonIcon,classes.downloadButton]}
                      onClick={() => downloadFile(file)}
                    >
                      <GetApp />
                    </IconButton>
                    {(permissions && (permissions.role ==="admin") && (
                      <IconButton
                        aria-label="delete"
                        className={[classes.buttonIcon,classes.removeButton]}
                        onClick={() => openDialog(file)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ))}
                  </div>
                  <FilePreview file={file} />
                </Box>
              ))} */}
            </>
          )}
        </Box>
      </Container>

      <Dialog
        open={dialogStat.visible}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete file ${(dialogStat.file && dialogStat.file.name) ? dialogStat.file.name : ''}`}</DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            No
          </Button>
          <Button color="primary" onClick={() => removeFile(dialogStat.file)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const FilePreview = ({file}) => {
  const classes = useStyles();
  if (file.type.search('image') >= 0) {
    return (
      <a href={file.file_location} target="_blank" rel="noopener noreferrer">
        <img
          style={{
            width: 150,
            height: 'auto',
            maxHeight: 130,
          }}
          src={file.file_location}
          alt=""
        />
        {(file.name) && (
          <Typography className={classes.fileTitle}>{file.name}</Typography>
        )}
        {(file.stat) && (
          <Typography className={classes.fileSubTitle}>{`Uploaded On ${file.stat}`}</Typography>
        )}
      </a>
    )
  } else if (file.type === 'application/pdf') {
    return (
      <div className={classes.fileIcon}>
        <a href={file.file_location} target="_blank" rel="noopener noreferrer">
          <PictureAsPdfIcon fontSize= "large" style={{color: 'red',width: 130,height: 100}} />
          <Typography className={classes.fileTitle}>{file.name}</Typography>
          {(file.stat) && (
            <Typography className={classes.fileSubTitle}>{`Uploaded On ${file.stat}`}</Typography>
          )}
        </a>
      </div>
    )
  } else if (file.type.search('video') >= 0) {
    return (
      <div className={classes.fileIcon}>
        <a href={file.file_location} target="_blank" rel="noopener noreferrer">
          <PlayCircleFilledIcon fontSize= "large" style={{color: '#6666fd',width: 130,height: 100}} />
          <Typography className={classes.fileTitle}>{file.name}</Typography>
          {(file.stat) && (
            <Typography className={classes.fileSubTitle}>{`Uploaded On ${file.stat}`}</Typography>
          )}
        </a>
      </div>
    )
  } else {
    return (
      <a href={file.file_location} target="_blank" rel="noopener noreferrer">
        {(file.name) && (
          <Typography className={classes.fileTitle}>{file.name}</Typography>
        )}
        {(file.stat) && (
          <Typography className={classes.fileSubTitle}>{`Uploaded On ${file.stat}`}</Typography>
        )}
      </a>
    )
  }
}

export default Projectfiles;
