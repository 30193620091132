import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { setSidebarVisibility,usePermissions, useDataProvider,Loading} from 'react-admin';
import {Tabs,Paper,Tab,Typography} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles((theme) =>({
  main: {
    margin: 20,
  },
  title: {
    padding: 5,
    fontSize: 15,
    background: '#9f9150',
    color: '#fff',
  },
  tableContainer: {
    margin: 20,
  }
}));

export default function ProjectTodoChart({id}){
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    dataProvider
      .getList('punchlist',
        { 
          pagination: { page: 20 , perPage: 10 },
          sort: { field: 'id', order: 'asc' },
          filter: {project: id, status: 0} 
        }
      )
      .then(({ data }) => {
        console.log(data)
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
    return () => {
      dispatch(setSidebarVisibility(true));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (loading) return <Loading />;
  if(!data || data.length < 1) {return null;}

  return (
    <div className={classes.main}>
      {data.map(task => (
        <TableContainer component={Paper} key={task.id} className={classes.tableContainer}>
          <div className={classes.title}>{task.title}</div>
          <Table  size="small" className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created By</TableCell>
              </TableRow>
            </TableHead>
            {(task.items.map(_item => (
              <TableBody>
                <TableRow >
                  <TableCell component="th" scope="row">
                    {_item.title}
                  </TableCell>
                  <TableCell>{_item.description}</TableCell>
                  <TableCell>{_item.creator.username}</TableCell>
                </TableRow>
              </TableBody>
            )))}
          </Table>
        </TableContainer>
      ))}
    </div>
  )
}
