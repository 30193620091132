import React from 'react';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  NumberInput,
  DateInput } 
from 'react-admin';
import { InputAdornment,Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) =>({
  main: {
    width: '100%',
  },
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color:'#e58f0a',
      textTransform: 'uppercase',
    }
  },
}));

function MaterialCreate(props) {
  const {save} = props;

  const classes = useStyles();

  const onSave = (data) => {
    save(data);
  };

  return (
    <SimpleForm save={onSave}>
      <SectionHeading label="Create New Expence" classProp={classes.heading}/>
      <SelectInput
        source="type"
        variant="outlined"
        label="Select Expense Type"
        choices={[
          { id: 'labour', name: 'Labour' },
          { id: 'travel', name: 'Travel' },
          { id: 'purchase', name: 'Purchase' },
          { id: 'other', name: 'Other' },
        ]}
        defaultValue={'labour'}
        validate={required()}
        fullWidth={true}
      />
      <GridField />
      <TextInput source="remarks" fullWidth={true} variant="outlined" multiline />
             
    </SimpleForm>
  )
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const GridField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="cost"
          validate={required()}
          variant="outlined"
          label="Cost"
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                AUD
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          source="cost_date"
          label="Date"
          fullWidth={true}
          variant="outlined"
          validate={required()}
        />
      </Grid>
    </Grid>
  );
}

export default MaterialCreate;
