import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  TextInput,
  RadioButtonGroupInput,
  useDataProvider
}
  from 'react-admin';
import { InputAdornment, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
}));

function MaterialCreate(props) {

  const { save, projectId, unit } = props;
  const classes = useStyles();
  const [rows, setRows] = useState();
  const [initialval, setDefault] = useState();
  const [loading, setLoading] = useState(true);
  // const [maincategory, setMaincategory] = useState(true);
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider.getList('tender-user', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      setDefault(data[0])
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  },[])
  const onSave = (data) => {
    data['project']=projectId
    dataProvider.createCategory(data, 'tender-user').then(({ data }) => {
    }).catch(error => {

    });
    // tender-user
  };

  return (
    <SimpleForm save={onSave}>
      <SectionHeading label="Tender Account Details" classProp={classes.heading} />
      <GridField initialval={initialval} />
    </SimpleForm>
  )
}

const GridField = (props) => {
  const {initialval } = props;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="account_name"
          label="Account Name"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.councile}

        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="account_number"
          label="Account Number"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.developer_condact}

        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="bsb"
          label="BSB"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.developer_condact}

        />
      </Grid>
    </Grid>
  );
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

export default MaterialCreate;
