import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  CreateButton

} from 'react-admin';
import ListIcon from '@material-ui/icons/List';
import { Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  heading: {
    padding: '20px 16px',
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  createButton: {
    margin: 30,
  },
});


const SectionAction = ({ label, classes,basePath }) => {
  return (
    <>
      <Grid container direction="row" alignItems="center" className={classes.heading}>
        <ListIcon />
        <Typography variant="h6" gutterBottom >
          {label}
        </Typography>
      </Grid>
      <CreateButton basePath={basePath} className={classes.createButton} />
    </>
  );
};

const StatusField = ({record,source}) => {
  let status = (record && record[source] === 1) ? 'Enabled' : 'Disabled';
  return(
    <span >{status}</span>
  );
}


const SupplierList = props => {
  const classes = useStyles();
  return(
    <List {...props}
      exporter={false}
      actions={<SectionAction {...props} label="Suppliers" classes={classes} />}
    >
      <Datagrid>
        <TextField source="fullname" label="Name" />
        <TextField source="email" />
        <TextField source="contact" />
        <ArrayField source="materials">
          <SingleFieldList linkType={false}>
              <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <StatusField source="status" />
        <TextField source="dateAdded" />
        <EditButton source="Action"/>
      </Datagrid>
    </List>
  );
}

export default SupplierList;

