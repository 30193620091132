import React, {useEffect,useState} from 'react';
import { useDataProvider,useAuthProvider } from 'react-admin';
import ScheduleTask from './ScheduleTask';
import ScheduleTodo from './Scheduletodo';

function ComingSchedules(props) {
  const { projectId,permission } = props;
  const [projectComingSchedules, setProjectComingSchedules] = useState();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();

  useEffect(() => {
    let params = {id:projectId};
    if(permission && permission !== 'admin') {
      params['assigneeId'] = user.id;
    }
    dataProvider.getProjectUpComingSchedules(params).then(({ data }) => {
      //console.log(data);
      setProjectComingSchedules(data);
    })
    .catch(error => {
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!projectComingSchedules) return null;

  return(
    <>
     <ScheduleTask
        Schedules={projectComingSchedules}
        title="Upcoming Tasks"
        isComplete={false}
      />
      {(permission!=="contractor" && (<ScheduleTodo
        Schedules={projectComingSchedules}
        title="UpComing To Do's" 
        isComplete={false}
      />))}
    </>
  )
}

export default ComingSchedules;

