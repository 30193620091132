import React from 'react';
import { cloneElement } from 'react'
import { Box, useMediaQuery } from '@material-ui/core';

import {
    // CreateButton,
    // ExportButton,
    Filter,
    //FilterProps,
    //InputProps,
    ListBase,
    //ListProps,
    // Pagination,
    Title,
    TopToolbar,
    useListContext,
    ListToolbar,
    sanitizeListRestProps,
} from 'react-admin';

import {
  DateInput as RaDateInput,
  ReferenceArrayInput as RaReferenceArrayInput,
  AutocompleteArrayInput as RaAutocompleteArrayInput,
  SelectInput as RaSelectInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles';
import TaskList from './TaskList';


const standardize = Component => props => <Component {...props} variant="outlined" />
const DateInput = standardize(RaDateInput);
const SelectInput = standardize(RaSelectInput);
const ReferenceArrayInput= standardize(RaReferenceArrayInput);
const AutocompleteArrayInput= standardize(RaAutocompleteArrayInput);
const optionRenderer = choice => `${choice.fullname}`;
const optionRendererproject = choice => `${choice.title} (${choice.description})`;

const useStyles = makeStyles({
  toolBar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    margin: '25px 10px',
    padding: 10,
  },
});


const StatusField = ({ record = {} }) => {
  const classes = useStyles();
  return (
    (record.status === 1) ? (
      <span className={`${classes.statusField} ${classes.activated}`} >{'activated'}</span>
    ) : (
      <span className={`${classes.statusField} ${classes.notActivated}`} >{'not activated'}</span>
    )
  )
};

StatusField.defaultProps = { label: 'Status' };


const ScheduleFilter = (props) => (
  <Filter {...props}>
    <ReferenceArrayInput
      reference="contractors"
      source="contractor"
      label="Contractors"
      variant="outlined"
      alwaysOn
      resource="contractors"
      fullWidth={true}
      filter={{all:true,status: 1}}
    >
      <AutocompleteArrayInput  allowEmpty={false} optionText={optionRenderer} optionValue="id">

      </AutocompleteArrayInput >
    </ReferenceArrayInput >
    <ReferenceArrayInput
      reference="user-projects"
      source="projects"
      label="Projects"
      variant="outlined"
      alwaysOn
      resource="user-projects"
      fullWidth={true}
    >
      <AutocompleteArrayInput  allowEmpty={false} optionText={optionRendererproject} optionValue="id">

      </AutocompleteArrayInput >
    </ReferenceArrayInput >
    <DateInput label="Start Date" source="start_date" variant="outlined" />
    <SelectInput
      source="status"
      label="Status"
      allowEmpty={false}
      choices={[
        { id: 'all', name: 'All' },
        { id: 0, name: 'Pending' },
        { id: 2, name: 'Completed' },
      ]}
    />
    
  </Filter>
);

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  // const custoClasses = useStyles();
  // console.log(custoClasses.toolBar)
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

const SchedulesList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
      <ListBase
        {...props}
        perPage={100}
        sort={{ field: '_id', order: 'DESC' }}
        filterDefaultValues={{ status: 'all'}}
      >
        <ListToolbar
          filters={<ScheduleFilter />}
          actions={<ListActions />}
        />
         {/* <Pagination /> */}
        <ScheduleListView isSmall={isSmall} {...props}/>
      </ListBase>
    );
};

const ScheduleListView = (props) => {
  const { defaultTitle } = useListContext();
  return (
    <>
      <Title defaultTitle={defaultTitle} />
      <Box display="flex">
        {/* <Aside /> */}{/* calc(100% - 16em */}
        <Box width={props.isSmall ? 'auto' : '100%'}>
          <TaskList />
        </Box>
      </Box>
    </>
  );
};

export default SchedulesList;
