/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useState, useEffect,useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  // Button,
  List,
  ListItem,
  Typography,
  Card,
  CardContent,
  IconButton,
  CircularProgress,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {
  useDataProvider
} from 'react-admin';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './Dialog';

const useStylesLoader = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function LoaderCircularProgress(props) {
  const classes = useStylesLoader();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const Employers = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [employees, setEmployees]  = useState();
  const [employee, setEmployee] = useState();
  const [modalType, setModalType] = useState();
  const [loading, setLoading] = useState(true);

  const getEmployeeList = useCallback(() => {
    if(!loading) setLoading(true);
    dataProvider.getEmployees({ id })
    .then(({data}) => {
      setEmployees(data);
      setLoading(false);

    })
    .catch(error => {
      setLoading(false);
    });
  }, [dataProvider, id, loading]);

  const saveEmployee = (values) => {
    setLoading(true);
    setOpen(false);
    dataProvider.addEmployee({...values, company: id})
    .then(({data}) => {
      getEmployeeList();
    })
    .catch(error => {

      setLoading(false);
    });
  }

  const openEditEmplolyee = (emploInfo) => {
    setModalType('edit_employee');
    setEmployee(emploInfo);
    setOpen(true);
  };

  const openDeleteEmployee = (emploInfo) => {
    setModalType('delete_employee');
    setEmployee(emploInfo);
    setOpen(true);
  };

  const addEmployee = () => {
    setModalType(null);
    if(!loading) {setOpen(!open);}
    else {setOpen(false);}
  }

  const editEmployee = (record) => {
    setLoading(true);
    setOpen(false);
    dataProvider.update('employee', { id: record.id, data: record })
    .then(({data}) => {
      if(data && data.id) {
        setEmployees((prevEmployees) => {
          return prevEmployees.map((emp) => {
            if(emp.id === data.id) {
              emp = {...emp, ...data};
            }
            return emp;
          });
        });
      }
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
    });
  }

  const deleteEmployee = (record) => {
    setLoading(true);
    setOpen(false);
    setEmployees((prevEmployees) => prevEmployees.filter((emp) => emp.id !== record.id));
    dataProvider.delete('employee', { id: record.id, data: record })
    .then(({data}) => {
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
      getEmployeeList();
    });
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getEmployeeList();
    }
    return () => { isMounted = false;};
  },[id]);


  if(!id || id === '') return null;

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.heading}>Employee's List</Typography>
          {loading && <LoaderCircularProgress />}
        </Box>
        <Box display="flex" alignItems="center">
          <Typography className={classes.subHeading}>Add Employee</Typography>
          <IconButton aria-label="add" onClick={() => addEmployee()}>
            <AddCircleIcon className={classes.addIcon} />
          </IconButton>
        </Box>
        {employees && employees.length > 0 && (
          <List>
            {employees.map((employee) => (
              <ListItem
                className={classes.item}
                disableGutters
                onClick={() => {
                
                }}
                key={employee.id}
              >
                {/* <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  // component={RouterLink}
                  // to={`/referals/${id}`}
                > */}
                  <div className={classes.button}>
                    <Box className={classes.content}>
                      <Box className={classes.title}>
                        {/* {data[id]._referal.company} */}
                        {employee.name}
                      </Box>
                      <Box className={classes.text}>
                        {`Email: ${employee.email}`}
                      </Box>
                      <Box className={classes.text}>
                        {`Contact: ${employee.contact}`}
                      </Box>
                      {/* <Box className={classes.text}>
                        {content.substr(0, 26)}
                        {content.length > 26 ? '...' : ''}
                      </Box> */}

                    </Box>
                  </div>
                {/* </Button> */}
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={() => openEditEmplolyee(employee)}>
                    <EditIcon style={{color: "#e19c3a"}} />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => openDeleteEmployee(employee)}>
                    <DeleteIcon style={{color: "#f44336"}} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
      <Dialog
        open={open}
        setOpen={setOpen}
        saveEmployee={saveEmployee}
        editEmployee={editEmployee}
        deleteEmployee={deleteEmployee}
        employee={employee}
        defaultType={modalType}
      />
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    // color: theme.palette.text.secondary,
    // fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    textAlign: 'left',
    color: '#000',
  },
  title: {
    fontWeight:'bold',
    textTransform: 'uppercase',
  },
  addIcon: {
    color: '#00AEEF',
    fontSize: 18,
  },
  heading: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  subHeading: {
    fontSize: 12,
  },
}));

export default Employers;
