import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
  Grid, Typography,
} from '@material-ui/core';
import Pdf from "react-to-pdf";
import { Document, Page, Text, View, Image, Font, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
const ref = React.createRef();

function MaterialList(props) {
  const { data, value, onEditClick, onDeleteClick } = props;
  const classes = useStyles();
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={{
        paddingBottom: 30,
      }}>
        <View fixed style={{ marginBottom: 30 }}>
          <Image src={process.env.PUBLIC_URL + '/construction stage 1.jpg'} />
        </View>
        <View style={{ padding: 10 }}>
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 25, textAlign: 'center' }}>VARIATION LIST</Text>
          <View style={{ borderWidth: 1, borderColor: '#000' }} ></View>
          <Text style={{ marginTop: 50, marginBottom: 15, marginRight: 30, fontSize: 16, fontFamily: 'Helvetica-Bold', alignSelf: 'flex-end' }}>{`Total Cost: AUD ${value}`}</Text>
          <View size="small" aria-label="material-table">
            <View>
              <View style={{ flexDirection: 'row', paddingLeft: 10, borderLeftWidth: 1, borderRightWidth: 1, borderTopWidth: 1, borderColor: '#ccc', paddingBottom: 5, paddingTop: 5 }}>
                <Text style={{ flex: 2, fontSize: 12, fontFamily: 'Helvetica-Bold', }}>Name</Text>
                <Text style={{ flex: 5, fontSize: 12, fontFamily: 'Helvetica-Bold', }}>Description</Text>
                <Text style={{ flex: 1, fontSize: 12, fontFamily: 'Helvetica-Bold', }}>Cost</Text>
                <Text style={{ flex: 1, fontSize: 12, fontFamily: 'Helvetica-Bold', }}>Quantity</Text>
                <Text style={{ flex: 1, fontSize: 12, fontFamily: 'Helvetica-Bold', }}>Total</Text>
              </View>
            </View>
            <View >
              {data?.variation?.length && data.variation.map((projectMaterial, index) => (
                <View style={{ flexDirection: 'row', paddingLeft: 10, borderBottomWidth: 1, borderLeftWidth: 1, borderRightWidth: 1, borderTopWidth: index === 0 ? 1 : 0, borderColor: '#ccc', paddingBottom: 5, paddingTop: 5 }} key={projectMaterial.id}>
                  <Text style={{ flex: 2, fontSize: 12 }} component="th" scope="row">
                    {projectMaterial?.name?.name}
                  </Text>
                  <Text style={{ flex: 5, fontSize: 12 }}>{projectMaterial.description}</Text>
                  <Text style={{ flex: 1, fontSize: 12 }}>{projectMaterial.cost}</Text>
                  <Text style={{ flex: 1, fontSize: 12 }}>{projectMaterial.quantity} {projectMaterial?.unit?.name ? projectMaterial.unit.name : null}</Text>
                  <Text style={{ flex: 1, fontSize: 12 }}>{projectMaterial.total}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>
        <View fixed style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          paddingTop: 20,
          zIndex: 0,
          textAlign: 'center',
        }}>
          <Image src={process.env.PUBLIC_URL + '/construction stage 2.jpg'} />
        </View>
      </Page>
    </Document>
  );
  return (
    <>
      <TableContainer ref={ref} component={Paper} className={classes.tableContainer}>
        <Grid container >
          <Grid item xs={12} sm={9}>
            <SectionHeading label="Variation List" classProp={classes.heading} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <div style={{ marginTop: 15, fontWeight: 'bold' }}>{`Total Cost: AUD ${value}`}</div>
          </Grid>
        </Grid>

        <Table className={classes.table} size="small" aria-label="material-table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: '600' }}>Name</TableCell>
              <TableCell style={{ fontWeight: '600' }}>Description</TableCell>
              <TableCell style={{ fontWeight: '600' }}>Cost</TableCell>
              <TableCell style={{ fontWeight: '600' }}>Quantity</TableCell>
              <TableCell style={{ fontWeight: '600' }}>Total</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.variation?.length && (
              <>
                {data.variation.map((projectMaterial) => (
                  <TableRow key={projectMaterial.id}>
                    <TableCell component="th" scope="row">
                      {projectMaterial?.name?.name}
                    </TableCell>
                    <TableCell>{projectMaterial.description}</TableCell>
                    <TableCell>{projectMaterial.cost}</TableCell>
                    <TableCell>{projectMaterial.quantity} {projectMaterial?.unit?.name ? projectMaterial.unit.name : null}</TableCell>
                    <TableCell>{projectMaterial.total}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => onEditClick(projectMaterial)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        className={classes.deleteButton}
                        onClick={() => onDeleteClick(projectMaterial.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* 
      <Pdf targetRef={ref} filename="code-example.pdf">
        {({ toPdf }) =>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            style={{ marginTop: 10, marginBottom: 10 }}
            className={classes.deleteButton}
            onClick={toPdf}>Generate Pdf</Button>
        }
      </Pdf> */}
      <Button
        variant="outlined"
        startIcon={<PrintIcon />}
        style={{ marginTop: 10, marginBottom: 10 }}
        className={classes.deleteButton}
      >
        <PDFDownloadLink
          document={<MyDocument />}
          fileName="Variation.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
        </PDFDownloadLink>
      </Button>

      {/* <div ref={ref} style={{ position: 'absolute', top: -10, zIndex: -1 }}  >
        <div style={{ width: 800, padding: 10 }}>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.3)', height: 660, width: 750, padding: 10 }}>
            <PrintSectionHeading label="Variation List" classProp={classes.heading} />
            <div style={{ marginTop: 15, marginBottom: 15, marginRight: 70, fontWeight: 'bold', float: 'right' }}>{`Total Cost: AUD ${value}`}</div>
            <Table size="small" aria-label="material-table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: '600' }}>Name</TableCell>
                  <TableCell style={{ fontWeight: '600' }}>Description</TableCell>
                  <TableCell style={{ fontWeight: '600' }}>Cost</TableCell>
                  <TableCell style={{ fontWeight: '600' }}>Quantity</TableCell>
                  <TableCell style={{ fontWeight: '600' }}>Total</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.variation?.length && (
                  <>
                    {data.variation.map((projectMaterial) => (
                      <TableRow key={projectMaterial.id}>
                        <TableCell component="th" scope="row">
                          {projectMaterial.name}
                        </TableCell>
                        <TableCell>{projectMaterial.description}</TableCell>
                        <TableCell>{projectMaterial.cost}</TableCell>
                        <TableCell>{projectMaterial.quantity} {projectMaterial?.unit?.name ? projectMaterial.unit.name : null}</TableCell>
                        <TableCell>{projectMaterial.total}</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div> */}
    </>
  )
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <ListIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const PrintSectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 15,
    paddingBottom: 15,
    boxShadow: 'none',
    border: '1px solid #ebebeb',
    width: 'auto',
    marginLeft: 10,
  },
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
  table: {
    minWidth: 650,
  },
  deleteButton: {
    border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
  }
});


export default MaterialList;
