import React from 'react';
import { useListContext,linkToRecord,usePermissions,useDataProvider,useRefresh,Loading } from 'react-admin';
import MuiGridList from '@material-ui/core/GridList';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import GridListTile from '@material-ui/core/GridListTile';
import {Paper, Tabs, Tab,Button, } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from './DeleteProject';
import UpdateProject from './UpdateProject';
import CloneDIalog from './cloneProject';
import ProjectWrapper from './ProjectWrapper';

const useStyles = makeStyles(theme => ({
  gridList: {
    margin: '20px 0 !important',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  anchor: {
    textDecoration: 'none',
    color: '#dadbe6',
    '&:active':{
      color: '#fff',
    }
  },
  card:{
    width:300,
    boxShadow:'0px 0px 15px rgba(73, 78, 92, 0.15)',
    borderRadius:8,
    minHeight: 270,
  },
  cardHeader: {
    background: '#e5d893',
    padding: 8,
  },
  cardContent: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 0,
    },
    '& p':{
      maxWidth: 300,
      padding: '5px 0',
      fontSize: 14,
      color: '#67655b',
    },
    '& h4': {
      paddingTop:5,
      fontSize: 16,
      textTransform: 'capitalize',
      color: '#a17607',
    }
  },
  action: {
    margin: 0,
    paddingLeft: 10,
  },
  avatar: {
    width: 30,
    height: 30,
  },
  title: {
    fontSize:15,
  },
  progress: {
    marginTop: 5,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  clientGrid: {
    padding: '10px 0'
  },
  clientName: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: '#443918 !important',
  },
  paper: {
    background: '#f3f3f3',
    marginTop:10,
    boxShadow:'none',
  },
  tab: {
    //width: 130,
    //minWidth: 130,
    background: '#f3f3f3',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#e1b122',
    color: '#fff !important',
  },
}));

const getColsForWidth = (width) => {
  if (width === 'xs') return 2;
  if (width === 'sm') return 3;
  if (width === 'md') return 4;
  if (width === 'lg') return 6;
  return 6;
};

const times = (nbChildren, fn) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({
  width,
  nbItems = 10,
}) => {
  const classes = useStyles();
  return (
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {' '}
      {times(nbItems, key => (
        <GridListTile key={key}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </MuiGridList>
  );
};

const LoadedGridList = (props) => {
  const { width } = props;
  const { permissions } = usePermissions();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openClone, setOpenClone] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { ids, data, basePath } = useListContext();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const refresh = useRefresh();
  const [Tabvalue, setTabValue] = React.useState(0);

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const openDeleteDialog = (project) => {
    setSelectedProject(project);
    setOpenDelete(true);
  }
  const openCloneDIalog = (project) => {
    setSelectedProject(project);
    setOpenClone(true);
  }
  const closeCloneDialog = (project) => {
    setOpenClone(false);
  }
  const closeDeleteDialog = () => {
    setOpenDelete(false);
  };

  const deleteProject = () => {
    closeDeleteDialog();
    setLoading(true);
    dataProvider.delete('projects',{ id: selectedProject.id, previousData: selectedProject })
      .then(({ data }) => {
        refresh();
        setLoading(false);
      })
      .catch(error => {
        refresh();
        setLoading(false);
      })
  }

  const openUpdateDialog = (project) => {
    setSelectedProject(project);
    setOpenUpdate(true);
  }

  const closeUpdateDialog = (project)=> {
    setOpenUpdate(false);
  }

  const updateProject = (data) => {
    closeUpdateDialog();
    setLoading(true);
    dataProvider.updateProject('projects',data)
      .then(({ data }) => {
        refresh();
        setLoading(false);
      })
      .catch(error => {
        refresh();
        setLoading(false);
      })
  }

  const cloneProject = (projectInfo) => {
    closeCloneDialog();
    setLoading(true);
    projectInfo['projectId'] = selectedProject.id;
    dataProvider.create('clone-project',{data: projectInfo}).then(({data}) => {
      // console.log(data);
      refresh();
      setLoading(false);
    })
    .catch(err => {
      console.log(err);
      setLoading(false);
    });
  }

  if(loading || !permissions ) return <Loading loadingPrimary="" loadingSecondary=""/>;

  if (!ids || !data) return null;

  return (
    <>
      <Paper square className={classes.paper}>
        <Tabs
          value={Tabvalue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}              
        >
          <Tab label="Pre Contract" classes={{ root: classes.tab,selected: classes.selectedTab }}/>
          <Tab label="Design & Approval" classes={{ root: classes.tab,selected: classes.selectedTab }}/>
          <Tab label="Construction" classes={{ root: classes.tab,selected: classes.selectedTab }}/>
          
        </Tabs>
        
              
        {(Tabvalue === 0) ? (
          <ProjectWrapper
            type="pre_contract"
            ids={ids}
            data={data}
            basePath={basePath}
            openUpdateDialog={openUpdateDialog}
            openDeleteDialog={openDeleteDialog}
            openCloneDIalog={openCloneDIalog}
            role={permissions.role}
          />
        ) : null}

        {(Tabvalue === 1) ? (
          <ProjectWrapper 
            type="design_approval"
            ids={ids}
            data={data}
            basePath={basePath}
            openUpdateDialog={openUpdateDialog}
            openDeleteDialog={openDeleteDialog}
            openCloneDIalog={openCloneDIalog}
            role={permissions.role}
          />
        ) : null}

        {(Tabvalue === 2) ? (
          <ProjectWrapper
            type="construction"
            ids={ids}
            data={data}
            basePath={basePath}
            openUpdateDialog={openUpdateDialog}
            openDeleteDialog={openDeleteDialog}
            openCloneDIalog={openCloneDIalog}
            role={permissions.role}
          />
        ) : null}

      </Paper>

      <DeleteDialog
        open={openDelete}
        close={closeDeleteDialog}
        project={selectedProject}
        deleteProject={deleteProject}
      />
      {(selectedProject && (
        <CloneDIalog
          open={openClone}
          close={closeCloneDialog}
          project={selectedProject}
          cloneProject={cloneProject}
        />
      ))}
      <UpdateProject
        open={openUpdate}
        close={closeUpdateDialog}
        project={selectedProject}
        updateProject={updateProject}
      />
    </>
  );
};


const GridList = ({ width }) => {
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedGridList width={width} />
  ) : (
    <LoadingGridList width={width} />
  );
};

export default withWidth()(GridList);
