import React from 'react';
import { useListContext,linkToRecord,useDataProvider,useRefresh,Loading } from 'react-admin';
import MuiGridList from '@material-ui/core/GridList';
import { makeStyles } from '@material-ui/core/styles';
import GridListTile from '@material-ui/core/GridListTile';
import {
  CircularProgress,
  IconButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  LinearProgress,
  CardMedia,
  Box,
  Avatar,

} from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(theme => ({
  gridList: {
    margin: '20px 0 !important',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  anchor: {
    textDecoration: 'none',
    color: '#dadbe6',
    '&:active':{
      color: '#fff',
    }
  },
  card:{
    width:300,
    boxShadow:'0px 0px 15px rgba(73, 78, 92, 0.15)',
    borderRadius:8,
    minHeight: 270,
  },
  cardHeader: {
    background: '#e5d893',
    padding: 8,
  },
  cardContent: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 0,
    },
    '& p':{
      maxWidth: 300,
      padding: '5px 0',
      fontSize: 14,
      color: '#67655b',
    },
    '& h4': {
      paddingTop:5,
      fontSize: 16,
      textTransform: 'capitalize',
      color: '#a17607',
    }
  },
  action: {
    margin: 0,
    paddingLeft: 10,
  },
  avatar: {
    width: 30,
    height: 30,
  },
  title: {
    fontSize:15,
  },
  progress: {
    marginTop: 5,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  clientGrid: {
    padding: '10px 0'
  },
  clientName: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: '#443918 !important',
  },
  detprogress: {
   
  },
  detProgressComplete: {
    backgroundColor: '#93bb6b',
  }
}));


const ProjectWrapper = (props) => {
  const { ids, data,type,basePath,openUpdateDialog,openDeleteDialog,role,openCloneDIalog } = props;
  const classes = useStyles();

  if (!ids || !data || !type) return null;

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
      >
        {ids.map((id) => {
          return (
            <div key={id}>
            {(data[id].type === type) && (
              <Box p={1} m={2} alignItems="center" key={id} >
                <Card className={classes.card} style={{ borderLeft: data[id].status === 2 || data[id].percentage >= 100 ? '2px solid #93bb6b' : ''}}>
                  <CardHeader
                    classes={{ root:classes.cardHeader, action: classes.action,title: classes.title}}
                    action={
                      <>
                        {role === 'admin' && (
                          <>
                            <IconButton size="small" aria-label="edit" onClick={() => openUpdateDialog(data[id])}>
                              <EditIcon />
                            </IconButton>
                            {data[id].type && data[id].type !== 'construction' && (
                              <IconButton size="small" aria-label="clone project" onClick={() => openCloneDIalog(data[id])}>
                                <FileCopyIcon />
                              </IconButton>
                            )}
                            <IconButton size="small" aria-label="delete" onClick={() => openDeleteDialog(data[id])} >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </>
                    }
                    title={data[id].title}
                  />
                  <Link to={linkToRecord(basePath, data[id].id)} className ={classes.anchor}>
                    <CardMedia
                      className={classes.media}
                      image={(data[id].facade) ? data[id].facade : process.env.PUBLIC_URL + '/facade-placeholder.png'}
                      title="project Facade"
                    />
                
                    <CardContent
                      classes={{ root:classes.cardContent}}
                    >

                      <Typography variant="body2" component="p">
                        {data[id].description}
                      </Typography>

                      <Typography variant="body2" component="p">
                        {`Date Of Commencement ${data[id].startDate}`}
                      </Typography>

                      <>
                      {(data[id].projectAssigned) ? 
                      (data[id].projectAssigned && data[id].tasks !== undefined) ? (
                        <>
                          <Typography variant="body2" component="p">
                          {`Task Done : ${data[id].tasksCompleted}/${data[id].tasks}`}
                          </Typography>

                          <Typography variant="body2" component="p">
                          {`Overall Progress: ${data[id].percentage}%`}
                          </Typography>

                          <LinearProgress
                            variant="determinate" 
                            value={data[id].percentage}
                            className={classes.progress}
                            classes={{bar1Determinate: (data[id].status === 2 || data[id].percentage >= 100) ? classes.detProgressComplete : classes.detprogress }}
                          />
                        </>
                      ) : <CircularProgress size={30} />
                      : null }
                      
                      </>

                      <Grid container direction="row" justify="space-between" className={classes.clientGrid}>
                        <Typography className={classes.clientName}>
                          {`${data[id].client.name}`}
                        </Typography>
                        <Avatar
                          alt={`${data[id].client.name}`}
                          src={data[id].client.avatar}
                          className={classes.avatar}>
                        </Avatar>
                      </Grid>

                    </CardContent>
                  </Link>
                </Card>
              </Box>
            )}
            </div>
          )
        })}
      </Box>
    </>
  );
};

export default ProjectWrapper;
