import * as React from "react";
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import SideBar from './SideBar';

const theme = {
  palette: {
    primary: {
      main: '#e5aa13',
    },
    secondary: {
      main: '#f6f6f6',
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
  },
  sidebar: {
    width: 200,
},
  overrides: {
    RaMenuItemLink: {
      active: {
        borderLeft: '1px solid #f0be40',
        // background: '#55524b',
      },
    },
  }
}
export default (props) => {
  // const theme = useSelector((state: AppState) =>
  //     state.theme === 'dark' ? darkTheme : lightTheme
  // );
  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={SideBar}
      menu={Menu}
      theme={theme}
    />       
  );
};

