
import React from 'react';
import { MessageText,Bubble } from 'react-web-gifted-chat';
import { makeStyles } from '@material-ui/core/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Button from '@material-ui/core/Button';

const DocumentPreview = (props) => {

  const classes = useStyles();

  const downloadFile = (url,name) => {
    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      })
      .catch(err => {
        // console.log(err);
      });
  }

  const openFile = (url,name) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target',"_blank",);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const { currentMessage, position } = props;
  return (
    <div className={classes.fileContainer}>
      <div
        className={classes.fileTitle}
        style={{color: position === 'right' ? '#fff' : '#000'}}
        onClick={() => openFile(currentMessage.document,currentMessage.text)}
      >
        {currentMessage.text}
      </div>
        <InsertDriveFileIcon className={[classes.fileIcon, position === 'right' ? classes.iconWhite : {}]} />
        {(currentMessage.sent || position === 'left') && (
          <Button
            className={classes.dwnldButton}
            variant="contained"
            onClick={() => downloadFile(currentMessage.document,currentMessage.text)}
          >
            Download
          </Button>
        )}
    </div>
  )
};

export const renderMessageText = (props) => {
  return (
  props.currentMessage && props.currentMessage.document ? (
    <DocumentPreview {...props} />
  ) : (
    <MessageText {...props} />
  )
  );
}

export const renderBubble = (props) => (
  <Bubble
      {...props}
      containerStyle={{
        //left: { borderColor: 'teal', borderWidth: 8 },
        right: {},
      }}
      wrapperStyle={{
        //left: { borderColor: 'tomato', borderWidth: 4 },
        right: {backgroundColor: '#e5aa13'},
      }}
    />
);

const useStyles = makeStyles({
  fileContainer: {
    padding: 20,
    cursor: 'pointer'
  },
  fileTitle: {
    fontWeight: 'bold',
  },
  fileIcon: {
    margin: '10px 0px',
  },
  iconWhite: {
    color: '#fff',
  },
  dwnldButton: {
    width: '100%',
    boxShadow: 'none',
  }
});

