import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  EmailField,
  ReferenceInput,
  AutocompleteInput,
  // useListContext,
  // sanitizeListRestProps,
  // TopToolbar,
  // CreateButton
  Filter,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ContractorCreate from './ContractorCreate';
import ContractorEdit from './ContractorEdit';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import FullNameField from './FullNameField';

const useStyles = makeStyles({
  heading: {
    padding: 16,
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color: '#e7b02c',
    }
  },
  statusField: {
    color: '#ffff',
    padding: 5,
    fontSize: 13,
    textTransform: 'capitalize',
    borderRadius: 5,
    display: 'block',
    textAlign: 'center',
  },
  activated: {
    background: '#1e911e',
  },
  notActivated: {
    background: '#ed3636',
  },
});

// const FullNameField = ({ record = {} }) => {
//   let name = (record.fullname)? `${record.fullname}`: `${record.first_name} ${record.last_name}`;
//   return (
//     <span>{name}</span>
//   )
// }
// FullNameField.defaultProps = { label: 'Name' };

const AddressField = ({ record }) =>
  record ? (
    <span>
      {record.street} {record.suburb} {record.state} {record.zip}
    </span>
  ) : null;

AddressField.defaultProps = { label: 'Address' };

const StatusField = ({ record = {} }) => {
  const classes = useStyles();
  return (
    (record.status === 1) ? (
      <span className={`${classes.statusField} ${classes.activated}`} >{'activated'}</span>
    ) : (
      <span className={`${classes.statusField} ${classes.notActivated}`} >{'not activated'}</span>
    )
  )
};

StatusField.defaultProps = { label: 'Status' };

// const ContractorListActions = (props) => {
//   const {...rest} = props;
//   const { basePath } = useListContext();
//   return (
//     <TopToolbar {...sanitizeListRestProps(rest)} >
//       <CreateButton basepath={basePath} />
//     </TopToolbar >
//   );
// }

const ContractorList = props => {
  // console.log(props);
  const classes = useStyles();
  return (
    <React.Fragment>

      <Route
        path="/contractors/create"
        render={() => (
          <ContractorCreate {...props} />
        )}
      />
      <Route path="/contractors/:id">
        {({ match }) => {
          const isMatch =
            match &&
            match.params &&
            match.params.id !== 'create';

          return (
            <div>
              {isMatch ? (
                <ContractorEdit id={isMatch ? match.params.id : null} {...props} />
              ) : (
                <>
                  <Grid container direction="row" alignItems="center" className={classes.heading}>
                    <ListIcon />
                    <Typography variant="h6" gutterBottom>
                      Sub Contractors
                    </Typography>
                  </Grid>
                  <List {...props} filters={<ContractorFilter />} sort={{ field: 'firstname', order: 'ASC' }} actions={false} >
                    <Datagrid>
                      <FullNameField {...props} />
                      {/* <FullNameField source="first_name" /> */}
                      <EmailField source="email" />
                      <TextField source="contact" />
                      <AddressField />
                      <StatusField />
                      <EditButton source="Action" />
                    </Datagrid>
                  </List>
                </>
              )}
            </div>
          );
        }}
      </Route>

    </React.Fragment>
  )
};
const optionRenderer = choice => (choice?.fullname || '');
const ContractorFilter = (props) => {
  const useStyles = makeStyles({
    filterHolder: {
      width: '100%',
      display: 'flex',
      flexDirection: "row",
      justifyContent: 'end'
    },

  });
  const classes = useStyles();
  return (
    <div className={classes.filterHolder}>
      <Filter {...props}>
        <ReferenceInput
          reference="contractors"
          source="_id"
          label="Search by Name"
          alwaysOn
          resource="contractors"
          fullWidth={true}
          filter={{ all: true, status: 1 }}
        >
          <AutocompleteInput
            optionText={optionRenderer}
            optionValue="id"
            options={{
              suggestionsContainerProps: {
                disablePortal: true,
              }
            }}
            variant="outlined"
          >
          </AutocompleteInput >
        </ReferenceInput >

      </Filter>
    </div>
  )
};

export default ContractorList;
