import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, List, ListItem } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import { usePermissions } from 'react-admin';
const CompanyList = (props) => {
  const { projectId, contacts } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { permissions } = usePermissions();
  if (!contacts) return null;

  return (
    <>
      <List>
        {contacts && contacts.length > 0 && contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
          <ListItem
            className={classes.item}
            disableGutters
            onClick={() => {

            }}
          >
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={RouterLink}
              to={permissions?.role === 'contractor' ? `#` : `/project-contacts-edit/${item.id}/${projectId}`}
            >
              <Box className={classes.content}>
                <Box className={classes.title}>
                  {item.designation || ''}
                </Box>
                <Box className={classes.text} style={{ color: '#ffa500' }}>
                  {item.name}
                </Box>
                <Box className={classes.text}>
                  {`Email: ${item.email}`}
                </Box>
                <Box className={classes.text}>
                  {`Contact: ${item.phone}`}
                </Box>
              </Box>
            </Button>
          </ListItem>
        ))}

      </List>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={contacts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    // color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    textAlign: 'left',
    color: '#000',
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  }
}));

export default CompanyList;
