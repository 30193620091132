import * as React from "react";
import {
  Edit, SimpleForm, TextInput, SelectInput, PasswordInput,
  useNotify, useRefresh, useRedirect, required, CheckboxGroupInput

} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { useForm } from 'react-final-form';
import ImageUpload from '../components/ImageUpload';
import { Field } from 'react-final-form';
var generator = require('generate-password');

const useStyles = makeStyles({
  main: {
    width: '100%',
  },
  heading: {
    '& h6': {
      fontSize: 14,
      padding: '10px 5px',
      fontWeight: 'bold',
      color: '#e5aa13',
    }
  },
  passwordTitle: {
    fontSize: 12,
    textTransform: 'capitalize',
    paddingBottom: 10,
    color: '#FF9800',
  },
  passwordButton: {
    margin: 10,
  }
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};


const UserEdit = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles();

  const onSuccess = ({ data }) => {
    notify(`Changes to manager updated`);
    redirect('/managers');
    refresh();
  };
  return (
    <Edit onSuccess={onSuccess} undoable={false} {...props}>
      <SimpleForm>
        {/* <SectionHeading classes={classes} label="Edit manager" /> */}
        <HeaderWIthUpload {...props} classes={classes} />
        <Grid container spacing={1} className={classes.main}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="first_name"
              validate={required()}
              fullWidth={true}
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="last_name"
              validate={required()}
              fullWidth={true}
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="username"
              validate={required()}
              fullWidth={true}
              label="User Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              source="designation"
              //validate={required()}
              fullWidth={true}
              label="Designation"
              variant="outlined"
              choices={ [
                { id: 'Supervisor', name: 'Supervisor' },
                { id: 'Project Coordinator', name: 'Project Coordinator' },
                { id: 'Accounts', name: 'Accounts' },
                { id: 'Architect',name: 'Architect'},
                { id: 'Manager',name: 'Manager'},
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="email"
              fullWidth={true}
              label="Email Address"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              source="contact"
              validate={required()}
              fullWidth={true}
              label="Contact Number"
              variant="outlined"
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
              <TextInput
                source="street"
                validate={required()}
                multiline
                fullWidth={true}
                label="Street"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                source="suburb"
                validate={required()}
                fullWidth={true}
                label="Suburb"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                source="state"
                validate={required()}
                fullWidth={true}
                label="State/Province/Region"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                source="zip"
                validate={required()}
                fullWidth={true}
                label="Postal code"
                variant="outlined"
              />
            </Grid> */}
          <PasswordField classes={classes} />
          <Grid item xs={12} >
            <SelectInput
              source="status"
              choices={[
                { id: 1, name: 'Enabled' },
                { id: 0, name: 'Disabled' },
              ]}
              defaultValue={1}
              fullWidth={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} >
            <SelectInput
              source="tenderaccess"
              choices={[
                { id: 1, name: 'Enabled' },
                { id: 0, name: 'Disabled' },
              ]}
              defaultValue={1}
              fullWidth={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} >
            <Grid item xs={12}>
              <CheckboxGroupInput
                source="activity"
                label="Activity Access"
                choices={[
                  { id: 0, name: 'Tender Management' },
                  { id: 1, name: 'Quality Inspection' },
                ]} />
            </Grid>
          </Grid>

        </Grid>
        <Field name="profile_Img" component="input" type="hidden" />
      </SimpleForm>
    </Edit>
  )
};

const HeaderWIthUpload = (props) => {
  const { classes } = props;
  const form = useForm();
  let profile_Img = form.getFieldState('profile_Img');

  return (
    <Grid container spacing={1} className={classes.main}>
      <Grid item xs={12} sm={6}>
        <Grid container direction="row" alignItems="center" className={classes.heading}>
          <CreateIcon />
          <Typography variant="h6" gutterBottom>
            Edit manager
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageUpload {...props} src={profile_Img && profile_Img.value ? profile_Img.value : ''} />
      </Grid>
    </Grid>
  )
}

const PasswordField = ({ classes }) => {
  const form = useForm();
  const changePassword = () => {
    form.change('password', generator.generate(
      {
        length: 15,
        numbers: true
      }
    ));
  }
  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={9}>
        <PasswordInput
          fullWidth={true}
          source="password"
          variant="outlined"
          helperText="leave blank if you doesn't want to change"
        //validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button variant="outlined"
          color="primary"
          className={classes.passwordButton}
          onClick={changePassword}
        >
          Generate Password
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserEdit;