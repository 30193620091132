/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Loading } from 'react-admin';
import Badge from '@material-ui/core/Badge';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ForumIcon from '@material-ui/icons/Forum';

function RoomList(props) {
  const { setSelectedRoom,selectedRoom,list } = props;
  //console.log(unreadCount)
  const classes = useStyles();

  if(!list) return <Loading loadingPrimary="" loadingSecondary="" />;
  if(!list || list.length < 0) {return null;}

  return (
    <List className={classes.root}>
      {list.map(room => (
        <div key={room.id}>
          <ListItem
            alignItems="flex-start"
            onClick={() => setSelectedRoom(room.id)}
            classes={{root: (selectedRoom && selectedRoom === room.id) ?  classes.selectedListItem : classes.listItem}}
          >
            <ListItemAvatar>
              <Avatar className={classes.small} alt={room.client.name} src={room.client.avatar} />
            </ListItemAvatar>
            <ListItemText
              classes={{primary:classes.listItemTitle,secondary: classes.listItemSubTitle}}
              primary={room.client.name}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {`${room.project.title}`}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                   {room.project.description}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              {room.unRead > 0 && (
                <Badge badgeContent={room.unRead} color="primary">
                  <ForumIcon />
                </Badge>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" />
        </div>
      ))}
  </List>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  selectedListItem: {
    backgroundColor: '#e7aa13',
    cursor: 'pointer',
    display: 'block',
  },
  listItem: {
    backgroundColor: '#fffff',
    cursor: 'pointer',
  },
  inline: {
    display: 'inline',
    fontSize: 13,
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listItemTitle: {
    fontSize: 14,
  },
  listItemSubTitle: {
    fontSize: 12,
  }
}));

export default RoomList;
