import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNotify, useDataProvider, Loading, Error,Button } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: `50%`,
    left: `55%`,
    transform: `translate(-50%, -55%)`,
    width: 300,
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  title: {
    color: '#d0a229',
  }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ApproveButton = ({ record, selectedContractors,loading,instance,onCloseModal}) => {
  selectedContractors = selectedContractors.map((contractors) => { 
    return contractors.id; 
  });
  const notify = useNotify();
  //const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const approve = () => {
    const params = {phaseInstanceid:instance, contractors: selectedContractors};
    dataProvider.updateTaskContractors(record['id'],params)
    .then(({ data }) => {
      onCloseModal();
    // success side effects go here
    // redirect('/phases');
    // notify('Contractor Updated');
  })
  .catch(error => {
    // failure side effects go here 
    notify(`Contractor Updating error: ${error.message}`, 'warning');
  })}; 
  return <Button label="Save" onClick={approve} disabled={loading} />
};

export default function ContractorSelection({record, onCloseModal}) {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [contractors, setContractors] = React.useState([]);
  const [instanceId, setInstanceId] = React.useState('');

  React.useEffect(() => {
    dataProvider.getTaskContractors(record['id']).then(({ data }) => {
      setData(data.contractors);     
      // setContractors(data.instances[0].contractors);
      if(data.instances) {
        setInstanceId(0);
        data.instances.contractors.map((contractor) => {
          let filteredContractor = data.contractors.filter((x) => {
            return x.id === contractor.id;
          });
          setContractors((contractors) => [...contractors, filteredContractor[0]]);
        });
      }
      
      setLoading(false);
    })
    .catch(error => {
      setError(error);
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;
  if (error) return <Error />;
  if (!data) return null;

  const handleChange = (event) => {
    // console.log(event.target.value);
    setContractors(event.target.value);
  };
  
  return (
    <div className={classes.paper}>
      <FormControl className={classes.formControl}>
        <Typography variant="h6" gutterBottom className={classes.title}>
          Select Sub Contractors
        </Typography>
        <Select
          multiple
          value={contractors}
          onChange={handleChange}
          fullWidth={true}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((contractor) => (
                <Chip key={contractor.id} label={`${contractor.fullname}`} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {data.map((contractor) => (
            <MenuItem key={contractor.id} value={contractor}>
              {`${contractor.fullname}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ApproveButton
        record={record}
        selectedContractors={contractors}
        instance={instanceId}
        loading={loading}
        onCloseModal={onCloseModal}
      />
    </div>
  );
}

