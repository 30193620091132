import React,{ useEffect, useState} from 'react';
import { 
  usePermissions,
  useAuthProvider,
  useDataProvider,
  useNotify,
  useRedirect,
  Button 
} from 'react-admin';

import {Grid,Typography,Container,TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Emptyschedule from './declineError';

const ConfirmTask = props => {
  const { taskId } = props;
  const { permissions } = usePermissions();
  const [taskInfo, setTaskInfo] = useState();
  const [error, setError] = useState();
  const [notes, setnotes] = useState({managerNote: '',requestNote:''});
  const [availableDate, setAvailableDate] = useState();
  const [loading, setLoading] = useState(false);
  const [notesError, setnotesError] = useState();
  const [ openRequestDialog, setOpenRequestDialog ] = React.useState(false);
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const closeRequestDialog = () => {
    setOpenRequestDialog(false);
    setnotesError(null);
    setnotes({managerNote:'',requestNote: ''});
  };

  const ApproveTask = () => {
    setLoading(true);
    let contractorNotes = (!notes.managerNote) ? '': notes.managerNote;
    dataProvider.create('accept-task', { data:{id: taskId,notes: contractorNotes,user: user.id}})
    .then(response => {
      setLoading(false);
      redirect('/');
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
      notify(`task approval error: ${error.message}`, 'warning');
    });
  }

  const DeclineTask = () => {
    if(!notes.managerNote || notes.managerNote === '') {
      setnotesError('Add Reason for Declining');
      return;
    }
    setLoading(true);
    dataProvider.create('decline-task', { data:{id: taskId,notes: notes.managerNote,user: user.id}})
    .then(response => {
      setLoading(false);
      redirect('/');
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
      notify(`task declining error: ${error.message}`, 'warning');
    });
  }

  const submitDateChange = () => {
    //notify(`task approval error: ${error.message}`, 'warning');
    if(!notes.requestNote || notes.requestNote === '') {
      setnotesError('Please Add Reason for Date CHanging');
      return;
    }
    if(!availableDate || availableDate === 'undefined') {
      notify(`Please Choose An Available Date`, 'warning');
      return;
    }
    setLoading(true);
    console.log({id: taskId,notes: notes.requestNote,user: user.id,date: availableDate});
    dataProvider.create('task-date_change', { data:{id: taskId,notes: notes.requestNote,user: user.id,date: availableDate}})
    .then(response => {
      setLoading(false);
      console.log(response);
      notify(`Succesfully Requested task Date Change`,'info');
      redirect('/');
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
      notify(` error occured: ${error.message}`, 'warning');
    });
  }

  const RequestDate = () => {
    setOpenRequestDialog(true);
    setnotes({managerNote:'',requestNote: ''});
    setnotesError(null);
  }

  const handleNoteChange = (event) => {
    setnotes({...notes,managerNote:event.target.value});
    setnotesError(null);
  }

  const handleRequestNoteChange = (event) => {
    setnotes({...notes,requestNote:event.target.value});
    setnotesError(null);
  }

  const handleDateChange = (event) => {
    setAvailableDate(event.target.value);
  }
  

  useEffect(() => {
    dataProvider.getOne('accept-task', {id: taskId})
      .then(({ data }) => {
        //console.log(data);
        if(!data.status) {
          setTaskInfo(data);
        } else {
          setError('Already Confirmed');
        }
        
      })
      .catch(error => {
        // console.log(error.message);
        setError(error.message);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(error) return <ErrorField message={error} />;
  if(!taskId || !permissions || permissions.role !== 'contractor' || !user || !taskInfo) {return null};

  return (
    <Container className={classes.main}>
      <div className={classes.wrapper} >
        <Grid container direction="row" alignItems="center" >
          <Typography className={classes.wrapperTitle}>Project:</Typography>
          <Typography className={classes.wrapperItem}>{`${taskInfo.project}`}</Typography>
        </Grid>
        <Grid container direction="row" alignItems="center" >
          <Typography className={classes.wrapperTitle}>Task:</Typography>
          <Typography className={classes.wrapperItem}>{`${taskInfo.title}`}</Typography>
        </Grid>
        <Grid container direction="row" alignItems="center" >
          <Typography className={classes.wrapperTitle}>Start date:</Typography>
          <Typography className={classes.wrapperItem}>{`${taskInfo.startDate}`}</Typography>
        </Grid>
        <Grid container direction="row" alignItems="center" >
          <Typography className={classes.wrapperTitle}>End Date:</Typography>
          <Typography className={classes.wrapperItem}>{`${taskInfo.endDate}`}</Typography>
        </Grid>
        <Grid container direction="row" alignItems="center" >
          <Typography className={classes.wrapperTitle}>Duration:</Typography>
          <Typography className={classes.wrapperItem}>{`${taskInfo.duration} Days`}</Typography>
        </Grid>

        <Grid container direction="row" alignItems="center" >
          <Button label="Approve" disabled={loading} onClick={ApproveTask}  className={classes.approveBtn} />
          <Button label="Request Date" disabled={loading} onClick={RequestDate}  className={classes.requestButton} />
          <Button label="Decline" disabled={loading} onClick={DeclineTask}  className={classes.declineButton} />   
        </Grid>

        <Grid container direction="row" alignItems="center" >
          <Grid xs={6} className={classes.fieldHolder}>
            <TextField
              variant="outlined"
              label="Notes"
              multiline
              rowsMax={5}
              fullWidth={true}
              onChange={handleNoteChange}
              className={classes.textField}
              error={(notesError && notesError !== '')}
              helperText="Add Some Notes For Manager"
            />
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openRequestDialog}
        onClose={closeRequestDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Choose Your Available Date"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Your Available Date"
            type="date"
            onChange={handleDateChange}
            //value={availableDate}
            //defaultValue="2017-05-24"
            helperText="Choose Your Available Date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            variant="outlined"
            label="Notes"
            multiline
            rowsMax={5}
            fullWidth={true}
            onChange={handleRequestNoteChange}
            className={classes.textField}
            error={(notesError && notesError !== '')}
            helperText="State your reason for date changing"
          />
           <Button label="Submit" disabled={loading} onClick={submitDateChange}  className={classes.submitButton} />
        </DialogContent>
        
      </Dialog>
    </Container>
  )
};

const ErrorField = ({message}) => {
  return (
    <Emptyschedule message={message} />
  );
}

const useStyles = makeStyles((theme) =>({
  main: {
    width: '100%',
    height: '100%',
    background: '#f3f3f3',
  },
  wrapper: {
    border: '1px solid #dfd9d9',
    margin: '30px 0',
    padding: 10,
    background: '#fff',
  },
  wrapperItem: {
    fontSize: 14,
    padding: '10px 0'
  },
  wrapperTitle: {
    fontSize: 14,
    padding: '10px 0',
    fontWeight: 'bold',
    paddingRight: 10,
  },
  approveBtn: {
    background: '#109716',
    marginTop: 20,
    marginRight: 10,
    color: '#fff',
    '&:hover': {
      background: '#084f0c',
    },
    paddingRight: 15,
  },
  declineButton: {
    background: '#F44336',
    marginTop: 20,
    marginRight: 10,
    color: '#fff',
    '&:hover': {
      background: '#a32920',
    },
    paddingRight: 15,
  },
  requestButton: {
    background: '#FF9802',
    marginTop: 20,
    marginRight: 10,
    color: '#fff',
    '&:hover': {
      background: '#c67d13',
    },
    paddingRight: 15,
  },
  submitButton: {
    background: '#FF9802',
    marginTop: 20,
    marginBottom: 20,
    marginRight: 10,
    color: '#fff',
    '&:hover': {
      background: '#c67d13',
    },
  },
  textField: {
    marginTop: 10,
  },
  fieldHolder: {
    padding: 10,
  }
}));

export default ConfirmTask;
