import * as React from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
const TaskSortButton = ({ searchQuery, serachTask }) => {
  const classes = useStyles();
  const [string, setString] = React.useState();

  return (
    <div className={classes.container}>
      <TextField
        onChange={(event) => { setString(event.target.value); }}
        defaultValue={searchQuery}
        variant="outlined"
        placeholder='Search by Task Name'
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="end">
            {searchQuery ?
              <IconButton
                disabled={!searchQuery}
                onMouseDown={() => serachTask('')}
              >
                <HighlightOffIcon fontSize="small" />
              </IconButton>
              : <></>}
          </InputAdornment>,
          endAdornment: <InputAdornment position="end">
            <IconButton
              disabled={!string && !searchQuery}
              aria-label=""
              onClick={() => serachTask(string)}
              onMouseDown={() => serachTask(string)}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>,
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '15px 10px',
    textAlign: 'right',
  }
}));

export default TaskSortButton;
