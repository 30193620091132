import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DeleteEmployee({ setOpen, deleteEmployee, employee }) {
  return (
    <div>
      <DialogTitle id="alert-dialog-title">{`Are you want to delete ${employee.name}?`}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">
        </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() =>deleteEmployee(employee)} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </div>
  );
}