import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import TablePagination from '@material-ui/core/TablePagination';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {
    TableContainer,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Grid, Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { setSidebarVisibility, useDataProvider, useRedirect } from 'react-admin';
function QAinspectionList(props) {
    const { data, onEditClick, onDeleteClick, id } = props;
    const [loading, setLoading] = useState(true);
    const [inspection, setInspection] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const redirect = useRedirect();
    useEffect(() => {
        if (!loading) setLoading(true);
        dispatch(setSidebarVisibility(false));
        dataProvider.getInspectionList('get-qa-types', { id: id }).then(({ data }) => {
            console.log(data);
            setInspection(data.results);
            setLoading(false);
        })
            .catch(error => {
                setLoading(false);
            });
        return () => {
            dispatch(setSidebarVisibility(true));
        };
    }, []);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const formatAMPM = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes.toString().padStart(2, '0');
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime; 
    }
    return (
        <>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <SectionHeading label="QA Inspection List" classProp={classes.heading} />
                <Table className={classes.table} size="small" aria-label="material-table">
                    <TableHead>
                        <TableRow >
                            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>QA</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Date of Inspection</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Client Signature</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>PDF</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inspection?.length > 0 && inspection.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((inspectionItem) => (
                            <TableRow key={inspectionItem?.id} style={{ marginTop: 20, cursor: 'pointer' }}>
                                <TableCell><DoubleArrowIcon /></TableCell>
                                <TableCell onClick={() => redirect(`/project-Inspectionslist/${id}/${inspectionItem.id}`)}>{inspectionItem?.title}</TableCell>
                                <TableCell onClick={() => redirect(`/project-Inspectionslist/${id}/${inspectionItem.id}`)}>{moment(inspectionItem.created_at ? inspectionItem?.created_at : '').format("DD/MM/YYYY")}{' '}{formatAMPM(new Date(inspectionItem.created_at))}</TableCell>
                                <TableCell align="center" >{inspectionItem?.signature ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                <TableCell>
                                    {inspectionItem.pdf && inspectionItem.pdf.url ?
                                        <a href={(inspectionItem.pdf && inspectionItem.pdf.url) ? inspectionItem.pdf.url : ''} target="_blank">
                                            <PictureAsPdfIcon fontSize="large" style={{ color: 'green' }} />
                                        </a> : <PictureAsPdfIcon fontSize="large" style={{ color: 'gray' }} />
                                    }
                                </TableCell>
                                <TableCell onClick={() => redirect(`/project-Inspectionslist/${id}/${inspectionItem.id}`)} style={{ paddingTop: 30, paddingBottom: 30 }}></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table >
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={inspection?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer >
        </>
    )
}

const SectionHeading = ({ label, classProp }) => {
    return (
        <Grid container direction="row" alignItems="center" className={classProp}>
            <ListIcon />
            <Typography variant="h6" gutterBottom >
                {label}
            </Typography>
        </Grid>
    );
};

const useStyles = makeStyles({
    tableContainer: {
        marginTop: 15,
        paddingBottom: 15,
        boxShadow: 'none',
        border: '1px solid #ebebeb',
        width: 'auto',
        marginLeft: 10,
    },
    heading: {
        padding: 10,
        '& h6': {
            fontSize: 14,
            paddingLeft: 5,
            fontWeight: 'bold',
            letterSpacing: 1,
            color: '#e58f0a',
            textTransform: 'uppercase',
            marginTop: 5,
        }
    },
    table: {
        minWidth: 650,
    },
    deleteButton: {
        border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
    },
    fileHolder: {
        display: 'inline-flex',
        background: '#f3f3f3',
        padding: 10,
        marginBottom: 10,
        '& a': {
            textDecoration: 'none',
            color: '#514646',
        },
        margin: 5,
        textAlign: 'center',
    },
    // fileTitle: {
    //   fontSize: 13,
    //   paddingLeft: 10,
    //   maxWidth: 85,
    // },
    fileTitleYes: {
        fontSize: 13,
        fontWeight: 'bold',
        padding: '5px 0',
        paddingLeft: 40,
        color: 'green'
    },
    fileTitleNo: {
        fontSize: 13,
        fontWeight: 'bold',
        padding: '5px 0',
        paddingLeft: 40,
        color: 'red'
    },
    fileTitle: {
        fontSize: 13,
        fontWeight: 'bold',
        padding: '5px 0',
    },
    fileSubTitle: {
        fontSize: 12,
        color: '#322f2f',
    },
    fileIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 130,
    },
});


export default QAinspectionList;
