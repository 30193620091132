import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BackupIcon from '@material-ui/icons/Backup';
import {
  FileInput,
  SimpleForm,
  FileField,
  SaveButton,
  Toolbar,
  required
} from 'react-admin';

export default function UploadFiles({open,handleClose,saveFiles}) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <SimpleForm toolbar={<FileUplaodToolbar />} save={saveFiles}>
            <FileInput source="files" label="Related files" 
              accept="image/png, image/jpeg, application/pdf"
              validate={required()}
              multiple={true}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const FileUplaodToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      label="Upload"
      icon={<BackupIcon />}
    />
  </Toolbar>
);

