import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Typography, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { useRedirect, TextInput, useDataProvider, useNotify, FileInput, SelectInput } from 'react-admin';
import GavelIcon from '@material-ui/icons/Gavel';
import SaveIcon from '@material-ui/icons/Save';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import get from 'lodash/get';
const useStyles = makeStyles((theme) => ({
    BtnHolder: {
        width: '100%',
        textAlign: 'center',
        padding: '10px 0px',
    },
    BtnSave: {
        backgroundColor: '#670e3c',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#670e3c',
            color: '#fff',
        }
    },
    Width400: {
        width: 400,
    },
    Width100_p: {
        width: '100%',
    },
    loader_holder: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    loader: { color: '#670e3c' },
    errorField: {
        color: '#f00',
        fontSize: '0.75rem',
    },
    saveBtnHolder: {
        marginTop: 20,
        padding: 10,
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    saveBtn: {
        background: '#e5aa13',
        color: '#000',
        fontWeight: 'bold',
        '&:hover': {
            background: '#e5aa13',
        }
    },
    heading: {
        padding: 16,
        '& h6': {
            fontSize: 15,
            padding: 10,
            fontWeight: 'bold',
            color: '#e7b02c',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    removeBtn: {
        display: 'flex'
    },
    dropzoneContainer: {
        border: '1px dotted #ddd',
        borderRadius: 10,
    },
    modalContent: {
        '&::-webkit-scrollbar': {
            width: '0.5em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ddd',
            borderRadius: 15,
            outline: '1px solid #ddd'
        }
    }
}))
export default function MaterialEdit({ id }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [checklistcategory, setChecklistcategory] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    useEffect(() => {
        dataProvider.getQuestionCategories('checklistcategory', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
            var result = data.map(cat => ({ id: cat?._id, name: cat?.name }));
            setChecklistcategory(result);
        })
            .catch(error => {
            });
        dataProvider.getOneFloorPlan('floorplan', { id: id })
            .then(({ data }) => {
                setFormValues(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, []);
    const required = (value) => {
        if (value) {
            return undefined;
        } else {
            return '*Required';
        }
    }
    const requiredFile = value => ((value && value.length > 0) ? undefined : "*Attachements can't be empty");
    const updatePlans = (data) => {
        console.log(data);
        data = { ...data, projectId: formValues.project };
        dataProvider.updatefloorplan('floorplan', { data, id: id })
            .then(({ data }) => {
                redirect(`/project-floorplan/${formValues.project}`);
            })
            .catch(error => {
            });
    }
    const FilePreview = ({ record, source }) => {
        const classes = useStyles();
        let sourceValue = get(record, source);
        if (!sourceValue) { sourceValue = record.url };
        return (
            <div className={classes.fileHolder} >
                {((record.rawFile && record.rawFile.type === "application/pdf") || (record.type === "application/pdf")) ? (
                    <div className={classes.fileIcon}>
                        <a href={(record.undefined) ? record.undefined : record.url} target="_blank">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <PdfIcon fontSize="large" style={{ color: 'red', width: 40, height: 40 }} />
                                <Typography className={classes.fileTitle}>{(record.rawFile) ? record.rawFile.name : record.name}</Typography>
                                {(record.stat) && (
                                    <Typography className={classes.fileSubTitle}>{`Uploaded On ${record.stat}`}</Typography>
                                )}
                            </div>
                        </a>
                    </div>
                ) : (
                    <a href={(record.undefined) ? record.undefined : record.url} target="_blank">
                        <img
                            style={{
                                width: 130,
                                height: 100,
                            }}
                            src={sourceValue}
                            // className={classes.image}
                            alt=""
                        />
                        {(record.name) && (
                            <Typography className={classes.fileTitle}>{record.name}</Typography>
                        )}
                        {(record.stat) && (
                            <Typography className={classes.fileSubTitle}>{`Uploaded On ${record.stat}`}</Typography>
                        )}
                    </a>
                )}
            </div>
        )
    }
    return (

        <Form
            onSubmit={updatePlans}
            initialValues={{
                category: formValues?.checklistcategory?._id,
                attachments: formValues?.files,
            }}
            render={({ handleSubmit, form }) => {
                const uploadAttachments = (value) => {
                    let files = form.getFieldState(`attachments`).value;
                    let uploadedFiles = [], uploadingFiles = [];
                    if (files && files.length > 0) {
                        files.map(file => {
                            if (file && file.rawFile) {
                                uploadingFiles.push(file);
                            } else {
                                uploadedFiles.push(file);
                            }
                        });
                        setLoading(true);
                        dataProvider
                            .uploadAttachments({ files: uploadingFiles })
                            .then(({ data }) => {
                                if (data && data.length > 0) {
                                    uploadingFiles = data.map(fileInfo => {
                                        return fileInfo;
                                    });
                                    form.change(
                                        `attachments`,
                                        uploadingFiles.concat(uploadedFiles)
                                    );
                                }
                                setLoading(false);
                            })
                            .catch(error => {
                                setLoading(false);
                            });
                    }
                }
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1} style={{ margin: 20 }}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Field name='category' validate={required}>
                                    {({ input, meta }) => (
                                        <>
                                            <SelectInput
                                                allowEmpty={true}
                                                emptyText="--Select Category--"
                                                variant="outlined"
                                                label="category"
                                                source="category"
                                                {...input}
                                                fullWidth={true}
                                                error={meta.error && meta.touched ? true : false}
                                                choices={checklistcategory || []}
                                            />
                                        </>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item lg={12} xs={12} md={12} sm={12}>
                                <FileInput
                                    source="attachments"
                                    label="Attachments"
                                    validate={(value) => requiredFile(value)}
                                    accept=""
                                    multiple={true}
                                    placeholder="Click here to upload attachments"
                                    classes={{ dropZone: classes.dropzoneContainer, removeButton: classes.removeBtn }}
                                    onChange={uploadAttachments}
                                >
                                    <FilePreview />
                                </FileInput>
                            </Grid>
                            <div className={classes.saveBtnHolder}>
                                <Button disabled={loading} className={classes.saveBtn} type="submit">
                                    <SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                            </div>
                        </Grid>
                    </form>
                )
            }} />
    );
}