import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function DenseTable({data,dialogOpen,role,projectAsssigned}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} style={{ maxHeight: '100vh' }}>
      <div className={classes.tableTitle}>NEXA PROJECT TASKS (NEXA HOMES)</div>
      <Table className={classes.table} size="small" stickyHeader>
        <TableHead className={classes.tableAttr}>
          <TableRow >
            <TableCell align="center" className={classes.tableHead}>Title</TableCell>
            <TableCell align="left" className={classes.tableHead}>Duration</TableCell>
            <TableCell align="center" className={classes.tableHead}>Start</TableCell>
            <TableCell align="center" className={classes.tableHead}>Finish</TableCell>
            <TableCell align="center" className={classes.tableHead}>Assigned to</TableCell>
            <TableCell align="center" className={classes.tableHead}>Confirmation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((task) => {
            
            let assignedDetail;

            if(role ==='admin') {
              if(task.all_assignees && task.all_assignees.length > 0) {
                assignedDetail = task.all_assignees.map(_assignee =>  _assignee.name).toString();
              } else {
                assignedDetail = 'NA';
              }

            } else if(role ==='manager') {
              assignedDetail = 'NA';
              if(task.contractor_assignees && task.contractor_assignees.length > 0) {
                if(projectAsssigned || task.isTaskManager) {
                  assignedDetail = task.contractor_assignees.map(_assignee =>  _assignee.name).toString();
                } else {
                  assignedDetail = 'Assigned';
                }
              }
              
              
            } else if(role ==='contractor') {
              assignedDetail = 'NA';
              if(task.contractor_assignees && task.contractor_assignees.length > 0) {
                if(task.taskMine) {
                  let assignedInfo = task.contractor_assignees.find(_asssignee => _asssignee.id === task.userId);
                  assignedInfo = (assignedInfo) ? `${assignedInfo.name}` : 'NA';
                  assignedDetail = assignedInfo;
                } else {
                  assignedDetail = 'Assigned';
                }
              }
            } else {
              return null;
            }
            return (
              <TableRow key={task.id}
                style={{backgroundColor: task.taskMine ? '#d0d8bc' : ''}}
                onClick={() => dialogOpen(task)}>
                <TableCell component="th" scope="row" align="left"
                 style={{color: task.isOverrun ? 'red' : ''}}
                >
                  {task.title}
                </TableCell>
                <TableCell align="left">{task.duration}</TableCell>
                <TableCell align="left">{task.startReadable}</TableCell>
                <TableCell align="left">{task.endReadable}</TableCell>
                <TableCell align="left">{assignedDetail}</TableCell>
                <TableCell align="left">{task.confirmationStatus}</TableCell>
              </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: '100%',
    margin: '10px 0',
  },
  tableTitle:{
    background: '#9f9150',
    padding: 5,
    color: '#fff',
    textAlign: 'center',
    textTransform:'uppercase',
    fontSize: 12,
    fontWeight:'bold',
    margin: '10px 0',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  tableAttr:{
    borderBottom: '2px solid #ddd',
  },
  tableHead:{
    fontWeight:'bold',
  }
});
