// in src/App.js
import * as React from "react";
import { useEffect } from 'react';
import './App.css';
import { Admin, Resource } from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
// import authProvider from './authProvider';
import authProvider from './new_authProvider';
import dataProvider from './dataProvider';
import { Layout, LoginPage } from './layout';
import { Dashboard } from './dashboard';
import adminRoutes from './adminRoutes';
import { createBrowserHistory as createHistory } from 'history';

import managers from './managers';
import tasks from './tasks';
import phases from './phases';
import contractors from './contractors';
import projects from './projects';
import materials from './materials';
import suppliers from './suppliers';
import todos from './to-do';
import templates from './template';
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import Settings from './settings';
import Schedule from './schedules';
import Certificate from './Certificates';
import appPopups from './app-popups';
import clientInvites from './client-invites';

import ProjectsNew from './projectsnew';
import referals from './referals';
import ProjectTask  from './projecttask';

import ContractorSchedules from './contractors/Schedules';

const history = createHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e5aa13',
    },
    secondary: {
      main: '#f6f6f6',
    },
  },
  // typography: {
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  //   fontSize: 12,
  // },
  // sidebar: {
  //   width: 200,
  //   closedWidth: 70,
  // },
  overrides: {
    RaCheckboxGroupInput: {
      root: {
        borderLeft: '3px solid #fff',
        backgroundColor: '#4f3cc9'
      },
      label: {
        backgroundColor: '#4f3cc9',
        borderLeft: '3px solid #4f3cc9',
      },
    },
  },
});

const messages = {
  en: englishMessages
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], "en", {
  allowMissing: true
});


window.OneSignal = window.OneSignal || [];
const OneSignal = window.OneSignal;

const App = () => {

  useEffect(() => {

    if(localStorage.getItem('nexa_pm_user_token')) {

      OneSignal.push(()=> {

        OneSignal.init({
          appId: "ae00703c-ab7f-4c08-95ba-b197894a6a30",  
          autoResubscribe: true,
          notifyButton: {
            enable: true,
          },
          
          // notificationClickHandlerMatch: 'origin',
          // notificationClickHandlerAction: 'focus',

          promptOptions: {
            slidedown: {
              enabled: true,
              actionMessage: "NexaHomes would like to show you notifications.",
              acceptButtonText: "Yes",
              cancelButtonText: "Not Now",
            } 
          },
          welcomeNotification: {
            "title": "Nexahomes",
            "message": "Thanks for subscribing!",
            // "url": "" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */
          }
        });
        
        const onNotificationClicked = (data) => { 
          console.log("Received NotificationOpened:");
          console.log(data);
        }

        const handler = (data) => {
          onNotificationClicked(data);
          OneSignal.addListenerForNotificationOpened(handler);
        };
        
        OneSignal.addListenerForNotificationOpened(handler);
      });
    }
     
  }, []);


  return (
    <Admin 
      layout={Layout}
      loginPage={LoginPage}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      //history={history}
      theme={theme}
      customRoutes={adminRoutes}
      //locale="en"
      //i18nProvider={i18nProvider}
    >
      {/* <Resource name="projects" {...projects} /> */}
      {permissions => [
        // <Resource name="projects" {...projects} />,
        // <Resource name="projects" {...ProjectsNew} />,
        permissions && permissions.role && (permissions.role === 'admin' || permissions.role === 'manager'|| permissions.role === 'contractor') ? <Resource name="projects" {...ProjectsNew} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="settings" list={Settings} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="tasks" {...tasks} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="phases" {...phases} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="contractors" {...contractors} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="certificate" {...Certificate} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="app-popups" {...appPopups} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="client-invites" {...clientInvites} /> : null,
        permissions && permissions.role && (permissions.role === 'admin' || permissions.role === 'manager')? <Resource name= "contractor-schedules" {...ContractorSchedules} /> : null,
        permissions && permissions.role && permissions.role === 'manager' ? (
          <Resource name="contractors"
          list={contractors.list}
          edit={null} />) : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="materials" {...materials} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="suppliers" {...suppliers} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="todos" {...todos} /> : null,
        permissions && permissions.role && permissions.role === 'admin' ? <Resource name="templates" {...templates} /> : null,
        // permissions === 'admin' ? <Resource name="users" {...users} /> : null,
        permissions && permissions.role && (permissions.role === 'admin' || permissions.role === 'manager') ? <Resource name="managers" {...managers} /> : null,
        permissions && permissions.role && (permissions.role === 'admin' || permissions.role === 'manager') ? <Resource name="projecttask" {...ProjectTask}/> : null,
        permissions && permissions.role && (permissions.role === 'admin' || permissions.role === 'company') ? <Resource name="referals" {...referals} /> : null,
        <Resource name="today-schedule" {...Schedule} />,
        <Resource name="user-projects" />,
      ]}
    </Admin>
  )
};

export default App;
