import * as React from "react";

import { 
  Create,TextInput,required,SimpleForm,SelectInput,AutocompleteInput,
  useNotify, useRefresh, useRedirect,ReferenceInput
} from 'react-admin';

import { Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const CertificateCreate = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Certificate Created`)
    redirect('/certificate');
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props} >
      <SimpleForm>
        <SectionHeading label="Create Certiifcate" classes={classes} />
        <TextInput
          fullWidth={true}
          source="title"
          validate={required()}
          variant="outlined"
        />
        <ReferenceInput
          reference="tasks"
          source={'task'}
          label="Task"
          variant="outlined"
          resource="tasks"
          fullWidth={true}
          validate={required()}
          filter={{all:true}}                     
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        
      </SimpleForm>
    </Create>
  );
};

export default CertificateCreate;
