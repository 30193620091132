import React, { useState,useEffect } from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import {Dialog,DialogContent,Select,MenuItem,Chip,Typography,ListSubheader} from '@material-ui/core';
import { 
  useDataProvider,
  Loading,
  SimpleForm,
  DateInput,
  TextInput,
  BooleanInput,
  useInput,
  required,
} from 'react-admin';
import NotesTab from './ContractorTab';

export default function TodoEdit(props) {
  const {open, handleClose, todoId,save, role} = props;
  const [loading, setLoading] = useState(true);
  const [todoData, setTodoData] = useState();
  const dataProvider = useDataProvider();

  const onSave = (data) => {
    delete data.contractorsList;
    delete data.managersList;
    save(data);
  }

  useEffect(() => {
    dataProvider.getOne('projecttodo',{id:todoId}).then(({ data }) => {
      let todoData = {
        id: data._id,
        title : (data.title) ? data.title : data.todo.title,
        dueDate: data.due_readable,
        assignees: data.assignees || [],
        complete: (data.status === 2) ? true : false,
        contractorsList:data.contractorslist,
        managersList: data.managerslist,
        all_notes: data.all_notes,
        internal_notes: data.internal_notes,
        sub_notes: data.sub_notes,
        owner_notes: data.owner_notes,
        files: (data.attachments && data.attachments.length > 0) ? data.attachments : [],
      }
      setTodoData(todoData);
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
    });
  },[]);

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      open={open} 
      maxWidth = {'md'}
    >
      <DialogContent>
        {(loading || !todoData) ? (
          <Loading loadingPrimary="" loadingSecondary="" />
        ) : (
          <SimpleForm initialValues={todoData} save={onSave}>
            <BooleanInput
              label={`Todo ${todoData.title} Completed`}
              source="complete"
              fullWidth={true}
            />
            <TextInput
              source="title"
              label="Title"
              fullWidth={true}
              variant="outlined"
              validate={required()}
            />
            <DateInput
              source="dueDate"
              label="Due Date"
              fullWidth={true}
              variant="outlined"
              validate={required()}
            />
             {(role && role === 'admin' && (
                <AssigneeInput
                  source="assignees"
                  todoData={todoData} 
                />           
              ))}     
            {/* <NotesTab /> */}
          </SimpleForm>
        )}
      </DialogContent>
    </Dialog>
  )
};
const AssigneeInput = props => {
  const classes = useStyles();
  const getLabelOfSelected = (id) => {
    if(id && id !== undefined) {
      let listArray = props.todoData.contractorsList.concat(props.todoData.managersList);
      let selectedInfo = listArray.find(item => {
        return item.id === id;
      });
      if(selectedInfo._contractor) {
        return selectedInfo._contractor.name.fullName;
      } else {
        return selectedInfo._manager.name.fullName;
      }
    }
  }
  const {
      input,
      meta: { touched, error }
  } = useInput(props);

  return (
    <>
    <Typography className={classes.assigneeTitle}>Choose Assignees</Typography>
      <Select
        {...input}
        label="Assignees"
        multiple
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map(value => {
              if(value){
                return (
                  <Chip key={value} label={getLabelOfSelected(value)} className={classes.chip} />
                )
              } else {
                return null
              }
            })}
          </div>
        )}
        className={classes.select}
      >
        <ListSubheader className={classes.listHeader}>Sub Contractors</ListSubheader>
        {props.todoData.contractorsList.map(contractor => (
          <StyledMenuItem
            key={contractor.id}
            value={contractor.id}
          >
            {contractor._contractor.name.fullName}
          </StyledMenuItem>
        ))}
        <ListSubheader className={classes.listHeader}>Managers</ListSubheader>
        {props.todoData.managersList.map(manager => (
          <StyledMenuItem
            key={manager.id}
            value={manager.id}
          >
            {manager._manager.name.fullName}
          </StyledMenuItem>
        ))}
      </Select>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    background: '#a16b1a',
    color: '#ffff',
  },
  listHeader: {
    fontSize: 14,
    textTransform: 'capitalize',
    color: '#dda809',
  },
  assigneeTitle: {
    fontSize: 13,
    padding: '10px 0',
    color: '#494743',
  },
  select: {
    width: '100%',
  }
}));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 14,
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
}))(MenuItem);