import * as React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useListContext } from 'react-admin';

const TypeFilterButton = ({ fields }) => {
  const { setFilters,filterValues } = useListContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [buttonFilter, setButtonFilter] = React.useState(filterValues && filterValues.type ? filterValues.type : 'pre_contract');

  // mouse handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSort = (event) => {
    const fieldName = event.currentTarget.dataset.filter;
    const field = event.currentTarget.dataset.value;
    setFilters({type: field})
    setButtonFilter(fieldName)
    setAnchorEl(null);
  };


    return (<>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        style={{background: '#e5aa13',color: '#ffff',fontSize: 14,padding: 6}}
        onClick={handleClick}
        startIcon={<SortIcon />}
        endIcon={<ArrowDropDownIcon />}
        size="small"
      >
        {`Project Type ${buttonFilter}`}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fields.map(field => (
          <MenuItem
            onClick={handleChangeSort}
            data-filter={field.type}
            data-value={field.value}
            key={field.value}
          >
            {field.type.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </>);
};


export default TypeFilterButton;
