import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  TextInput,
  RadioButtonGroupInput,
  useDataProvider
}
  from 'react-admin';
import { InputAdornment, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import {useHistory} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
}));

function MaterialCreate(props) {
  const { save, projectId, unit ,RevId } = props;
  const classes = useStyles();
  const [rows, setRows] = useState();
  const [initialval, setDefault] = useState();
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const history = useHistory();
  useEffect(() => {
    dataProvider.getList('final', { filter: { _id:RevId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      console.log(data)
      setDefault(data[data.length-1])
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  }, [])
  const onSave = (data) => {
    data['project'] = projectId
    dataProvider.createCategory(data, 'final').then(({ data }) => {
      // console.log(data);
      history.push( `/project-tender/${projectId}/${RevId}`)
    }).catch(error => {

    });
  };

  return (
    <SimpleForm save={onSave}>
      <SectionHeading label="Tender Revision Details" classProp={classes.heading} />
      <GridField initialval={initialval} />
    </SimpleForm>
  )
}

const GridField = (props) => {
  // console.log(props)
  const { initialval } = props;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3}>
        <DateInput
          allowEmpty={true}
          variant="outlined"
          label="Tender Prepared"
          source="prepared_date"
          fullWidth={true}
          validate={required()}
          defaultValue={initialval?.prepared_date}

        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput
          source="rev_no"
          label="Rev No"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.rev_no}

        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput
          source="drg_rev"
          label="Drg Rev"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.drg_rev}

        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput
          source="construction_period"
          label="Construction Period"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.construction_period}

        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="description"
          label="Description"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.description}

        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="remarks"
          label="Remarks"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.remarks}

        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          source="rev_highlights"
          label="Revision Highlights"
          fullWidth={true}
          multiline
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.rev_highlights}

        />
      </Grid>
    </Grid>
  );
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

export default MaterialCreate;
