import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation,Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';

import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';

import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from '@material-ui/icons/Person';

import { lightTheme } from './themes';

import Logo from './Logo';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(2376627.jpg)',
    // background: 'rgb(186, 149, 56)',
    // background: '#27424b',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    backgroundColor: '#fff',
  },
  card: {
    minWidth: 310,
    marginTop: '6em',
    borderRadius: 15,
    // background: '#20353c',
    background: '#282a3c',
    border: '1px solid #ebbd17',
    // background: 'rgb(157 116 14)',
  },
  avatar: {
    marginTop: '2em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    root: {
      background: 'red',
    }
    // backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    fontSize: 13,
    display: 'flex',
    justifyContent: 'center',
    color:'#eda506',
    textDecoration: 'underline',
    padding: 20,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  button: {
    background: '#ffbe2e',
    borderRadius: 0,
    '&:hover':{
      background: '#ebbd17',
    },
  },
  circularProgress: {
    color: '#ebb10e',
  },
  multilineColor:{
    color:'#dadbe6',
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      // InputProps={{
      //   className: props.stylesProp
      // }}
      fullWidth
    />
  );

const { Form } = withTypes();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();
  const handleSubmit = (auth) => {
    setLoading(true);
    let successPath = (location.state && location.state.nextPathname && location.state.nextPathname !== '/login') ? location.state.nextPathname : '/';
    //console.log(successPath);
    login(auth, successPath).catch(
      (error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        );
      }
    );
  };

  const validate = (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "required";
      }
      
      if (!values.password) {
        errors.password = "required";
      }
      return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Logo />
                {/* <Avatar className={classes.icon}>
                    <LockIcon />
                </Avatar> */}
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="email"
                    type="email"
                    component={renderInput}
                    label={"Email"}
                    disabled={loading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <PersonIcon className={classes.icon}/>
                        </InputAdornment>
                      ),
                      className: classes.multilineColor
                    }}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    component={renderInput}
                    label="Password"
                    type="password"
                    disabled={loading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <LockIcon />
                        </InputAdornment>
                      ),
                      className: classes.multilineColor
                    }}
                  />
                </div>
              </div>

              <CardActions className={classes.actions}>
                
                <Button
                  className={classes.button}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={5}
                      className={classes.circularProgress}
                    />
                  )}
                  Sign in
                </Button>
              </CardActions>

              <Link to={'/forgot-password'}>
                <div className={classes.hint}>
                  Forgot Password?
                </div>
              </Link>
              

            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};
const LoginWithTheme = (props) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
