import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Grid, Badge, CircularProgress } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import Create from './create';
import Edit from './edit';
import { useDataProvider } from 'react-admin';
import PeopleIcon from '@material-ui/icons/People';
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	heading: {
		padding: 16,
		'& h6': {
			fontSize: 15,
			padding: 10,
			fontWeight: 'bold',
			color: '#e7b02c',
		},
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	loader_holder: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loader: { color: '#e5aa13' },
	tableheading: {
		padding: '0px 10px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	editBtn: {
		color: '#e5aa13',
		fontWeight: 'bold',
	},
	memberItem: {
		background: '#ddd',
		display: 'inline-block',
		margin: 5,
		padding: '2px 10px',
		fontSize: '13px',
		borderRadius: '10px',
	},
	emptyClass: {
		padding: 10, 
		textAlign: 'center',
		fontStyle: 'italic',
		 paddingBottom: 30,
	}
}));

export default function EnhancedTable() {
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rows, setRows] = React.useState(false);
	const [contractorList, setContractorList] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);
	const [flag, setFlag] = React.useState(false);
	const dataProvider = useDataProvider();
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	React.useEffect(() => {
		setLoading(true);
		dataProvider.getGroups().then(({ data }) => {
			setRows(data);
			setLoading(false);
		})
			.catch(error => {
				setLoading(false);
			});
		dataProvider.getList('contractors', {
			pagination: { page: 1, perPage: 10 },
			sort: { field: '_id', order: 'asc' },
			filter: { "all": true, "status": 1 }
		}
		).then(({ data }) => {
			let dataList = data.map(item => ({ id: item.id, name: item.fullname }));
			setContractorList(dataList);
		})
			.catch(error => {
			});
	}, [flag]);
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	if (loading) {
		return (<div className={classes.loader_holder}> <CircularProgress className={classes.loader} /></div>)
	}
	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid container spacing={1} direction="row" alignItems="center" className={classes.main}>
					<Grid item xs={12} sm={12}>
						<Grid container direction="row" alignItems="center" className={classes.tableheading} >
							<div className={classes.heading} >
								<GroupIcon />
								<Typography variant="h6" gutterBottom>
									Groups
								</Typography>
							</div>
							<div>
								<Create setFlag={setFlag} flag={flag} contractorlist={contractorList} />
							</div>
						</Grid>
					</Grid>
				</Grid>
				{rows?.length ? (
					<>
						<TableContainer>
							<Table
								className={classes.table}
								aria-labelledby="tableTitle"
								size='medium'
								aria-label="enhanced table"
							>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>
										<TableCell>Group Name</TableCell>
										<TableCell align="left">Members</TableCell>
										<TableCell align="left">Description</TableCell>
										<TableCell align="right"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
										let members = row?.members?.length && row.members.map(item => item.id);
										return (
											<TableRow key={index}>
												<TableCell component="td" scope="row" padding="10px">
													<Badge color="primary" badgeContent={members?.length}>
														<PeopleIcon style={{ fontSize: 17 }} />
													</Badge>
												</TableCell>
												<TableCell component="td" scope="row" padding="10px">
													{row?.name || ''}
												</TableCell>
												<TableCell align="left">
													{row?.members?.length && row.members.map((item, index) => (
														<Typography className={classes.memberItem}>{item.username}</Typography>
													))}
												</TableCell>
												<TableCell component="td" align="left">{row?.description || ''}</TableCell>
												<TableCell component="td" align="right">
													<Edit contractorlist={contractorList} setFlag={setFlag} flag={flag} data={{ id: row.id, name: row.name, members: members, description: row.description }} />
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</>
				) : (<div className={classes.emptyClass}>Empty Group list..!</div>)}
			</Paper>
		</div >
	);
}
