import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { SimpleForm,TextInput,CheckboxGroupInput,required  } from 'react-admin';
const useStyles = makeStyles({
  drawer: {
    width: 250,
  },
});

export default function AddPermission({toggleDrawer,open,savepermission}) {
  
  const classes = useStyles();
  const save = (data) => {
    savepermission(data);
  };

  return (
    <div>
      <React.Fragment>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          <div className={classes.drawer}>
            <SimpleForm variant="outlined" save={save}>
              <TextInput label="Permission Title" fullWidth={true} source="title" validate={[required()]} />
              <CheckboxGroupInput source="added_to"
                row={false}
                label="Assign this permission to"
                choices={[
                  { id: 'manager', name: 'Managers' },
                  { id: 'contractor', name: 'Contractors' },
                  { id: 'client', name: 'Clients' },
                ]}
                validate={[required()]}
              />
            </SimpleForm>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
