import {
  Dialog as MaterialDialog
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Content from './Content';

const Dialog = ({
  open, setOpen, defaultType, saveEmployee, employee, editEmployee, deleteEmployee
}) => {
  const [type, setType] = useState();

  useEffect(() => {
    if (open && !defaultType) { setType('add_employee'); }
    if (open && defaultType) { setType(defaultType); }
    if (!open) {
      setTimeout(() => setType(null), 500);
    }
  }, [open, defaultType]);

  return (
    <MaterialDialog onClose={() => {setOpen(false)}} aria-labelledby="customized-dialog-title" open={open}>
      <Content
        type={type}
        open={open}
        setType={setType}
        setOpen={setOpen}
        saveEmployee={saveEmployee}
        editEmployee={editEmployee}
        employee={employee}
        deleteEmployee={deleteEmployee}
      />
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  saveEmployee: PropTypes.func,
  defaultType: PropTypes.string,
  employee: PropTypes.object,
};

export default Dialog;
