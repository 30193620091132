import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Switch,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox
} from '@material-ui/core';
import { useDataProvider } from 'react-admin';

export default function ProjectStatus(props) {
  const dataProvider = useDataProvider();
  const [online, setOnline] = React.useState(props.onlineStatus);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [notification, setNotifiation] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setOnline(!online);
  };

  const changeProjectStatus = (status) => {
    setOpen(false);
    setLoading(true);
    dataProvider.projectOnlineStatus({data: { id: props.id, status: online,sendNotification: notification}})
      .then(({ data }) => {
        if (data && data.projectStatus !== null) {
          setOnline(data.projectStatus);
        } else {
          setOnline(!online);
        }
        setLoading(false);
        setNotifiation(true);
      })
      .catch(error => {
        setLoading(false);
        setNotifiation(true);
      })
  };

  const handleChange = (event) => {
    setOpen(true);
    setOnline(event.target.checked);
  };
  
  return (
    <>
      <FormGroup row style={{paddingLeft: 20,alignItems: 'center'}}>
        {!loading ? (
          <FormControlLabel
            control={
              <Switch
                checked={online}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label={online ? "Online" : "Offline"}
          />
        ) : (
          <CircularProgress  size={25} />
        )} 
      </FormGroup>
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        setOnline={changeProjectStatus}
        online={online}
        notification={notification}
        setNotifiation={setNotifiation}
      />
    </>
  );
}

const ConfirmationDialog = ({open,handleClose,setOnline,online,notification,setNotifiation}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Are you sure want to make project ${online ? 'online' : 'offline'}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {online ? (
            'Making Project Online Sends notification to contractors about assigned tasks.'
          ) : (
            'Making Project offline stops every notification from each modification.'
          )}
          
        </DialogContentText>
        {online && (
          <FormControlLabel
            control={
              <Checkbox
                checked={notification}
                onChange={(event) => setNotifiation(event.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="Send notification to contrators"
          />
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={() => setOnline(!online)} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}