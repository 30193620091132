/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';
import { Grid, Typography, Button, Modal, Box } from '@material-ui/core';
import Create from './Checklistcatcreate';
import Edit from './Checklistcatedit';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {
    FcGenealogy,
} from "react-icons/fc";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


function Checklistcategory() {
    const dataProvider = useDataProvider();
    const [flag, setFlag] = useState(false);
    const [rows, setRows] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteid, setDeleteid] = useState(false);


    const handleClickOpen = (data) => {
        setDeleteid(data.id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const classes = useStyles();
    React.useEffect(() => {
        setLoading(true);
        dataProvider.getQuestionCategories('checklistcategory').then(({ data }) => {
            console.log(data);
            setRows(data);
            setLoading(false);
        })
            .catch(error => {
                setLoading(false);
            });
    }, [flag]);

    const handleDelete = (data) => {
        dataProvider.delete('checklistcategory', { id: data.id }).then(({ data }) => {
            setRows(data);
            setLoading(false);
        })
            .catch(error => {
                if (error.status == '400') {
                    setOpen(true);
                }
                setLoading(false);
            });
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={1} direction="row" alignItems="center" className={classes.main}>
                    <Grid item xs={12} sm={12}>
                        <Grid container direction="row" alignItems="center" className={classes.tableheading} >
                            <div className={classes.heading} >
                                <FcGenealogy />
                                <Typography variant="h6" gutterBottom>
                                    Category
                                </Typography>
                            </div>
                            <div>
                                <Create setRows={setRows} rows={rows} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left">Category Name</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.length > 0 &&
                                rows.map((item) => {
                                    return (
                                        <TableRow >
                                            <TableCell className={classes.table_cell} ></TableCell>
                                            <TableCell className={classes.table_cell}>{item.name}</TableCell>
                                            <TableCell className={classes.table_cell} style={{ flexDirection: 'row', display: 'flex', }}>
                                                <Edit data={{ id: item._id, name: item.name, }} question_arr={item.questions || []} setRows={setRows} />
                                                <Button onClick={() => handleDelete(item)} className={classes.deleteBtn}>
                                                    <DeleteOutlineIcon style={{ fontSize: 17, marginRight: 2 }} />
                                                    Delete
                                                </Button>
                                            </TableCell>
                                            <TableCell className={classes.table_cell}></TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Dialog
                open={open}
                style={{ padding: 20 }}
                // TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle style={{ color: 'red' }}>{"This Category Cannot be Deleted,There are already questions under this category"}</DialogTitle> */}
                <label style={{ color: 'white', padding: 20, width: '355px', backgroundColor: '#e7b02c' }}>This Category Cannot be Deleted,There Are Already Questions Underthis Category</label>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ color: 'red' }} onClick={() => setOpen(false)}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Checklistcategory;

const useStyles = makeStyles((theme) => ({
    container: {
        background: '#fff',
        margin: 'auto',
        marginTop: 15,
        maxHeight: 600,
    },
    main: {
        width: '100%',
    },
    root: {
        width: '100%',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        //float: 'right',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    heading: {
        padding: 16,
        '& h6': {
            fontSize: 15,
            padding: 10,
            fontWeight: 'bold',
            color: '#e7b02c',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    table_cell: {
        paddingTop: 6,
        paddingBottom: 6
    },
    loader_holder: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    loader: { color: '#e5aa13' },
    tableheading: {
        padding: '0px 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    editBtn: {
        color: '#e5aa13',
        fontWeight: 'bold',
    },
    deleteBtn: {
        color: 'red',
        fontWeight: 'bold',
    },
    memberItem: {
        background: '#ddd',
        display: 'inline-block',
        margin: 5,
        padding: '2px 10px',
        fontSize: '13px',
        borderRadius: '10px',
    },
    emptyClass: {
        padding: 10,
        textAlign: 'center',
        fontStyle: 'italic',
        paddingBottom: 30,
    }
}));