import * as React from "react";
import { forwardRef } from 'react';
import Logo from './Logo';
import { AppBar, UserMenu, MenuItemLink,useAuthProvider,usePermissions } from 'react-admin';
import {Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import { useMediaQuery, IconButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    // backgroundColor: "#282a3c",
    backgroundImage: 'linear-gradient(60deg, #282a3c 0%, #42434d 100%)',
    // color: '#c4beaa',
    color: '#dadbe6',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  avatarHolder: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    background: '#f0be40',
  },
  avatar: {
    height: 30,
    maxWidth: 30,
    // margin: 5,
  },
  bellIcon: {
    color: '#dadbe6',
  }
});

const MyCustomIcon = () => {
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  // console.log(user);

  return (
    <>
      <Avatar alt="Admin"
        src={user && user.avatar ? user.avatar : process.env.PUBLIC_URL + '/avatar.png'}
        className={classes.avatar}
      >
      </Avatar>
      {(!isXSmall && user!==null) ? (<div className="admin-label">{(user.username) ? user.username : user.email}</div>) : ''}
    </>
  )
};
const ConfigurationMenu = forwardRef((props, ref) => {
  // const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/settings"
      primaryText="Settings"
      // primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
    />
  );
});

const CustomUserMenu = (props) => (
  <UserMenu {...props} icon={<MyCustomIcon />} >
    {props.permission && props.permission.role === 'admin' && (
     <ConfigurationMenu />
    )}
  </UserMenu>
);

const CustomAppBar = (props) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const { permissions } = usePermissions();
  const history = useHistory()
  const goBack = () => {
    if(history.length && history.length > 0) {
      history.goBack();
    }
  }
  return (
    <AppBar {...props} elevation={1} className={classes.root} userMenu={<CustomUserMenu {...props} permission={permissions}/>}>
      <IconButton
        aria-label="back"
        color="secondary"
        onClick={goBack}
      > 
        <ArrowBackIosIcon className={classes.bellIcon} />
      </IconButton>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-titlde"
      />
      <Logo />
      <span className={classes.spacer} />
      {!isXSmall ? (
        <IconButton >
          <NotificationsIcon className={classes.bellIcon} />
        </IconButton>
      ) : ''}
    </AppBar>
  );
};

export default CustomAppBar;
