/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDataProvider, Loading, usePermissions, useAuthProvider,TextInput, DateInput,  } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Typography, Dialog,Button, DialogTitle,DialogContent, List, ListItem, ListItemText,Grid } from '@material-ui/core';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
// import List from './variationlist';
import SaveIcon from '@material-ui/icons/Save';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GavelIcon from '@material-ui/icons/Gavel';
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import {
  FcGenealogy
} from "react-icons/fc";
function SimpleDialog(props) {
  const { onClose, selectedValue, open, revisions, id } = props;
  const routeHistory = useHistory();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value, index) => {
    console.log(value, index)
    // onClose(value);
    routeHistory.push(`/project-tender/${id}/${value.id}`)
  };
  const CreateGroup = (values) => {
    console.log(values)
    values['project'] = id
    dataProvider.createCategory(values, 'final').then(({ data }) => {
      console.log(data);
      routeHistory.push(`/project-tender/${id}/${data[0].id}`)
    }).catch(error => {

    });
  }
  return (
    <>

      <Dialog onClose={handleClose} open={open}>
        {revisions && revisions.length > 0 ?
          <>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" >
                <div className={classes.heading} >
                  <Typography variant="h6" gutterBottom>
                    CHOOSE REVISION
                  </Typography>
                </div>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.modalContent}>
                <List sx={{ pt: 0 }}>
                  {revisions?.map((email, index) => (
                    <ListItem button onClick={() => handleListItemClick(email, index)} key={email}>
                      <ListItemText primary={email?.rev_no} />
                    </ListItem>
                  ))}
                </List>
            </DialogContent>
          </>
          :
          <>
            <DialogTitle id="form-dialog-title">
              <Grid container direction="row" alignItems="center" >
                <div className={classes.heading} >
                  <FcGenealogy />
                  <Typography variant="h6" gutterBottom>
                    Create Revision
                  </Typography>
                </div>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.modalContent}>
              <Form
                onSubmit={CreateGroup}
                render={({ handleSubmit, form }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <DateInput
                            allowEmpty={true}
                            variant="outlined"
                            label="Tender Prepared"
                            source="prepared_date"
                            fullWidth={true}
                          // validate={required()}
                          // defaultValue={initialval?.prepared_date}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            source="rev_no"
                            label="Rev No"
                            fullWidth={true}
                            variant="outlined"
                          // validate={required()}
                          // defaultValue={initialval?.rev_no}

                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            source="drg_rev"
                            label="Drg Rev"
                            fullWidth={true}
                            variant="outlined"
                          // validate={required()}
                          // defaultValue={initialval?.drg_rev}

                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            source="construction_period"
                            label="Construction Period"
                            fullWidth={true}
                            variant="outlined"
                          // validate={required()}
                          // defaultValue={initialval?.construction_period}

                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextInput
                            source="description"
                            label="Description"
                            fullWidth={true}
                            variant="outlined"
                          // validate={required()}
                          // defaultValue={initialval?.description}

                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextInput
                            source="remarks"
                            label="Remarks"
                            fullWidth={true}
                            variant="outlined"
                          // validate={required()}
                          // defaultValue={initialval?.remarks}

                          />
                        </Grid>
                        <div className={classes.saveBtnHolder}>
                          <Button className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                        </div>
                      </Grid>
                    </form>
                  )
                }} />
            </DialogContent>
          </>
        }
      </Dialog>

    </>
  );
}
export default function ProjectTaskChart({ id, match, location, history }) {

  const classes = useStyles();
  const [data, setdata] = useState();
  const [loading, setLoading] = useState(true);
  const [tender, setTender] = useState(false);
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();

  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider.getList('final', { filter: { project: id }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      console.log(data)
      if (data && data.length > 0) {
        setdata(data);
        setLoading(false);
      }
      else {
        setLoading(false);
      }

    })
      .catch(error => {
        setLoading(false);
      });
    if (permissions?.role === 'manager') {
      dataProvider.getTenderAccess({ id: user?.id })
        .then(({ data }) => {
          if (data?.activity?.includes(0)) {
            setTender(true);
          } else {
            setTender(false);
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        })
    }
  }, [id, permissions]);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  }
  if (loading || !permissions) return <Loading loadingPrimary="" loadingSecondary="" />;
  return (
    <Paper square className={classes.titlePaper}>
      <Box
        display="flex"
        flexWrap="wrap"
        bgcolor="background.paper"
      >
        {(permissions?.role === 'admin' || permissions?.role === 'manager') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-variations/${id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <AssignmentIcon className={classes.reportIcon} />
                <Typography>Contract</Typography>
              </div>
            </Link>
          </Box>
        ) : null}
        {(permissions?.role === 'admin' || (tender && (permissions?.role === 'manager'))) ? (
          <Box p={1} m={2} alignItems="center"
            onClick={handleClickOpen}
          >
            {/* <Link to={`/project-tender/${id}`} className={classes.anchor}> */}
            <div className={classes.iconHolder}>
              <GavelIcon className={classes.materialIcon} />
              <Typography>Tender</Typography>
            </div>
            {/* </Link> */}
          </Box>
        ) : null}
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          id={id}
          revisions={data || []}
          onClose={handleClose}
        />
        {(permissions?.role === 'admin' || permissions?.role === 'manager') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-variations/${id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <TrendingDownIcon className={classes.reportIcon} />
                <Typography>Variations</Typography>
              </div>
            </Link>
          </Box>
        ) : null}
      </Box>
    </Paper>
  )
}
const useStyles = makeStyles((theme) => ({
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  iconHolder: {
    width: 115,
    boxShadow: '0px 0px 10px rgba(73, 78, 92, 0.15)',
    cursor: 'pointer',
    display: 'block',
    padding: '28px 10px 10px 10px',
    textAlign: 'center',
    color: '#000',
    minHeight: 70,
    '& p': {
      paddingTop: 5,
      fontSize: 14,
      textTransform: 'uppercase',
    },
  },
  tab: {
    minWidth: 130,
    width: 130,
    background: '#fff',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: ' #555cb1',
    color: ' #fff !important',
  },
  anchor: {
    textDecoration: 'none',
    color: '#dadbe6',
    '&:active': {
      color: '#fff',
    }
  },
  reportIcon: {
    color: '#726708',
  },
  materialIcon: {
    color: '#850d88',
  },
  tabIndicator: {
    // background:' #555cb1',
    // color:' #fff !important',
    // height: '100%',
  },
  saveBtnHolder: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  saveBtn: {
    background: '#e5aa13',
    color: '#000',
    fontWeight: 'bold',
    '&:hover': {
      background: '#e5aa13',
    }
  },
  modalContent: {
    minWidth:300,
    paddingBottom:30,
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ddd',
      borderRadius: 15,
      outline: '1px solid #ddd'
    }
  },
  heading: {
    padding: 16,
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color: '#e7b02c',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));


