import React,{ useEffect, useState} from 'react';
import { 
  useDataProvider,
  useNotify,
  useRedirect,
  Loading
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export default function PreList() {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const[loading, setLoading] = useState(true);
  const [forms, setForms] = useState();

  useEffect(() => {
    dataProvider.getPreContractForms()
      .then(({ data }) => {
        // console.log(data);
        setForms(data);
        setLoading(false);
      })
      .catch(error => {
        // console.log(error.message);
        setLoading(false);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(loading) {return <Loading />}
  if(!forms) {return null}

  return(
    <div className={classes.container}>
      {forms.map(form => {
        return (
          <Link to={`pre-contracts/${form.id}`} key={form.id} className={classes.anchor}>
            <div className={classes.row}>
              <div className={classes.title}>
                {form.title}
              </div>
              <div className={classes.clientDetails}>
                {`Client : ${form.client.name.first_name} ${form.client.name.last_name}`}
              </div>
              <div className={classes.created}>
                {`Created On : ${form.createdAt}`}
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) =>({
  container: {
    width: '100%',
    height: '100%',
    background: '#f3f3f3',
    margin: 20,
  },
  row: {
    margin: 10,
    padding: '10px 10px',
    backgroundColor: '#fff',
    color: '#000',
  },
  title: {
    fontWeight: 'bold',
    padding: '10px 0',
    textTransform: 'uppercase',
  },
  created: {
    padding:'10px 0',
    color: 'orangered',
  },
  anchor: {
    textDecoration: 'none',
    //color: '#dadbe6',
    '&:active':{
      //color: '#fff',
    }
  },
}));