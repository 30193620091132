import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading, usePermissions, useRedirect } from 'react-admin';
import { useHistory } from "react-router-dom";
import Create from './FloorplanCreate';
import List from './FloorplanList';

function ProjectFloorplan({ projectId }) {
    const redirect = useRedirect();
    const [materials, setMaterials] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    const history = useHistory();
    const { permissions } = usePermissions();

    const onEditClick = (Data) => {
        // materialData.supplier = materialData.project;
        // materialData.material = materialData.material.id;
        history.push(`/project-floorplan/edit/${Data.id}`);
    }

    const onDeleteClick = (id) => {
        setLoading(true);
        dataProvider.delete('floorplan', { id: id, projectId: projectId })
            .then(({ data }) => {
                setMaterials(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const saveProjectMaterial = (data) => {
        setLoading(true)
        data = { ...data, projectId: projectId };
        dataProvider.createFloorplan('floorplan', { data: data })
            .then(({ data }) => {
                setMaterials(data.category);
                setLoading(false)
                window.location.reload();
                // redirect(`/project-floorplan/${projectId}`);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    useEffect(() => {
        dataProvider.getList('floorplan', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } })
            .then(({ data }) => {
                setMaterials(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, []);


    if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;
    if (!materials) return null;

    return (
        <>
            <Create save={saveProjectMaterial} />
            <List
                projectId={projectId}
                data={materials}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
            />
        </>
    )
}

export default ProjectFloorplan;