import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Typography, Grid } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteArrayInput, TextInput, useDataProvider, useNotify, FileField, FileInput, Confirm, SelectInput } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GavelIcon from '@material-ui/icons/Gavel';
import moment from 'moment';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import get from 'lodash/get';

const useStyles = makeStyles((theme) => ({
  BtnHolder: {
    width: '100%',
    textAlign: 'center',
    padding: '10px 0px',
  },
  BtnSave: {
    backgroundColor: '#670e3c',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#670e3c',
      color: '#fff',
    }
  },
  Width400: {
    width: 400,
  },
  Width100_p: {
    width: '100%',
  },
  loader_holder: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loader: { color: '#670e3c' },
  errorField: {
    color: '#f00',
    fontSize: '0.75rem',
  },
  removeBtn: {
    display: 'flex'
  },
  actionBtnHolder: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  saveBtn: {
    background: '#e5aa13',
    color: '#000',
    fontSize: 12,
    '&:hover': {
      background: '#e5aa13',
    }
  },
  DeleteBtn: {
    background: 'transparent',
    color: '#ff0000a1',
    fontSize: 12,
    '&:hover': {
      background: '#ff000014',
    }
  },
  heading: {
    padding: 16,
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color: '#e7b02c',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    color: '#e5aa13'
  },
  dropzoneContainer: {
    border: '1px dotted #ddd',
    borderRadius: 10,
  },
  modalContent: {
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ddd',
      borderRadius: 15,
      outline: '1px solid #ddd'
    }
  }

}))
export default function EditTender({ data, flag, setFlag, groups, projects }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const required = (value) => {
    if (value) {
      return undefined;
    } else {
      return '*Required';
    }
  }
  const editTender = (values) => {
    values['id'] = data.id;
    dataProvider.updateTender(values).then(({ data }) => {
      setFlag(!flag);
      notify(data?.message, 'success');
      handleClose();
    })
      .catch(error => {
        notify("Can't update tender", 'warning');
      });
  }
  const deleteTender = () => {
    dataProvider.deleteTender({ id: data.id }).then(({ data }) => {
      setFlag(!flag);
      setConfirmOpen(false);
      notify(data?.message, 'success');
      handleClose();
    })
      .catch(error => {
        notify("Can't delete tender", 'warning');
      });
  }
  const requiredFile = value => ((value && value.length > 0) ? undefined : "*Attachements can't be empty");
  const FilePreview = ({ record, source }) => {
    const classes = useStyles();
    let sourceValue = get(record, source);
    if (!sourceValue) { sourceValue = record.url };
    return (
      <div className={classes.fileHolder} >
        {((record.rawFile && record.rawFile.type === "application/pdf") || (record.type === "application/pdf")) ? (
          <div className={classes.fileIcon}>
            <a href={(record.undefined) ? record.undefined : record.url} target="_blank">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <PdfIcon fontSize="large" style={{ color: 'red', width: 40, height: 40 }} />
                <Typography className={classes.fileTitle}>{(record.rawFile) ? record.rawFile.name : record.name}</Typography>
                {(record.stat) && (
                  <Typography className={classes.fileSubTitle}>{`Uploaded On ${record.stat}`}</Typography>
                )}
              </div>
            </a>
          </div>
        ) : (
          <a href={(record.undefined) ? record.undefined : record.url} target="_blank">
            <img
              style={{
                width: 130,
                height: 100,
              }}
              src={sourceValue}
              // className={classes.image}
              alt=""
            />
            {(record.name) && (
              <Typography className={classes.fileTitle}>{record.name}</Typography>
            )}
            {(record.stat) && (
              <Typography className={classes.fileSubTitle}>{`Uploaded On ${record.stat}`}</Typography>
            )}
          </a>
        )}
      </div>
    )
  }
  return (
    <>
      <Button onClick={handleClickOpen} className={classes.editBtn}>
        <EditIcon style={{ fontSize: 17, marginRight: 2 }} />
        Edit</Button>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" >
            <div className={classes.heading} >
              <GavelIcon />
              <Typography variant="h6" gutterBottom>
                Edit Tender Details
              </Typography>
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Form
            onSubmit={editTender}
            initialValues={{
              name: data?.name,
              attachments: data?.attachments,
              description: data?.description,
              project: data?.project?.id,
              lastdate: moment(data?.lastdate).format('YYYY-MM-DD'),
              groups: data?.groups?.length && data.groups.map(item => (item.id)),
            }}
            render={({ handleSubmit, form }) => {
              const uploadAttachments = (value) => {
                let files = form.getFieldState(`attachments`).value;
                let uploadedFiles = [], uploadingFiles = [];
                if (files && files.length > 0) {
                  files.map(file => {
                    if (file && file.rawFile) {
                      uploadingFiles.push(file);
                    } else {
                      uploadedFiles.push(file);
                    }
                  });
                  setLoading(true)
                  dataProvider
                    .uploadAttachments({ files: uploadingFiles })
                    .then(({ data }) => {
                      setLoading(false)
                      if (data && data.length > 0) {
                        uploadingFiles = data.map(fileInfo => {
                          return fileInfo;
                        });
                        form.change(
                          `attachments`,
                          uploadingFiles.concat(uploadedFiles)
                        );
                      }
                    })
                    .catch(error => {
                      setLoading(false)
                    });
                }
              }
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Field name='name' validate={required}>
                        {({ input, meta }) => (
                          <>
                            <TextInput
                              id="outlined-multiline-static"
                              label="Tender Name"
                              style={{ width: '100%' }}
                              size="small"
                              {...input}
                              error={meta.error && meta.touched ? true : false}
                              variant="outlined" />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Field name='project' validate={required}>
                        {({ input, meta }) => (
                          <>
                            <SelectInput choices={(projects?.length && projects) || []}
                              id="outlined-multiline-static"
                              label="Project"
                              style={{ width: '100%' }}
                              size="small"
                              {...input}
                              error={meta.error && meta.touched ? true : false}
                              variant="outlined" />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid item lg={12} xs={12} md={12} sm={12}>
                      <Field name='description' validate={required}>
                        {({ input, meta }) => (
                          <>
                            <TextInput
                              id="outlined-multiline-static"
                              label="Description"
                              multiline
                              style={{ width: '100%' }}
                              rows={3}
                              size="small"
                              {...input}
                              error={meta.error && meta.touched ? true : false}
                              variant="outlined" />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Field name='groups' validate={required}>
                        {({ input, meta }) => (
                          <>
                            <AutocompleteArrayInput
                              label="Groups"
                              style={{ minWidth: '100%' }}
                              size="small"
                              fullWidth={true}
                              {...input}
                              choices={(groups?.length && groups) || []}
                              options={{
                                suggestionsContainerProps: {
                                  disablePortal: true,
                                }
                              }}
                              error={meta.error && meta.touched ? true : false}
                              variant="outlined"
                            />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid item lg={12} xs={12} md={12} sm={12}>
                      <Field name='lastdate' validate={required}>
                        {({ input, meta }) => (
                          <>
                            <TextInput
                              id="outlined-multiline-static"
                              label="Closing Date"
                              style={{ width: '100%' }}
                              type="date"
                              size="small"
                              InputLabelProps={{ shrink: true, }}
                              {...input}
                              error={meta.error && meta.touched ? true : false}
                              variant="outlined" />
                          </>
                        )}
                      </Field>
                    </Grid>

                    <Grid item lg={12} xs={12} md={12} sm={12}>
                      <FileInput
                        source="attachments"
                        label="Attachments"
                        accept="application/pdf"
                        multiple={true}
                        placeholder="Click here to upload tender attachments"
                        classes={{ dropZone: classes.dropzoneContainer, removeButton: classes.removeBtn }}
                        onChange={uploadAttachments}
                        validate={(value) => requiredFile(value)}
                      >
                        <FilePreview />
                      </FileInput>
                    </Grid>
                    <Confirm
                      isOpen={confirmOpen}
                      title="Delete Group"
                      content={`Are you sure you want to delete tender ${data.name}?`}
                      onConfirm={deleteTender}
                      confirm="Yes"
                      cancel="No"
                      onClose={() => setConfirmOpen(false)}
                    />
                    <div className={classes.actionBtnHolder}>
                      <Button className={classes.DeleteBtn} onClick={() => setConfirmOpen(true)}><DeleteOutlineIcon style={{ fontSize: 17, marginRight: 2 }} />DELETE</Button>
                      <Button disabled={loading} className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                    </div>
                  </Grid>
                </form>
              )
            }} />
        </DialogContent>
      </Dialog>
    </>
  );
}