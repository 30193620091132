import React from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  //useNotify,
  //useRefresh,
  useRedirect,
  SelectInput,
  PasswordInput,
  CheckboxGroupInput,
} from 'react-admin';
import { Grid, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
var generator = require('generate-password');

const useStyles = makeStyles({
  main: {
    width: '100%',
  },
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color: '#e7b02c',
    }
  },
  passwordTitle: {
    fontSize: 12,
    textTransform: 'capitalize',
    paddingBottom: 10,
    color: '#FF9800',
  },
  passwordButton: {
    margin: 10,
  }
});



const ManagerCreate = props => {
  //const notify = useNotify();
  //const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    // notify(`Changes to post "${data.title}" saved`)
    redirect('/managers');
    //refresh();
  };
  const classes = useStyles();
  return (
    <Create onSuccess={onSuccess} {...props} >
      <SimpleForm>
        <Grid container direction="row" alignItems="center" className={classes.heading}>
          <CreateIcon />
          <Typography variant="h6" gutterBottom>
            Create Manager
          </Typography>
        </Grid>
        <Grid container spacing={1} className={classes.main}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="first_name"
              validate={required()}
              fullWidth={true}
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="last_name"
              validate={required()}
              fullWidth={true}
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="username"
              validate={required()}
              fullWidth={true}
              label="User Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              source="designation"
              //validate={required()}
              fullWidth={true}
              label="Designation"
              variant="outlined"
              choices={[
                { id: 'Supervisor', name: 'Supervisor' },
                { id: 'Project Coordinator', name: 'Project Coordinator' },
                { id: 'Accounts', name: 'Accounts' },
                { id: 'Architect',name: 'Architect'},
                { id: 'Manager',name: 'Manager'},
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="email"
              fullWidth={true}
              label="Email Address"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              source="contact"
              validate={required()}
              fullWidth={true}
              label="Contact Number"
              variant="outlined"
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextInput
              source="street"
              validate={required()}
              multiline
              fullWidth={true}
              label="Street"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="suburb"
              validate={required()}
              fullWidth={true}
              label="Suburb"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="state"
              validate={required()}
              fullWidth={true}
              label="State/Province/Region"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="zip"
              validate={required()}
              fullWidth={true}
              label="Postal code"
              variant="outlined"
            />
          </Grid> */}
          <PasswordField classes={classes} />
          <Grid item xs={12} >
            <SelectInput
              source="status"
              choices={[
                { id: 1, name: 'Enabled' },
                { id: 0, name: 'Disabled' },
              ]}
              defaultValue={1}
              fullWidth={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxGroupInput
              source="activity"
              label="Activity Access"
              choices={[
                { id: 0, name: 'Tender Management' },
                { id: 1, name: 'Quality Inspection' },
              ]} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
}

const PasswordField = ({ classes }) => {
  const form = useForm();
  const changePassword = () => {
    form.change('password', generator.generate(
      {
        length: 15,
        numbers: true
      }
    ));
  }
  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={9}>
        <PasswordInput
          fullWidth={true}
          source="password"
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button variant="outlined"
          color="primary"
          className={classes.passwordButton}
          onClick={changePassword}
        >
          Generate Password
        </Button>
      </Grid>
    </Grid>
  );
};

export default ManagerCreate;

