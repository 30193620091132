import React, { useState } from "react";
import { Dialog, DialogTitle, Divider, DialogContent, Button, Typography, Grid, InputAdornment } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput, useDataProvider, useAuthProvider, useNotify, FileField, FileInput } from 'react-admin';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import GavelIcon from '@material-ui/icons/Gavel';
import moment from 'moment';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import get from 'lodash/get';

import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  BtnHolder: {
    width: '100%',
    textAlign: 'center',
    padding: '10px 0px',
  },
  BtnSave: {
    backgroundColor: '#670e3c',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#670e3c',
      color: '#fff',
    }
  },
  Width400: {
    width: 400,
  },
  Width100_p: {
    width: '100%',
  },

  errorField: {
    color: '#f00',
    fontSize: '0.75rem',
  },
  actionBtnHolder: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  saveBtn: {
    background: '#e5aa13',
    color: '#000',
    fontSize: 12,
    '&:hover': {
      background: '#e5aa13',
    }
  },
  DeleteBtn: {
    background: 'transparent',
    color: '#ff0000a1',
    fontSize: 12,
    '&:hover': {
      background: '#ff000014',
    }
  },
  heading: {
    '& h6': {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#e7b02c',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subHeading: {
    '& h6': {
      fontSize: 13,
      fontWeight: 'bold',
      color: '#e7b02c',
      marginTop: 10,
      marginBottom: 10,
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    color: '#e5aa13',
    whiteSpace: 'nowrap',
    fontSize: 10,
  },
  dropzoneContainer: {
    border: '1px dotted #ddd',
    borderRadius: 10,
  },
  modalContent: {
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ddd',
      borderRadius: 15,
      outline: '1px solid #ddd'
    }
  },
  header: {
    textAlign: 'center',
    fontStyle: 'italic'
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    marginBottom: 50,
  },

  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8
  },
  removeBtn: {
    display: 'flex'
  },
  th: {
    textAlign: 'left',
    padding: 8,
    background: '#000',
    color: ' #fff',
    fontSize: 12,
    fontWeight: 400,
  }
}))
export default function ViewTender({ flag, setFlag, data }) {
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState(false);
  const [quoteInfo, setQuoteInfo] = useState();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  useEffect(() => {
    dataProvider.getTenderQuote({ tender: data?.id, quotee: user.id }).then(({ data }) => {
      if (data) {
        setQuote(true);
        setQuoteInfo(data);
        dataProvider.markTender({ tender: data?.id, quotee: user.id }).then(({ data }) => {
        });
      }
    })
      .catch(error => {
      });
  }, [open]);
  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        placeholder='$'
        variant="outlined"
        thousandSeparator={true}
        prefix={'$'}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        isNumericString />
    );
  }
  const requiredNumber = (value) => {
    if (value) {
      if (!isNaN(value)) {
        return undefined;
      } else {
        return '*Invalid Amount';
      }
    } else {
      return '*Required';
    }

  }
  const required = (value) => {
    if (value) {
      return undefined;
    } else {
      return '*Required';
    }
  }
  const FilePreview = ({ record, source }) => {
    const classes = useStyles();
    let sourceValue = get(record, source);
    if (!sourceValue) { sourceValue = record.url };
    return (
      <div className={classes.fileHolder} >
        {((record.rawFile && record.rawFile.type === "application/pdf") || (record.type === "application/pdf")) ? (
          <div className={classes.fileIcon}>
            <a href={(record.undefined) ? record.undefined : record.url} target="_blank">
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <PdfIcon fontSize="large" style={{ color: 'red', width: 40, height: 40 }} />
                <Typography className={classes.fileTitle}>{(record.rawFile) ? record.rawFile.name : record.name}</Typography>
                {(record.stat) && (
                  <Typography className={classes.fileSubTitle}>{`Uploaded On ${record.stat}`}</Typography>
                )}
              </div>
            </a>
          </div>
        ) : (
          <a href={(record.undefined) ? record.undefined : record.url} target="_blank">
            <img
              style={{
                width: 130,
                height: 100,
              }}
              src={sourceValue}
              // className={classes.image}
              alt=""
            />
            {(record.name) && (
              <Typography className={classes.fileTitle}>{record.name}</Typography>
            )}
            {(record.stat) && (
              <Typography className={classes.fileSubTitle}>{`Uploaded On ${record.stat}`}</Typography>
            )}
          </a>
        )}
      </div>
    )
  }
  const QuoteTender = (values) => {
    dataProvider.quoteTender({ tender: data?.id, quotee: user.id, quote: values }).then(({ data }) => {
      setFlag(!flag);
      setConfirmOpen(false);
      notify(data?.message, 'success');
      setOpen(false);
    })
      .catch(error => {
        notify("Error in submission, Please try later", 'warning');
      });
  }
  const requiredFile = value => ((value && value.length > 0) ? undefined : "*Attachements can't be empty");
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div
          style={{
            width: 55,
            fontSize: 9,
            textAlign: 'center',
            borderRadius: 15,
            color: '#fff',
            backgroundColor: quote ? '#4dab3c' : '#f00'
          }}>
          {quote ? 'Quoted' : 'Unquoted'}
        </div>
        <Button onClick={() => setOpen(true)} className={classes.editBtn}> View Details</Button>
      </div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" >
            <div className={classes.heading} >
              <GavelIcon style={{ marginRight: 5 }} />
              <Typography variant="h6">
                Tender Details
              </Typography>
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <div>
            <Typography component="h4"><b>{data?.name || ''}</b></Typography>
            <Typography style={{ fontSize: 12, marginTop: 10 }}>{data?.description || ''}</Typography>
            <Typography style={{ fontSize: 12, marginTop: 10 }}><b>Project : </b>{data?.project?.title || ''}</Typography>
            <Typography style={{ fontSize: 12 }}><b>Posted on : </b>{moment(data?.created_at).format('DD/MM/YYYY') || ''}</Typography>
            <Typography style={{ fontSize: 12 }}><b>Closing Date : </b>{moment(data?.lastdate).format('DD/MM/YYYY') || ''}</Typography>
            <Typography style={{ fontSize: 12 }}><b>Attachments:</b></Typography>
            <ul>
              {data?.attachments?.length && data.attachments.map((item, index) => (
                <li key={index}> <a target="_blank" style={{ fontSize: 13 }} href={item?.url}>Attachement ({index + 1})</a> </li>
              ))}
            </ul>
          </div>
          {!quoteInfo && (
            <div className={classes.actionBtnHolder}>
              <Button className={classes.saveBtn} type="submit" onClick={() => setQuoteInfo(true)}>
                <EditIcon style={{ fontSize: 17, marginRight: 2, color: '#000' }} />
                Quote Now
              </Button>
            </div>
          )}
          {quoteInfo && (
            <>
              {quote ? (
                <>
                  <Grid container direction="row" alignItems="center" >
                    <div className={classes.subHeading} >
                      <Typography variant="h6">
                        Quote Details
                      </Typography>
                    </div>
                  </Grid>
                  <Grid container spacing={1} direction="row" alignItems="center">
                    <table className={classes.table}>
                      <tr>
                        <th className={classes.th}>Description</th>
                        <th className={classes.th}>Quoted Amount</th>
                        <th className={classes.th}>Attachments</th>
                      </tr>
                      <tr>
                        <td className={classes.td}>{quoteInfo?.description}</td>
                        <td className={classes.td}>{"$ " + (Math.round(quoteInfo?.amount * 100) / 100).toLocaleString()}</td>
                        <td className={classes.td}>
                          <ul>
                            {quoteInfo?.attachments?.length && quoteInfo?.attachments.map((item, index) => (
                              <li key={index}> <a target="_blank" style={{ fontSize: 13 }} href={item?.url}>Attachement ({index + 1})</a> </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </>
              ) : (
                <>
                  <Divider light />
                  <div className={classes.subHeading} >
                    <Typography variant="h6">
                      {quote ? 'Quote Details' : 'Add Quote Details'}
                    </Typography>
                  </div>
                  <Form
                    onSubmit={QuoteTender}
                    render={({ handleSubmit, form }) => {
                      const uploadAttachments = (value) => {
                        let files = form.getFieldState(`attachments`).value;
                        let uploadedFiles = [], uploadingFiles = [];
                        if (files && files.length > 0) {
                          files.map(file => {
                            if (file && file.rawFile) {
                              uploadingFiles.push(file);
                            } else {
                              uploadedFiles.push(file);
                            }
                          });
                          setLoading(true);
                          dataProvider
                            .uploadAttachments({ files: uploadingFiles })
                            .then(({ data }) => {
                              if (data && data.length > 0) {
                                uploadingFiles = data.map(fileInfo => {
                                  return fileInfo;
                                });
                                form.change(
                                  `attachments`,
                                  uploadingFiles.concat(uploadedFiles)
                                );
                              }
                              setLoading(false);
                            })
                            .catch(error => {
                              setLoading(false);
                            });
                        }
                      }
                      return (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={1}>
                            <Grid item lg={12} xs={12} md={12} sm={12}>
                              <Field name='description' validate={required}>
                                {({ input, meta }) => (
                                  <>
                                    <TextInput
                                      id="outlined-multiline-static"
                                      label="Description"
                                      multiline
                                      style={{ width: '100%' }}
                                      rows={4}
                                      size="small"
                                      {...input}
                                      error={meta.error && meta.touched ? true : false}
                                      variant="outlined" />
                                  </>
                                )}
                              </Field>
                              <Field name='amount' validate={(value) => requiredNumber(value)}>
                                {({ input, meta }) => (
                                  <>
                                    <TextInput
                                      id="outlined-multiline-static"
                                      label="Quote Amount (Including GST)"
                                      multiline
                                      style={{ width: '100%' }}
                                      isNumericString
                                      size="small"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom
                                      }}
                                      {...input}
                                      error={meta.error && meta.touched ? true : false}
                                      variant="outlined" />
                                  </>
                                )}
                              </Field>
                            </Grid>
                            <Grid item lg={12} xs={12} md={12} sm={12}>
                              <FileInput
                                source="attachments"
                                label="Attachments"
                                accept=""
                                validate={(value) => requiredFile(value)}
                                multiple={true}
                                placeholder="Click here to upload your quote attachments"
                                classes={{ dropZone: classes.dropzoneContainer, removeButton: classes.removeBtn  }}
                                onChange={uploadAttachments}
                              >
                                <FilePreview />
                              </FileInput>
                            </Grid>
                            <div className={classes.actionBtnHolder}>
                              <Button disabled={loading} className={classes.saveBtn} type="submit">SUBMIT</Button>
                            </div>
                          </Grid>
                        </form>
                      )
                    }} />
                </>
              )}

            </>
          )
          }


        </DialogContent>
      </Dialog>
    </>
  );
}