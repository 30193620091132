import React, { useState } from "react";
import { SimpleForm, TextInput, required, SelectInput, useDataProvider, AutocompleteInput } from 'react-admin';
import {
  Grid,
  makeStyles,
  Typography,
  Card, TextField, Button
} from '@material-ui/core';
import { Form } from 'react-final-form';
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles({
  main: {
    width: '100%',
  },
  card: {
    padding: 20,
    marginTop: 22

  },
  title: {
    fontSize: 16,
    padding: '10px 10px 5px 0px',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 12,
    // padding: '0px 10px',
  },
  saveBtnHolder: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  saveBtn: {
    background: '#e5aa13',
    color: '#000',
    fontWeight: 'bold',
    '&:hover': {
      background: '#e5aa13',
    }
  },
});

const ContactCreate = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [contacts, setContacts] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [designation, setDesignation] = useState();
  const [selectedContact, setSelectedContact] = useState();
  const [loading, setLoading] = useState(false);
  const { save } = props;
  const managerrole = [
    'Supervisor',
    'Project Coordinator',
    'Accounts',
    'Architect',
    'Manager'
  ]
  const onSave = (data) => {
    data['name'] = selectedContact.name
    save(data);
  };
  // const onchangetype = (e) => {

  // }
  const onchangedesignation = (e) => {

    setLoading(true)
    setDesignation(e.target.value)
    if(managerrole.includes(e.target.value)){
      dataProvider.getManagers('manager', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } })
      .then(({ data }) => {
        setLoading(false)
        setContacts(data.filter((item) => item.designation === e.target.value))
      })
      .catch(error => {
        setLoading(false)
      });
    }
    else{
      dataProvider.getContractors('list-contractors', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } })
      .then(({ data }) => {
        setLoading(false)
        let arr=data.filter((item) =>{
          return(
            item.designation === e.target.value
          )
        })
        setContacts(arr)
      })
      .catch(error => {
        setLoading(false)
      });
    }
    
  }
  const onchangename = (e) => {
    setName(e)
    setSelectedContact(contacts.find((item) => item.id === e))
  }
  return (
    <Card className={classes.card}>
      <Form
        onSubmit={onSave}
        initialValues={{
          type: type && type,
          designation: designation && designation,
          name: name && name,
          email: selectedContact && selectedContact.email && selectedContact.email,
          phone: selectedContact && selectedContact.contact && selectedContact?.contact,
        }}
        render={({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Typography className={classes.title}>Contact</Typography>
              <Typography className={classes.subTitle}>Add New</Typography>
              <Grid container spacing={1} className={classes.main}>
                {/* <Grid item xs={12} sm={6}>
                  <SelectInput
                    source="type"
                    //validate={required()}
                    fullWidth={true}
                    label="Select From"
                    onChange={(e) => onchangetype(e)}
                    variant="outlined"
                    defaultValue={'Manager'}
                    choices={
                      [
                        { id: 'Contractor', name: 'Contractor' },
                        { id: 'Manager', name: 'Manager' },
                        
                      ]}
                  />
                </Grid> */}
                <Grid item xs={12} sm={12}>
                  <SelectInput
                    source="designation"
                    //validate={required()}
                    fullWidth={true}
                    label="Designation"
                    onChange={(e) => onchangedesignation(e)}
                    variant="outlined"
                    choices={
                      [
                        { id: 'Privet Certifier', name: 'Privet Certifier' },
                        { id: 'Supervisor', name: 'Supervisor' },
                        { id: 'Project Coordinator', name: 'Project Coordinator' },
                        { id: 'Engineer', name: 'Engineer' },
                        { id: 'Manager', name: 'Manager' },
                        { id: 'Accounts', name: 'Accounts' },
                        { id: 'Plumber', name: 'Plumber' },
                        { id: 'Level 2 Electration', name: 'Level 2 Electration' },
                        { id: 'Private Certifiers', name: 'Private Certifiers' },
                        { id: 'Frame', name: 'Frame' },
                        { id: 'Concreter', name: 'Concreter' },
                        { id: 'Pest control', name: 'Pest control' },
                      ]}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  {/* <TextInput
                source="name"
                fullWidth={true}
                label="Name"
                variant="outlined"
                size="small"
                validate={required()}
              /> */}
                  <AutocompleteInput
                    source="name"
                    fullWidth={true}
                    label="Name"
                    variant="outlined"
                    size="small"
                    onChange={(e) => onchangename(e)}
                    validate={required()}
                    choices={contacts} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextInput
                    source="email"
                    fullWidth={true}
                    label="Email Address"
                    validate={required()}
                    // defaultValue={selectedContact && selectedContact.email && selectedContact.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextInput
                    source="phone"
                    validate={required()}
                    fullWidth={true}
                    // defaultValue={selectedContact && selectedContact.contact && selectedContact?.contact}
                    label="Contact Number"
                    variant="outlined"
                  />
                </Grid>
                <div className={classes.saveBtnHolder}>
                  <Button disabled={loading} className={classes.saveBtn} type="submit">
                    <SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                </div>
              </Grid>
            </form>
          )
        }} />
    </Card>
  )
}

export default ContactCreate;
