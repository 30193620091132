import * as React from "react";
import { List, Datagrid, TextField,EditButton } from 'react-admin';
import MaterialCreate from './MaterialCreate';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme) =>({
  heading: {
    margin: '25px 15px 0',
    paddingBottom: 10,
    '& h6': {
      fontSize: 18,
      paddingLeft: 5,
      // fontWeight: 'bold',
      color:'#826724',
      textTransform: 'capitalize',
    }
  },
}));


const StatusField = ({record,source}) => {
  let status = (record && record[source] === 1) ? 'Enabled' : 'Disabled';
  return(
    <span >{status}</span>
  );
}

const MaterialList = props => {
  const classes = useStyles();
  return(
    <>
      <MaterialCreate {...props}/>
      <List {...props}
        actions={<SectionHeading label="materials" classProp={classes.heading}/>}
      >
        <Datagrid>
          <TextField source="name" />
          <StatusField source="status" />
          <TextField source="dateAdded" />
          <EditButton source="Action"/>
        </Datagrid>
      </List>
    </>
  );
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <ListIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

export default MaterialList;
