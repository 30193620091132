import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import {
  FileInput,
} from 'react-admin';
import get from 'lodash/get';
import NotesTab from './ContractorNotestab';
import TaskMessenger from './TaskMessages';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useField } from 'react-final-form';

const useStyles = makeStyles((theme) =>({
  paper: {
    background: '#f3f3f3',
    marginTop:20,
    boxShadow:'none',
  },
  tab: {
    minWidth: 140,
    width: 140,
    background: '#f3f3f3',
    fontSize: 14,
    // textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#fff',
  },
  fileHolder: {
    display: 'inline-flex',
    background: '#f3f3f3',
    padding: 10,
    marginBottom: 10,
    '& a': {
      textDecoration: 'none',
      color: '#514646',
    },
    margin: 5,
    textAlign: 'center',
  },
  // fileTitle: {
  //   fontSize: 13,
  //   paddingLeft: 10,
  //   maxWidth: 85,
  // },
  fileTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    padding: '5px 0',
  },
  fileSubTitle: {
    fontSize: 12,
    color: '#322f2f',
  },
  fileIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 130,
  },
}));

function ContractorTabField(props) {
  const{ data } = props;
  const [Tabvalue, setTabValue] = useState(0);
  const classes = useStyles();

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  return(
    <>
      <Paper square className={classes.paper}>
        <Tabs
          value={Tabvalue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          
        >
          <Tab label="Notes" classes={{ root: classes.tab,selected: classes.selectedTab }} />
          <Tab label="Files" classes={{ root: classes.tab,selected: classes.selectedTab }} />
          <Tab label="Messages" classes={{ root: classes.tab,selected: classes.selectedTab }} />
        </Tabs>
      </Paper>

      
      {(Tabvalue === 0) ? (
        <NotesTab data={data}/>
      ): (
        null
      )}

      {(Tabvalue === 1) ? (
        <>
          <FileInput source="files" label="Related files" accept="" multiple={true} className="task-file-input">
            <FilePreview />
          </FileInput>

          <NonField name="uploads" />
        </>
      ): (
        null
      )}

      {(Tabvalue === 2) && (
        <TaskMessenger taskId={data.id} />
      )}
    </>
  )
}

const NonField = ({ name, label }) => {
  const classes = useStyles();
  const {
      input: { onChange,value },
      meta: { touched, error }
  } = useField(name);
  return (
    <>
      {(value && value.length > 0) && (
        <div className={classes.previewHolder}>
          {(value.map(_upload => {
            return (
              <FilePreview record={_upload} key={_upload.id}/>
            )
          }))}
        </div>
      )}
    </>
  );
};

const FilePreview = ({record,source}) => {
  const classes = useStyles();
  let sourceValue = get(record, source);
  if(!sourceValue){sourceValue = record.url};
  return (
    <div className={classes.fileHolder} >
      {((record.rawFile && record.rawFile.type === "application/pdf") || (record.type === "application/pdf")) ? (
        <div className={classes.fileIcon}>
          <a href={(record.undefined) ? record.undefined : record.url} target="_blank">
            <PictureAsPdfIcon fontSize= "large" style={{color: 'red',width: 130,height: 100}} />
            <Typography className={classes.fileTitle}>{(record.rawFile) ? record.rawFile.name : record.name}</Typography>
            {(record.stat) && (
              <Typography className={classes.fileSubTitle}>{`Uploaded On ${record.stat}`}</Typography>
            )}
          </a>
        </div>
      ) : (
        <a href={(record.undefined) ? record.undefined : record.url} target="_blank">
          <img
            style={{
              width: 130,
              height: 100,
            }}
            src={sourceValue}
            // className={classes.image}
            alt=""
          />
          {(record.name) && (
            <Typography className={classes.fileTitle}>{record.name}</Typography>
          )}
          {(record.stat) && (
            <Typography className={classes.fileSubTitle}>{`Uploaded On ${record.stat}`}</Typography>
          )}
        </a>
      )}
    </div>
  )
}

export default ContractorTabField;
