import React from 'react';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray } from "react-final-form-arrays";

const useStyles = makeStyles({
  permissionSection: {
    padding: '10px 20px',
    border: '1px solid #d0caca',
    marginTop: 10,
    background: '#fff',
    margin: 'inherit',
    alignItems: 'center',
  },
  infoTitle: {
    textTransform: 'capitalize',
    fontSize: 14,
    fontWeight: 'bold',
  }
});


function Permission(props) {
  const classes = useStyles();
  const { name,list,label } = props;
  return (
    <Grid container spacing={1} className={classes.permissionSection}>
      <Grid item xs={12} sm={6}>
        <Typography className={classes.infoTitle}>{label}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
      <FieldArray
        name={name}
        component={CheckboxGroup}
        options={list}
      />
      </Grid>
    </Grid>
  )
}

const CheckboxGroup = ({ fields, options }) => {

  const toggle = (event, option) => {
    if (event.target.checked) fields.push(option);
    else fields.remove(option);
  };
  
  return (
    <FormControl>
      {options.map(option => {
        let checked = (fields.value && fields.value.length && fields.value.length > 0) ? 
          fields.value.includes(option) : false;
        return (
          <FormGroup key={option}>
            <FormControlLabel
              control={<Checkbox value={option} checked={checked} color="primary" onChange={event => toggle(event, option)}/>}
              label={option}
            />
          </FormGroup>
        )
      })}
    </FormControl>
  );
};

export default Permission;
