import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Schedules from './Schedules';
import ComingSchedules from './Comingschedules';
import { useAuthState,usePermissions,useAuthProvider  } from 'react-admin';

export default (props) => {
  const { loading, authenticated } = useAuthState();
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const classes = useStyles();
  
  if (loading) {
    return null;
  }
  if (authenticated) {
    return(
      <div className={classes.main} >
        {user && user.id && permissions && permissions.role &&
          <>
            <Schedules role={permissions.role} userId ={user.id} />
            <ComingSchedules role={permissions.role} userId ={user.id} />
          </>
        }
       
      </div>
    )
  }
  return null;
}

const useStyles = makeStyles((theme) => ({
  main: {
    margin: '10px 0',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
}));
