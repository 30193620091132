/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect,useState} from 'react';
import {Button,Dialog, Typography, IconButton, DialogContent, DialogTitle  } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { 
  useDataProvider,
  Loading,
  SimpleForm
} from 'react-admin';
import TaskMessenger from './TaskMessages';
import ContractorTab from './ContractorTab';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({open,onClose,taskId,role,isTaskManager}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [task, setTask] = useState();
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const saveTask = (data) => {
    setLoading(true);
    if(!data.sub_notes || data.sub_notes === null){data['sub_notes'] = ''};
    if(!data.all_notes || data.all_notes === null){data['all_notes'] = ''};
    dataProvider.updateProjectTaskContractor(data.id,{data:data}).then(({ data }) => {
      setTask({...task,sub_notes: data.sub_notes,all_notes:data.all_notes,files: data.files,uploads: data.uploads})
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if(!loading) {setLoading(true);}
    dataProvider.getOne('projecttask',{id:taskId}).then(({ data }) => {
      setLoading(false);
      setTask(data)
      setError(null);
    })
    .catch(error => {
      setLoading(false);
      setError(error);
    });
    // return () => {
    //   taskId = null;
    // };
  
  },[taskId]);

  if(role === 'manager' && isTaskManager) return null;

  return (
      <Dialog 
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth = {'md'}
      >
        <DialogTitle className={classes.topheader} id="draggable-dialog-title">
          <Typography className={classes.Modal_title}>Schedule Item Details</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon className={classes.closeButtonIcon} />
          </IconButton>
        </DialogTitle>

        {(loading || !task) ? (
          <Loading loadingPrimary="" loadingSecondary="" />
        ) : (

          <DialogContent className={classes.content_bg}>
            <div className={classes.content_holder}>
              <div className={classes.content_title}>
                 <Typography className={classes.Heading_content}>Schedule Item Information</Typography>
              </div>
              <div>
                <div className={classes.content_item}>
                  <Typography className={classes.content_item_title}>Project</Typography>
                  <Typography className={classes.content_item_data}>{task.project}</Typography>
                </div>
                <div className={classes.content_item}>
                  <Typography className={classes.content_item_title}>Title</Typography>
                  <Typography className={classes.content_item_data}>{task.title}</Typography>
                </div>
                <div className={classes.content_item}>
                  <Typography className={classes.content_item_title}>Start Date</Typography>
                  <Typography className={classes.content_item_data}>{task.startDate}</Typography>
                </div>
                <div className={classes.content_item}>
                  <Typography className={classes.content_item_title}>End Date</Typography>
                  <Typography className={classes.content_item_data}>{task.endDate}</Typography>
                </div>
                <div className={classes.content_item}>
                  <Typography className={classes.content_item_title}>Work Days</Typography>
                  <Typography className={classes.content_item_data}>{`${task.duration} day(s)`}</Typography>
                </div>

                {(task.contractors !== '' && task.assignedToMe) && (
                  <div className={classes.content_item}>
                    <Typography className={classes.content_item_title}>Contractors</Typography>
                    <Typography className={classes.content_item_data_block}>{task.contractors}</Typography>
                  </div>
                )}
                
                {(task.contractors !== '' && task.assignedToMe) && (
                  <div className={classes.content_item}>
                    <Typography className={classes.content_item_title}>Confirmation Status</Typography>
                    <Typography className={classes.content_item_data}>{`Confirmed by Sub Contractor on ${task.confirmationStatus}`}</Typography>
                  </div>
                )}
                
              </div>
              {/* {(task && task.assignedToMe) && (
                <div className={classes.content_item}>
                  <TaskMessenger taskId={taskId} />
                </div>
              )} */}
               {(task && task.assignedToMe) && (
                 <SimpleForm initialValues={task} save={saveTask}>
                  <ContractorTab data={task} />
                 </SimpleForm>
                
              )}
              
            </div>
            
          </DialogContent>
        )}

      </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  topheader: {
    padding: 10,
    background: '#14151e',
    color: '#fff',
    cursor: 'move',
  },
  closeButton:{
    right:5,
    position: 'absolute',
    padding:2,
    top:2,
    color:'#fff',
  },
  Modal_title:{
    fontSize:14,
  },
  content_bg:{
    padding:13,
  },
  content_holder:{
    background:'#fff',
  },
  content_holder_bottom:{
    background:'#fff',
    marginTop:14,
  },
  Heading_content:{
    fontWeight: '600',
    fontSize: 12,
  },
  content_title:{
    padding:10,
    borderBottom: '1px solid #e8e8e8',
  },
  content_item:{
    padding: '10px'
  },  
  content_item_title:{
    fontSize:'12px',
    fontWeight:'600',
    marginBottom: '10px',
  },
  content_item_data:{
    fontSize:'13px',
  },
  content_item_holder:{
    padding:'10px',
  },
  content_item_data_block:{
      display: 'inline-block',
      border: '1px solid #ddd',
      fontSize: '13px',
      padding: '4px',
      borderRadius: '6px',
      //background: '#ddd',
  },
  message_area:{
      width: '100%',
      border: '1px solid #ddd',
      borderRadius:'3px',
  },
  closeButtonIcon:{
    fontSize:'18px',
    margin: '5px',
  },
  btn_send:{
    background: '#999305',
    color: '#fff',
    marginTop: 10,
    textTransform: 'capitalize',
  }
}));
