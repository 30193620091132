import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Typography, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteArrayInput, TextInput, useDataProvider, useNotify, BooleanInput } from 'react-admin';
import GroupIcon from '@material-ui/icons/Group';
import SaveIcon from '@material-ui/icons/Save';
import { FcRuler } from "react-icons/fc";
import {
  FcGenealogy,
  FcMindMap
} from "react-icons/fc";
const useStyles = makeStyles((theme) => ({
  BtnHolder: {
    width: '100%',
    textAlign: 'center',
    padding: '10px 0px',
  },
  BtnSave: {
    backgroundColor: '#670e3c',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#670e3c',
      color: '#fff',
    }
  },
  Width400: {
    width: 400,
  },
  Width100_p: {
    width: '100%',
  },
  loader_holder: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loader: { color: '#670e3c' },
  errorField: {
    color: '#f00',
    fontSize: '0.75rem',
  },
  saveBtnHolder: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  saveBtn: {
    background: '#e5aa13',
    color: '#000',
    fontWeight: 'bold',
    '&:hover': {
      background: '#e5aa13',
    }
  },
  heading: {
    padding: 16,
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color: '#e7b02c',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalContent: {
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ddd',
      borderRadius: 15,
      outline: '1px solid #ddd'
    }
  }
}))
export default function CreateGroup({ rows, setRows }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const required = (value) => {
    if (value) {
      return undefined;
    } else {
      return '*Required';
    }
  }
  const CreateGroup = (values) => {
    console.log(values)
    dataProvider.createCategory(values, 'maincategory').then(({ data }) => {
      if (!data) {
        alert('Baseprice already exist')
      }
      else {
        setRows(data);
        handleClose();
      }

    }).catch(error => {
      console.log(error)
    });
  }
  return (
    <>
      <Button onClick={handleClickOpen} style={{ display: 'flex', alignItems: 'center', background: 'transparent', color: '#e5aa13' }}>
        <AddIcon />
        Create</Button>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" >
            <div className={classes.heading} >
              <FcGenealogy />
              <Typography variant="h6" gutterBottom>
                Add new Category
              </Typography>
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <Form
            onSubmit={CreateGroup}
            render={({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Field name='category_name' validate={required}>
                        {({ input, meta }) => (
                          <>
                            <TextInput
                              id="outlined-multiline-static"
                              label="Category Name"
                              style={{ width: '100%' }}
                              size="small"
                              {...input}
                              error={meta.error && meta.touched ? true : false}
                              variant="outlined" />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Field name='sort_order' validate={required}>
                        {({ input, meta }) => (
                          <>
                            <TextInput
                              id="outlined-multiline-static"
                              label="Sort Order"
                              style={{ width: '100%' }}
                              size="small"
                              {...input}
                              error={meta.error && meta.touched ? true : false}
                              variant="outlined" />
                          </>
                        )}
                      </Field>
                    </Grid>
                    <BooleanInput label="BasePrice" source="base" />
                    <BooleanInput label="Additional" source="additional" />
                    <div className={classes.saveBtnHolder}>
                      <Button className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                    </div>
                  </Grid>
                </form>
              )
            }} />
        </DialogContent>
      </Dialog>
    </>
  );
}