import React,{ useEffect, useState} from 'react';
import { 
  TextInput,
  required,
  SimpleForm,
  RadioButtonGroupInput,
  NumberInput,
  FormDataConsumer,
  FileInput,
  FileField,
  SelectInput,
  PasswordInput,
  DateInput,
  Loading,
  SaveButton,
  Toolbar,
  useDataProvider,
  useNotify,
  email,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  ReferenceInput,
} from 'react-admin';

import {Grid,Button,InputAdornment,Paper,Tabs,Tab } from '@material-ui/core';
import {makeStyles } from '@material-ui/core/styles';
import InvitedList from './InvitedList';

export default function InviteClient() {

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    // console.log(values)
    setLoading(true);
    dataProvider.inviteClient(values)
    .then(({data}) => {
      setLoading(false);
      notify(`Successfully sent invitation mail to ${data.name}`, 'info');
    })
    .catch(error => {
      notify(`${error.message}`, 'warning');
      setLoading(false);
    });
  };

  if(loading) return <Loading />
  
  return (
    <div className={classes.container}>
      <div className={classes.heading} >Invite Client</div>
      <SimpleForm
        save={onSubmit}
        validate={validateUserCreation}
        toolbar={
          <NavigationToolBar
          />
        }
      >
        <Grid container spacing={1} fullWidth={true}>
          <Grid item xs={12} sm={6}>
            <TextInput 
              source="first_name"
              label="First Name"
              fullWidth={true}
              variant="outlined"
              validate={required()}
              helperText="Your firstname"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput 
              source="last_name"
              label="Last name"
              fullWidth={true}
              variant="outlined"
              // validate={required()}
              helperText="lastname/Surname"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="email"
              label="Email"
              fullWidth={true}
              variant="outlined"
              validate={validateEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source="contact"
              label="Phone"
              fullWidth={true}
              variant="outlined"
              validate={required()}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} fullWidth={true} alignItems='center'>
          <Grid item xs={12} sm={6}>
            <TextInput source="title" fullWidth={true} label="Project Title" variant="outlined" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="description" fullWidth={true} variant="outlined" multiline />
          </Grid>
        </Grid>


        <ReferenceArrayInput
          reference="managers"
          source="managers"
          label="Project Managers"
          fullWidth={true}
          variant="outlined"
          resource="managers"
          validate={required()}
          filter={{all:true,status: 1}}
        >
          <SelectArrayInput optionText="fullname" optionValue="id" options={{ fullWidth: true }}>
            <ChipField />
          </SelectArrayInput>
        </ReferenceArrayInput>

        <Grid container spacing={1} fullWidth={true}>
          <Grid item xs={12} sm={6}>
            <SelectInput
              source="project_type"
              label="Project Type"
              choices={[
                { id: 'construction', name: 'Construction' },
                { id: 'pre_contract', name: 'Pre Contract' },
                { id: 'design_approval', name: 'Design Approval' },
              ]}
              defaultValue={'pre_contract'}
              variant="outlined"
              fullWidth={true}
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceInput
              source="template"
              reference="templates"
              label="Template"
              fullWidth={true}
              validate={required()}
              variant="outlined"
              filter={{all:true,status: 1}}
            >
              <SelectInput optionText="name" fullWidth={true} />
            </ReferenceInput>
          </Grid>
        </Grid>

        <TextInput
          source="email_content"
          label="Email Body Content"
          fullWidth={true}
          variant="outlined"
          multiline={true}
          validate={required()}
          initialValue={`NEXA HOMES is asking that you participate in it's project management software that is absolutely free to you. By activating your free account by clicking complete form, you can follow along with the progress of the work, get notified about work progress, and communicate with us easier.`}
        />
      </SimpleForm>
      <InvitedList />
    </div>
  )
}

const validateEmail = [required(),email()];

const validateUserCreation = async (values) => {
  const errors = {};
  try {
    if(values.email && values.email !== '') {
      const isEmailUnique = await checkEmailIsUnique(values.email);
      if(isEmailUnique && isEmailUnique.status) {

      } else {
        errors.email = ['Email is already registered Please use another one'];
      }
      console.log(isEmailUnique);
    }
    return errors
  } catch(err) {
    return errors
  }

};

const checkEmailIsUnique = async (email) => {

  const response = (await fetch('https://www.nexaplan.com.au:3000/auth/verify-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email : email})
  })).json();
  return response;
}

const useToolbarStyles = makeStyles({
  toolbar: {
    justifyContent: 'flex-end',
  },
});

const NavigationToolBar = props => {
  const toolbarStyles = useToolbarStyles();
  return (
    <Toolbar {...props} classes={toolbarStyles} >
      <SaveButton
        label="Invite"
        //variant="outlined"
        //className={}
        //redirect="show"
        //submitOnEnter={true}
      />
    </Toolbar>
  )
};
const useStyles = makeStyles((theme) =>({
  container: {
    border: '1px solid #f3f3f3',
    margin: 20,
  },
  heading: {  
    fontSize: 16,
    padding: 10,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));