
import React from 'react';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Badge } from '@material-ui/core';
import { usePermissions, useAuthProvider, useDataProvider } from 'react-admin';
import {
  FcInTransit,
  FcParallelTasks,
  FcTodoList,
  FcManager,
  FcVoicePresentation,
  FcSurvey,
  FcHome,
  FcTimeline,
  FcServices,
  FcReadingEbook,
  FcTemplate,
  FcCalendar,
  FcFaq,
  FcDocument,
  FcAdvertising,
  FcPhoneAndroid,
  FcViewDetails,
  FcFeedback,
  FcBriefcase,
  FcInspection,
  FcConferenceCall,FcRuler,FcGenealogy,FcCheckmark
} from "react-icons/fc";
import GavelIcon from '@material-ui/icons/Gavel';
import BusinessIcon from '@material-ui/icons/Business';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
const useStyles = makeStyles({
  IconHolder: {
    minWidth: 100,
    boxShadow: '0px 0px 30px rgb(73 78 92 / 15%)',
    display: 'block',
    textAlign: 'center',
    //width: 100,
    borderRadius: 5,
    //background: '#282a3c',
    //border: '1px solid #ebbd17',
    padding: '15px 5px',
    cursor: 'pointer',
    minHeight: 70,
    '&:hover': {
      background: '#e5aa13', //'#ebbd17',
      '& svg': {
        color: '#26231b'
      },
      '& p': {
        color: '#fff'
      },

    },
    '& p': {
      fontSize: 12,
      textTransform: 'uppercase',
      color: '#3a2400',
      letterSpacing: 1,
      paddingTop: 5,
      width: 100,
      //fontWeight: 'bold',
    },
    '& svg': {
      textDecoration: 'none',
      color: '#dadbe6',
      fontSize: 32,
      '&:active': {
        color: '#fff',
      }
    },
  },
  anchor: {
    textDecoration: 'none',
    color: '#000',
    '&:active': {
      color: '#fff',
    }
  },
});

const iconList = [
  { id: 1, label: 'View Schedules', icon: <FcCalendar />, route: '/today-schedule' },
  { id: 1, label: 'projects', icon: <FcHome />, route: '/projects' },
  { id: 2, label: 'create new project', icon: <FcReadingEbook />, route: '/projects/create' },
  { id: 3, label: 'project phases', icon: <FcParallelTasks />, route: '/phases' },
  { id: 4, label: 'project tasks', icon: <FcTimeline />, route: '/tasks' },
  { id: 5, label: `add to-Do'S`, icon: <FcTodoList />, route: '/todos' },
  { id: 6, label: 'add sub contractor', icon: <FcManager />, route: '/contractors/create' },
  { id: 7, label: 'add suppliers', icon: <FcInTransit />, route: '/suppliers' },
  { id: 8, label: 'add material', icon: <FcServices />, route: '/materials' },
  { id: 9, label: 'Create template', icon: <FcTemplate />, route: '/templates' },
  { id: 10, label: 'Invite new Client', icon: <FcVoicePresentation />, route: '/invite-client' },
  { id: 11, label: 'certificates', icon: <FcDocument />, route: '/certificate' },
  { id: 12, label: 'Pre-contract projects', icon: <FcSurvey />, route: '/pre-contracts' },
  { id: 13, label: 'App Popup"s', icon: <FcAdvertising />, route: '/app-popups' },
  { id: 14, label: 'Client Invites', icon: <FcPhoneAndroid />, route: '/client-invites' },
  { id: 16, label: 'Referal Teams', icon: <FcBriefcase />, route: '/referals' },
  // {id: 15,label: 'Contractor Calendar', icon: <FcViewDetails />, route: '/contractor-schedules'}
  // {id: 10,label:'settings', icon: 'FcEngineering', route: '/settings'},
];

export default function MenuIcon() {

  const classes = useStyles();
  const { permissions } = usePermissions();
  const [tenderRead, setTenderUnread] = React.useState(0);
  const [quoteRead, setQuoteUnread] = React.useState(0);
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const user = authProvider.getIdentity();
  React.useEffect(() => {
    dataProvider.getTenders({ user: user.id }).then(({ data }) => {
      if (data.length) {
        data.map(item => {
          if (!item.seen.includes(user.id)) {
            setTenderUnread(tenderRead + 1);
          }
        })
      }
    })
      .catch(error => {});
      dataProvider.getAllTenderQuotes({}).then(({ data }) => {
        if (data.length) {
          data.map(item => {
            if (!item.seen.includes(user.id)) {
              setQuoteUnread(quoteRead + 1);
            }
          })
        }
      })
        .catch(error => {});
  }, []);
  const IconBox = iconList.map((row, index) => {
    return (
      <Box p={1} m={2} alignItems="center" key={index} >
        <Link to={row.route} className={classes.anchor}>
          <div className={classes.IconHolder}>
            {/* <CallToActionIcon /> */}
            {row.icon}
            <Typography>{row.label}</Typography>
          </div>
        </Link>
      </Box>
    )
  })

  return (
    <div style={{ width: '100%' }}>
      <Box
        display="flex"
        flexWrap="wrap"
        bgcolor="background.paper"
      >
        {(permissions && permissions.role === 'admin') && (
          IconBox
        )}

        {(permissions && (permissions.role === 'admin' || permissions.role === 'manager')) ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/contractor-schedules`} className={classes.anchor}>
              <div className={classes.IconHolder}>
                <FcViewDetails />
                <Typography>Contractor Calendar</Typography>
              </div>
            </Link>
          </Box>
        ) : null}

        {(permissions && (permissions.role === 'admin' || permissions.role === 'manager')) ? (
          <>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/groups`} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcConferenceCall />
                  <Typography>Groups</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/tenders`} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <GavelIcon style={{ color: '#3f51b5' }} />
                  <Typography>Tenders</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/tender-quotes`} className={classes.anchor}>
                <Badge badgeContent={quoteRead} color="primary">
                  <div className={classes.IconHolder}>
                    <AllInboxIcon style={{ color: '#e96720' }} />
                    <Typography>Tenders Quotes</Typography>
                  </div>
                </Badge>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/home-tenders`} className={classes.anchor}>
                <Badge badgeContent={quoteRead} color="primary">
                  <div className={classes.IconHolder}>
                    <BusinessIcon style={{ color: '#2196f3' }} />
                    <Typography>Home Tenders</Typography>
                  </div>
                </Badge>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/client-chat`} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcFaq />
                  <Typography>Chat With Client</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/send-message`} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcFeedback />
                  <Typography>Send SMS</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/units`} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcRuler />
                  <Typography>{'Units & Mesurement'}</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/categories`} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcGenealogy />
                  <Typography>{'Estimate Category'}</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/ChecklistCategory`} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <CheckCircleOutlineIcon style={{ color: 'green' }} />
                  <Typography>{'QA Checklist'}</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/inspection`} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcInspection />
                  <Typography>{'Inspection Section'}</Typography>
                </div>
              </Link>
            </Box>
          </>
        ) : null}
        {(permissions && permissions.role === 'contractor') && (
          <>
            <Box p={1} m={2} alignItems="center" >
              <Link to={'/today-schedule'} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcCalendar />
                  <Typography>{'View Schedules'}</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center">
              <Link to={`/posted-tenders`} className={classes.anchor}>
                <Badge badgeContent={tenderRead} color="primary">
                  <div className={classes.IconHolder}>
                    <GavelIcon style={{ color: '#3f51b5' }} />
                    <Typography>Tenders</Typography>
                  </div>
                </Badge>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center" >
              <Link to={'/projects'} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcHome />
                  <Typography>{'Projects'}</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center" >
              <Link to={'/request-material'} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcServices />
                  <Typography>{'Request Materials'}</Typography>
                </div>
              </Link>
            </Box>
          </>
        )}
        {(permissions && permissions.role === 'manager') && (
          <>
            <Box p={1} m={2} alignItems="center" >
              <Link to={'/today-schedule'} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcCalendar />
                  <Typography>{'View Schedules'}</Typography>
                </div>
              </Link>
            </Box>
            <Box p={1} m={2} alignItems="center" >
              <Link to={'/projects'} className={classes.anchor}>
                <div className={classes.IconHolder}>
                  <FcHome />
                  <Typography>{'Projects'}</Typography>
                </div>
              </Link>
            </Box>
          </>
        )}
        {(permissions && permissions.role === 'company') && (
          <Box p={1} m={2} alignItems="center" >
            <Link to={`/referals${user && user.id ? `/${user.id}` : ''}`} className={classes.anchor}>
              <div className={classes.IconHolder}>
                <FcBriefcase />
                <Typography>{'Add Employees'}</Typography>
              </div>
            </Link>
          </Box>
        )}
      </Box>
    </div>
  );
}
