import * as React from "react";
import {
  Edit,
  TextInput,
  required,
  SimpleForm,
  SelectInput,
  SelectArrayInput,
  ChipField,
  ReferenceArrayInput
} from 'react-admin';
import CreateIcon from '@material-ui/icons/Create';
import { Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  main: {
    width: '100%',
  },
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  }
});


const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const SupplierEdit = props => {
  const classes = useStyles();
  return (
  <Edit {...props}>
    <SimpleForm>
    <SectionHeading label="Edit Supplier" classes={classes} />
      <FormFields classes={classes} />
    </SimpleForm>
  </Edit>
)};

const FormFields = (props) => {
  return(
    <Grid container spacing={1} className={props.classes.main}>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="first_name"
          validate={required()}
          fullWidth={true}
          label="First Name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="last_name"
          validate={required()}
          fullWidth={true}
          label="Last Name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="email"
          fullWidth={true}
          label="Email Address"
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="company"
          fullWidth={true}
          label="Company Name"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="contact"
          validate={required()}
          fullWidth={true}
          label="Contact Number"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="registerId"
          fullWidth={true}
          label="Registeration Id"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="address"
          validate={required()}
          multiline
          fullWidth={true}
          label="Street"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="city"
          validate={required()}
          fullWidth={true}
          label="Suburb"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="state"
          validate={required()}
          fullWidth={true}
          label="State/Province/Region"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="zip"
          validate={required()}
          fullWidth={true}
          label="Postal code"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="country"
          validate={required()}
          fullWidth={true}
          label="Country"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
      <ReferenceArrayInput
        reference="materials"
        source="materials"
        label="materials"
        fullWidth={true}
        variant="outlined"
        resource="materials"
        filter={{all:true}}
      >
        <SelectArrayInput options={{ fullWidth: true }}>
          <ChipField source="name" />
        </SelectArrayInput>
      </ReferenceArrayInput>
       
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextInput
          source="remarks"
          fullWidth={true}
          variant="outlined"
          multiline
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectInput
          source="status"
          choices={[
            { id: 1, name: 'Enabled' },
            { id: 0, name: 'Disabled' },
          ]}
          variant="outlined"
          fullWidth={true}
        />
      </Grid>
    </Grid>
  )
}

export default SupplierEdit;