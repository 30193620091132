import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  PasswordInput,
  SelectInput,
  // useRedirect,
} from 'react-admin';
import {Grid,Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-final-form';
import ImageUpload from '../components/ImageUpload';
import { Field } from 'react-final-form';

var generator = require('generate-password');

const useStyles = makeStyles({
  main: {
    width: '100%',
    //alignItems: 'center',
  },
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  passwordTitle: {
    fontSize: 12,
    textTransform: 'capitalize',
    paddingBottom: 10,
    color: '#FF9800',
  },
  passwordButton: {
    margin: 10,
  }
});



const ContractorEdit = props => {
  //const redirect = useRedirect();
  // const onSuccess = ({ data }) => {
  //   // notify(`Changes to post "${data.title}" saved`)
  //   redirect('/contractors/create');
  // };
  const classes = useStyles();
  return(
    <Edit {...props}>
      <SimpleForm redirect="create">
        <HeaderWIthUpload {...props} classes={classes} />
        <Grid container spacing={1} className={classes.main}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="first_name"
              fullWidth={true}
              label="First Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="last_name"
              fullWidth={true}
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              source="designation"
              //validate={required()}
              fullWidth={true}
              label="Designation"
              variant="outlined"
              choices={[
                { id: 'Privet Certifier', name: 'Privet Certifier' },
                { id: 'Engineer', name: 'Engineer' },
                { id: 'Plumber',name: 'Plumber'},
                { id: 'Level 2 Electration',name: 'Level 2 Electration'},
                { id: 'Private Certifiers',name: 'Private Certifiers'},
                { id: 'Frame',name: 'Frame'},
                { id: 'Concreter',name: 'Concreter'},
                { id: 'Pest control',name: 'Pest control'}
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="email"
              fullWidth={true}
              label="Email Address"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="company"
              fullWidth={true}
              label="Company Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="contact"
              fullWidth={true}
              label="Contact Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="registerId"
              fullWidth={true}
              label="Registeration Id"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
               source="street"
               multiline
               fullWidth={true}
               label="Street"
               variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
               source="suburb"
               fullWidth={true}
               label="Suburb"
               variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="state"
              fullWidth={true}
              label="State/Province/Region"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="zip"
              fullWidth={true}
              label="Postal code"
              variant="outlined"
            />
          </Grid>
          <PasswordField classes={classes} />
          <Grid item xs={12} >
            <SelectInput
              source="status"
              choices={[
                { id: 1, name: 'Enabled' },
                { id: 0, name: 'Disabled' },
              ]}
              fullWidth={true}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Field name="profile_Img" component="input" type="hidden"/>
      </SimpleForm>
    </Edit>
  );
}

const HeaderWIthUpload = (props) => {
  const {classes} = props;
  const form = useForm(); 
  let profile_Img = form.getFieldState('profile_Img');

  return (
    <Grid container spacing={1} className={classes.main}>
      <Grid item xs={12} sm={6}>
        <Grid container direction="row" alignItems="center" className={classes.heading}>
          <CreateIcon />
          <Typography variant="h6" gutterBottom>
            Edit Sub Contractor
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageUpload {...props} src={profile_Img && profile_Img.value ? profile_Img.value : ''} />
      </Grid>
    </Grid>
  )
}


const PasswordField = (props) => {
  const {classes} = props;
  const form = useForm();
  const changePassword = () => {
    form.change('password', generator.generate(
      {
        length: 15,
        numbers: true
      }
    ));
  }
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12} sm={9}>
        <Typography className ={classes.passwordTitle}>Update password</Typography>
        <PasswordInput
          fullWidth={true}
          source="password"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button variant="outlined"
          color="primary" 
          className={classes.passwordButton}
          onClick={changePassword}
        >
          Generate Password
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContractorEdit;

