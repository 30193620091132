export const darkTheme = {
  palette: {
    primary: {
      main: '#90caf9',
    },
    type: 'dark', // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: '#e5aa13',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#e5aa13',
      dark: '#001064',
      contrastText: '#fff',
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 12,
    },
    background: {
      default: '#fcfcfe',
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
      },
      active: {
        borderLeft: '3px solid #4f3cc9',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      // root: {
      //   border: '1px solid #e0e0e3',
      //   backgroundClip: 'padding-box',
      // },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: '#4f3cc9',
        boxShadow: 'none',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#dadbe6',
        fontSize: 14,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
};

export const ExtraTheme = {
  palette: {
    primary: {
      main: '#e5aa13',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#e5aa13',
      dark: '#001064',
      contrastText: '#fff',
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 12,
    },
    background: {
      default: '#fcfcfe',
    },
  },
  shape: {
    //borderRadius: 10,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
      },
      active: {
        borderLeft: '3px solid #4f3cc9',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      // root: {
      //   border: '1px solid #e0e0e3',
      //   backgroundClip: 'padding-box',
      // },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: '#4f3cc9',
        boxShadow: 'none',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiInputLabel: {
      root: {
        //color: '#dadbe6',
        fontSize: 14,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    RaRadioButtonGroupInput: {
    },
  },
};