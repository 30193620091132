import React, {useEffect,useState} from 'react';
import { useDataProvider } from 'react-admin';
import ScheduleTask from './ScheduleTask';
import ScheduleTodo from './ScheduleTodo';

function Schedules(props) {

  const [schedules, setSchedules] = useState();
  const dataProvider = useDataProvider();

  const handleComplete = (item,stat) => (event) => {
    let taskList = schedules.map(project => {

      if(stat === 'task') {
        let FIlteredlist = project.tasks.map(task => 
          item.id === task.id 
          ? {...task, status:2} 
          : task
        );
        project.tasks = FIlteredlist;

      } else {
        let FIlteredlist = project.todos.map(todo => 
          item.id === todo.id 
          ? {...todo, status:2} 
          : todo
        );
        project.todos = FIlteredlist;
      }
      return project;
    });
    setSchedules(taskList);
    let params = {id:item.id,stat: stat};
    if(props.role && props.role !== 'admin') {
      params['assigneeId'] = props.userId;
    }
    dataProvider.updateTaskStatus({data:params}).then(({ data }) => {
      setSchedules(data);
    })
    .catch(error => {
      console.log(error);
    });
  };

  
  useEffect(() => {
    let filter = {};
    if(props.role && props.role !== 'admin') {
      filter.assigneeId = props.userId;
    }
    dataProvider.getScheduletasks(filter).then(({ data }) => {
      //console.log(data);
      setSchedules(data);
    })
    .catch(error => {
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!schedules) return null;

  return(
    <>
      <ScheduleTask
        Schedules={schedules}
        title="Today's Tasks"
        isComplete={true}
        onComplete = {handleComplete}
      />
      {(props.role && props.role === "admin" &&
        <ScheduleTodo
          Schedules={schedules}
          title="Todays's To Do's" 
          isComplete={true}
          onComplete={handleComplete}
        />
      )}
    </>
  )
}

export default Schedules;
