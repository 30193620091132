import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import {Dialog,DialogContent,Grid,Typography,Button} from '@material-ui/core';
import {
  SimpleForm,
  DateInput,
  required,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  useDataProvider,
  NumberInput,
  usePermissions,
  AutocompleteArrayInput,
  TextInput,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-input';
import TabField from './TabFields';
import ContractorTab from './ContractorTab';
import { useForm } from 'react-final-form';
import TaskDeleteConfirmation from './TaskDeleteConfirmation';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  title: {
    margin: '8px 8px 8px 0px',
    fontSize: 14,
  },
  colorInput: {
    width: '100%',
    marginTop: 4,
    // padding: 5
  },
  deleteButton: {
    width: 'auto',
    float: 'right',
    margin: 10,
    border: '1px solid red',
    color: 'red',
    '&:hover': {
      background: '#ff3939',
      color: '#fff',
    }
  },
});

export default function EventEditDialog(props) {
  const classes = useStyles();
  const { event, onClose, open,editProjectTask,role,projectAssigned,deleteTask } = props;
  const isEventAssigned = (event.extendedProps)? event.extendedProps.isAssigned:event.isAssigned;

  const [openDelete, setOpenDelete] = React.useState(false);

  const { permissions } = usePermissions();

  const openDeleteDialog = (project) => {
    setOpenDelete(true);
  }

  const closeDeleteDialog = () => {
    setOpenDelete(false);
  };

  const handleClose = () => {
    onClose();
  };

  const saveData = (data) => {
    if(event.isGhant) {
      editProjectTask(event.id,data,true);
    } else {
      editProjectTask(event.id,data);
    }
    onClose();
  }

  
  let taskInitialValue = (event) ? (
    { 
      id: event.id,
      title: event.title,
      start_date: event.start,
      end_date: (event.extendedProps)? event.extendedProps.actual_end_date:event.actual_end_date,
      assignees:(event.extendedProps)? event.extendedProps.assignees:event.assignees,
      contractors:(event.extendedProps)? event.extendedProps.contractors:event.contractors,
      color:(event.extendedProps) ? event.extendedProps.colorField:event.colorField,
      complete:(event.extendedProps)? event.extendedProps.complete:event.complete,
      predecessor:(event.extendedProps) ? event.extendedProps.predecessor:event.predecessor,
      all_notes:(event.extendedProps)? event.extendedProps.all_notes:event.all_notes,
      internal_notes:(event.extendedProps)? event.extendedProps.internal_notes:event.internal_notes,
      sub_notes:(event.extendedProps)? event.extendedProps.sub_notes:event.sub_notes,
      owner_notes:(event.extendedProps)? event.extendedProps.owner_notes:event.owner_notes,
      // duration:(event.extendedProps)? event.extendedProps.calender_duration:event.calender_duration,
      duration:(event.extendedProps)? event.extendedProps.work_days:event.work_days,
      files: [],
      completedBy: (event.extendedProps)? event.extendedProps.completedBy:event.completedBy,
      //files: (event.extendedProps)? event.extendedProps.files:event.files,
      uploads: (event.extendedProps)? event.extendedProps.uploadedFiles:event.uploadedFiles,
      is_notify: true,
    }
  ) : null;

  if((role !== 'admin') && (!projectAssigned && !isEventAssigned)) return null;

  return (
    <Dialog
      onClose={handleClose}
      fullWidth={true}
      open={open} 
      // classes={{ paper: classes.dialogPaper }} 
      maxWidth = {'md'}
    >
      {(event) && (
        <>
          <DialogContent>
            <SimpleForm save={saveData} initialValues={taskInitialValue}>
              {permissions && permissions.role === 'admin' && (
                <Button
                  variant="outlined" className={classes.deleteButton}
                  onClick={openDeleteDialog}>
                  Remove task
                </Button>
              )}
              <BooleanInput label="Send Notification" source="is_notify" />
              <CompleteField event={event} classes={classes} />
              <SpanField event={event} />
              <DurationColorField event={event} classes={classes}/>
              {(permissions && (permissions.role === 'admin' || permissions.role === 'manager') && (
                <>
                  <AssigneesField role={permissions.role} classes={classes}/>
                  <TabField data={event} />
                </>             
              ))}
             {/* {(permissions && permissions.role === 'contractor' && (
                <ContractorTab data={event} />           
              ))} */}
            </SimpleForm>
           
          </DialogContent>
          <TaskDeleteConfirmation
            open={openDelete}
            close={closeDeleteDialog}
            task={taskInitialValue}
            deleteTask={deleteTask}
          />
        </>
      )}  
    </Dialog>
  );
}

const SpanField = ({event}) => {
  const form = useForm();
  const dataProvider = useDataProvider();

  const updateValuesForStart = (value) => {
    let startDate = form.getFieldState('start_date').value;
    let endDate = form.getFieldState('end_date').value;
    let predecessorData = [];
    if(form.getFieldState('predecessor')) {
      predecessorData = form.getFieldState('predecessor').value ;
    }
    let duration = form.getFieldState('duration').value;
    if(duration > 0) {
      let proejctId = (event.extendedProps) ? event.extendedProps.project: event.project;
      let data = {data: {predecessor: predecessorData,taskId:event.id,project:proejctId,endDate:endDate,startDate:startDate,duration:duration}}
      dataProvider.setLagFromStartDate(data).then(({data}) => {
        if(predecessorData.length > 0 && form.getFieldState('predecessor')) {
          form.change('predecessor', data.predecessors);
        }
        form.change('end_date', data.endDate);
        //form.change('duration', data.duration);
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

   const updateValuesForEnd = (value) => {
    let endDate = form.getFieldState('end_date').value;
    let startDate = form.getFieldState('start_date').value;
    if(endDate && endDate!==null) {
      let data = {data: {startDate:startDate,endDate:endDate}}
      dataProvider.setDurationFromEndDate(data).then(({data}) => {
        form.change('duration', data.duration);
      })
      .catch(error => {
        console.log(error);
      });

    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <DateInput
          source="start_date"
          label="Start Date"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          onChange={updateValuesForStart}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          source="end_date"
          label="End Date"
          //disabled
          fullWidth={true}
          variant="outlined"
          validate={required()}
          onChange={updateValuesForEnd}
        />
      </Grid>
    </Grid>
  );
};

const AssigneesField = ({classes,role}) => {
  return (
    <Grid container spacing={1}>
       {(role === 'admin') && (
        <Grid item xs={12} sm={6}>
          <SelectArrayInput
            label="Task Completes By"
            source="completedBy"
            fullWidth={true}
            variant="outlined"
            choices={[
              { id: 'contractor', name: 'Contractor' },
              { id: 'client', name: 'Client' },
              { id: 'manager', name: 'Manager' },
              // { id: 'admin', name: 'Admin' },
            ]}
          />
        </Grid>
      )}
      {(role === 'admin') && (
        <Grid item xs={12} sm={6}>
          <ReferenceArrayInput
            reference="managers"
            source="assignees"
            label="Assignees"
            fullWidth={true}
            variant="outlined"
            resource="managers"
            filter={{all:true}}
          >
            <SelectArrayInput 
              optionText="fullname" optionValue="id" options={{ fullWidth: true }}
            >
              <ChipField />
            </SelectArrayInput>
          </ReferenceArrayInput>
        </Grid>
      )}
      <Grid item xs={role === 'admin' ? 6 : 12} sm={role === 'admin' ? 6 : 12}>
        <ReferenceArrayInput
          reference="contractors"
          source="contractors"
          label="Sub Contractors"
          fullWidth={true}
          variant="outlined"
          resource="contractors"
          filter={{all:true,status: 1}}
        >
          <AutocompleteArrayInput
            optionText="fullname"
            optionValue="id"
            options={{
              suggestionsContainerProps: {
                disablePortal: true,
              }
            }}
          />
          {/* <SelectArrayInput optionText="fullname" optionValue="id" options={{ fullWidth: true }}>
            <ChipField />
          </SelectArrayInput> */}
        </ReferenceArrayInput>
      </Grid>
    </Grid>
  )
}

const DurationColorField = ({classes}) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const updateValuesForDuration = (value) => {
    let duration = form.getFieldState('duration').value;
    let startDate = form.getFieldState('start_date').value;
    if(duration > 0) {
      let data = {data: {duration:duration,startDate:startDate}}
      dataProvider.setEndDateFromDuration(data).then(({data}) => {
        form.change('end_date', data.endDate);
        // form.change('duration', data.duration);
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="duration"
          validate={required()}
          variant="outlined"
          label="Work Days"
          fullWidth={true}
          onChange={updateValuesForDuration}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ColorInput
          source="color"
          fullWidth={true}
          variant="outlined"
          // picker="Twitter"
          label="Display Color"
          className={classes.colorInput}
        />    
      </Grid>
    </Grid>
  )
}

const CompleteField = ({event,classes})=> {
  return (
    <Grid container direction="row" justify="space-between">
      {/* <Typography variant="h6" gutterBottom className={classes.title}>
        {event.title}
      </Typography> */}
      <TextInput
        label="Title"
        source="title"
        fullWidth={true}
        variant="outlined"
        validate={required()}
      />
      <BooleanInput
        label="Completed"
        source="complete"
      />
    </Grid>
  )
}

EventEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
