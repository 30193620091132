import React,{ useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// import listPlugin from '@fullcalendar/list';
import { Tooltip,Typography,TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export default function FullCalender(props){
  const calendarComponentRef = useRef(null);
  const {isEdit } = props;
  let events = props.data
  events = events.concat(
  {
    daysOfWeek: [0],
    rendering:"background",
    color: "#ef2516",
    overLap: true,
    allDay: true,
    title: 'Weekend',
    }
  )
  const setDate = (date) => {
    let calendarApi = calendarComponentRef.current.getApi();
    calendarApi.gotoDate(date); 
  }
  const handleEventChange = (changeInfo) => {
    //updateTask(changeInfo.event.toPlainObject(),0);
  }

  const handleEventClick = (info)=> {
    if(info.event.toPlainObject().id) {
      //dialogOpen(info.event.toPlainObject());
    }
  }

  const renderEventContent = (arg) => {
    return (
      <Eventtooltip arg={arg} />
    );
  }

  return (
    <div className="full-calender-container">
      <DatePicker setDate={setDate} />
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek'
        }}
        contentHeight={'auto'}
        editable={isEdit}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        events={events}
        eventChange={handleEventChange}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        ref={calendarComponentRef}
      />
    </div>
  )
}

function Eventtooltip( {arg} ) {
  const classes = useStyles(arg);
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
      title={
        <>
          <Typography color="inherit" variant="body1">{arg.event.title}</Typography>
          <div style={{display:'block'}}>
            {(arg.event.extendedProps.project) && (
              <p>{`${arg.event.extendedProps.project}`}</p>
            )}
            {(arg.event.extendedProps.startDate) && (
              <p>{`Start Date: ${arg.event.extendedProps.startDate}`}</p>
            )}
            {(arg.event.extendedProps.endDate) && (
              <p>{`End Date: ${arg.event.extendedProps.endDate}`}</p>
            )}
            {(arg.event.extendedProps.calender_duration) && (
              <p>{`Duration: ${arg.event.extendedProps.calender_duration}`}</p>
            )}
            {(arg.event.extendedProps.contractors && arg.event.extendedProps.contractors.length > 0) && (
              <ContractorsList contractors={arg.event.extendedProps.contractors} />
            )}
          </div>
        </>
      }
      arrow
    >
      {renderInnerContent( arg )}
    </Tooltip>
  )
}

const DatePicker = ({setDate}) => {
  const classes = useStyles();
  const onChangeDate = (e) => {
    setDate(e.target.value)
  } 
  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        label="Choose Date"
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChangeDate}
      />
    </form>
  )
}

const ContractorsList = (props) => {
  return (
    <>
      {props.contractors.map(contractor => (<p>{`Contractor: ${contractor.name}`}</p>))}
    </>
    
  )
}

function renderInnerContent( innerProps ) {
  return (
    <div className='fc-event-main-frame'>
      { innerProps.timeText &&
        <div className='fc-event-time'>{ innerProps.timeText }</div>
      }
      <div className='fc-event-title-container'>
        <div className='fc-event-title fc-sticky'>
          {innerProps.event.extendedProps && innerProps.event.extendedProps.project && (
            <p style={{fontSize: 10, fontWeight: 'bold'}}>{ innerProps.event.extendedProps.project || <>&nbsp;</> }</p>
          )}
          {innerProps.event.extendedProps && innerProps.event.extendedProps.projectId ? (
            <p>
              <Link style={{color: 'inherit', textDecoration: 'none'}}to={`/project-schedule-chart/${innerProps.event.extendedProps.projectId}?taskId=${innerProps.event.id}`}>
                { innerProps.event.title || <>&nbsp;</> }
              </Link>
            </p>
          ) : (
            <p>{ innerProps.event.title || <>&nbsp;</> }</p>
          )}
        </div>
       
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    borderColor: props => props.backgroundColor,
    borderWidth: 1,
    borderStyle:'solid',
  },
  arrow: {
    "&::before": {
      borderColor: props => props.backgroundColor,
      borderWidth: 1,
      borderStyle:'solid',
      backgroundColor: "#fff",
      boxSizing: "border-box"
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

