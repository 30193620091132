import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Button,List,ListItem} from '@material-ui/core';
import {
  useListContext,
} from 'react-admin';
import { NavLink as RouterLink } from 'react-router-dom';

const CompanyList = ({ width }) => {
  const { ids, data } = useListContext();
  const classes = useStyles();

  if (!ids || !data) return null;

  return (
    <>
      <List>
        {ids.map((id) => (
          data[id] && data[id]._referal && (
            <ListItem
              className={classes.item}
              disableGutters
              onClick={() => {
              
              }}
            >
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={RouterLink}
                to={`/referals/${id}`}
              >
      
                <Box className={classes.content}>
                  <Box className={classes.title}>
                    {data[id]._referal.company}
                  </Box>
                  <Box className={classes.text}>
                    {`Email: ${data[id].email}`}
                  </Box>
                  <Box className={classes.text}>
                    {`Contact: ${data[id]._referal.contact}`}
                  </Box>
                  {data[id]._referal.address && (
                    <Box className={classes.text}>
                    {`Address: ${data[id]._referal.address.street || ''}
                      ${data[id]._referal.address.suburb || ''} ${data[id]._referal.address.state || ''}
                      ${data[id]._referal.address.zip || ''}
                    `}
                  </Box>
                  )}
                  
                  {/* <Box className={classes.text}>
                    {content.substr(0, 26)}
                    {content.length > 26 ? '...' : ''}
                  </Box> */}
    
                </Box>
              </Button>
            </ListItem>
          )
        ))}
      </List>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    // color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    textAlign: 'left',
    color: '#000',
  },
  title: {
    fontWeight:'bold',
    textTransform: 'uppercase',
  }
}));

export default CompanyList;
