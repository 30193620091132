import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  TextInput,
  RadioButtonGroupInput,
  useDataProvider
}
  from 'react-admin';
import { InputAdornment, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#e58f0a',
      textTransform: 'uppercase',
    }
  },
}));

function MaterialCreate(props) {

  const { save, projectId, unit } = props;
  const classes = useStyles();
  const [rows, setRows] = useState();
  const [initialval, setDefault] = useState();
  const [loading, setLoading] = useState(true);
  // const [maincategory, setMaincategory] = useState(true);
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider.getList('additional-details', { filter: { project: projectId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      // console.log(data)
      setDefault(data[0])
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  }, [])
  const onSave = (data) => {
    data['project'] = projectId
    dataProvider.createCategory(data, 'additional-details').then(({ data }) => {
      // console.log(data)
      setDefault(data[0])
      setLoading(false);
    }).catch(error => {

    });
    // tender-user
  };

  return (
    <SimpleForm save={onSave}>
      <SectionHeading label="Tender Basic Details" classProp={classes.heading} />
      <GridField initialval={initialval} />
    </SimpleForm>
  )
}

const GridField = (props) => {
  // console.log(props)
  const { initialval } = props;
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="electricity_supplier"
          label="Electricity Supplier"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.electricity_supplier}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          allowEmpty={true}
          variant="outlined"
          label="Electricity Supplier Account Number"
          source="electricity_supplier_account_number"
          fullWidth={true}
          defaultValue={initialval?.electricity_supplier_account_number}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          allowEmpty={true}
          variant="outlined"
          label="Communication Supplier"
          source="communication_supplier"
          fullWidth={true}
          defaultValue={initialval?.communication_supplier}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="dp_number"
          label="DP Number"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.dp_number}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="solicitor"
          label="Solicitor"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.solicitor}

        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          source="solicitors_address"
          label="Solicitors Address "
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.solicitors_address}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="solicitors_phone_number"
          label="Solicitors Phone Number"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.solicitors_phone_number}

        />
      </Grid> 
      <Grid item xs={12} sm={4}>
        <TextInput
          source="finance_source"
          label="Finance Source"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.finance_source}

        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          source="finance_source_address"
          label="Finance Source Address"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.finance_source_address}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="finance_source_contact"
          label="Finance Source Contact "
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.finance_source_contact}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="finance_source_phone"
          label="Finance Source Phone"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.finance_source_phone}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="finance_type"
          label="Finance Type"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.finance_type}

        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="copy_of_title_deeds"
          label="copy of Title Deeds"
          fullWidth={true}
          variant="outlined"
          defaultValue={initialval?.copy_of_title_deeds}

        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          source="account_name"
          label="Account Name"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.account_name}

        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="account_number"
          label="Account Number"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.account_number}

        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="BSB"
          label="BSB"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.BSB}

        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="acceptedby"
          label="Accepted by"
          fullWidth={true}
          variant="outlined"
          validate={required()}
          defaultValue={initialval?.acceptedby}

        />
      </Grid>
    </Grid>
  );
}


const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

export default MaterialCreate;
