import * as React from 'react';
import { useState } from 'react';

import { Field, withTypes } from 'react-final-form';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import {Notification, useNotify,useRedirect, useAuthProvider } from 'react-admin';

import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from '@material-ui/icons/Person';

import { lightTheme } from '../layout/themes';

import Logo from '../layout/Logo';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(2376627.jpg)',
    // background: 'rgb(186, 149, 56)',
    // background: '#27424b',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    backgroundColor: '#fff',
  },
  card: {
    minWidth: 310,
    marginTop: '6em',
    borderRadius: 15,
    // background: '#20353c',
    background: '#282a3c',
    border: '1px solid #ebbd17',
    // background: 'rgb(157 116 14)',
  },
  avatar: {
    marginTop: '2em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    root: {
      background: 'red',
    }
    // backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    marginBottom: '1em',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    color:'#dadbe6',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  button: {
    margin: '20px 0',
    background: '#ffbe2e',
    borderRadius: 0,
    '&:hover':{
      background: '#ebbd17',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 0',
  },
  multilineColor:{
    color:'#dadbe6',
  }
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
  }) => (

  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const { Form } = withTypes();

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const authProvider = useAuthProvider();
  
  const handleSubmit = (auth) => {
    setLoading(true);
    authProvider.forgotPassword(auth).then((response) => {
      notify('Please use the password reset mail', 'info');
      setLoading(false);
      redirect('/login');
    })
    .catch(err => {
      notify(err.message, 'warning');
      setLoading(false);
    })
  };

  const validate = (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "required";
      }
      return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Logo />
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="email"
                    type="email"
                    component={renderInput}
                    label={"Your Account Email Id"}
                    disabled={loading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <PersonIcon className={classes.icon}/>
                        </InputAdornment>
                      ),
                      className: classes.multilineColor
                    }}
                  />
                </div>
              </div>

              
              {(loading) ? (
                <div className={classes.loader} >
                  <CircularProgress
                    size={25}
                    thickness={5}
                  />
                </div>
              ) : (
                <CardActions className={classes.actions}>
                
                  <Button
                    className={classes.button}
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    Submit
                  </Button>
                </CardActions>
              )}
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

const ForgotPasswordWithTheme = (props) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <ForgotPassword {...props} />
  </ThemeProvider>
);

export default ForgotPasswordWithTheme;
