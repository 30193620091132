import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
const useStyles = makeStyles((theme) => ({

	root: {
	  display: 'flex',
	},
	Upperbox:{
		background :'#ff000040',
		padding:50,
		textAlign:'center',
		margin: 10,
	},
	msg:{
		verticalAlign: 'middle',
	},
	msgHead:{
		color:'#fff',
		fontSize:32,
	},
	msgSub:{
		color:'#fff',
		fontSize:16,
	},
	outlineicon:{
		color: '#fff',
	    fontSize:150,
	    marginTop:50,
	},

}));

export default function Emptyschedule(props) {
	const classes = useStyles();
	  return (
			<div className={classes.Upperbox}>
				<ErrorOutlineIcon  className={classes.outlineicon}/>
				<div className={classes.msgHead}>{props.message}</div>
				{/* <div className={classes.msgSub}>has already been marked confirmed!</div> */}
			</div>
	  )
}
