import React from 'react';
import { 
  usePermissions,
  SimpleForm,
  TextInput,
  DateInput,
  useAuthProvider,
  required,
  ArrayInput,
  SimpleFormIterator,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProjectField from './ProjectField';

const RequestForm = props => {
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const requestMaterial = (request) => {
    request['contractor'] = user.id;
    dataProvider.create('requestmaterial', { data: request }).then(({ data }) => {
      console.log(data);
      redirect('/');
      notify('Your Material Request Has Been Notified');
    })
    .catch(error => {
      console.log(error);
      notify(`Request Creating error: ${error.message}`, 'warning');
    })
  }

  if(!permissions || permissions.role !== 'contractor' || !user) {return null};

  return (
    <SimpleForm variant="outlined" save={requestMaterial}>
      <SectionHeading label="Request Material" />
      <GridField {...props} source="project" role={permissions} userId={user.id} fullWidth={true} />
      <ArrayInput source="materials_list" validate={required()}>
        <SimpleFormIterator>
          <TextInput
            source="material"
            label="Material"
            validate={required()}
            helperText="Name of the material you needed"
          />
          <TextInput
            source="quantity"
            label="Quantity"
            helperText="Quantity should be in nos, tonne, etc."
            validate={required()}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput
        source="notes"
        label="Notes"
        multiline
        rows={4}
        fullWidth={true}
        validate={required()}
      />
    </SimpleForm>
  )
};

const GridField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <ProjectField
          {...props}
          source="project"
          validate={required()}
          role={props.role}
          userId={props.userId}
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          {...props}
          source="required_date"
          fullWidth={true}
          validate={required()}
        />
      </Grid>
    </Grid>
  );
};
const SectionHeading = ({ label }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center">
      <Typography gutterBottom className={classes.heading}>
        {label}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles((theme) =>({
  heading: {
    fontSize: 14,
    margin: '10px 0',
    color: '#a38119',
    fontWeight: 'bold',
  },
}));

export default RequestForm;
