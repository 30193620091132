import ApiConfig from './config/apiconfig';
import jwt_decode from "jwt-decode";
import { stringify } from 'query-string';

const apiUrl = ApiConfig.REST_API_AUTH_URL;
const apiDataUrl = ApiConfig.REST_API_URL;

export default {
  login: ({ email, password }) =>  {
    const request = new Request(`${apiUrl}/login`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    localStorage.removeItem('nexa_pm_user_token');
    localStorage.removeItem('nexa_pm_user_permissions');
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(userInfo => {
        localStorage.setItem('nexa_pm_user_token', userInfo.token);
        localStorage.setItem('nexa_pm_user_permissions', JSON.stringify(userInfo.permissions));
        window.location.href = "/";
        Promise.resolve();
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    removePlayerId({playerId: localStorage.getItem('nexa_pm_user_player_id'),token: localStorage.getItem('nexa_pm_user_token')})
    localStorage.removeItem('nexa_pm_user_token');
    localStorage.removeItem('nexa_pm_user_permissions');
    localStorage.removeItem('nexa_pm_user_player_id');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      removePlayerId({playerId: localStorage.getItem('nexa_pm_user_player_id'),token: localStorage.getItem('nexa_pm_user_token')})
      localStorage.removeItem('nexa_pm_user_token');
      localStorage.removeItem('nexa_pm_user_permissions');
      localStorage.removeItem('nexa_pm_user_player_id');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('nexa_pm_user_token')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    //window.location.pathname
    if(isPublicUrl(window.location.hash)){
      return Promise.resolve();
    }
    if(localStorage.getItem('nexa_pm_user_permissions')) {
      let permissionDetails = JSON.parse(localStorage.getItem('nexa_pm_user_permissions'));
      //console.log(permissionDetails);
      return Promise.resolve(permissionDetails);
    } else {
      return Promise.reject();
    }
  },

  getIdentity: () => {
    if(localStorage.getItem('nexa_pm_user_token')) {
      var token = localStorage.getItem('nexa_pm_user_token');
      const { id, username, email, avatar } = jwt_decode(token);
      return { id, username, email, avatar };
    } else {
      return null;
    }
  },
  forgotPassword: ({ email }) => {
    const request = new Request(`${apiUrl}/forgot-password`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
  },
  getResetPasssword:(params) => {
    const query = {
      info: JSON.stringify(params),
    };
    const request = new Request(`${apiUrl}/reset-password?${stringify(query)}`);
    return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
  },
  postResetPasssword: (params) => {
    const request = new Request(`${apiUrl}/reset-password`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
  },
};

function removePlayerId(info) {
  if(!info || !info.token || !info.playerId) {
    return ;
  }
  const request = new Request(`${apiDataUrl}/remove-playerid`, {
    method: 'POST',
    body: JSON.stringify({ playerId:  info.playerId}),
    headers: new Headers({ 'Content-Type': 'application/json' ,'Authorization': `Bearer ${info.token}`}),
  });
  return fetch(request)
  .then(response => {
    if (response.status < 200 || response.status >= 300) {
      console.log("Error while removing playerid");
    }
    return response.json();
  })
}

function isPublicUrl(url) {
  //let publicUrls = ['activate-contractor','register-project','forgot-password','reset-password']
  let urlString = url;
  if(url && url !== '' && url.indexOf('/') >= 0) {
    urlString = urlString.slice(1);
    //return publicUrls.includes('urlString')
    return urlString.includes('activate-contractor') || urlString.includes('forgot-password') || urlString.includes('reset-password')
    || urlString.includes('register-project');
   
  } else {
    return false;
  }
}
