import * as React from "react";
import { List, EmailField,Datagrid, TextField,EditButton,CreateButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import { Grid,Typography } from '@material-ui/core';
import FullNameField from './FullNameField';

const useStyles = makeStyles({
  heading: {
    padding: '20px 16px',
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  createButton: {
    margin: 30,
  },
});

const SectionAction = ({ label, classes,basePath }) => {
  return (
    <>
      <Grid container direction="row" alignItems="center" className={classes.heading}>
        <ListIcon />
        <Typography variant="h6" gutterBottom >
          {label}
        </Typography>
      </Grid>
      <CreateButton basePath={basePath} className={classes.createButton} />
    </>
  );
};


// const FullNameField = ({ record = {} }) => {
//   let name = (record.fullName)? `${record.fullName}`: 
//   `${record.first_name} ${record.last_name}`;
//   return (
//     <span>{name}</span>
//   )
// }
// FullNameField.defaultProps = { label: 'Name' };

const AddressField = ({ record = {} }) => {
  let address = (record.state)? (
   `${record.state} ${record.street} ${record.suburb} ${record.zip}`
  ) : '';
  return (
    <span>{address}</span>
  )
}
AddressField.defaultProps = { label: 'Address' };

const UserList = props => {
  const classes = useStyles();
  return(
  <List {...props}
    exporter={false}
    actions={<SectionAction {...props} label="Managers" classes={classes} />} 
  >
      <Datagrid>
        {/* <FullNameField source="first_name" /> */}
        <FullNameField {...props} />
        <TextField source="username" />
        <EmailField source="email" />
        {/* <AddressField source="address" /> */}
        <TextField source="dateAdded" />
        <EditButton source="Action"/>
      </Datagrid>
    </List>
  );
}

export default UserList;

