import React from 'react';
import { Actions, Send } from 'react-web-gifted-chat';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';

export const RenderActions = (props) => (
  <Actions
    {...props}
    icon={() => (
      <IconButton aria-label="add attachment" onClick={() => props.openFileUpload()}>
        <AttachFileIcon />
      </IconButton>
    )}
  />
);

export const renderSend = (props) => {
  return (
    <Send
      {...props}
      disabled={!props.text || props.text === ""}
    >
      <IconButton color="primary" disabled={!props.text || props.text === ""} aria-label="send message">
        <SendIcon />
      </IconButton>
    </Send>
  )
}
