import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
  Grid,Typography,
} from '@material-ui/core';

function MaterialList(props) {
  const { data, onEditClick,onDeleteClick } = props;
  const classes = useStyles();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <SectionHeading label="Material List" classProp={classes.heading}/>
      <Table className={classes.table} size="small" aria-label="material-table">
        <TableHead>
          <TableRow>
            <TableCell>Material</TableCell>
            <TableCell>Supplier</TableCell>
            <TableCell>Purchase Date</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((projectMaterial) => (
            <TableRow key={projectMaterial.id}>
              <TableCell component="th" scope="row">
                {projectMaterial.material.name}
              </TableCell>
              <TableCell>{projectMaterial.supplier.fullname}</TableCell>
              <TableCell>{projectMaterial.purchaseDate}</TableCell>
              <TableCell>{projectMaterial.quantity}</TableCell>
              <TableCell>{projectMaterial.cost}</TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => onEditClick(projectMaterial)}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  className={classes.deleteButton}
                  onClick={() => onDeleteClick(projectMaterial.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <ListIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles({
  tableContainer: {
    marginTop:15,
    paddingBottom: 15,
    boxShadow: 'none',
    border: '1px solid #ebebeb',
    width: 'auto',
    marginLeft: 10,
  },
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color:'#e58f0a',
      textTransform: 'uppercase',
    }
  },
  table: {
    minWidth: 650,
  },
  deleteButton: {
    border: '1px solid #ff0000',color: '#ff0000',marginLeft:15,
  }
});


export default MaterialList;
