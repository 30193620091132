import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

export default function TaskDeleteConfirmation({open,close,task,deleteTask}) {

  if(!task) return null;

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Are you sure want to delete task ${task.title}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Removing this task may cause other task to re arrange their dates. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            No
          </Button>
          <Button  onClick={() => {
              deleteTask(task.id);
              close()
            }}
            color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
