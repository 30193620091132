/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import  { io }  from 'socket.io-client';
import RoomList from './RoomList';
import ChatRoom from './ChatRoom';
import { setSidebarVisibility, useDataProvider, useAuthProvider} from 'react-admin';

function Chat(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [chatInfo, setChatinfo] = useState({selectedChat: null, chatList: null});
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const socketIO = useRef(null);

  const setSelectedChat = (room) => {
    socketIO.current.removeListener('new-message-added');
    setChatinfo((prevInfo) => {
      if(prevInfo.selectedChat && prevInfo.selectedChat !== room) {
        socketIO.current.emit('user-went-offline-room', {user: user.id, room : prevInfo.selectedChat});
      }
      let updatedInfo = prevInfo.chatList.map(chatRoom => {
        if(chatRoom.id === room) {
          chatRoom['unRead'] = -1;
        }
        return chatRoom;
      })
      return {...prevInfo,selectedChat: room,chatList: updatedInfo}
    });
  };

  const getClientRooms = useCallback(() => {
     dataProvider.getChatRooms({filter: {}})
      .then(({data}) => {
        // console.log(data)
        setChatinfo((prevInfo) => { return {...prevInfo,chatList: data}});
      })
      .catch(error => {
        
      })
  }, []);

  const addUnreadCount = useCallback((chatData) => {
    setChatinfo((prevInfo) => {
      let updatedInfo = prevInfo.chatList.map(room => {
        if(room.id === chatData.room) {
          if(room.unRead && room.unRead > 0) {
            room.unRead = room.unRead + chatData.count;
          } else {
            room['unRead'] = chatData.count;
          }
        }
        return room;
      })
      updatedInfo = updatedInfo.sort(function (a, b) {
        return b.unRead - a.unRead;
      });
      return {...prevInfo,chatList: updatedInfo}
    });
  }, [])

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    getClientRooms();
    return () => {
      dispatch(setSidebarVisibility(true));
    };
  },[]);

  useEffect(() => {
    socketIO.current = io('https://www.nexaplan.com.au:3000/client_chat', {
      forceNew: true,
      transports: ['websocket'],
      jsonp: false,
    });
    if (socketIO.current) {
      socketIO.current.emit('set-chat-user', {id: user.id, name : user.username});
    }
    socketIO.current.on('new-message-on-room', (data) => {
      addUnreadCount(data);
    });
    
    return () => {
      if (socketIO.current){socketIO.current.disconnect(); }
    };
  },[]);


  return (
    <div className={classes.container}>
      <div className={classes.channelList}>
        <RoomList
          setSelectedRoom={setSelectedChat}
          selectedRoom={chatInfo.selectedChat}
          list={chatInfo.chatList}
          socket={socketIO.current}
        />
      </div>
      <div className={classes.chat}>
        <ChatRoom room={chatInfo.selectedChat} socket={socketIO.current} />
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    height: "calc(100% - 66px)",
    // marginTop: 10,
  },
  channelList: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    // maxHeight: '100vh',
    overflowY: 'scroll',
    height: 'calc(100vh - 60px)',
    //paddingBottom: 40,
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
     // outline: '1px solid slategrey'
     borderRadius: 10,
    }
  },
  chat: {
    marginTop: 10,
    display: "flex",
    flex: 3,
    flexDirection: "column",
    borderWidth: "1px",
    borderColor: "#ccc",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    width: '100%',
    height: 'calc(100vh - 70px)',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
     // outline: '1px solid slategrey'
     borderRadius: 10,
    }
  },
});

export default Chat;
