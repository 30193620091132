import React, { useState, useEffect } from 'react';
import {
  SimpleForm,
  SelectInput,
  TextInput,
  required,
  DateInput,
  ReferenceInput,
} from 'react-admin';
import {Button, TextField,Dialog} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>({
  main: {
    width: '100%',
  },
  form: {
    // margin: 20,
    display: 'inline-grid',
  },
  inputField: {
    // marginTop: 15,
  },
}));

export default function AlertDialog({open,close,project,cloneProject}) {
  const classes = useStyles();
  const[cloneInfo, setCloneInfo] = useState();

  let typeChoices = [
    { id: 'pre_contract', name: 'Pre Contract' },
    { id: 'design_approval', name: 'Design Approval' },
    { id: 'construction', name: 'Construction' },
  ];

  typeChoices = typeChoices.filter(type => type.id !== project.type);

  let initialValues = {
    id: project.id,
    title: project.title,
    description: project.description,
    type: typeChoices[0].id,
    template: project.template,
  }

  useEffect(() => {
    setCloneInfo({title: project.title, description: project.description})
  },[project]);

  

  if(!cloneInfo || !project) return null;

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Are you sure want to create a clone of ${project.title}`} ?</DialogTitle>
        <div className={classes.form}>
          <SimpleForm save={cloneProject} initialValues={initialValues}>
            <TextInput
              source="title"
              className={classes.inputField}
              size="small"
              label="Title"
              fullWidth={true}
              variant="outlined"
              validate={required()}
            />
            <TextInput
              source="description"
              className={classes.inputField}
              size="small"
              label="Description"
              fullWidth={true}
              variant="outlined"
              validate={required()}
            />
            <DateInput
              source="start_date"
              label="Start Date"
              fullWidth={true}
              variant="outlined"
              validate={required()}
            />
          {/* <TextField
            className={classes.inputField}
            size="small"
            id="outlined-basic"
            label="Title"
            variant="outlined"
            value={cloneInfo.title}
            onChange={(event) => setCloneInfo({...cloneInfo,title: event.target.value})}
          />
          <TextField
            className={classes.inputField}
            size="small"
            id="outlined-basicd"
            label="Description"
            multiline
            variant="outlined"
            value={cloneInfo.description}
            onChange={(event) => setCloneInfo({...cloneInfo,description: event.target.value})}
          /> */}
            <SelectInput
              source="type"
              label="Project Type"
              choices={typeChoices}
              defaultValue={typeChoices[0].id}
              variant="outlined"
              fullWidth={true}
            />
            <ReferenceInput
              source="template"
              reference="templates"
              label="Template"
              fullWidth={true}
              validate={required()}
              variant="outlined"
              filter={{all:true,status: 1}}
            >
              <SelectInput optionText="name" fullWidth={true} />
            </ReferenceInput>
          </SimpleForm>
        </div>
        {/* <DialogActions>
          <Button onClick={close} color="primary">
            No
          </Button>
          <Button onClick={() => cloneProject(cloneInfo)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
