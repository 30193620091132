import React, {useState,useEffect} from 'react';
import { Form, Field } from 'react-final-form';
import { useDataProvider,Loading,usePermissions} from 'react-admin';
import {Paper, Tabs, Tab,Button,Typography,Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import arrayMutators from "final-form-arrays";
import Header from './Header';
import General from './General';
import Permission from './Permission';
import AddPermission from './AddPermission';

const useStyles = makeStyles({
  main: {
    width: '100%',
    marginTop: 10,
    paddingLeft: 16,
  },
  paper: {
    background: '#f3f3f3',
    marginTop:10,
    boxShadow:'none',
  },
  tab: {
    width: 130,
    minWidth: 130,
    background: '#f3f3f3',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#e1b122',
    color: '#fff !important',
  },
  addPermissionWrapper: {
    padding: 10,
    '& h6': {
      fontSize: 13,
      textTransform: 'capitalize',
      marginRight: 20,
    }
  },
});

function SystemSettings() {
  const [settings, setSettings] = useState();
  const [drawer, openDrawer] = useState(false);
  const [permission, setPermission] = useState({list: [], manager: [], contractor: [], clent: []});
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const [Tabvalue, setTabValue] = useState(0);

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    openDrawer(false);
  };

  const openAddpermission = () => {
    openDrawer(true);
  }

  const savePermission = (permissionData) => {
    console.log(permissionData);
    openDrawer(false);
    setLoading(true);
    dataProvider.create('permissions', {data: permissionData})
    .then(({ data }) => {
      console.log(data);
      let permissionTitleList = data.permissions.map(_permission => {
        return _permission.title;
      });
      let managerPermissions = data.permissions.filter(_permission => {
        return _permission.role.includes('manager');
      });

      let clientPermissions = data.permissions.filter(_permission => {
        return _permission.role.includes('client');
      });

      let contractorPermissions = data.permissions.filter(_permission => {
        return _permission.role.includes('contractor');
      });
      console.log(managerPermissions.map(_permission => _permission.title));
      setPermission({
        list:permissionTitleList,
        manager: managerPermissions.map(_permission => _permission.title),
        contractor: contractorPermissions.map(_permission => _permission.title),
        clent:clientPermissions.map(_permission => _permission.title),
      })
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
    })
  }
  
  const classes = useStyles();

  const onSubmit = (data) => {
    console.log(data);
  }
  useEffect(() => {
    dataProvider.getSettings()
      .then(({ data }) => {
        let permissionTitleList = data.permissions.map(_permission => {
          return _permission.title;
        });
        let managerPermissions = data.permissions.filter(_permission => {
          return _permission.role.includes('manager');
        });

        let clientPermissions = data.permissions.filter(_permission => {
          return _permission.role.includes('client');
        });

        let contractorPermissions = data.permissions.filter(_permission => {
          return _permission.role.includes('contractor');
        });

        setPermission({
          list:permissionTitleList,
          manager: managerPermissions.map(_permission => _permission.title),
          contractor: contractorPermissions.map(_permission => _permission.title),
          clent:clientPermissions.map(_permission => _permission.title),
        })
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading/>;
  if (permission.list.length < 1) return null;


  return (
    <div className={classes.main} >
      <Form
        onSubmit={onSubmit}
        initialValues={permission}
        mutators={{
          ...arrayMutators
        }}
        //validate={validate}
        render={({ handleSubmit, reset, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Header label="Settings" submitting={submitting}/>
            <Paper square className={classes.paper}>
              <Tabs
                value={Tabvalue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}              
              >
                <Tab label="General" classes={{ root: classes.tab,selected: classes.selectedTab }}/>
                <Tab label="Permissions" classes={{ root: classes.tab,selected: classes.selectedTab }}/>
                <Tab label="Profile" classes={{ root: classes.tab,selected: classes.selectedTab }}/>
          
              </Tabs>
        
              
            {(Tabvalue === 0) ? (
             <General />
            ): (
              null
            )}

            {(Tabvalue === 1) ? (
              <div>
               <Grid container direction="row" alignItems="center" className={classes.addPermissionWrapper}>
                  <Typography variant="h6" className={classes.permissiontext} >
                    Permission list for different types of users
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={openAddpermission}
                  >
                    Add New Permission
                  </Button>
                </Grid>
                <Permission name={'manager'} label="Manager permissions" list={permission.list} />
                <Permission name={'contractor'} label="Contractor permissions"  list={permission.list} />
                <Permission name={'client'} label="Client permissions"  list={permission.list} />
              </div>
            ): (
              null
            )}

            {(Tabvalue === 2) ? (
              <div>owner settings should be find here</div>
            ): (
              null
            )}
            
          </Paper>
        </form>
        )}
      />
      {drawer && (
        <AddPermission open={drawer} toggleDrawer={toggleDrawer} savepermission={savePermission}/>
      )}

    </div>
  )
}

export default SystemSettings;
