import * as React from "react";

import {
  Edit, SimpleForm,TextInput,SelectInput,ArrayInput,SimpleFormIterator,
  useNotify, useRefresh, useRedirect,required
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { Grid,Typography } from '@material-ui/core';

const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const PopupEdit = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Status Change updated`);
    redirect('/client-invites');
    refresh();
  };

  return (
    <Edit onSuccess={onSuccess}  undoable={false} {...props}>
      <SimpleForm>
        <SectionHeading label="Change Details" classes={classes} />
        {/* <SelectInput
          source="status"
          label="Invite Status"
          choices={[
            { id: 0, name: 'Pending' },
            { id: 1, name: 'Confirmed' },
            { id: 2, name: 'Rewarded' },
            { id: 3, name: 'Failed' },
          ]}
          variant="outlined"
          fullWidth={true}
        />
        <TextInput fullWidth={true} source="change_text" label="Status change text" variant="outlined" /> */}
        <ArrayInput source="progress">
          <SimpleFormIterator>
            <SelectInput
              source="status"
              label="Status"
              choices={[
                { id: 0, name: 'Pending' },
                { id: 1, name: 'Confirmed' },
                { id: 2, name: 'Rewarded' },
                { id: 3, name: 'Failed' },
              ]}
              variant="outlined"
              fullWidth={true}
            />
            <TextInput
              source="text"
              label="Status Text"
              fullWidth={true}
              variant="outlined"
              multiline={true}
              validate={required()}
              no
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
)};

export default PopupEdit;