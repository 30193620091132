import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  // Create,
  TextInput,
  SimpleForm,
  required,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  FileInput,
  SelectInput,
  PasswordInput,
 // useNotify,
} from 'react-admin';

import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  Button,
  InputAdornment
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useForm } from 'react-final-form';
var generator = require('generate-password');

const UpdateProject = props => {
  const [Tabvalue, setTabValue] = React.useState(0);
  const { project, open, close, updateProject} = props;
  const classes = useStyles();
  //const notify = useNotify();

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const editproject = (data) => {
    //notify(`Comment approval error: `, 'warning');
    updateProject(data);
  }

  if(!project) return null;

  let initialValues = {
    id: project.id,
    title: project.title,
    description:(project.description) ? project.description : '',
    project_address_id: project.addressId,
    street:project.street,
    suburb:project.suburb,
    zip:project.zip,
    area:project.area,
    amount:project.amount,
    facade:{url:project.facade,title: project.client.name},
    image:{url:project.client.avatar,title: ''},
    managers: project.managers,
    client_id:project.client._id,
    client_nameId: project.client.nameId,
    client_Info_Id: project.client.client_Info_Id,
    first_name: project.client.first_name,
    last_name: project.client.last_name,
    email: project.client.email,
    contact: project.client.contact,
    client_address_id: project.client.addressId,
    client_street: project.client.street,
    client_suburb: project.client.suburb,
    client_zip: project.client.zip,
    client_status: project.client.status,
    contractors: project.contractors || [],
    project_type: project.type,
  }
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="edit-project"
        aria-describedby="edit-project"
      >
        <Paper square className={classes.paper}>
          <Tabs
            value={Tabvalue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            
          >
            <Tab label="General" classes={{ root: classes.tab,selected: classes.selectedTab }} />
            <Tab label="Client" classes={{ root: classes.tab,selected: classes.selectedTab }} />
            <Tab label="Facade" classes={{ root: classes.tab,selected: classes.selectedTab }} />


          </Tabs>
        </Paper>

        <SimpleForm save={editproject} initialValues={initialValues}>

          <TabContainer id={0} active={Tabvalue === 0}>
            <>
              <TextInput source="title" fullWidth={true} variant="outlined" validate={required()} />
              <TextInput source="description" fullWidth={true} variant="outlined" multiline />
              <AddressField />
              <EstimateField />
              <ReferenceArrayInput
                reference="managers"
                source="managers"
                label="Project Managers"
                fullWidth={true}
                variant="outlined"
                resource="managers"
                filter={{all:true,status: 1}}
              >
                <SelectArrayInput optionText="fullname" optionValue="id" options={{ fullWidth: true }}>
                  <ChipField />
                </SelectArrayInput>
               </ReferenceArrayInput>
              <ReferenceArrayInput
                reference="contractors"
                source="contractors"
                label="Sub Contractors"
                fullWidth={true}
                variant="outlined"
                resource="contractors"
                filter={{all:true,status: 1}}
              >
                <SelectArrayInput optionText="fullname" optionValue="id" options={{ fullWidth: true }}>
                  <ChipField />
                </SelectArrayInput>
              </ReferenceArrayInput>
              <SelectInput
                source="project_type"
                label="Project Type"
                choices={[
                  { id: 'construction', name: 'Construction' },
                  { id: 'pre_contract', name: 'Pre Contract' },
                  { id: 'design_approval', name: 'Design Approval' },
                ]}
                variant="outlined"
                fullWidth={true}
              />
            </>
          </TabContainer>

          <TabContainer id={1} active={Tabvalue === 1}>
            <>
              <ImageInput
                source="image"
                label="Client Image"
                accept="image/*"
                placeholder={<p>Drop here</p>}
              >
                <ImageField source="url" title="title" />
              </ImageInput>
              <FormFields gridStyle={classes.main} />
              <PasswordField classes={classes} />
              <SelectInput
                source="client_status"
                label="Status"
                choices={[
                  { id: 1, name: 'Enabled' },
                  { id: 0, name: 'Disabled' },
                ]}
                fullWidth={true}
                variant="outlined"
              />
            </>
          </TabContainer>

          <TabContainer id={2} active={Tabvalue === 2}>
            <>
              <ImageInput
                source="facade"
                label="Facade"
                accept="image/*"
                placeholder={<p>Upload Project Facade</p>}
              >
                <ImageField source="url" title="title" />
              </ImageInput>
            </>
          </TabContainer>
          
        </SimpleForm>
      </Dialog>
    </div>
  );
}

function TabContainer(props) {
  const classes = useStyles();
  return (
    <div
      className={clsx({
        [classes.tabcontainerInActive]: !props.active,
      })}
    >
      {props.children}
    </div>
  );
}

const AddressField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="street"
          fullWidth={true}
          variant="outlined"
          label="Street"
          multiline
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="suburb"
          label="Suburb"
          fullWidth={true}
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          source="zip"
          fullWidth={true}
          label="Postal Code"
          variant="outlined"
          validate={required()}
        />
      </Grid>
    </Grid>
  );
};

const EstimateField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="area"
          validate={required()}
          variant="outlined"
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                square metre
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="amount"
          validate={required()}
          variant="outlined"
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                AUD
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

const FormFields = (props) => {
  return (
    <Grid container spacing={1} className={props.gridStyle}>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="first_name"
          validate={required()}
          fullWidth={true}
          label="First Name"
          variant="outlined" 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="last_name"
          validate={required()}
          fullWidth={true}
          label="Last Name"
          variant="outlined" 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="email"
          fullWidth={true}
          label="Email Address"
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="contact"
          validate={required()}
          fullWidth={true}
          label="Contact Number"
          variant="outlined" 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="client_street"
          validate={required()}
          multiline
          fullWidth={true}
          label="Street"
          variant="outlined" 
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput
          source="client_suburb"
          validate={required()}
          fullWidth={true}
          label="Suburb"
          variant="outlined" 
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput
          source="client_zip"
          validate={required()}
          fullWidth={true}
          label="Postal code"
          variant="outlined" 
        />
      </Grid>       
    </Grid>
  );
}

const PasswordField = ({classes}) => {
  const form = useForm();
  const changePassword = () => {
    form.change('client_password', generator.generate(
      {
        length: 15,
        numbers: true
      }
    ));
  }
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12} sm={9}>
        <Typography className ={classes.passwordTitle}>Update password</Typography>
        <PasswordInput
          fullWidth={true}
          source="client_password"
          label="Password"
          variant="outlined"
          size="small"
          // validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button variant="outlined"
          color="primary" 
          className={classes.passwordButton}
          onClick={changePassword}
        >
          Generate Password
        </Button>
      </Grid>
    </Grid>
  );
};

export default UpdateProject;

const useStyles = makeStyles((theme) =>({
  paper: {
    marginTop:20,
    boxShadow:'none',
  },
  tab: {
    width: 140,
    background: '#f3f3f3',
    fontSize: 14,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#fff',
  },
  tabcontainerInActive: {
    display: "none"
  },
  passwordTitle: {
    fontSize: 12,
    textTransform: 'capitalize',
    paddingBottom: 10,
    color: '#FF9800',
  },
  passwordButton: {
    margin: 10,
  }
}));
