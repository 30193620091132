import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, List, Button, Typography, CardContent } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
const Updateview = (props) => {
  const { contact,deleteFn } = props;
  const classes = useStyles();
  if (!contact) return null;

  return (
    <>
      <List>
        <Typography variant="inherit" component="div" className={classes.mtb5} style={{ fontWeight: 'bold', textTransform: 'uppercase'}}>
          {contact.designation}
        </Typography>
        <Typography variant="subtitle2" className={classes.mtb5} style={{ color: '#ffa500'}}>
          {contact.name}
        </Typography>
        <Typography variant="subtitle2" className={classes.mtb5}>
          {`Email: ${contact.email}`}
        </Typography>
        <Typography variant="subtitle2" className={classes.mtb5}>
          {`Contact Number : ${contact.phone}`}
        </Typography>
        <div className={classes.saveBtnHolder}>
          <Button  className={classes.saveBtn} onClick={()=>deleteFn(contact.id)}>
            <DeleteIcon color='red' style={{ fontSize: 17, marginRight: 2,color:'red' }} /> Delete</Button>
        </div>
      </List>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    // color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
    textAlign: 'left',
    color: '#000',
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  mtb5:{
    margin:'0px 0px 5px 0px'
  },
  saveBtnHolder: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
},
saveBtn: {
    background: '#ffff',
    marginRight:30,
    color: '#000',
    fontWeight: 'bold',
    '&:hover': {
        background: '#db6e6e',
    }
},
}));

export default Updateview;
