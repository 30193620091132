import React from 'react';
import { Typography,Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import SaveIcon from '@material-ui/icons/Save';

const Header = ({label,submitting}) => {
  const classes = headingStyles();
  return(
    <div className={classes.header}>
      <div className={classes.titleHolder}>
        <HomeIcon color="primary" fontSize="small" />
        <Typography variant="h6" className={classes.title}>
          {label}
        </Typography>
      </div>
      <Button
        variant="outlined"
        startIcon={<SaveIcon />}
        color="primary"
        type="submit"
        disabled={submitting}
      >
        Save
      </Button>
    </div>
  )
};

const headingStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0',
    justifyContent: 'space-between',
  },
  titleHolder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#2e2d2c',
    textTransform: 'capitalize',
    fontSize: 15,
    paddingLeft: 5,
    fontWeight: 'bold',
  },
});

export default Header;
