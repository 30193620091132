import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useAuthProvider } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import { useEffect } from "react";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  BtnHolder: {
    width: '100%',
    textAlign: 'center',
    padding: '10px 0px',
  },
  BtnSave: {
    backgroundColor: '#670e3c',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#670e3c',
      color: '#fff',
    }
  },
  Width400: {
    width: 400,
  },
  Width100_p: {
    width: '100%',
  },

  errorField: {
    color: '#f00',
    fontSize: '0.75rem',
  },
  actionBtnHolder: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  saveBtn: {
    background: '#e5aa13',
    color: '#000',
    fontSize: 12,
    '&:hover': {
      background: '#e5aa13',
    }
  },
  DeleteBtn: {
    background: 'transparent',
    color: '#ff0000a1',
    fontSize: 12,
    '&:hover': {
      background: '#ff000014',
    }
  },
  heading: {
    '& h6': {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#e7b02c',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subHeading: {
    '& h6': {
      fontSize: 13,
      fontWeight: 'bold',
      color: '#e7b02c',
      marginTop: 10,
      marginBottom: 10,
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    color: '#e5aa13',
    whiteSpace: 'nowrap',
    border: '1px solid #e5aa13'
  },

  modalContent: {
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ddd',
      borderRadius: 15,
      outline: '1px solid #ddd'
    }
  },
  header: {
    textAlign: 'center',
    fontStyle: 'italic'
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    marginBottom: 50,
  },

  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8
  },
  th: {
    textAlign: 'left',
    padding: 8,
    background: '#000',
    color: ' #fff',
    fontSize: 12,
    fontWeight: 400,
  }
}))
export default function ViewTender({ tender, user }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [quoteInfo, setQuoteInfo] = useState();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const current_user = authProvider.getIdentity();
  useEffect(() => {
    if (tender) {
      dataProvider.getTenderQuote({ tender: tender?.id, quotee: user?.id }).then(({ data }) => {
        if (data) {
          setQuoteInfo(data);
        }
      })
    }
  }, []);
  const handleClickOpen = () => {
    setOpen(true)
    if (!quoteInfo?.seen.includes[current_user.id]) {
      dataProvider.markQuote({ user: current_user.id, q_id: quoteInfo?.id }).then(({ data }) => {
      })
        .catch(error => {
        });
    }
  }
  return (
    <>
      <Button onClick={handleClickOpen} className={classes.editBtn}> View</Button>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" >
            <div className={classes.heading} >
              <EditIcon style={{ marginRight: 5 }} />
              <Typography variant="h6">
                Quote Details
              </Typography>
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <div style={{ marginBottom: 20 }}>
            <Typography component="h4"><b>{tender?.name || ''}</b></Typography>
            <Typography style={{ fontSize: 12, marginTop: 10 }}><b>Project : </b>{tender?.project?.title || ''}</Typography>
            <Typography style={{ fontSize: 12 }}><b>Posted on : </b>{moment(tender?.created_at).format('DD/MM/YYYY') || ''}</Typography>
            <Typography style={{ fontSize: 12 }}><b>Closing date : </b>{moment(tender?.lastdate).format('DD/MM/YYYY') || ''}</Typography>
            <Typography style={{ fontSize: 12 }}><b>Quoted By : <span style={{ textTransform: 'uppercase' }}>{user?.username || ''}</span></b></Typography>
          </div>

          <Grid container spacing={1} direction="row" alignItems="center">
            <table className={classes.table}>
              <tr>
                <th className={classes.th}>Description</th>
                <th className={classes.th}>Quoted Amount (Including GST)</th>
                <th className={classes.th}>Attachments</th>
              </tr>
              <tr>
                <td className={classes.td}>{quoteInfo?.description}</td>
                <td className={classes.td}>{"$ " + (Math.round(quoteInfo?.amount * 100) / 100).toLocaleString()}</td>
                <td className={classes.td}>
                  <ul>
                    {quoteInfo?.attachments?.length && quoteInfo?.attachments.map((item, index) => (
                      <li key={index}> <a target="_blank" style={{ fontSize: 13 }} href={item?.url}>Attachement ({index + 1})</a> </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </table>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}