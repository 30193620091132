import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box,Card,CardHeader,CardMedia,CardContent,Typography,Grid,Avatar } from '@material-ui/core';
import { useAuthProvider,useDataProvider, Loading,linkToRecord} from 'react-admin';
import { Link } from 'react-router-dom';

function FilteredList(props) {
  const { role } = props;
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(true);
  const user = authProvider.getIdentity();

  useEffect(() => {
    if(user && role) {
      dataProvider.getProjectsOfContractor({data: {role: role,id:user.id}}).then(({ data }) => {
        setProjects(data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;
  if (!projects) return null;

  return (
    <Box
      display="flex"
      flexWrap="wrap"
    >
    {projects.map((project) => (
      <Box p={1} m={2} alignItems="center" key={project.id} >
        <Card className={classes.card}>
          <CardHeader
            classes={{ root:classes.cardHeader, action: classes.action,title: classes.title}}
            title={project.title}
          />
          <Link to={linkToRecord('projects', project.id)} className ={classes.anchor}>
            <CardMedia
              className={classes.media}
              image={(project.facade) ? project.facade : process.env.PUBLIC_URL + '/facade-placeholder.png'}
              title="project Facade"
            />
            
            <CardContent
              classes={{ root:classes.cardContent}}
            >

              <Typography variant="body2" component="p">
                {project.description}
              </Typography>

              
              <Grid container direction="row" justify="space-between" className={classes.clientGrid}>
                <Typography className={classes.clientName}>
                  {`${project.client.first_name} ${project.client.last_name}`}
                </Typography>
                <Avatar
                  alt={`${project.client.first_name} ${project.client.last_name}`}
                  src={project.client.profileImg}
                  className={classes.avatar}>
                </Avatar>
              </Grid>

            </CardContent>
          </Link>
        </Card>
      </Box>
    ))}
  </Box>
  )
}


const useStyles = makeStyles({
  card:{
    width:300,
    boxShadow:'0px 0px 15px rgba(73, 78, 92, 0.15)',
    borderRadius:8,
    minHeight: 270,
  },
  cardHeader: {
    background: '#e5d893',
    padding: 8,
  },
  anchor: {
    textDecoration: 'none',
    color: '#dadbe6',
    '&:active':{
      color: '#fff',
    }
  },
  cardContent: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 0,
    },
    '& p':{
      maxWidth: 300,
      padding: '5px 0',
      fontSize: 14,
      color: '#67655b',
    },
    '& h4': {
      paddingTop:5,
      fontSize: 16,
      textTransform: 'capitalize',
      color: '#a17607',
    }
  },
  action: {
    margin: 0,
    paddingLeft: 10,
  },
  avatar: {
    width: 30,
    height: 30,
  },
  title: {
    fontSize:15,
  },
  progress: {
    marginTop: 5,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  clientGrid: {
    padding: '10px 0'
  },
  clientName: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: '#443918 !important',
  }
});


export default FilteredList;
