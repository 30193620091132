
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDataProvider,SelectInput,LinearProgress } from 'react-admin';

const ProjectField = (props) => {
  const { role,userId,className } = props;
  const dataProvider = useDataProvider();
  const [projects, setprojects] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dataProvider.getProjectsOfContractor({data: {role: role,id:userId}})
      .then(({ data }) => {
        setprojects(data.map(project => {
          return {
            id: project._id,
            name: project.title,
          }
        }));
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      })
  }, []);

  if (loading) return <LinearProgress className={className} />;
  if (!projects) return null;

  return (
    <SelectInput
      choices={projects}
      {...props}
    />
  )
};

export default ProjectField;
