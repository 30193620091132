import React,{ useEffect, useState} from 'react';
import { 
  useDataProvider,
  useNotify,
  useRedirect,
  Loading
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

export default function PreDetails({id}) {
  const dataProvider = useDataProvider();
  const[loading, setLoading] = useState(true);
  const[projectInfo, setProjectInfo] = useState();
  const classes = useStyles();

  useEffect(() => {
    dataProvider.getPreContractFormDetails({id: id})
      .then(({ data }) => {
        // console.log(data);
        setProjectInfo(data)
        setLoading(false);

      })
      .catch(error => {
        // console.log(error.message);
        setLoading(false);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(loading) {return <Loading />}
  if(!projectInfo) {return null}

  return(
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.mainHeading}>Client Details</div>
        <div className={classes.field}>
          {`Name: ${projectInfo.client.username}`}
        </div>
        <div className={classes.field}>
          {`Email: ${projectInfo.client.email}`}
        </div>
        <div className={classes.field}>
          {`Contact: ${projectInfo.client._client.contact}`}
        </div>
        <div className={classes.field}>
          {`Address: ${projectInfo.client._client.address.street}  ${projectInfo.client._client.address.suburb}  ${projectInfo.client._client.address.state}`}
        </div>

        <div className={classes.mainHeading}>Site Details</div>
        <div className={classes.field}>
          {`Address: ${projectInfo.address}`}
        </div>
        <div className={classes.field}>
          {`Length: ${projectInfo.length}`}
        </div>
        <div className={classes.field}>
          {`Frontage: ${projectInfo.frontage}`}
        </div>
        <div className={classes.field}>
          {`Land Size: ${projectInfo.landSize}`}
        </div>
        <div className={classes.field}>
          {`Council: ${projectInfo.council}`}
        </div>
        <div className={classes.field}>
          {`Type: ${projectInfo.siteBlock}, ${projectInfo.siteType}`}
        </div>
        {projectInfo.isSurveyAvailable && (
          <div className={classes.field}>
            {`Is Survey Available: ${projectInfo.isSurveyAvailable}`}
          </div>
        )}
        {projectInfo.isLandRegistered && (
          <div className={classes.field}>
            {`Is Land registered: ${projectInfo.isLandRegistered}`}
          </div>
        )}
         {projectInfo.surveyComment && (
          <div className={classes.field}>
            {`Comment About Survey: ${projectInfo.surveyComment}`}
          </div>
        )}
        

        <div className={classes.mainHeading}>Building Details</div>
        <div className={classes.field}>
          {`Squares: ${projectInfo.squares}`}
        </div>
        <div className={classes.field}>
          {`Building Type: ${projectInfo.buildingType}`}
        </div>
        <div className={classes.field}>
          {`Storey: ${projectInfo.buildingStorey}`}
        </div>

        <div className={classes.mainHeading}>Floor Plan</div>

        {projectInfo.rooms && (
          <>
            {projectInfo.rooms.groundFloor && (
              <>
                <div className={classes.floorTitle}>Ground Floor</div>
                {Object.entries(projectInfo.rooms.groundFloor).map(([key, value]) => (
                  value && (
                    <div className={classes.field} key={key}>
                      {`${key}: ${value}`}
                    </div>
                  )
                ))}
              </>
            )}
            {projectInfo.rooms.firstFloor && (
              <>
                <div className={classes.floorTitle}>First Floor</div>
                {Object.entries(projectInfo.rooms.firstFloor).map(([key, value]) => (
                  value && (
                    <div className={classes.field} key={key}>
                      {`${key}: ${value}`}
                    </div>
                  )
                ))}
              </>
            )}
            {projectInfo.rooms.secondFloor && (
              <>
                <div className={classes.floorTitle}>Second Floor</div>
                {Object.entries(projectInfo.rooms.secondFloor).map(([key, value]) => (
                  value && (
                    <div className={classes.field} key={key}>
                      {`${key}: ${value}`}
                    </div>
                  )
                ))}
              </>
            )}
          </>
        )}

        <div className={classes.mainHeading}>Facade</div>
        <div className={classes.field}>
          {`Facade Type: ${projectInfo.facadeType}`}
        </div>
        {projectInfo.facade_comment && (
          <div className={classes.field}>
            {`Comment About Facade: ${projectInfo.facade_comment}`}
          </div>
        )}
        <div className={classes.mainHeading}>Attachments</div>

        {projectInfo.contractFiles && projectInfo.contractFiles.length > 0 && (
          <>
            {projectInfo.contractFiles.map((file,index) => (
              <div className={classes.file} key={index}>
              <a target="_blank" href={`https://www.nexaplan.com.au:3000${file.url}`}>
                {file.name}
              </a>
            </div>
            ))}
          </>
        )}

        {projectInfo.noRegContractFiles && projectInfo.noRegContractFiles.length > 0 && (
          <>
            {projectInfo.noRegContractFiles.map((file,index) => (
              <div className={classes.file} key={index}>
              <a target="_blank" href={`https://www.nexaplan.com.au:3000${file.url}`}>
                {file.name}
              </a>
            </div>
            ))}
          </>
        )}

        {projectInfo.surveyDwgFiles && projectInfo.surveyDwgFiles.length > 0 && (
           <>
            {projectInfo.surveyDwgFiles.map((file,index) => (
              <div className={classes.file} key={index}>
              <a target="_blank" href={`https://www.nexaplan.com.au:3000${file.url}`}>
                {file.name}
              </a>
            </div>
            ))}
         </>
        )}

        {projectInfo.surveyFiles && projectInfo.surveyFiles.length > 0 && (
         <>
         {projectInfo.surveyFiles.map((file,index) => (
           <div className={classes.file} key={index}>
              <a target="_blank" href={`https://www.nexaplan.com.au:3000${file.url}`}>
                {file.name}
              </a>
            </div>
         ))}
      </>
        )}

      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>({
  container: {
    width: '100%',
    height: '100%',
    background: '#f3f3f3',
  },
  main: {
    margin: 20,
    backgroundColor: '#fff',
    padding: 10,
  },
  field: {
    padding: '10px 0',
    textTransform: 'capitalise',
  },
  mainHeading: {
    fontSize: 15,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingBottom: 10,
    paddingTop: 10,
  },
  file: {
    padding: '10px 0',
    color: '#fd482b',
    textDecoration: 'underline',
  },
  floorTitle: {
    fontSize: 14,
    paddingBottom: 10,
    paddingTop: 10,
    fontWeight: 'bold',
  },
}));