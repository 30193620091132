import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  Badge,
  AccordionDetails,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,

} from '@material-ui/core';
import ScheduleHeader from '../components/ScheduleHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';

function ScheduleTodo(props) {
  const { Schedules,title, isComplete } = props;
  const classes = useStyles();
  let tableHeaders = ['Todo','Project','Due Date'];
  if(isComplete) {tableHeaders.push('Complete')}

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Typography className={classes.tableheading}>{title}</Typography>

      {Schedules.map((project,index) => (project.todos.length > 0 && (
        <Accordion
          key={index}
          //defaultExpanded={(index === 0)? true : false}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.projectTitle}>{`${project.title} ${project.description}`}</Typography>

            <Badge color="primary" badgeContent={project.todos.length || 0} className={classes.badgeHolder}>         
            </Badge>

          </AccordionSummary>

          <AccordionDetails>
            <Table size="small">
              <ScheduleHeader 
                titles={tableHeaders}
              />
              <SchdeduleBody
                project={project}
                classes={classes}
                isComplete={isComplete}
                onComplete={isComplete ? props.onComplete : undefined}
              />
            </Table>
          </AccordionDetails>
        </Accordion>
      )))}
    </TableContainer>
  )
}

const SchdeduleBody = ({project,classes,isComplete,onComplete}) => {
  return (
    <TableBody>
      {project.todos.map((todoItem) =>(
        (todoItem.todo) && (
          <StyledTableRow key={todoItem.id}>
            <TableCell component="th" scope="row" className={classes.rowStyle}>
              <div className={classes.linkWrapper}>
                <Link
                  to={`/project-todo-chart/${project._id}?todoId=${todoItem.id}`}
                >
                  {todoItem.todo.title}
                </Link>
              </div>
            </TableCell>
            <TableCell className={classes.rowStyle}>{project.title}</TableCell>
            <TableCell className={classes.rowStyle}>{todoItem.DueDate}</TableCell>
            {(isComplete) && (
              <>
                <TableCell padding="checkbox" className={classes.rowStyle}>
                  <FormControlLabel
                    className={classes.formLabel}
                    control={
                      <Checkbox
                        checked={(todoItem.status === 2)? true: false}
                        onChange={onComplete(todoItem,'todo')}
                        name={todoItem.todo.title}
                        color="primary"
                      />
                    }
                    label="Completed"
                  />
                </TableCell>
              </>
            )}
        </StyledTableRow>
      )
    ))}
  </TableBody>
)}

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: '10px',
  },
  tableheading: {
    background:'#f3f3f3',
    padding: 8,
    color: '#1b1809',
    fontSize: 14,
    margin: '10px 0',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    border: '1px solid #9f9150',
  },
  projectTitle: {
    fontSize: 13,
  },
  rowStyle: {
    padding: '10px 5px',
    fontSize: 13,
  },
  formLabel: {
    padding: '8px 10px',
    '& span': {
      fontSize: 13,
    }
  },
  badgeHolder: {
    '& span': {
      position: 'relative',
      transform: 'none',
      margin: '0 10px',
      background: '#c08c01',
      color: '#fff',
    }
  },
  linkWrapper: {
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      fontWeight: 'bold',
    }
  },
}));

export default ScheduleTodo;
