import * as React from "react";
import {
  Edit,
  TextInput,
  required,
  SimpleForm,
  FormTab,
  TabbedForm,
  SelectInput,
  ReferenceArrayInput,
  CheckboxGroupInput,
  useNotify, useRefresh, useRedirect

} from 'react-admin';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 17,
      padding: 10,
      // fontWeight: 'bold',
      color:'#8e680d',
    }
  },
  CheckLabel: {
    fontSize: 25,
    margin: '10px 0',
  },
});


function TemplateEdit(props){
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    console.log(data);
    notify(`Changes to template updated`);
    redirect('/templates');
    refresh();
  };

  return (
    <Edit onSuccess={onSuccess} undoable={false} {...props} >
      <TabbedForm>
        <FormTab label="General">
          <TextInput
            source="name"
            label="Template Name"
            fullWidth={true}
            variant="outlined"
            validate={required()}
          />
          <SelectInput
            source="status"
            choices={[
              { id: 1, name: 'Enabled' },
              { id: 0, name: 'Disabled' }           
            ]}
            defaultValue={1}
            validate={required()}
            fullWidth={true}
            variant="outlined"
          />
        </FormTab>
        <FormTab label="Tasks">
          <ReferenceArrayInput
            source="tasks"
            resource="templates"
            reference="tasks"
            fullWidth={true}
            label="Choose tasks items for template"
            variant="outlined"
            validate={required()}
            filter={{all:true}}
          >
            <CheckboxGroupInput
              classes={{label:classes.CheckLabel}}
              optionText="title"
              //row={false}       
            />
          </ReferenceArrayInput>     
        </FormTab>

        <FormTab label="Todos">
          <ReferenceArrayInput
            source="todos"
            resource="templates"
            reference="todos"
            fullWidth={true}
            label="Choose todo items for template"
            variant="outlined"
            validate={required()}
            filter={{all:true}}
          >
            <CheckboxGroupInput
              classes={{label:classes.CheckLabel}}
              optionText="title"
              // row={false}       
            />
          </ReferenceArrayInput>
        </FormTab>
            
        <FormTab label="Certificates">
          <ReferenceArrayInput
            source="certificates"
            resource="templates"
            reference="certificate"
            fullWidth={true}
            label="Choose Certificates items for template"
            variant="outlined"
            // validate={required()}
            //initialValue = {initialData.todo}
            //filter={{all:true}}
          >
            <CheckboxGroupInput
              classes={{label:classes.CheckLabel}}
              optionText="type"
              // row={false}       
            />
          </ReferenceArrayInput>
        </FormTab>
        
        <FormTab label="Non Working Days">
          
        </FormTab>

      </TabbedForm>
    </Edit>
  );
};

export default TemplateEdit;

