import React,{useEffect, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDataProvider,useRedirect } from 'react-admin';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,

} from '@material-ui/core';
import ScheduleHeader from '../components/ScheduleHeader';


function PendingList() {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [assignedTasks, setAssignedTasks] = useState();

  useEffect(() => {
    dataProvider.getList('accept-task',{
      pagination: { page: 100 , perPage: 10 },
      sort: { field: '_id', order: 'asc' },
      filter: {}
    }
    ).then(({ data }) => {
      setAssignedTasks(data);
    })
    .catch(error => {
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToAccept = (taskId) => {
    redirect(`/task-confirmation/${taskId}`);
  }

  if(!assignedTasks || assignedTasks.length < 1) return null;

  return(
    <div className={classes.main}>
      <div className={classes.heading} > 
        Pending assigned tasks
      </div>
      <Table size="small">
        <ScheduleHeader
          titles={['Task','Project','Task Period','Working Days','Action']}
        />
        <TableBody>
          {(assignedTasks.map(_task => (
            <StyledTableRow key={_task.id} >
              <TableCell className={classes.rowStyle}>{_task.task}</TableCell>
              <TableCell className={classes.rowStyle}>{_task.project}</TableCell>
              <TableCell className={classes.rowStyle}>{_task.period}</TableCell>
              <TableCell className={classes.rowStyle}>{_task.workDays}</TableCell>
              <TableCell >
                <Button 
                  variant="outlined" color="primary"
                  onClick={() => goToAccept(_task.id)}
                >
                  accept/reject
                </Button>
              </TableCell>
            </StyledTableRow>
          )))}
        </TableBody>
      </Table>
    </div>
  )
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  main: {
    padding: 10,
    margin: '10px',
    border: '1px solid #dfdfdf',
  },
  heading: {
    fontSize: 13,
    padding: '10px 5px',
    border: '1px solid #9f9150',
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  rowStyle: {
    fontSize: 13,
  },
}));

export default PendingList;
