/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import MenuIcons from './MenuIcon';
// import Schedules from './Schedules';
// import ComingSchedules from './Comingschedules';
import PendingTasks from './PendingTaskList';
import { useAuthState,usePermissions,useAuthProvider,useDataProvider  } from 'react-admin';
import { useEffect } from 'react';

window.OneSignal = window.OneSignal || [];
const OneSignal = window.OneSignal;

export default (props) => {
  const { loading, authenticated } = useAuthState();
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  
  useEffect(() => {
    const setPlayerId = (userId) => {
      let playerId = localStorage.getItem('nexa_pm_user_player_id');
      if(!playerId || (playerId !== userId)) {
        dataProvider.updateUserPlayerId({playerId: userId}).then(({ data }) => {
          localStorage.setItem('nexa_pm_user_player_id', userId); 
        })
        .catch(error => {
          console.log(error);
        });
      }
    };

    if(authenticated) {
      OneSignal.push(()=> {

        OneSignal.getUserId((userId) => {
          if(userId){setPlayerId(userId);} 
        });
       
        OneSignal.on('subscriptionChange', (isSubscribed) => {
          OneSignal.push(() =>{ 
            OneSignal.getUserId(function(userId) {
              if(userId){setPlayerId(userId);}            
            });
          });
        });
      });
    }
     
  }, [authenticated]);

    
  if (loading) {
    return null;
  }
  if (authenticated) {
    return(
      <div className={classes.main} >
        <MenuIcons />
        {permissions && permissions.role === 'contractor' &&
          <PendingTasks />
        }
        {user && user.id && permissions && permissions.role &&
          <>
            {/* <Schedules role={permissions.role} userId ={user.id} />
            <ComingSchedules role={permissions.role} userId ={user.id} /> */}
          </>
        }
       
      </div>
    )
  }
  return null;
}

const useStyles = makeStyles((theme) => ({
  main: {
    margin: '10px 0',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
}));
