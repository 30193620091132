import * as React from "react";

import { 
  Create,TextInput,required,SimpleForm,SelectInput,
  useNotify, useRefresh, useRedirect
} from 'react-admin';

import { Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const PhaseCreate = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Phases Created`)
    redirect('/phases');
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props} >
      <SimpleForm>
        <SectionHeading label="Create Phase" classes={classes} />
        <TextInput
          fullWidth={true}
          source="title"
          validate={required()}
        />
        
        <SelectInput
          source="status"
          choices={[
            { id: 1, name: 'Enabled' },
            { id: 0, name: 'Disabled' },
          ]}
          defaultValue={0}
          fullWidth={true}
        />
      </SimpleForm>
    </Create>
  );
};

export default PhaseCreate;
