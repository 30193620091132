/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';


function EmptyRoom(props) {


  return (
    <div className="empty-chat">
      <img className="empty-chat-image" src={ process.env.PUBLIC_URL + '/chat.png'} alt="nochat" />
    </div>
  )
}

export default EmptyRoom;
