import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import PropTypes from 'prop-types';
import {
  DialogContent,
  Divider,
  DialogTitle,
  makeStyles,
  Grid,
  CircularProgress,
} from '@material-ui/core';
// import { useNavigate } from 'react-router-dom';
import {
  SimpleForm,
  required,
  TextInput,
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    justifyContent: 'space-between',
    margin: '0 12px',
  },
  loadingContainer: {
    minWidth: 300,
    minHeight: 100,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function EditEmployee({
  setOpen, employee, editEmployee
}) {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [empDetails, setEmpDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dataProvider
      .getOne('employee', { id: employee.id })
      .then(({ data }) => {
        setEmpDetails(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.id]);


  return (
    <div>
      <DialogTitle id="assign-task-title">Edit Employee</DialogTitle>
      <Divider />
      <DialogContent style={{ padding: 0 }} className={classes.root}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <SimpleForm
            initialValues={empDetails}
            save={editEmployee}
          >
            <Grid container spacing={1} fullWidth={true}>
              <Grid item xs={12} md={6}>
                <TextInput 
                  source="firstName"
                  label="First Name"
                  fullWidth={true}
                  variant="outlined"
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput 
                  source="lastName"
                  label="Last Name"
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  source="contact"
                  label="Conatct No"
                  fullWidth={true}
                  variant="outlined"
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  source="password"
                  label="Password"
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </SimpleForm>
        )}
      </DialogContent>
    </div>
  );
}

EditEmployee.propTypes = {
  // setType: PropTypes.func,
  setOpen: PropTypes.func,
};

