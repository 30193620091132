/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect, useState, useCallback} from 'react';
import { 
  usePermissions,
  useAuthProvider,
  useDataProvider,
  useNotify,
  useRedirect,
 // Button,
  Loading
} from 'react-admin';
import {Grid,Typography,Container,TextField,Button } from '@material-ui/core';
import { Form,Field  } from 'react-final-form';
import ApiConfig from '../config/apiconfig';
import Emptyschedule from './declineError';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {useHistory} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { lightTheme } from '../layout/themes';

function ActivateAccount(props) {

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [fieldvisibility, setFieldVisibility] = React.useState({
    showConfirmPassword: false,
    showPassword: false,
  });
  const history = useHistory();
  const { id } = props.match.params;

  const classes = useStyles();

  const onSubmit = (values) => {
    fetch(`${ApiConfig.REST_API_AUTH_URL}/user/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }).then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      history.push('/');

    }).catch(err => {
      throw new Error(err.statusText);
    })
  };
  

  useEffect(() => {
    fetch(`${ApiConfig.REST_API_AUTH_URL}/user/${id}`).then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      // console.log(response.data);
      setLoading(false);
    }).catch(err => {
      setHasError(true);
      setLoading(false);
    })
  }, []);
  
 
  const handleClickShowPassword = () => {
    setFieldVisibility({...fieldvisibility, showPassword : !fieldvisibility.showPassword});
  };
  const handleClickShowConfirmPassword = () => {
    setFieldVisibility({...fieldvisibility, showConfirmPassword : !fieldvisibility.showConfirmPassword});
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  if (loading) return <Loading />;
  if (hasError) return <Emptyschedule message={'This user is already activated or not exists'} />

  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <div className={classes.titleText}>
          Create New Password
        </div>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Field name="password">
                {props => (
                  <div>
                    <TextField
                      label="Password"
                      name={props.input.name}
                      value={props.input.value}
                      type={fieldvisibility.showPassword ? 'text' : 'password'}
                      onChange={props.input.onChange}
                      error={!!(props.meta.touched && props.meta.error)}
                      helperText={props.meta.touched ? props.meta.error : ''}
                      fullWidth={true}
                      className={classes.textField}
                      //variant="outlined"
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>),
                           className: classes.multilineColor                     
                      }}                 
                    />
                  </div>
                )}
              </Field>
              <Field name="conpassword">
                {props => (
                  <div>
                    <TextField
                      label="Confirm Password"
                      name={props.input.name}
                      value={props.input.value}
                      //className={classes.textField}
                      type={fieldvisibility.showConfirmPassword ? 'text' : 'password'}
                      onChange={props.input.onChange}
                      error={(props.meta.touched && props.meta.error) || false}
                      helperText={props.meta.touched ? props.meta.error : ''}
                      fullWidth={true}
                      //variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showConPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment> ),
                          className: classes.multilineColor                 
                      }}
                    />
                  </div>
                )}
              </Field>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submitButton}
                disabled={submitting}
              >
                Submit
              </Button>
            </form>
          )}
        />
      </div>
    </div>
  );
}

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.conpassword) {
    errors.conpassword = 'Required';
  }
  if ((values.conpassword && values.conpassword.length < 5) || (values.password &&values.password.length) < 5) {
    errors.conpassword = 'Password Must be includes more than 5 characters';
  }
  if(values.password !== values.conpassword) {
    errors.password = "Password Doesn't Match";
    errors.conpassword = "Password Doesn't Match";
  }
  return errors;
};

const useStyles = makeStyles((theme) =>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      height: '100vh',
      marginTop: '-3em',
    },
    background: 'url(2376627.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    backgroundColor: '#fff',
  },
  box: {
    width: 320,
    margin: 'auto',
    padding: 15,
    borderRadius: 15,
    // background: '#20353c',
    background: '#282a3c',
    border: '1px solid #ebbd17',
  },
  titleText: {
    fontSize: 12,
    // color: '#5b4e4e',
    color: '#e5aa13',
    padding: '10px 0',
  },
  textField: {
    margin: '10px 0',
    color: 'red',
  },
  submitButton: {
    background: '#ffbe2e',
    '&:hover': {
      background: '#e3a723',
    },
    margin: '20px 0px',
    width: '100%'
  },
  multilineColor:{
    color:'#dadbe6',
  }
}));

const ActivateAccountWithTheme = (props) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <ActivateAccount {...props} />
  </ThemeProvider>
);

export default ActivateAccountWithTheme;
