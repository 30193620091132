import * as React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

const SortButton = ({fields,sortGhantData,currentSort}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();
  
  const handleChangeSort = (event) => {
    sortGhantData(event.currentTarget.dataset.sort,event.currentTarget.dataset.sorttitle)
    setAnchorEl(null);
  };
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if(!fields) return null;

  return (
    <div className={classes.container}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
        startIcon={<SortIcon />}
        endIcon={<ArrowDropDownIcon />}

      >
       {`SORT BY ${currentSort}`}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
            onClick={handleChangeSort}
            data-sort={'none'}
            data-sorttitle={'None'}
            key={0}
          >
            {'None'}
        </MenuItem>
        {fields.map(field => (
          <MenuItem
            onClick={handleChangeSort}
            data-sort={field.id}
            data-sorttitle={field.title}
            key={field.id}
          >
            {field.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles((theme) =>({
  container: {
    margin:'15px 10px',
    textAlign: 'right',
  }
}));

export default SortButton;
