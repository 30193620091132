import React, { useState,useEffect } from 'react';
import { useDataProvider,Loading,usePermissions } from 'react-admin';
import { useHistory } from "react-router-dom";
import Create from './MaterialCreate';
import List from './MaterialList';

function ProjectMaterial({projectId}) {
  const[materials, setMaterials] = useState();
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const history = useHistory();
  const { permissions } = usePermissions();

  const onEditClick = (materialData) => {
    materialData.supplier = materialData.supplier.id;
    materialData.material = materialData.material.id;
    history.push(`/project-materials/edit/${materialData.id}`);
  }

  const onDeleteClick = (id) => {
    setLoading(true);
    dataProvider.deleteProjectinfo('projectmaterials',{id:id,projectId:projectId})
      .then(({ data }) => {
        setMaterials(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }

  const saveProjectMaterial = (data) => {
    setLoading(true);
    data = {...data, projectId:projectId};
    dataProvider.createProjectinfo('projectmaterials',{data:data})
      .then(({ data }) => {
        setMaterials(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }

  useEffect(() => {
    dataProvider.getList('projectmaterials',
      { filter: {project: projectId} , pagination: {}, sort: { field: 'id', order: 'asc' }})
      .then(({ data }) => {
        setMaterials(data);
        setLoading(false);
      })
      .catch(error => {
        // console.log(error);
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;
  if(!materials) return null;

  return (
    <>
      <Create save={saveProjectMaterial} />
      <List
        projectId ={projectId}
        data={materials}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
    </>
  )
}

export default ProjectMaterial;
