/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextInput,
  NumberInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  useDataProvider,
  useNotify,
} from 'react-admin';
import {FcFeedback} from "react-icons/fc";
import {Grid, Typography} from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    background: '#fff',
    margin: 'auto',
    marginTop: 15,
    maxHeight: 600,
  },
  main:{
    width: '100%',
  }
});
const validateMessage = (values) => {
  const errors = {};
  if (!values.phone_number) {
    errors.phone_number = 'Phone number is required';
  }
  if (!values.message) {
    errors.message = "Required";
  }else if(values.message.length > 160){
    errors.message = "Message should be less tha or equal to 160 characters";
  }
  return errors;
};

function Message() {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState();

  const sendMessage= (values) => { console.log(values);
    dataProvider
    .sendMessage({data: values})
    .then( async({data}) => {
      setPhone('');
      setMessage('');
      notify('Message send Successfully');
      
    })
  }
  const classes = useStyles();
  return (
    <div className={classes.container}>
    <SimpleForm toolbar={<SendButton />} save={ sendMessage } validate= {validateMessage}>
      <Grid container spacing={1} className={classes.main}>
        <Grid item xs={12} sm={8}>
            <Grid container direction="row" alignItems="center" className={classes.heading}>
            <FcFeedback style={{ marginRight: 10, fontSize: 30 }}/> 
              <Typography variant="h6" gutterBottom>
                Send Message
              </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <NumberInput
            source="phone_number"
            fullWidth={true}
            label="Phone Number"
            variant="outlined"
            size="small"
            value={phone}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <TextInput
            source="message"
            numberOfLines={5}
            fullWidth={true}
            label="Message"
            variant="outlined" 
            value={message}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </div>
  )
}

export default Message;
const SendButton = props => {
  const toolbarStyles = useToolbarStyles();
  return(
    <Toolbar {...props} classes={toolbarStyles} >
      <SaveButton label="SEND" />
    </Toolbar>
  );
}
const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    //float: 'right',
  },
});