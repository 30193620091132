import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Grid, CircularProgress } from '@material-ui/core';
import Info from './quoteInfo';
import { useDataProvider } from 'react-admin';
import GavelIcon from '@material-ui/icons/Gavel';
import moment from 'moment';
import AllInboxIcon from '@material-ui/icons/AllInbox';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  heading: {
    padding: 16,
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color: '#e7b02c',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableheading: {
    padding: '0px 10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editBtn: {
    color: '#e5aa13',
    fontWeight: 'bold',
  },
  memberItem: {
    background: '#ddd',
    display: 'inline-block',
    margin: 5,
    padding: '2px 10px',
    fontSize: '13px',
    borderRadius: '10px',
    whiteSpace: 'nowrap'
  },
  emptyClass: {
    padding: 10,
    textAlign: 'center',
    fontStyle: 'italic',
    paddingBottom: 30,
  },
  loader_holder: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loader: { color: '#e5aa13' },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(false);
  const [quotes, setQuotes] = React.useState(false);
  const [tenders, setTenders] = React.useState(false);
  const [projects, setProjects] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState({ project_id: null, tender_id: null });
  const dataProvider = useDataProvider();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  React.useEffect(() => {
    setLoading(true);
    dataProvider.getTenders().then(({ data }) => {
      setTenders(data);;
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getAllProjects({}).then(({ data }) => {
      setProjects(data);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getAllTenderQuotes({ filter: {} }).then(({ data }) => {
      setQuotes(data);
    })
      .catch(error => {
      });
  }, []);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onChangeProject = (e) => {
    let filterInfo = {};
    if (e.target.value) {
      filterInfo = { project_id: e.target.value, tender_id: null }
      let filterArr = tenders?.length && tenders.filter(row => row?.project?.id === e.target.value);
      setRows(filterArr);
    } else {
      filterInfo = { project_id: null, tender_id: null };
      setRows(false);
    }
    setFilter(filterInfo);
    dataProvider.getAllTenderQuotes({ filter: filterInfo }).then(({ data }) => {
      setQuotes(data);
    })
  }
  const onChangeTender = (e) => {
    let filterInfo = {};
    if (e.target.value) {
      filterInfo = { project_id: filter.project_id, tender_id: e.target.value };
    } else {
      filterInfo = { project_id: filter.project_id, tender_id: null };
    }
    setFilter(filterInfo);
    dataProvider.getAllTenderQuotes({ filter: filterInfo }).then(({ data }) => {
      setQuotes(data);
    })
  }
  if (loading) {
    return (<div className={classes.loader_holder}> <CircularProgress className={classes.loader} /></div>)
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={1} direction="row" alignItems="center" className={classes.main}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="row" alignItems="center" className={classes.tableheading} >
              <div className={classes.heading} >
                <AllInboxIcon />
                <Typography variant="h6" gutterBottom>
                  Tender Quotes
                </Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography style={{ fontSize: 12, fontWeight: 'bold' }}> Select By Project</Typography>
                  <select onChange={(e) => onChangeProject(e)} style={{ minWidth: 250, marginRight: 10, border: '1px solid #e5aa13', padding: 10, backgroundColor: '#efefef', borderRadius: 5 }}>
                    <option value=''>All</option>
                    {projects.length && projects.map((row, index) => (
                      <option key={index} value={row.id}>{`${row.title} ${row.description}`}</option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginLeft: 10 }}>
                  <Typography style={{ fontSize: 12, fontWeight: 'bold' }}> Select By Tender</Typography>
                  <select onChange={(e) => onChangeTender(e)} style={{ minWidth: 250, border: '1px solid #e5aa13', padding: 10, backgroundColor: '#efefef', borderRadius: 5 }}>
                    {rows?.length && (
                      <>
                        <option value=''>All</option>
                        {rows.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {quotes?.length ? (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size='medium'
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Quotee</TableCell>
                    <TableCell>Tender</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell align="left">Quoted on</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="td" scope="row"  >
                          <GavelIcon style={{ color: '#d5d0d0' }} />
                        </TableCell>
                        <TableCell component="td" scope="row"  style={{ whiteSpace: 'nowrap' }}>
                          {row?.quotee?.username || ''}
                        </TableCell>
                        <TableCell component="td" scope="row" >
                          {row?.tender_id?.name || ''}
                        </TableCell>
                        <TableCell component="td" scope="row" >
                          {row?.tender_id?.project?.title || ''}
                        </TableCell>
                        <TableCell component="td" scope="row" style={{ fontSize: 13 }} >
                          {moment(row?.created_at).format('DD-MM-YYYY, h:mm a') || ''}
                        </TableCell>
                        <TableCell component="td" scope="row" style={{ fontSize: 13 }} padding="10px">
                          {row?.tender_id && (
                            <Info user={row?.quotee} tender={row?.tender_id || false} />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={quotes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        ) : (<div className={classes.emptyClass}>Empty Tender quote list..!</div>)}
      </Paper>
    </div >
  );
}
