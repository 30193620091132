import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import './Gantt.css';
import { Typography } from '@material-ui/core';
 
export default class Gantt extends Component {
  
  // constructor(props) {
  //   super(props);
  //   //this.initZoom();
  // }

  // instance of gantt.dataProcessor
  dataProcessor = null;
  onTaskClick = null;
  dateFormat = null;

  initZoom() {
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Hours',
          scale_height: 60,
          min_column_width: 30,
          scales: [
            { unit: 'day', step: 1, format: '%d %M' },
            { unit: 'hour', step: 1, format: '%H' }
          ]
        },
        {
          name: 'Days',
          scale_height: 60,
          min_column_width: 70,
          scales: [
            { unit: 'week', step: 1, format: 'Week #%W' },
            { unit: 'day', step: 1, format: '%d %M' }
          ]
        },
        {
          name: 'Months',
          scale_height: 60,
          min_column_width: 70,
          scales: [
            { unit: "month", step: 1, format: '%F' },
            { unit: 'week', step: 1, format: '#%W' }
          ]
        }
      ]
    });
  }

  setZoom(value) {
    gantt.ext.zoom.setLevel(value);
  }

  

  initGanttDataProcessor() {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    const onDataUpdated = this.props.onDataUpdated;
    this.dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      return new Promise((resolve, reject) => {
        if (onDataUpdated) {
          onDataUpdated(type, action, item, id);
        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        // resolve({id: databaseId});
        return resolve();
      });
    });
  }

  initGanttOnclick() {
    const onItemClick = this.props.onClick;
    const tasks = this.props.tasks;
    this.onTaskClick =  gantt.attachEvent("onTaskDblClick", function(id, e) {
      // console.log(e);
     
      return new Promise((resolve, reject) => {
        if (onItemClick && tasks) {
          let clickedItem = tasks.data.filter((task)=> {
            return task.id == id;
          });
          //console.log(clickedItem[0]);
          onItemClick(clickedItem[0],true);
        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        //resolve({id: id});
        return resolve(e);
      });
    });
   
  }
 

  componentDidMount() {

    const { tasks, zoom,isDraw } = this.props;
    //this.setZoom(zoom);

    gantt.config.details_on_dblclick = false;
    if(!isDraw) {
      gantt.config.drag_links = false;
    }

    gantt.config.columns=[
      {name:"demoId",     label:"Id",align: "center",width:44},
      {name:"text",       label:"Task name",  tree: false, width:'*',align: "left"},
      {name:"start_date", label:"Start time", align: "center" },
      {name:"duration",   label:"Duration",   align: "center" },
      {name:"add",        label:"" }
    ];
    gantt.config.xml_date = '%Y-%m-%d';
    gantt.config.show_errors = false;
    gantt.plugins({ 
      tooltip: true 
    });

    gantt.templates.tooltip_text = (start,end,task) => {
      let renderText = `<div class="ghant-tooltip-holder" style='border: 1px solid ${task.backgroundColor}'>`;
      renderText+= "<p><b>Task:</b> "+task.text+"</p><br/>";
      if(task.due_date) {
        renderText+= "<p><b>Due Date:</b> "+task.due_date+"</p><br/>";
      } else {
        renderText+= "<p><b>Start Date:</b> "+task.read_start_date+"</p><br/>";
        renderText+= "<p><b>End Date:</b> "+task.calender_end_date+"</p><br/>";
        renderText+= "<p><b>Duration:</b> " + task.calender_duration + '</p><br/>';
      }
      if(task.contractorNames && task.contractorNames.length > 0) {
        let contractorList = task.contractorNames.map(name => {
          return name;
        });
        renderText+= "<p><b>Sub Contractors:</b> " + contractorList + '</p>';
      }
      renderText+= "</div>";
      return renderText;
    };
    gantt.templates.scale_cell_class = function(date){
      if(date.getDay() === 0){
        return "weekend";
      }
    };

    gantt.templates.timeline_cell_class = function(task,date){
      if(date.getDay() === 0){ 
        return "weekend";
      }
    };

    /*
    
    gantt.$doFilter = function(value){
      filterValue = value;
      console.log(filterValue)
      clearTimeout(delay);
      delay = setTimeout(function(){
        gantt.render();
        gantt.$root.querySelector("[data-text-filter]").focus();
      }, 200)
    }
    
    gantt.attachEvent("onBeforeTaskDisplay", function(id, task){
      if(!filterValue) return true;
   
       var normalizedText = task.text.toLowerCase();
       var normalizedValue = filterValue.toLowerCase();
       return normalizedText.indexOf(normalizedValue) > -1;
     });

    */

    gantt.init(this.ganttContainer);
    this.initGanttDataProcessor();
    this.initGanttOnclick();
    //gantt.config.readonly = true;
    gantt.parse(tasks);
  }

  componentWillUnmount() {
    gantt.clearAll();
    if (this.dataProcessor) {
      this.dataProcessor.destructor();
      this.dataProcessor = null;
    }
    if (this.onTaskClick) {
      gantt.detachEvent(this.onTaskClick);
      this.onTaskClick = null;
    }
  }

  render() {
    return (
      <div
        ref={ (input) => { this.ganttContainer = input } }
        style={ { width: '100%', height: '100%' } }
      ></div>
    );
  }
}
