import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading, usePermissions, useRedirect } from 'react-admin';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Container,
    Grid,
    Card,
    CardContent,
} from '@material-ui/core';
import Page from '../components/Page';
import RefererUpdate from './ContactUpdate';
import Updateview from './UpdateView';
function ContactList(props) {
    const { id,projectId } = props;
    const classes = useStyles();
    const redirect = useRedirect();
    const [contacts, setContacts] = useState();
    const [loading, setLoading] = useState(true);
    const dataProvider = useDataProvider();
    const history = useHistory();
    const { permissions } = usePermissions();

    useEffect(() => {
        dataProvider.getOneFloorPlan('contacts', { id: id })
            .then(({ data }) => {
                setContacts(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, []);
    const onDeleteClick = (id) => {
        setLoading(true);
        dataProvider.delete('contacts', { id: id })
            .then(({ data }) => {
                redirect(`/project-contacts/${projectId}`)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }

    const saveProjectContact = (data) => {
        setLoading(true)
        data = { ...data, id: id };
        dataProvider.updatefloorplan('contacts', data)
            .then(({ data }) => {
                setContacts(data);
                setLoading(false)
                // window.location.reload();
                // redirect(`/project-floorplan/${projectId}`);
            })
            .catch(error => {
                setLoading(false);
            });
    }
    if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;
    if (!contacts) return null;
    return (
        <Page
            className={classes.root}
            title={'Nexa Referer'}
        >
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <Box mt={3}>
                            <Card>
                                <CardContent>
                                    {/* <Typography className={classes.title}>Details</Typography> */}
                                    <Updateview contact={contacts} deleteFn={onDeleteClick} />
                                </CardContent>
                            </Card>
                            {/* <Pagination rowsPerPageOptions={[5, 10, 20, 40]} /> */}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <Box>
                            <RefererUpdate contact={contacts} save={saveProjectContact} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

const useStyles = makeStyles({
    tableContainer: {
        marginTop: 15,
        paddingBottom: 15,
        boxShadow: 'none',
        border: '1px solid #ebebeb',
        width: 'auto',
        marginLeft: 10,
    },
    heading: {
        padding: 10,
        '& h6': {
            fontSize: 14,
            paddingLeft: 5,
            fontWeight: 'bold',
            letterSpacing: 1,
            color: '#e58f0a',
            textTransform: 'uppercase',
        }
    },
    table: {
        minWidth: 650,
    },
    deleteButton: {
        border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
    }
});


export default ContactList;
