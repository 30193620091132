import React, { useEffect, useState } from 'react';
import {
  useDataProvider
}
  from 'react-admin';
import PropTypes from 'prop-types';
import { Tabs, AppBar, Box, Tab, Typography } from '@material-ui/core';
import TenderBasicDetails from './TenderBasicDetails';
import TenderOtherDetails from './TenderAdditionalDetails';
import ProjectBasicDetails from './ProjectTenderBasicDetails';
import TenderSiteCost from './ProjectTenderSitecost'
import TenderLuxurySpec from './ProjectTenderluxury'
import TenderAuthority from './ProjectTender_authority'
import TenderEstate from './ProjectTender_estate'
import Upgrade from '../HomeTenders/upgrades/upgrades_list'
import PdfView from '../HomeTenders/pdfview/Tender_list'
import Finalsubmission from './Tenderfinal'
import { useSelector } from 'react-redux';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs(props) {
  const { id, RevId } = props;
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [Prev_RevId, setPrev_RevId] = React.useState();
  const dataProvider = useDataProvider();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dataProvider.getList('final', { filter: { project: id }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      data.map((item, index) => {
        if (item.id === RevId && index !== 0) {
          setPrev_RevId(data[index - 1].id)
          return null;
        }
        else {
          return null;
        }
      })
      // console.log(data[data.length-1].id)
      // setPrev_RevId(data[data.length-2].id)
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  }, [])
  return (
    <div style={{}}>
      <Box sx={{ bgcolor: 'background.paper', width: open ? '82vw' : '92vw' }}>
        <AppBar position="static" >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            aria-label="full width tabs example"
            style={{ width:1120 }}
          >
            <Tab label="Revision Details" {...a11yProps(0)} />
            <Tab label="Tender Details" {...a11yProps(1)} />
            <Tab label="Basic Details" {...a11yProps(2)} />
            <Tab label="Site Cost" {...a11yProps(3)} />
            <Tab label="Authority Conditions" {...a11yProps(4)} />
            <Tab label="Estate inclusion" {...a11yProps(5)} />
            <Tab label="Upgrades" {...a11yProps(6)} />
            <Tab label="Luxury Specifications" {...a11yProps(7)} />
            {/* <Tab label="Council/authority fees and application requirement" {...a11yProps(7)} />
          <Tab label="PROCESS FLOW" {...a11yProps(8)} /> */}
            {/* <Tab label="COSTS" {...a11yProps(7)} /> */}
            <Tab label="Other Details" {...a11yProps(8)} />

            <Tab label="PDF VIEW" {...a11yProps(9)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} >
          <Finalsubmission projectId={id} RevId={RevId} />
        </TabPanel>
        <TabPanel value={value} index={1} >
          <TenderBasicDetails projectId={id} RevId={RevId} />
        </TabPanel>
        <TabPanel value={value} index={2} >
          <ProjectBasicDetails projectId={id} RevId={RevId} Prev_revId={Prev_RevId} />
        </TabPanel>
        <TabPanel value={value} index={3} >
          <TenderSiteCost projectId={id} RevId={RevId} Prev_revId={Prev_RevId} />
        </TabPanel>
        <TabPanel value={value} index={4} >
          <TenderAuthority projectId={id} RevId={RevId} Prev_revId={Prev_RevId} />
        </TabPanel>
        <TabPanel value={value} index={5} >
          <TenderEstate projectId={id} RevId={RevId} Prev_revId={Prev_RevId} />
        </TabPanel>
        <TabPanel value={value} index={6} >
          <Upgrade projectId={id} RevId={RevId} Prev_revId={Prev_RevId} />
        </TabPanel>
        <TabPanel value={value} index={7} >
          <TenderLuxurySpec projectId={id} RevId={RevId} Prev_revId={Prev_RevId} />
        </TabPanel>
        {/* <TabPanel value={value} index={7} >
        <Councilefees projectId={id} />
      </TabPanel>
      <TabPanel value={value} index={8} >
        <Process_Flow projectId={id} />
      </TabPanel> */}
        {/* <TabPanel value={value} index={7} >
        <TenderCost projectId={id} />
      </TabPanel> */}
        <TabPanel value={value} index={8} >
          <TenderOtherDetails projectId={id} />
        </TabPanel>

        <TabPanel value={value} index={9} >
          <PdfView projectId={id} RevId={RevId} Prev_revId={Prev_RevId} />
        </TabPanel>
      </Box>
    </div>
  );
}
