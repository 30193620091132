import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  Divider,
  DialogTitle,
  makeStyles,
  Grid,
} from '@material-ui/core';
// import { useNavigate } from 'react-router-dom';
import {
  SimpleForm,
  required,
  TextInput,
  email
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    justifyContent: 'space-between',
    margin: '0 12px',
  },
}));

export default function AddEmployee({
  setOpen, saveEmployee
}) {
  const classes = useStyles();
  return (
    <div>
      <DialogTitle id="assign-task-title">Add Employee</DialogTitle>
      <Divider />
      <DialogContent style={{ padding: 0 }} className={classes.root}>
        <SimpleForm
          save={saveEmployee}
          validate={validateUserCreation}
        >
          <Grid container spacing={1} fullWidth={true}>
            <Grid item xs={12} md={6}>
              <TextInput 
                source="first_name"
                label="First Name"
                fullWidth={true}
                variant="outlined"
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput 
                source="last_name"
                label="Last Name"
                fullWidth={true}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                source="email"
                label="Email"
                fullWidth={true}
                variant="outlined"
                validate={validateEmail}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                source="contact"
                label="Conatct No"
                fullWidth={true}
                variant="outlined"
                validate={required()}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextInput
                source="password"
                label="Password"
                fullWidth={true}
                variant="outlined"
                validate={required()}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </DialogContent>
    </div>
  );
}

const validateEmail = [required(),email()];

const validateUserCreation = async (values) => {
  const errors = {};
  try {
    if(values.email && values.email !== '') {
      const isEmailUnique = await checkEmailIsUnique(values.email);
      if(isEmailUnique && isEmailUnique.status) {

      } else {
        errors.email = ['Email is already registered Please use another one'];
      }
    }
    return errors
  } catch(err) {
    return errors
  }

};

const checkEmailIsUnique = async (email) => {

  const response = (await fetch('https://www.nexaplan.com.au:3000/auth/verify-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email : email})
  })).json();
  return response;
}


AddEmployee.propTypes = {
  // setType: PropTypes.func,
  setOpen: PropTypes.func,
};

