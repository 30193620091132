import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  AdminLogo: {
    width: 130,
    paddingTop: 1.5,
    paddingBottom: 1.5,
  }
});

export default function Logo() {
  const classes = useStyles();
  return(
    <Link to="/" replace >
      <img src={process.env.PUBLIC_URL + '/logo-small.png'} className={classes.AdminLogo} alt="logo" />
    </Link>
  );
}
