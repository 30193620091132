import * as React from "react";
import { Create, SimpleForm, TextInput, required, useRefresh } from 'react-admin';
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  main: {
    width: '100%',
  },
  title: {
    fontSize: 16,
    padding: '10px 10px 5px 0px',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 12,
    // padding: '0px 10px',
  }
});

const RefererCreate = (props) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const onSuccess = ({ data }) => {
    refresh();
  };
  return(
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <Typography className={classes.title}>Referer</Typography>
        <Typography className={classes.subTitle}>Add New</Typography>
        <Grid container spacing={1} className={classes.main}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="company"
              fullWidth={true}
              label="Company Name"
              variant="outlined"
              size="small"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="tradingName"
              fullWidth={true}
              label="Trading Name"
              variant="outlined"
              size="small"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="email"
              fullWidth={true}
              label="Email Address"
              validate={required()}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="person"
              validate={required()}
              fullWidth={true}
              label="Contact Person"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="contact"
              validate={required()}
              fullWidth={true}
              label="Contact Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="street"
              validate={required()}
              multiline
              fullWidth={true}
              label="Street"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="suburb"
              //validate={required()}
              fullWidth={true}
              label="Suburb"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="state"
              //validate={required()}
              fullWidth={true}
              label="State/Province/Region"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              source="zip"
              //validate={required()}
              fullWidth={true}
              label="Postal code"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              source="password"
              fullWidth={true}
              label="Password"
              validate={required()}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className={classes.title}>Bank Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="account_name"
              //validate={required()}
              fullWidth={true}
              label="Name in Account"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="bank_name"
              //validate={required()}
              fullWidth={true}
              label="Bank"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              source="accountNumber"
              //validate={required()}
              fullWidth={true}
              label="Account Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="bank_bsb"
              //validate={required()}
              fullWidth={true}
              label="BSB"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
}

export default RefererCreate;
