import React from 'react';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput } 
from 'react-admin';
import { InputAdornment,Grid,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) =>({
  main: {
    width: '100%',
  },
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color:'#e58f0a',
      textTransform: 'uppercase',
    }
  },
}));

function MaterialCreate(props) {
  const {save} = props;
  const classes = useStyles();
  const onSave = (data) => {
    save(data);
  };

  return (
    <SimpleForm save={onSave}>
      <SectionHeading label="Create Project Material" classProp={classes.heading}/>
      <ReferenceInput
        source="material"
        reference="materials"
        label="Add Material"
        fullWidth={true}
        validate={required()}
        variant="outlined"
        filter={{all:true}}
      >
        <SelectInput optionText="name" fullWidth={true} />
      </ReferenceInput>
      <ReferenceInput
        source="supplier"
        reference="suppliers"
        label="Add Supplier"
        fullWidth={true}
        validate={required()}
        variant="outlined"
        filter={{all:true}}
      >
        <SelectInput optionText="fullname" fullWidth={true} />
      </ReferenceInput>
      <DateInput
        source="purchase_date"
        label="Date of purchase"
        fullWidth={true}
        variant="outlined"
        validate={required()}
      />
      <GridField />
    </SimpleForm>
  )
}

const GridField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
      <NumberInput
        source="quantity"
        validate={required()}
        variant="outlined"
        label="Quantity"
        fullWidth={true}
      />
      </Grid>
      <Grid item xs={12} sm={6}>
      <NumberInput
        source="cost"
        validate={required()}
        variant="outlined"
        label="Cost"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              AUD
            </InputAdornment>
          ),
        }}
      />
      </Grid>
    </Grid>
  );
}


const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

export default MaterialCreate;
