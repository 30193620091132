import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper,Tab,Tabs,Grid,Button } from '@material-ui/core';
import {
  TextInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  required,
  number,
} from 'react-admin';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import NotesTab from './Notestab';

const useStyles = makeStyles((theme) =>({
  formIterator: {
    [theme.breakpoints.down('lg')]: {
      '& section': {
        display: 'inline-flex',
      }
    },
    [theme.breakpoints.down('md')]: {
      '& section': {
        display: 'block',
      }
    },
  },
  formIteratorGrid: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: '98%',
      width:300,
    },
    [theme.breakpoints.down('md')]: {
      
    },
  },
  removeButton: {
    border: '1px solid #F44336',
    color: '#F44336',
    '&:hover': {
      border: '1px solid #F44336',
    }
  },
  paper: {
    background: '#f3f3f3',
    marginTop:20,
    boxShadow:'none',
  },
  tab: {
    minWidth: 140,
    width: 140,
    background: '#f3f3f3',
    fontSize: 14,
    // textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#fff',
  },
}));

function TabField(props) {

  const [Tabvalue, setTabValue] = useState(0);
  const [predecessorCount, setPredecessorCount] = useState(0);

  const setPredCount = (count) => {
    setPredecessorCount(count);
  }

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };
  const classes = useStyles();

  return(
    <>
      <Paper square className={classes.paper}>
        <Tabs
          value={Tabvalue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          
        >
          <Tab label="Predecessor" classes={{ root: classes.tab,selected: classes.selectedTab }} />
          <Tab label="Notes" classes={{ root: classes.tab,selected: classes.selectedTab }} />
          <Tab label="Files" classes={{ root: classes.tab,selected: classes.selectedTab }} />
        </Tabs>
      </Paper>

      {(Tabvalue === 0) ? (
        <Grid container spacing={1}>
          <FormDataConsumer>
            {({ formData, ...rest}) => {
              if(formData.predecessor && formData.predecessor.length) {
                setPredCount(formData.predecessor.length);
              }
              return(
                <ArrayInput
                  source="predecessor"
                  label="" 
                  className={classes.formIterator}
                >
                  <SimpleFormIterator
                    addButton={<CustomAddButton count={predecessorCount} setCount={setPredCount} />}
                    removeButton={<PredRemoveButton count={predecessorCount} setCount={setPredCount} btnStyle={classes}/>}
                  >
                    <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                        return (
                          <Grid item xs={12} sm={6} className={classes.formIteratorGrid}>
                            <ReferenceInput
                              reference="tasks"
                              //source="tasks"
                              source={getSource('task')}
                              record={scopedFormData}
                              label="Task"
                              variant="outlined"
                              resource="tasks"
                              fullWidth={true}
                              validate={[required(),validatePredecessor]}
                              filter={{before: (props.record && props.record.sort) ? props.record.sort: 10000}}
                            >
                              <SelectInput optionText="title" fullWidth={true} />

                            </ReferenceInput>
                          </Grid>
                        )
                      }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                        return (
                          <Grid item xs={12} sm={3} className={classes.formIteratorGrid}>
                            <SelectInput
                              fullWidth={true}
                              variant="outlined"
                              //source="type"
                              source={getSource('type')}
                              validate={required()}
                              label="Type"
                              record={scopedFormData}
                              choices={[
                                { id: 'finish_to_start', name: 'Finish to start (FS)' },
                                { id: 'start_to_start', name: 'Start to start (SS)' },
                              ]}
                            
                            />
                          </Grid>
                        )
                      }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                        return (
                          <Grid item xs={12} sm={3} className={classes.formIteratorGrid}>
                            <TextInput
                              //source="lag"
                              source={getSource('lag')}
                              validate={[number(),required()]}
                              record={scopedFormData}
                              fullWidth={true}
                              variant="outlined" 
                              label="Lag (Days)"                      
                            />
                          </Grid>
                        )
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }}
          </FormDataConsumer>
        </Grid>

      ) : (
        null
      )}

      {(Tabvalue === 1) ? (
        <NotesTab />
      ): (
        null
      )}

      {(Tabvalue === 2) ? (
        <FileInput source="files" label="Related files" accept="">
          <FileField source="url" title="title" target="_blank" />
        </FileInput>
      ): (
        null
      )}
    </>
  )
}
// / multiple={true} download={true}

const CustomAddButton = (props)=> {
  const {count,className,onClick,setCount} = props;

  const onAddClick = () => {
    onClick();
    setCount(count + 1);
  }

  return (
    (count <= 3) ? (
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddClick}
        lassName={className}
        startIcon={<AddCircleOutlineIcon />}
      >
        Add
      </Button>
    ) : null
  )
}

const PredRemoveButton = (props) => {

  const onRemoveClick = () => {
    onClick();
    setCount(count-1);
  }
  const {count,className,onClick,setCount,btnStyle} = props;
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onRemoveClick}
      className={`${className} ${btnStyle.removeButton}`}
      startIcon={<RemoveCircleOutlineIcon />}
    >
      Remove

    </Button>
  )
}

const validatePredecessor = (value, allValues) => {
  
  if(allValues.predecessor && allValues.predecessor.length > 1) {
    let predecessorTasks = allValues.predecessor.map((previousPred) => {
      if(previousPred && previousPred!== undefined && previousPred.task) {
        return previousPred.task;
      }
    });
    let uniq = [...new Set(predecessorTasks)];
    if(uniq.length !== predecessorTasks.length) {
      return 'Cannot add same predecessor twice';;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export default TabField;
