import React from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  //useNotify,
  //useRefresh,
  useRedirect,
  SelectInput,
  PasswordInput,
  ImageField,
  ImageInput,
} from 'react-admin';
import {Grid, Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
var generator = require('generate-password');

const useStyles = makeStyles({
  main: {
    width: '100%',
  },
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  passwordTitle: {
    fontSize: 12,
    textTransform: 'capitalize',
    paddingBottom: 10,
    color: '#FF9800',
  },
  passwordButton: {
    margin: 10,
  }
});



const ContractorCreate = props => {
  //const notify = useNotify();
  //const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    // notify(`Changes to post "${data.title}" saved`)
    redirect('/contractors/create');
    //refresh();
  };
  const classes = useStyles();
  return(
    <Create onSuccess={onSuccess} {...props} >
      <SimpleForm>

        <Grid container spacing={1} direction="row" alignItems="center" className={classes.main}>
          <Grid item xs={12} sm={8}>
            <Grid container direction="row" alignItems="center" className={classes.heading}>
              <CreateIcon />
              <Typography variant="h6" gutterBottom>
                Create Sub Contractor
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* <ContractorImage /> */}
          </Grid>
        </Grid>
        
        <Grid container spacing={1} className={classes.main}>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="first_name"
              validate={required()}
              fullWidth={true}
              label="Contact Person"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="last_name"
              // validate={required()}
              fullWidth={true}
              label="Last Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              source="designation"
              //validate={required()}
              fullWidth={true}
              label="Designation"
              variant="outlined"
              choices={[
                { id: 'Privet Certifier', name: 'Privet Certifier' },
                { id: 'Engineer', name: 'Engineer' },
                { id: 'Plumber',name: 'Plumber'},
                { id: 'Level 2 Electration',name: 'Level 2 Electration'},
                { id: 'Private Certifiers',name: 'Private Certifiers'},
                { id: 'Frame',name: 'Frame'},
                { id: 'Concreter',name: 'Concreter'},
                { id: 'Pest control',name: 'Pest control'}
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="email"
              fullWidth={true}
              label="Email Address"
              validate={required()}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="company"
              fullWidth={true}
              label="Company Name"
              variant="outlined"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="contact"
              validate={required()}
              fullWidth={true}
              label="Contact Number"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="registerId"
              fullWidth={true}
              label="ABN"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="street"
              //validate={required()}
              multiline
              fullWidth={true}
              label="Street"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="suburb"
              //validate={required()}
              fullWidth={true}
              label="Suburb"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="state"
              //validate={required()}
              fullWidth={true}
              label="State/Province/Region"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="zip"
              //validate={required()}
              fullWidth={true}
              label="Postal code"
              variant="outlined"
            />
          </Grid>
          {/* <PasswordField classes ={classes} />
          <Grid item xs={12} >
            <SelectInput
              source="status"
              choices={[
                { id: 1, name: 'Enabled' },
                { id: 0, name: 'Disabled' },
              ]}
              defaultValue={1}
              fullWidth={true}
              variant="outlined"
            />
          </Grid> */}
        </Grid>
      </SimpleForm>
  </Create>
  );
}

const ContractorImage = () => {
  return (
    <ImageInput
      source="data.pictures"
      label=""
      accept="image/png, image/jpg, image/jpeg"
      maxSize={5000000}
      placeholder={
        <p>
          Upload Image
        </p>
      }
    >
      <ImageField source="src" title="images" />
    </ImageInput>
  )
};

const PasswordField = ({classes}) => {
  const form = useForm();
  const changePassword = () => {
    form.change('password', generator.generate(
      {
        length: 15,
        numbers: true
      }
    ));
  }
  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={9}>
        <PasswordInput
          fullWidth={true}
          source="password"
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button variant="outlined"
          color="primary" 
          className={classes.passwordButton}
          onClick={changePassword}
        >
          Generate Password
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContractorCreate;

