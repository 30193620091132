import React, {useEffect,useState} from 'react';
import { useDataProvider,useAuthProvider } from 'react-admin';
import ScheduleTask from './ScheduleTask';
import ScheduleTodo from './Scheduletodo';

function Schedules(props) {
  const { projectId,permission } = props;
  const [projectSchedules, setProjectSchedules] = useState();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();

  const handleComplete = (item,stat) => (event) => {
    if(stat === 'task') {

      let FIlteredlist = projectSchedules.tasks.map(task => 
        item.id === task.id 
        ? {...task, status:2} 
        : task
      );
      setProjectSchedules({...projectSchedules, tasks: FIlteredlist});

    } else {
      let FIlteredlist = projectSchedules.todos.map(todo => 
        item.id === todo.id 
        ? {...todo, status:2} 
        : todo
      );
      setProjectSchedules({...projectSchedules, todos: FIlteredlist});
    }
    let params = {id:item.id,stat: stat,projectid:projectId};
    if(permission && permission !== 'admin') {
      params['assigneeId'] = user.id;
    }
    dataProvider.updateprojectTaskStatus({data:params}).then(({ data }) => {
      setProjectSchedules(data);
    })
    .catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    let params = {id:projectId};
    if(permission && permission !== 'admin') {
      params['assigneeId'] = user.id;
    }
    dataProvider.getProjectSchedules(params).then(({ data }) => {
      //console.log(data);
      setProjectSchedules(data);
    })
    .catch(error => {
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!projectSchedules) return null;

  return(
    <>
     <ScheduleTask
        Schedules={projectSchedules}
        title="Today's Tasks"
        isComplete={true}
        onComplete = {handleComplete}
      />
      {(permission!=="contractor" && (<ScheduleTodo
        Schedules={projectSchedules}
        title="Todays's To Do's" 
        isComplete={true}
        onComplete = {handleComplete}
      />))}
    </>
  )
}

export default Schedules;
