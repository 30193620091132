import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, Typography, Grid, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput, useDataProvider, SelectInput, SimpleListLoading } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom'
import {
    FcGenealogy,

} from "react-icons/fc";
const useStyles = makeStyles((theme) => ({
    BtnHolder: {
        width: '100%',
        textAlign: 'center',
        padding: '10px 0px',
    },
    BtnSave: {
        backgroundColor: '#670e3c',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#670e3c',
            color: '#fff',
        }
    },
    Width400: {
        width: 400,
    },
    Width100_p: {
        width: '100%',
    },
    loader_holder: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    loader: { color: '#670e3c' },
    errorField: {
        color: '#f00',
        fontSize: '0.75rem',
    },
    actionBtnHolder: {
        marginTop: 20,
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    deleteButton: {
        border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15, padding: 5
    },
    saveBtn: {
        background: '#e5aa13',
        color: '#000',
        fontSize: 12,
        '&:hover': {
            background: '#e5aa13',
        }
    },
    saveBtnquest: {
        background: 'green',
        color: '#fff',
        fontSize: 12,
        '&:hover': {
            background: '#e5aa13',
        }
    },
    DeleteBtn: {
        background: 'transparent',
        color: '#ff0000a1',
        fontSize: 12,
        '&:hover': {
            background: '#ff000014',
        }
    },
    heading: {
        padding: 16,
        '& h6': {
            fontSize: 15,
            padding: 10,
            fontWeight: 'bold',
            color: '#e7b02c',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    editBtn: {
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        color: '#e5aa13'
    },
    modalContent: {
        '&::-webkit-scrollbar': {
            width: '0.5em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ddd',
            borderRadius: 15,
            outline: '1px solid #ddd'
        }
    }

}))
export default function EditCategory({ data, setRows, question_arr }) {
    const classes = useStyles();
    const history = useHistory();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [editQuestopen, setEditQuestopen] = useState(false);
    const [checkedval, setCheckedval] = useState(false);
    const [questions, setQuestions] = useState(question_arr || false);
    const [subcheck, setSubcheck] = useState([]);
    const [filterresult, setFilterresult] = useState(false);
    const [editvalues, setEditvalues] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const required = (value) => {
        if (value) {
            return undefined;
        } else {
            return '*Required';
        }
    }
    React.useEffect(() => {
        dataProvider.getList('subchecklist', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
            setSubcheck(data);
        })
            .catch(error => { });
    }, []);

    const updateCategory = (values) => {
        console.log(data.id);
        values['id'] = data.id;
        dataProvider.updateChecklist({ id: data.id, name: values.category_name }, 'checklistcategory').then(({ data }) => {
            console.log(data);
            setRows(data);
            handleClose();
        })
            .catch(error => { });
    }

    const addQuestions = (values) => {
        // values['remarkable'] = checkedval;
        values['checklistcategory'] = data.id;
        dataProvider.createSubchecklist(values, 'subchecklist').then(({ data }) => {
            setQuestions(prev => [...prev, data]);
            setConfirmOpen(false)
        }).catch(error => { });
    }



    const onEditClick = (item) => {
        setEditvalues(item)
        setEditQuestopen(true)
    }
    const onDeleteClick = (id) => {
        var newQuest = questions.filter(item => item._id !== id);
        setQuestions(newQuest);
        dataProvider.delete('subchecklist', { id: id }).then(({ data }) => {

        })
            .catch(error => { });
    }
    const editQuestions = (values) => {
        values['id'] = editvalues._id;
        values['category'] = data.id;
        dataProvider.updateSubchecklist({ id: values.id, question: values.question, checklistcategory: values.category, type: values.type }, 'subchecklist').then(({ data }) => {
            setQuestions(data);
            setEditQuestopen(false);
        })
            .catch(error => { });
    }
    const questModalopen = () => {
        setConfirmOpen(true)
    }
    const handleChange = (val) => {
        setCheckedval(val)
    }
    const handleClickOpen = () => {
        setFilterresult(subcheck.filter(item => item.checklistcategory._id == data.id))
        setOpen(true);
    };
    console.log(editvalues)


    return (
        <>
            <Button onClick={handleClickOpen} className={classes.editBtn}>
                <EditIcon style={{ fontSize: 17, marginRight: 2 }} />
                Edit</Button>
            <Dialog
                open={open}
                fullWidth={true}
                contentStyle={{
                    width: '100%',
                    maxWidth: 'none',
                }}
                maxWidth="md"
                onClose={handleClose}
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container direction="row" alignItems="center" >
                        <div className={classes.heading} >
                            <FcGenealogy />
                            <Typography variant="h6" gutterBottom>
                                Edit Category Details
                            </Typography>
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.modalContent}>
                    <Form
                        onSubmit={updateCategory}
                        initialValues={{
                            category_name: data?.name,
                        }}
                        render={({ handleSubmit, form }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Field name='category_name' validate={required}>
                                                {({ input, meta }) => (
                                                    <>
                                                        <TextInput
                                                            id="outlined-multiline-static"
                                                            label="Category Name"
                                                            style={{ width: '100%' }}
                                                            size="small"
                                                            {...input}
                                                            error={meta.error && meta.touched ? true : false}
                                                            variant="outlined" />
                                                    </>
                                                )}
                                            </Field>
                                        </Grid>
                                        {questions?.length > 0 &&
                                            questions.map((item, i) => {
                                                return (
                                                    <div key={i} style={{ width: '100%', marginBottom: 10, justifyContent: 'space-between', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                        <div style={{ width: '30%' }} >{i + 1} . {item?.question}</div>
                                                        <div style={{ textAlign: 'left' }} >{item?.type === 0 ? 'Pre Framing Inspection' : item?.type === 2 ? 'Pre Construction Inspection' : 'Final Inspection'}</div>
                                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                startIcon={<EditIcon />}
                                                                onClick={() => onEditClick(item)}
                                                            >
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                variant="outlined"
                                                                startIcon={<DeleteIcon />}
                                                                className={classes.deleteButton}
                                                                onClick={() => onDeleteClick(item._id, i)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        <div className={classes.actionBtnHolder}>
                                            <Button className={classes.saveBtnquest} onClick={() => questModalopen()}><AddIcon style={{ fontSize: 17, marginRight: 2 }} />question</Button>
                                            <Button className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                                        </div>
                                    </Grid>
                                </form>
                            )
                        }} />
                </DialogContent>
            </Dialog>
            <Dialog
                open={confirmOpen}
                fullWidth={true}
                maxWidth="sm"
                onClose={() => setConfirmOpen(false)}
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container direction="row" alignItems="center" >
                        <div className={classes.heading} >
                            <FcGenealogy />
                            <Typography variant="h6" gutterBottom>
                                Add Questions
                            </Typography>
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.modalContent}>
                    <Form
                        onSubmit={addQuestions}
                        render={({ handleSubmit, form }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Field name='question' validate={required}>
                                                {({ input, meta }) => (
                                                    <>
                                                        <TextInput
                                                            id="outlined-multiline-static"
                                                            label="Question"
                                                            style={{ width: '100%' }}
                                                            size="small"
                                                            {...input}
                                                            error={meta.error && meta.touched ? true : false}
                                                            variant="outlined" />
                                                    </>
                                                )}
                                            </Field>
                                        </Grid>
                                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => handleChange(e.target.checked)}
                                                        color="primary"
                                                        source={checkedval}
                                                        name="check"
                                                    />
                                                }
                                                label="Remarks"
                                            />
                                        </Grid> */}
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <SelectInput
                                                source="type"
                                                choices={[
                                                    { id: 2, name: 'Pre Construction Inspection' },
                                                    { id: 0, name: 'Pre Framing Inspection' },
                                                    { id: 1, name: 'Final Inspection' },
                                                ]}
                                                defaultValue={2}
                                                variant="outlined"
                                                fullWidth={true}
                                            />
                                        </Grid>
                                        <div className={classes.actionBtnHolder}>
                                            <Button className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                                        </div>
                                    </Grid>
                                </form>
                            )
                        }} />
                </DialogContent>
            </Dialog>
            <Dialog
                open={editQuestopen}
                fullWidth={true}
                maxWidth="xs"
                onClose={() => setEditQuestopen(false)}
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container direction="row" alignItems="center" >
                        <div className={classes.heading} >
                            <FcGenealogy />
                            <Typography variant="h6" gutterBottom>
                                Edit Questions
                            </Typography>
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.modalContent}>
                    <Form
                        onSubmit={editQuestions}
                        initialValues={{
                            question: editvalues?.question,
                            type: editvalues.type,
                            remarkable: editvalues.remarkable
                        }}
                        render={({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={1}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Field name='question' validate={required}>
                                                {({ input, meta }) => (
                                                    <>
                                                        <TextInput
                                                            id="outlined-multiline-static"
                                                            label="Question"
                                                            style={{ width: '100%' }}
                                                            size="small"
                                                            {...input}
                                                            error={meta.error && meta.touched ? true : false}
                                                            variant="outlined"
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Grid>
                                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked={editvalues?.remarkable ? true : false}
                                                        onChange={(e) => handleChange(e.target.checked)}
                                                        color="primary"
                                                        name="check"
                                                    />
                                                }
                                                label="Remarks"
                                            />
                                        </Grid> */}
                                        <SelectInput
                                            source="type"
                                            choices={[
                                                { id: 2, name: 'Pre Construction Inspection' },
                                                { id: 0, name: 'Pre Framing Inspection' },
                                                { id: 1, name: 'Final Inspection' },
                                            ]}
                                            variant="outlined"
                                            fullWidth={true}
                                        />
                                        <div className={classes.actionBtnHolder}>
                                            <Button className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                                        </div>
                                    </Grid>
                                </form>
                            )
                        }} />
                </DialogContent>
            </Dialog>
        </>
    );
}