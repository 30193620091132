import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  useNotify, useRefresh, useRedirect

} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { Grid, Typography,InputAdornment } from '@material-ui/core';


const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const ToDoEdit = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    console.log(data);
    notify(`Changes to ${data.title} updated`);
    redirect('/todos');
    refresh();
  };

  return (
    <Edit onSuccess={onSuccess} undoable={false} {...props}>
      <SimpleForm>
        <SectionHeading label="Edit Todo" classes={classes} />
        <FormFields classes={classes}/>
      </SimpleForm>
    </Edit>
)};


const FormFields = (props) => {
  return(
    <>
      <TextInput
        source="title"
        fullWidth={true}
        label="Title"
        variant="outlined"
        validate={required()}
      />
      <Grid container spacing={1}>
        <PriorityField />
        <TaskField />
        <DueField />
        <StatusField />
      </Grid>
    </>
  )
}

const PriorityField = (props) => {

  return(
    <>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="notes"
          fullWidth={true}
          label="Notes"
          variant="outlined"
          multiline
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="priority"
          choices={[
            { id: 'none', name: 'None' },
            { id: 'low', name: 'Low' },
            { id: 'high', name: 'High' },
            { id: 'highest', name: 'Highest' },
          ]}
          variant="outlined"
          validate={required()}
          fullWidth={true}
        />
      </Grid>
    </>
  )
}

const TaskField = (props) => {
  return(
    <Grid item xs={12}>
      <ReferenceInput
        source="task"
        reference="tasks"
        fullWidth={true}
        label="Schedule Item"
        variant="outlined"
        validate={required()}
        filter={{all:true}}
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
    </Grid>
  )
}

const DueField = (props) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="due"
          fullWidth={true}
          label="Due"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                days
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RadioButtonGroupInput
          source="duestatus"
          choices={[
            { id: 'before', name: 'Before' },
            { id: 'after', name: 'After' },
          ]}
          fullWidth={true}
          label=""
        />
      </Grid>
    </>
  )
}


const StatusField = (props) => {
  return(
    <>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="reminder"
          choices={[
            { id: 1, name: '1 hrs' },
            { id: 2, name: '2 hrs' },
            { id: 4, name: '4 hrs' },
            { id: 16, name: '16 hrs' },
            { id: 24, name: '1 day' },
            { id: 48, name: '2 day' },
            { id: 72, name: '3 day' }
          ]}
          validate={required()}
          fullWidth={true}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="status"
          choices={[
            { id: 1, name: 'Enabled' },
            { id: 0, name: 'Disabled' }           
          ]}
          validate={required()}
          fullWidth={true}
          variant="outlined"
        />
      </Grid>
    </>
  )
}


export default ToDoEdit;