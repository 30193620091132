import React from 'react';
import PropTypes from 'prop-types';
import AddEmployee from './AddEmployee';
import EditEmployee from './EditEmployee';
import DeleteEmployee from './DeleteEmployee';

const Content = ({
  open, type, saveEmployee, setOpen, employee, editEmployee, deleteEmployee
}) => {
  switch (type) {
    case 'add_employee': // file_upload
      return (
        <AddEmployee saveEmployee={saveEmployee} setOpen={setOpen} />
      );
    case 'edit_employee': // file_upload
      return (
        <EditEmployee editEmployee={editEmployee} setOpen={setOpen} employee={employee} />
      );
    case 'delete_employee': // file_upload
      return (
        <DeleteEmployee deleteEmployee={deleteEmployee} employee={employee} setOpen={setOpen}/>
      );
    default:
      return <></>;
  }
};

Content.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  setType: PropTypes.func,
  setOpen: PropTypes.func,
  employee: PropTypes.object,
};

export default Content;
