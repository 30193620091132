import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessDialog({open,handleClose}) {

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-slide-title">{"Your form has been submitted successfully."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please download Nexa Apps from app stores. You can use the same username and password to login to the app.
            Within 24-48 hours, we will book you an appointment through the app.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <a href="https://play.google.com/store/apps/details?id=com.nexabuilders" target="_blank">
            <img style={{borderRadius: 8}} src={process.env.PUBLIC_URL + '/playstore.png'} alt="download from playstore"/>
          </a>
          <a href="https://apps.apple.com/in/app/nexa-homes/id1551096247" target="_blank">
            <img style={{borderRadius: 8}} src={process.env.PUBLIC_URL + '/appstore.png'} alt="download from appstore "/>
          </a>
        </DialogActions>
      </Dialog>
    </div>
  );
}
