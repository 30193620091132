import React, {useEffect, useState }from 'react';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  useDataProvider,
  useRedirect,
  Loading
} 
from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { InputAdornment,Typography,Grid } from '@material-ui/core';


function MaterialEdit({id}) {
  const [ materialDetail, setMaterialDetail] = useState();
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const classes = useStyles();
  const onSave = (data) => {
    data = {...data, projectId:materialDetail.project};
    dataProvider.update('projectmaterials',{data,id:id})
    .then(({ data }) => {
      redirect(`/project-materials/${materialDetail.project}`);
    })
    .catch(error => {
    });
  }

  useEffect(() => {
    dataProvider.getOne('projectmaterials',{ id: id })
      .then(({ data }) => {
        setMaterialDetail(data);
        setLoading(false);
      })
      .catch(error => {
        // console.log(error);
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;
  if(!materialDetail) return null;

  return(
    <SimpleForm save={onSave}>
       <SectionHeading label="Edit Material" classProp={classes.heading}/>
      <ReferenceInput
        source="material"
        reference="materials"
        label="Add Material"
        fullWidth={true}
        validate={required()}
        variant="outlined"
        filter={{all:true}}
        initialValue={materialDetail.material}
      >
        <SelectInput optionText="name" fullWidth={true} />
      </ReferenceInput>
      <ReferenceInput
        source="supplier"
        reference="suppliers"
        label="Add Supplier"
        fullWidth={true}
        validate={required()}
        variant="outlined"
        filter={{all:true}}
        initialValue={materialDetail.supplier}
      >
        <SelectInput optionText="fullname" fullWidth={true} />
      </ReferenceInput>
      <DateInput
        source="purchase_date"
        label="Date of purchase"
        fullWidth={true}
        variant="outlined"
        validate={required()}
        initialValue={materialDetail.puchase_date}
      />
      <NumberInput
        source="quantity"
        validate={required()}
        variant="outlined"
        label="Quantity"
        fullWidth={true}
        initialValue={materialDetail.quantity}
      />
      <NumberInput
        source="cost"
        validate={required()}
        variant="outlined"
        label="Cost"
        fullWidth={true}
        initialValue={materialDetail.cost}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              AUD
            </InputAdornment>
          ),
        }}
      />
    </SimpleForm>
  )
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles({
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color:'#e58f0a',
      textTransform: 'uppercase',
    }
  },
});

export default MaterialEdit;
