import * as React from "react";

import {
  Edit, SimpleForm,TextInput,SelectInput,DateInput,ImageInput,ImageField,ArrayInput, SimpleFormIterator,
  useNotify, useRefresh, useRedirect,required
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { Grid,Typography } from '@material-ui/core';
import { ColorInput } from 'react-admin-color-input';

const useStyles = makeStyles({
  heading: {
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
});

const SectionHeading = ({ label, classes }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classes.heading}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const PopupEdit = props => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Changes to Popup updated`);
    redirect('/app-popups');
    refresh();
  };

  return (
    <Edit onSuccess={onSuccess}  undoable={false} {...props}>
      <SimpleForm>
      <SectionHeading label="Edit Popup" classes={classes} />
      <Grid container spacing={1} fullWidth={true}>
        <Grid item xs={12} sm={6}>
          <SelectInput
            source="projectPhase"
            label="Project Type"
            choices={[
              { id: 'construction', name: 'Construction' },
              { id: 'pre_contract', name: 'Pre Contract' },
              { id: 'design_approval', name: 'Design Approval' },
            ]}
            variant="outlined"
            fullWidth={true}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput
            source="user_type"
            label="User Type"
            choices={[
              { id: 'client', name: 'Client' },
              // { id: 'contractor', name: 'Contractor' },
              // { id: 'manager', name: 'Manager' },
            ]}
            defaultValue={'client'}
            variant="outlined"
            fullWidth={true}
            validate={required()}
          />
        </Grid>
      </Grid>
      <SpanField />
      <Grid container spacing={1} fullWidth={true}>
        <Grid item xs={12} sm={6}>
          <ImageInput
            source="popupImage"
            label="Ad Image"
            accept="image/*"
            placeholder={<p>Upload Ad Image Here</p>}
            validate={required()}
          >
            <ImageField source="url" title="name" />
          </ImageInput>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            source="buttonText"
            label="button Label"
            fullWidth={true}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <ColorField classes={classes}/>
      <ArrayInput source="terms">
        <SimpleFormIterator>
          <TextInput
            source="content"
            label="Term Content"
            fullWidth={true}
            variant="outlined"
            multiline={true}
            validate={required()}
            no
          />
        </SimpleFormIterator>
      </ArrayInput>
      </SimpleForm>
    </Edit>
)};

const ColorField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <ColorInput
          source="buttonbackground"
          fullWidth={true}
          variant="outlined"
          //picker="Sketch"
          label="button background Color"
          className={props.classes.colorInput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ColorInput
          source="buttonLabelColor"
          fullWidth={true}
          variant="outlined"
          //picker="Sketch"
          label="button Label Color"
          className={props.classes.colorInput}
        />
      </Grid>
    </Grid>
  )
}
const SpanField = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <DateInput
          source="start_date"
          label="Ad Start Date"
          fullWidth={true}
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          source="end_date"
          label="Ad End Date"
          //disabled
          fullWidth={true}
          variant="outlined"
          validate={required()}
        />
      </Grid>
    </Grid>
  );
};


export default PopupEdit;