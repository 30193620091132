import * as React from 'react';
import { Fragment } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme=>({
  menuItem: {
    color: '#dadbe6',
    // paddingTop: 15,
    fontSize: 13,
    '&:hover': {
      background:' rgba(50, 56, 80,0.7)',
      //background: '#f0be40',
      // color: '#000',
      // '& svg': {
      //   color: '#000',
      // },
    },
  },
  icon: { minWidth: theme.spacing(5), color: '#dadbe6',},
  sidebarIsOpen: {
    '& a': {
        paddingLeft: theme.spacing(4),
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a': {
        paddingLeft: theme.spacing(2),
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
}));


  function SubMenu(props) {
    const classes = useStyles();

    const header = (
      <MenuItem dense={props.dense} button onClick={props.handleToggle} className={classes.menuItem}>
        <ListItemIcon className={classes.icon} >
          {props.isOpen ? <ExpandMore /> : props.icon}
        </ListItemIcon>
        <Typography variant="inherit">
          {props.name}
        </Typography>
      </MenuItem>
    );

    return (
      <Fragment>
        {props.sidebarIsOpen || props.isOpen ? (
            header
        ) : (
            <Tooltip title={props.name} placement="right">
                {header}
            </Tooltip>
        )}
        <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
          <List
            dense={props.dense}
            component="div"
            disablePadding
            className={
              props.sidebarIsOpen
                  ? classes.sidebarIsOpen
                  : classes.sidebarIsClosed
            }
          >
            {props.children}
          </List>
        </Collapse>
      </Fragment>
    );
};

export default SubMenu;