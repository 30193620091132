import React, {useState,useEffect} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Loading,useDataProvider,Error,EditButton } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    sorting: {
      zIndex: theme.zIndex.modal + 100,
      border: '1px solid #d6d6d6',
      backgroundColor: '#f3f3f3',
      '& button': {
        display:'none',
      }
    },
  })
);

const SortableItem = SortableElement(({value,open}) => (
  <TableRow tabIndex={0}>
    <TableCell component="th" scope="row">
      {value.title}
    </TableCell>
    <TableCell >{value.days}</TableCell>
    <TableCell component="th" scope="row" align="center">
      {/* <Button
        variant="outlined"
        color="primary"
        startIcon={<EditIcon />}
        component={Link} to={`/tasks/${value.id}/`}
      >
        Edit
      </Button> */}
      <EditButton basePath="/tasks" record={value} />
    </TableCell>
    <TableCell component="th" scope="row" align="center">
      <Button
        onClick={() => {open(value) }}
        variant="contained"
        color="primary"
        size="small"
      >
      Assign Sub Contractor
      </Button>
    </TableCell>
    
  </TableRow>
));

const SortableList = SortableContainer(({items,open}) => {
  // console.log(items);
  return (
    <TableContainer component={Paper} style={{margin: '0 10px'}}>
    <Table >
      <TableHead>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>Days</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} data={value} open={open} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
});

function TaskList({openContractorModal}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [items, setItems] = useState([]);
  const dataProvider = useDataProvider();
  const classes = useStyles();

  useEffect(() => {
    dataProvider.getList('tasks',
      {
        pagination: {},
        sort: { field: 'sort',order: 'asc' },
        filter: {}

      }).then(({ data }) => { 
        setItems(data);
        setLoading(false);          
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSortEnd = ({oldIndex, newIndex}) => {
    let sortedItems = arrayMove(items, oldIndex, newIndex);
    setItems(sortedItems);
    dataProvider.updatetasksort({data:sortedItems}).then(({ data }) => {
    })
    .catch(error => {
    });
  };

  if (loading) return <Loading loadingPrimary="Loading Tasks" loadingSecondary="" />;
  // if (error) return <Error />;
  if (!items) return null;

  return (
    <SortableList
      distance={2}
      items={items}
      onSortEnd={onSortEnd}
      helperClass={classes.sorting}
      open={openContractorModal}
    />
  );
}

export default TaskList;

