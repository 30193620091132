import React, { useState } from 'react';
import {
  // Create,
  TextInput,
  DateInput,
  SimpleForm,
  required,
  Loading,
  useDataProvider,
  showNotification,
  useRedirect,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  ImageInput,
  ImageField

} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Modal, Button, Card, CardContent, InputAdornment } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import AddCustomer from './AddCustomer';
import SecondaryCustomer from './SecondaryCustomer';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 18,
      paddingLeft: 5,
      // fontWeight: 'bold',
      color: '#e58f0a',
      textTransform: 'capitalize',
    }
  },
  title: {
    fontSize: 16,
    textTransform: 'capitalize',
    color: '#3e2604',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '100%',
    display: 'block'
  },
  addButton: {
    background: '#363957',
    color: '#fff',
    '&:hover': {
      background: '#484b5f',
    },
    marginBottom: 20,
  },
  hidden: {
    display: 'none',
  },
  card: {
    margin: '10px 25px',
    position: 'relative',
  },
  cardText: {
    fontSize: 14,
    color: '#525457',
  },
  clientTab: {
    border: '1px dashed #ddd'
  },
  clientTabHolder: {
    width: '100%',
    margin: '20px 0px'

  },

}));

const ProjectCreate = props => {
  const [clientDetails, setClientDetails] = useState({});
  const [secondaryClientDetails, setSecondaryClientDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [secondOpen, setSecondopen] = React.useState();
  const proposedRef = React.useRef(null);
  const [proposedAddress, setProposedAddress] = React.useState();
  const dataProvider = useDataProvider();
  const { showNotification } = props;
  const handleSecondaryOpen = () => {
    setSecondopen(true);
  };

  const handleSecondaryClose = () => {
    setSecondopen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const handleClientInfo = (client) => {
    setClientDetails(client);
  };
  const redirect = useRedirect();

  let propsedAddressAuto;
  React.useEffect(() => {
    hadleInitialLoad(setProposedAddress, proposedRef);
  }, []);
  function hadleInitialLoad(updatePropseAddress, proposedRef) {
    propsedAddressAuto = new window.google.maps.places.Autocomplete(
      proposedRef.current,
      { types: ["address"], componentRestrictions: { country: "aus" } }
    );
    propsedAddressAuto.setFields(["address_components", "formatted_address"]);
    propsedAddressAuto.addListener("place_changed", () =>
      handleAddressSelect(updatePropseAddress)
    );
  }

  function handleAddressSelect(updatePropseAddress) {
    const addressObject = propsedAddressAuto.getPlace();
    const s = addressObject.formatted_address;
    updatePropseAddress(s);
    setProposedAddress(s);
  }
  const createProject = (data) => {
    data.map_address = proposedAddress;
    if (Object.keys(clientDetails).length === 0) {
      showNotification('Please Add Client Details', 'warning');
    } else {
      setLoading(true);
      data = { ...data, client: clientDetails, secondary_clients: secondaryClientDetails };
      dataProvider.createproject('projects', data).then(({ response }) => {
        setLoading(false);
        showNotification(`Project Created`, 'info');
        redirect('/projects');
      })
        .catch(error => {
          setLoading(false);
          showNotification(`${error.message}`, 'warning');
        })
    }
  };

  if (loading) return <Loading loadingPrimary="Creating Project..." loadingSecondary="" />;

  return (
    <SimpleForm save={createProject}>
      <SectionHeading label="create project" classProp={classes.heading} />
      <SectionTitle label="general details" classProp={classes.title} />
      <TextInput source="title" fullWidth={true} variant="outlined" validate={required()} />
      <TextInput source="description" fullWidth={true} variant="outlined" multiline />
      <SpanField {...props} />
      <SectionTitle label="proposed location address" classProp={classes.title} />
      <AddressField
        {...props}
        proposedRef={proposedRef}
        setProposedAddress={setProposedAddress}
        proposedAddress={proposedAddress}
      />
      <Grid container spacing={1} className={classes.clientTabHolder}>
        <Grid item xs={12} md={6} justifyContent='center' className={classes.clientTab}>
          <ClientSectionTitle label="Primary client details" classProp={classes.title} />
          <AddClient
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            classProp={classes.modalStyle}
            buttonStyle={classes.addButton}
            clientDetails={clientDetails}
            setClientDetails={handleClientInfo}
            cardStyle={classes.card}
            textStyle={classes.cardText}
          />
        </Grid>
        <Grid item xs={12} md={6} direction='column' justifyContent='center' className={classes.clientTab}>
          <ClientSectionTitle label="Secondary Clients Details" classProp={classes.title} />
          <SecondaryClinet
            classProp={classes.modalStyle}
            buttonStyle={classes.addButton}
            secondOpen={secondOpen}
            setSecondopen={setSecondopen}
            handleSecondaryOpen={handleSecondaryOpen}
            handleSecondaryClose={handleSecondaryClose}
            secondaryClientDetails={secondaryClientDetails}
            setSecondaryClientDetails={setSecondaryClientDetails}
            cardStyle={classes.card}
            textStyle={classes.cardText}
          />
        </Grid>
      </Grid>
      <SectionTitle label="Estimate" classProp={classes.title} />
      <EstimateField {...props} />
      <ReferenceInput
        source="template"
        reference="templates"
        label="Template"
        fullWidth={true}
        validate={required()}
        variant="outlined"
        filter={{ all: true, status: 1 }}
      >
        <SelectInput optionText="name" fullWidth={true} />
      </ReferenceInput>
      <ManagerField />
      <ReferenceArrayInput
        reference="contractors"
        source="contractors"
        label="Sub Contractors"
        fullWidth={true}
        variant="outlined"
        resource="contractors"
        filter={{ all: true, status: 1 }}
      >
        <SelectArrayInput optionText="fullname" optionValue="id" options={{ fullWidth: true }}>
          <ChipField />
        </SelectArrayInput>
      </ReferenceArrayInput>
    </SimpleForm>
  );
}

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const SpanField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <ImageInput
          source="facade"
          label="Facade"
          accept="image/*"
          placeholder={<p>Upload Project Facade</p>}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* <DateInput
          source="end_date"
          label="Estimated Date To Finish"
          fullWidth={true}
          variant="outlined"
          validate={required()}
        /> */}
        <DateInput
          source="start_date"
          label="Start Date"
          fullWidth={true}
          variant="outlined"
          validate={required()}
        />
      </Grid>
    </Grid>
  );
};

const ManagerField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <ReferenceArrayInput
          reference="managers"
          source="managers"
          label="Project Managers"
          fullWidth={true}
          variant="outlined"
          resource="managers"
          validate={required()}
          filter={{ all: true, status: 1 }}
        >
          <SelectArrayInput optionText="fullname" optionValue="id" options={{ fullWidth: true }}>
            <ChipField />
          </SelectArrayInput>
        </ReferenceArrayInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="project_type"
          label="Project Type"
          choices={[
            { id: 'construction', name: 'Construction' },
            { id: 'pre_contract', name: 'Pre Contract' },
            { id: 'design_approval', name: 'Design Approval' },
          ]}
          defaultValue={'construction'}
          variant="outlined"
          fullWidth={true}
        />
      </Grid>
    </Grid>
  );
};

const SectionTitle = ({ label, classProp }) => {
  return (
    <Typography variant="h6" gutterBottom className={classProp}>
      {label}
    </Typography>
  );
};
const ClientSectionTitle = ({ label, classProp }) => {
  const style = {
    width: '100%',
    textAlign: 'center',
    background: '#00000047',
    color: '#fff'
  }
  return (
    <div style={style}>
      <Typography variant="h6" style={{ color: '#fff' }} gutterBottom className={classProp}>
        {label}
      </Typography>
    </div>
  );
};
const AddressField = (props) => {
  const { proposedRef, setProposedAddress, proposedAddress } = props;
  return (
    <>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <TextInput
            source="street"
            fullWidth={true}
            variant="outlined"
            label="Street"
            multiline
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            source="suburb"
            label="Suburb"
            fullWidth={true}
            variant="outlined"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            source="zip"
            fullWidth={true}
            label="Postal Code"
            variant="outlined"
            validate={required()}
          />
        </Grid>
      </Grid> */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TextInput
            value={proposedAddress || null}
            source="map_address"
            fullWidth={true}
            variant="outlined"
            label="Proposed location Address"
            placeholder=""
            multiline
            inputRef={proposedRef}
            onChange={(e) => {
              setProposedAddress(e.target.value);
            }}
            validate={required()}
          />
        </Grid>
      </Grid>
    </>
  );
};
const cardAction = {
  main: {
    postion: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: 5,
    top: 5,
    position: 'absolute',
  },
  Edit: {
    margin: 5,
    fontSize: 20,
    color: '#0074ff',
    cursor: 'pointer'
  },
  delete: {
    margin: 5,
    fontSize: 20,
    color: '#f00',
    cursor: 'pointer'
  },
}


const AddClient = ({
  handleOpen,
  open,
  handleClose,
  classProp,
  buttonStyle,
  clientDetails,
  setClientDetails,
  cardStyle,
  textStyle,
}) => {
  const save = (client) => {
    setClientDetails(client);
    handleClose();
  }
  const tabStyle = {
    textAlign: 'center',
    marginTop: 35,
  }

  return (
    <>
      {(Object.keys(clientDetails).length !== 0) && (
        <Card className={cardStyle}>
          <CardContent>
            <Typography className={textStyle} gutterBottom>
              {`Name: ${clientDetails.first_name} ${clientDetails.last_name}`}
            </Typography>
            <Typography className={textStyle} gutterBottom>
              {`Email: ${clientDetails.email}`}
            </Typography>
            <Typography className={textStyle} gutterBottom>
              {`Contact: ${clientDetails.contact}`}
            </Typography>
            {clientDetails.map_address ?
              <Typography className={textStyle} gutterBottom>
                {`Address: ${clientDetails.map_address}`}
              </Typography>
              :
              <Typography className={textStyle} gutterBottom>
                {`Address: ${clientDetails.street}  ${clientDetails.suburb}  ${clientDetails.zip}`}
              </Typography>
            }

          </CardContent>
        </Card>
      )}
      <div style={tabStyle}>
        <Button size="small" variant="contained" onClick={handleOpen} className={buttonStyle}>
          {(Object.keys(clientDetails).length === 0) ? 'Add Client' : 'Edit Client'}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          className={classProp}
        >
          <AddCustomer save={save} record={clientDetails} />
        </Modal>
      </div>
    </>
  );
};
const SecondaryClinet = ({
  classProp,
  buttonStyle,
  secondaryClientDetails,
  setSecondaryClientDetails,
  secondOpen,
  setSecondopen,
  handleSecondaryClose,
  handleSecondaryOpen,
  cardStyle,
  textStyle,
}) => {
  const [editOpen, setEditOpen] = React.useState({ open: false, data: {}, key: 0 });
  const saveSecondaryClient = (client) => {
    setSecondaryClientDetails(prev => [...prev, client]);
    handleSecondaryClose();
  }
  const editSecondaryClient = (client) => {
    let data  = [...secondaryClientDetails];
    data[editOpen.key] = client;
    console.log(data);
    setSecondaryClientDetails(data);
    handleEditClose();
  }
  const remove = (index) => {
    const details = secondaryClientDetails.filter((item, i) => i !== index);
    setSecondaryClientDetails(details);
  }
  const handleEditClose = () => {
    setEditOpen({ open: false, data: {}, key: 0 })
  }

  const tabStyle = {
    textAlign: 'center',
    marginTop: 35,
  }
  return (
    <>
      {secondaryClientDetails?.length > 0 && (
        <>
          {secondaryClientDetails?.map((data, index) => (
            <Card className={cardStyle}>
              <CardContent>
                <Typography className={textStyle} gutterBottom>
                  {`Name: ${data.first_name} ${data.last_name}`}
                </Typography>
                <Typography className={textStyle} gutterBottom>
                  {`Email: ${data.email}`}
                </Typography>
                <Typography className={textStyle} gutterBottom>
                  {`Contact: ${data.contact}`}
                </Typography>
                {data.map_address ?
                  <Typography className={textStyle} gutterBottom>
                    {`Address: ${data.map_address}`}
                  </Typography>
                  :
                  <Typography className={textStyle} gutterBottom>
                    {`Address: ${data.street}  ${data.suburb}  ${data.zip}`}
                  </Typography>
                }
              </CardContent>
              <div style={cardAction.main}>
                <EditIcon style={cardAction.Edit} onClick={() => setEditOpen({ open: true, data: data, key: index })} />
                <DeleteIcon style={cardAction.delete} onClick={() => remove(index)} />
                <Modal
                  open={editOpen.open}
                  onClose={handleEditClose}
                  className={classProp}
                >
                  <SecondaryCustomer save={editSecondaryClient} record={editOpen.data} key={index} />
                </Modal>
              </div>
            </Card>
          ))}
        </>
      )}
      <div style={tabStyle}>
        <Button size="small" variant="contained" onClick={handleSecondaryOpen} className={buttonStyle}>
          Add Client
        </Button>
        <Modal
          open={secondOpen}
          onClose={handleSecondaryClose}
          className={classProp}
        >
          <SecondaryCustomer save={saveSecondaryClient} record={{}} />
        </Modal>
      </div>
    </>
  );
};
const EstimateField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="area"
          validate={required()}
          variant="outlined"
          fullWidth={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                square metre
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          source="amount"
          validate={required()}
          variant="outlined"
          fullWidth={true}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                $
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

// export default ProjectCreate;
export default connect(null, {
  showNotification
})(ProjectCreate);