import React from 'react';
import { ListBase, TopToolbar, CreateButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import { Modal,Grid,Typography } from '@material-ui/core';
import ContractorSelection from './ContractorSelect';
import TaskList from './SortableList';

const useStyles = makeStyles({
  heading: {
    padding: '0 16px',
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  assignButton: {
    // float:'right',
    marginRight: 25,
    color: '#fff',
    background: '#c68e00',
  },
  updateButton: {
    marginRight: 15,
  }
});

// const CustomChipField = ({record}) => {
//   let name = (record && record['firstname']) ? `${record['firstname']} ${record['lastname']}` : '';
//   return(
//     <Chip label={name} />
//   );
// }

function TasksList(props){
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRecord, setSelectedrecord] = React.useState([]);
  const handleOpen = (record) => {
    setModalOpen(true);
    setSelectedrecord(record);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedrecord([]);
  };
  const classes = useStyles();
  return (
    <>
      <ListActions classProp={classes.heading} btnStyle={classes.updateButton} />
      <ListBase {...props} >
        <TaskList openContractorModal={handleOpen} /> 
      </ListBase>  
      <Modal
        open={modalOpen}
        onClose={handleClose}
      >
        <ContractorSelection record={selectedRecord} onCloseModal={handleClose} />
      </Modal>
    </>
  )
};
const ListActions = ({classProp,btnStyle}) => (
  <TopToolbar>
      <SectionHeading label="Project Tasks" classProp={classProp} />
      {/* <Button
        variant="outlined"
        color="primary"
        className={btnStyle}
      >
        Update
      </Button> */}
      <CreateButton basePath="/tasks" />
  </TopToolbar>
);

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <ListIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

export default TasksList;

/*
<List {...props} sort={{ field: 'title', order: 'ASC' }} actions={false}>
  <Datagrid>
    <TextField source="title" />
    {/* <TextField source="date" /> }
    <TextField source="days" label="Days"/>
    {/* <ReferenceManyField
      label="Assigned Contractors"
      target="id"
      source="contractors"
      reference="contractors"
    >
      <SingleFieldList linkType={false}>
        <CustomChipField source="firstname"/>
      </SingleFieldList>
    </ReferenceManyField> }
    <ButttonField classes ={classes} openModal= {handleOpen}/>
    <EditButton source="Action" label="Edit"/>
  </Datagrid>
</List>
*/