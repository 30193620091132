import * as React from "react";
import { useDataProvider, Loading, usePermissions } from 'react-admin';
import { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import CategoryIcon from '@material-ui/icons/Category';
import {
  FcGenealogy,
  FcMindMap
} from "react-icons/fc";
function ProjectEdit(props) {
  const [project, setProject] = useState();
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loading) setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (loading) return <Loading />;

  return (
    <>
      <Box
        style={{ paddingTop: 20 }}
        display="flex"
        flexWrap="wrap"
        bgcolor="background.paper"
      >

        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/luxury-main`} className={classes.anchor}>
              <div className={classes.iconHolder}>
              <CategoryIcon className={classes.estimate} />
                <Typography>MainCategory</Typography>
              </div>
            </Link>
          </Box>
        ) : null}
        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/luxury-sub`} className={classes.anchor}>
              <div className={classes.iconHolder}>
              <CategoryIcon className={classes.estimate} />
                <Typography>SubCategory</Typography>
              </div>
            </Link>
          </Box>
        ) : null}
      </Box>
    </>
  )
};

const useStyles = makeStyles((theme) => ({
  iconHolder: {
    width: 115,
    boxShadow: '0px 0px 10px rgba(73, 78, 92, 0.15)',
    cursor: 'pointer',
    display: 'block',
    padding: '28px 10px 10px 10px',
    textAlign: 'center',
    color: '#000',
    minHeight: 70,
    '& p': {
      paddingTop: 5,
      fontSize: 14,
      textTransform: 'uppercase',
    },
  },
  galleryIcon: {
    color: '#046b08',
  },
  chartIcon: {
    color: '#df3703',
  },
  reportIcon: {
    color: '#726708',
  },
  estimate: {
    color: '#040e3f',
  },
  materialIcon: {
    color: '#850d88',
  },
  moneyIcon: {
    color: '#0b1a6b',
  },
  todoChartIcon: {
    color: '#930a1e',
  },
  punchListIcon: {
    color: '#ca7a03',
  },
  anchor: {
    textDecoration: 'none',
    color: '#dadbe6',
    '&:active': {
      color: '#fff',
    }
  },
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  projectTitle: {
    background: '#282a3c',
    color: '#fff',
    padding: 10,
    fontSize: 15,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
  }

}));

export default ProjectEdit;

