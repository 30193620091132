import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { setSidebarVisibility,usePermissions, useDataProvider,Loading} from 'react-admin';
import {Tabs,Paper,Tab,Typography} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FullCalender from './Fullcalender';
import Gantt from '../components/Gantt';
import TodoEdit from './TodoEdit';

const useStyles = makeStyles((theme) =>({
  titlePaper: {
    background: '#f3f3f3',
    marginTop:20,
    boxShadow:'none',
  },
  tab: {
    minWidth: 130,
    width: 130,
    background: '#fff',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background:' #555cb1',
    color:' #fff !important',
  },
}));

export default function ProjectTodoChart({id}){
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [data,setdata] = useState();
  const [project,setProject] = useState();
  const [loading, setLoading] = useState(true);
  const [todoId, setTodoId] = useState();
  const [editDialog, OpenEditDialog] = useState(false)
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setCalenderData = (todos) => {
    let taskList = todos.map((todo,index) => {
      let startDate = new Date(todo.CalenderDueDate);
      let endDate = new Date(todo.end_date);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      endDate.setDate(endDate.getDate() + 1);
      let title = (todo.title) ? todo.title : todo.todo.title;
      let color = "#8bc34a";
      if (todo.color) {
        color = todo.color;
      } else if (todo.todo && todo.todo.color){
        color = todo.todo.color;
      } else {
        color = "#8bc34a";
      }
      return {
        id : todo.id,
        demoId:index + 1,
        title: title,         
        start: startDate,
        start_date:todo.DueDate, //'15-04-2019',
        due_date:todo.DueDate,
        end: todo.DueDate,
        backgroundColor: color,
        name: title,
        text:title,
        duration:1,
        category: (todo.todo) ? todo.todo.task : '',
        color: color,
        complete: (todo.status === 2) ? true : false,
        progress: 1,
        allDay : true,
      }
    })
    //console.log(taskList);
    return taskList;
  }
  
  const updateTaskEvent = () => {

  }
  const handleTodoEdit = (event) => {
    //console.log(event);
    setTodoId(event.id)
    OpenEditDialog(true);
  }
  const closeTodoEdit = () => {
    OpenEditDialog(false);
  }
  const saveEditedTodo = (toditem) => {
    OpenEditDialog(false);
    setLoading(true);
    toditem['projectId'] = id;
    dataProvider.updateProjectTodo('projecttodo',toditem).then(({ data }) => {
      setdata(setCalenderData(data.todos));
      setLoading(false);
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  }

  useEffect(() => {
    setdata([]);
    dispatch(setSidebarVisibility(false));
    dataProvider.getProjectDetailInstance(id).then(({ data }) => {
      //console.log(data.todos);
      setProject(data.project);
      setdata(setCalenderData(data.todos));
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
      // console.log(error);
    });
    return () => {
      dispatch(setSidebarVisibility(true));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (loading) return <Loading loadingPrimary="Initializing Chart..." loadingSecondary="" />;
  if (!data) return null;

  return (
    <>
      <Paper square  className={classes.titlePaper}>
        <Typography variant="h5" color="inherit" display="inline">
          {`${project.title}, ${project.description}`}
        </Typography>
        <Typography variant="p" color="inherit" display="inline">
          {` Date of commencement: ${project.startDate}.`}
        </Typography>
      </Paper>
      <Paper square className={classes.titlePaper}>

        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Show Calender" classes={{ root: classes.tab,selected: classes.selectedTab }}/>
          {/* <Tab label="Show Gantt" classes={{ root: classes.tab,selected: classes.selectedTab }}/> */}

        </Tabs>

      </Paper>
      {(value === 0) ? (
        <FullCalender data={data} updateTask={updateTaskEvent} dialogOpen={handleTodoEdit}/>
      ) : (
        null
      )}
      {/* {(value && value === 1) ? (
        <div className="gantt-container">
        <Gantt
          tasks={
            {
              data: data,
              links:[],
            }
          }
          onDataUpdated={updateTaskEvent}
          onClick={handleTodoEdit} 
          //taskDataClick
          //zoom={'Months'}
        />
      </div>
        // <GhantChart data={data} updateTask={updateTaskEvent} dialogOpen={handleDialogOpen}/>
      ) : (
        null
      )} */}
      {editDialog && todoId && permissions && permissions.role === 'admin' && (
        <TodoEdit
          open={editDialog}
          handleClose = {closeTodoEdit}
          todoId={todoId}
          save={saveEditedTodo}
          role={permissions.role}
        />
      )}
    </>
  )
}
