/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { setSidebarVisibility, useDataProvider, Loading, usePermissions, useAuthProvider, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import List from './Estimatelist';

const queryString = require('query-string');
export default function ProjectEstimate({ id, match, location, history, RevId }) {
  // console.log(RevId)
  const [project, setProject] = useState(id);
  const [projectdetails, setProjectdetails] = useState();
  const routeHistory = useHistory();
  const redirect = useRedirect();
  const parsedQuery = queryString.parse(location.search);
  const classes = useStyles();
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Prev_revId, setPrev_RevId] = useState();
  const [maincategory, setMaincategory] = useState(false);
  const { permissions } = usePermissions();
  // const authProvider = useAuthProvider();
  // const user = authProvider.getIdentity();
  // console.log(user.username)
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (!loading) setLoading(true);
    dispatch(setSidebarVisibility(false));
    dataProvider.getList('project-estimate-rev', { filter: { project: id }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      data.map((item, index) => {
        if (item.id === RevId && index !== 0) {
          setPrev_RevId(data[index - 1].id)
          return null;
        }
        else {
          return null;
        }
      })
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getvariations('project-estimate-list', { filter: { project: id, revId: RevId }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      // console.log(data)
      setMaincategory(data);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
    dataProvider.getOne('projects', { id: id })
      .then(({ data }) => {
        setProjectdetails(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      })
    return () => {
      dispatch(setSidebarVisibility(true));
    };
  }, [id, change, RevId]);

  if (loading || !permissions) return <Loading loadingPrimary="" loadingSecondary="" />;
  if (!maincategory && !projectdetails) return null;
  return (
    <>
      {(projectdetails && maincategory && (
        <Paper square className={classes.titlePaper}>
          <List
            projectId={id}
            RevId={RevId}
            Prev_revId={Prev_revId}
            projectdetails={projectdetails}
            maincategory={maincategory}
            setMaincategory={setMaincategory}
          />
        </Paper>
      ))}
    </>
  )
}
const useStyles = makeStyles((theme) => ({
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
}));


