/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import {
  useListContext,
  setSidebarVisibility,
  Datagrid,
  TextField,
} from 'react-admin';
import FullCalender from './FullCalendar';
import { useDispatch } from 'react-redux';
import { Typography, Tab, Tabs } from '@material-ui/core';
import ProgressBar from '../components/ProgressBar';
import { Link } from "react-router-dom";
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
// const getColsForWidth = (width) => {
//     if (width === 'xs') return 2;
//     if (width === 'sm') return 3;
//     if (width === 'md') return 3;
//     if (width === 'lg') return 5;
//     return 6;
// };

// const times = (nbChildren, fn) =>
//   Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingTaskList = ({
  width,
  nbItems = 10,
}) => {
  // const classes = useStyles();
  return (
    null
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ProgressField = ({ record = {} }) => (
  <ProgressBar value={(record.progressperc) ? record.progressperc : 0} />
)
ProgressField.defaultProps = { label: 'Progress' };

const TextUrlField = ({ record = {}, rowClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.linkWrapper}>
      <Link
        className={record.isOverrun ? classes.linkWrapperOverdue : {}}
        to={`/project-schedule-chart/${record.projectId}?taskId=${record.id}`}
      >
        {record.title}
      </Link>
    </div>
  )
}
TextUrlField.defaultProps = { label: 'Task' };

const ContractorsField = ({ record = {} }) => {
  const classes = useStyles();
  let inside = record?.assignees?.in || [];
  let filter = record.assignees.filter;
  let allFoundedInside = filter.every(ai => !inside.includes(ai));
  if (record.contractors.length) {
    return (
      <div className={classes.contractorBox} >
        {allFoundedInside ? <ErrorOutlinedIcon className={classes.unconfirmed} /> : <></>}
        {record.contractors.map(contractor => (
          <span key={contractor.id} style={{ margin: 5 }}>{contractor.name}</span>
        ))}
      </div>
    )
  } else {
    return (
      <div className={classes.contractorBox}>
        {allFoundedInside ? <ErrorOutlinedIcon className={classes.unconfirmed} /> : <></>}
        <span>UNCONFIRMED</span>
      </div>
    )
  }

}
ContractorsField.defaultProps = { label: 'Contractors' };

const LoadedTaskList = ({ width }) => {
  // basePath, resource
  const { ids, data } = useListContext();
  const [tasks, setTasks] = React.useState();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (ids && ids.length > 0 && data) {
      dispatch(setSidebarVisibility(false));
      setTasks(ids.map(id => {
        return data[id];
      }));
    }
    return () => {
      dispatch(setSidebarVisibility(true));
    };
  }, [ids, data]);

  if (!ids || !data || !tasks) return null;

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="" className={classes.tabs}>
        <Tab label="Calendar" classes={{ root: classes.tab, selected: classes.selectedTab }} {...a11yProps(0)} />
        <Tab label="List" classes={{ root: classes.tab, selected: classes.selectedTab }} {...a11yProps(1)} />
      </Tabs>

      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== 0}
        id={`simple-tabpanel-${0}`}
        aria-labelledby={`simple-tab-${0}`}
      >
        <FullCalender data={tasks} isEdit={false} />
      </Typography>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== 1}
        id={`simple-tabpanel-${1}`}
        aria-labelledby={`simple-tab-${1}`}

      >
        <Datagrid>
          <TextUrlField label="Task" source="title" />
          <TextField label="Start Date" source="startDate" />
          <TextField label="End Date" source="endDate" />
          <TextField source="project" />
          <ContractorsField source="assigned" label="Contractor" />
          <ProgressField source="progressperc" label="Progress" />
        </Datagrid>
      </Typography>
    </>
  );
};

const TaskList = ({ width }) => {
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedTaskList width={width} />
  ) : (
    <LoadingTaskList width={width} />
  );
};

const useStyles = makeStyles(theme => ({
  taskList: {
    margin: 0,
  },
  linkWrapper: {
    '& a': {
      fontSize: 13,
      textDecoration: 'none',
      color: '#000', //634a08
      //fontWeight: 'bold',
    }
  },
  linkWrapperOverdue: {
    color: 'red !important',
  },
  tab: {
    minWidth: 130,
    width: 130,
    background: '#fff',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: ' #555cb1',
    color: ' #fff !important',
  },
  tabs: {
    background: '#ffffff',
  },
  contractorBox: {
    position: 'relative',
  },
  unconfirmed: {
    position: 'absolute',
    color: '#ff0000ab',
    right: 0,
  }
}));

export default withWidth()(TaskList);
