import React, {useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import {CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function ConfirmationInfo({taskId}) {

  const [confirmationInfo, setConfirmationInfo] = useState();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const dataProvider = useDataProvider();

  useEffect(() => {
    let isMounted = true;
    dataProvider.getOne('task-confirmation', { id: taskId })
    .then(({ data }) => {
      if (isMounted) {
        console.log(data);
        setLoading(false);
        setConfirmationInfo(data);
      }
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
    
    return () => {isMounted = false;};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  if(loading) return <DataLoader />;
  if(!confirmationInfo || confirmationInfo.length < 1) return null;

  return (
    <div className={classes.main}>
      {confirmationInfo.map(_info => (
        <div key={_info.id} >
          {(_info.status === 0 || _info.status === 3) && (
            <div className={`${classes.statusHolder} ${classes.pending}`}>
              {`Task Confirmation Pending for ${_info.contractor} of date ${_info.assignedTime}`}
            </div>
          )}
          {(_info.status === 1) && (
            <div className={`${classes.statusHolder} ${classes.declined}`}>
              {`${_info.contractor} rejected task on ${_info.assignedTime}`}
            </div>
          )}
          {(_info.status === 2) && (
            <div className={`${classes.statusHolder} ${classes.confirmed}`}>
              {`${_info.contractor} accepted task on ${_info.assignedTime}`}
            </div>
          )}
           {(_info.status === 4) && (
            <div className={`${classes.statusHolder} ${classes.declined}`}>
              {`${_info.contractor} removed from task on ${_info.assignedTime}`}
            </div>
          )}
          {/* {(_info.notes !== '') && (
            <div className={classes.message} >
              {`Notes: ${_info.notes}`}
            </div>
          )} */}
        </div>
      ))}
    </div>
  )
}

const DataLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <CircularProgress size={30} />
    </div>
  );
}

const useStyles = makeStyles((theme) =>({
  main: {
    width: '100%',
    marginTop: 15,
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
  },
  statusHolder:{
    textTransform: 'capitalize',
    marginBottom: 10,
    fontSize: 14,
    padding: 10,
    color: '#2e2c2c',
    border: '1px solid #f3f3f3',
  },
  pending:{
    background: '#e8e8ff',
  },
  confirmed:{
    background: '#aee1aa',
  },
  declined:{
    background: '#ffcebd',
  },
  message: {
    padding: 10,
    background: '#f3f3f3',
    border: '1px solid #dfd5d5',
    fontSize: 13,
    borderRadius: 15,
  }
}));

