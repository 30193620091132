import * as React from "react";
import { Create, TextInput, required, SimpleForm, SelectInput} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) =>({
  heading: {
    paddingBottom: 10,
    '& h6': {
      fontSize: 18,
      paddingLeft: 5,
      // fontWeight: 'bold',
      color:'#826724',
      textTransform: 'capitalize',
    }
  },
}));

const MaterialCreate = props => {
  const classes = useStyles();
  return (
    <Create {...props} >
      <SimpleForm>
        <SectionHeading label="add material" classProp={classes.heading}/>
        <SingleLinefField />
      </SimpleForm>
    </Create>
  );
};

const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const SingleLinefField = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextInput
          fullWidth={true}
          source="name"
          variant="outlined"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput
          source="status"
          variant="outlined"
          choices={[
            { id: 1, name: 'Enabled' },
            { id: 0, name: 'Disabled' },
          ]}
          defaultValue={1}
          fullWidth={true}
        />
      </Grid>
    </Grid>
  );
};

export default MaterialCreate;
