import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper,Tab,Tabs,Button } from '@material-ui/core';
import {
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  required,
  useDataProvider,
  NumberInput,
  
} from 'react-admin';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import NotesTab from './Notestab';
import TaskMessenger from './TaskMessages';
import { useForm } from 'react-final-form';
import ConfirmationInfo from './ConfirmationInfo';
import FilePreview from './FilePreview';
import { useField } from 'react-final-form';

const useStyles = makeStyles((theme) =>({
  formIterator: {
    [theme.breakpoints.down('lg')]: {
      '& section': {
        display: 'inline-flex',
      }
    },
    [theme.breakpoints.down('md')]: {
      '& section': {
        display: 'block',
      }
    },
  },
  formIteratorGrid: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: '98%',
      width:300,
    },
    [theme.breakpoints.down('md')]: {
      
    },
  },
  iteratorField: {
    width: 230,
    marginRight: 10,
  },
  removeButton: {
    border: '1px solid #F44336',
    color: '#F44336',
    '&:hover': {
      border: '1px solid #F44336',
    }
  },
  paper: {
    background: '#f3f3f3',
    marginTop:20,
    boxShadow:'none',
  },
  tab: {
    //minWidth: 140,
    //width: 140,
    background: '#f3f3f3',
    fontSize: 14,
    // textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#fff',
  },
  previewContainer: {
    background: 'red',

    // '& previews': {
    //   color: 'red',
    //   background: 'red',
    // }
  },
  previewHolder: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function TabField(props) {
  const{ data } = props;
  const uploads = (data.extendedProps)? data.extendedProps.uploadedFiles : data.uploadedFiles;

  const [Tabvalue, setTabValue] = useState(0);
  const [predecessorCount, setPredecessorCount] = useState(0);

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const form = useForm();
  const setPredCount = (count) => {
    setPredecessorCount(count);
  }

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const updateStartDate = (value) => {
    let predecessorData = form.getFieldState('predecessor').value;
    let predecessorList = predecessorData.filter((predecessor) => {
      return predecessor !== undefined && Number.isInteger(predecessor.lag) && predecessor.type && predecessor.task;
    });

    if(predecessorList.length === predecessorData.length) {
      let projectId = data.project || data.extendedProps.project;
      dataProvider.setStartDateFromPredecessor(
        {data: {predecessor: predecessorList,taskId:data.id,project:projectId}}).then(({data}) => {
        form.change('start_date', data.start_date);
        form.change('end_date', data.end_date);
        // form.change('duration', data.duration);
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  return(
    <>
      <Paper square className={classes.paper}>
        <Tabs
          value={Tabvalue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          
        >
          <Tab label="Predecessor" classes={{ root: classes.tab,selected: classes.selectedTab }} />
          <Tab label="Notes" classes={{ root: classes.tab,selected: classes.selectedTab }} />
          <Tab label="Files" classes={{ root: classes.tab,selected: classes.selectedTab }} />
          <Tab label="Messages" classes={{ root: classes.tab,selected: classes.selectedTab }} />
          <Tab label="Confirmation Status" classes={{ root: classes.tab,selected: classes.selectedTab }} />
        </Tabs>
      </Paper>

      {(Tabvalue === 0) ? (
        <>
        <FormDataConsumer>
            {({ formData, ...rest}) => {
              if(formData.predecessor && formData.predecessor.length) {
                setPredCount(formData.predecessor.length);
              }
             
              return(
                <ArrayInput source="predecessor"
                  label="" 
                  className={classes.formIterator}
                  onChange={updateStartDate}
                >
                  <SimpleFormIterator
                    addButton={<CustomAddButton count={predecessorCount} setCount={setPredCount} />}
                    removeButton={
                      <PredRemoveButton
                        count={predecessorCount}
                        setCount={setPredCount}
                        btnStyle={classes}
                        updateStart={updateStartDate}
                      />}
                  >
                    <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                        return (
            
                            <ReferenceInput
                              reference="projecttask"
                              className={classes.iteratorField}
                              source={getSource('task')}
                              record={scopedFormData}
                              label="Task"
                              variant="outlined"
                              resource="projecttask"
                              //fullWidth={true}
                              validate={required()}
                              filter={{
                                project: (data.extendedProps) ? data.extendedProps.project : data.project,
                                not:data.id,
                                all: true
                              }}
                              onChange={updateStartDate}                         
                            >
                              <SelectInput optionText="title" />

                            </ReferenceInput>
               
                        )
                      }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                        return (

                            <SelectInput
                              //fullWidth={true}
                              variant="outlined"
                              className={classes.iteratorField}
                              source={getSource('type')}
                              validate={required()}
                              label="Type"
                              record={scopedFormData}
                              choices={[
                                { id: 'finish_to_start', name: 'Finish to start (FS)' },
                                { id: 'start_to_start', name: 'Start to start (SS)' },
                              ]}
                              onChange={updateStartDate}               
                            />
          
                        )
                      }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                        return (
                            <NumberInput
                              className={classes.iteratorField}
                              source={getSource('lag')}
                              record={scopedFormData}
                              validate={required()}
                              fullWidth={true}
                              variant="outlined" 
                              label="Lag (Days)"
                              onChange={updateStartDate}                   
                            />
                        )
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }}
          </FormDataConsumer>
        </>

      ) : (
        null
      )}

      {(Tabvalue === 1) ? (
        <NotesTab data={data}/>
      ): (
        null
      )}

      {(Tabvalue === 2) ? (
        <>
          <FileInput
            source="files" 
            label="Related files"
            accept="image/png, image/jpeg, application/pdf"
            multiple={true}
            className="task-file-input"
            //classes={{ dropZone: classes.previewContainer }}
          >
            {/* <FileField source="url" title="name" /> */}
            <FilePreview />
          </FileInput>

          <NonField name="uploads" />
          
        </>
      ): (
        null
      )}

      {(Tabvalue === 3 && data.id) ? (
        <TaskMessenger taskId={data.id} />
      ): (
        null
      )}

      {Tabvalue === 4 && data.id && (
        <ConfirmationInfo taskId={data.id} />
      )}

    </>
  )
}

const CustomAddButton = (props)=> {
  const {count,className,onClick,setCount} = props;

  const onAddClick = () => {
    onClick();
    setCount(count + 1);
  }

  return (
    (count <= 3) ? (
      <Button
        variant="outlined"
        color="primary"
        onClick={onAddClick}
        className={className}
        startIcon={<AddCircleOutlineIcon />}
      >
        Add
      </Button>
    ) : null
  )
}

const PredRemoveButton = (props) => {
  const {count,className,onClick,setCount,btnStyle,updateStart} = props;
  const onRemoveClick = (props) => {
    onClick();
    setCount(count-1);
    updateStart();
  }
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onRemoveClick}
      className={`${className} ${btnStyle.removeButton}`}
      startIcon={<RemoveCircleOutlineIcon />}
    >
      Remove
    </Button>
  )
}

const NonField = ({ name, label }) => {
  const classes = useStyles();
  const {
      input: { onChange,value },
      meta: { touched, error }
  } = useField(name);
  return (
    <>
      {(value && value.length > 0) && (
        <div className={classes.previewHolder}>
          {(value.map(_upload => {
            return (
              <FilePreview record={_upload} key={_upload.id}/>
            )
          }))}
        </div>
      )}
    </>
  );
};

export default TabField;

