import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import AddBoxIcon from '@material-ui/icons/AddBox';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { Badge } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import {
    TableContainer,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Button,
    Grid,
    Typography,
    DialogTitle,
    DialogContent, Checkbox,
    Dialog, MenuItem, Menu
} from '@material-ui/core';
// import  ImageListItem from '@material-ui/core/ImageListItem';
// import ImageList from '@material-ui/core/ImageList';
import { useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import { Form } from 'react-final-form';
import { setSidebarVisibility, useDataProvider, SelectInput } from 'react-admin';

function InspectionList(props) {
    const { id, qaId } = props;
    const [loading, setLoading] = useState(true);
    const [inspection, setInspection] = useState([]);
    const [contractorassign, setContractorAssign] = useState(false);
    const [inspectionbackup, setInspectionbackup] = useState(false);
    const [page, setPage] = React.useState(0);
    const [load, setLoad] = React.useState(true);
    const [attachment, setAttachment] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState({ index: -1, visibility: false });
    // const [menuopen, setMenuopen] = React.useState({ index: -1, visibility: false });
    const [newopen, setNewopen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([1]);
    const [contractor, setContractor] = React.useState();
    const [assignedcontractor, setAssignedcontractor] = React.useState();
    const [retrivedcontractor, setRetrivedcontractor] = React.useState();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const area_sections = [
        'None', 'Concrete', 'Plumber', 'Frame', 'Bricks', 'Clading', 'Electrical', 'Air conditioning', 'Facsia and gutter',
        'Gyprock', 'Painting', 'Cabinetry', 'Stones', 'Silicon', 'Driway', 'Landscape', 'Flooring', 'Rendering',
        'Windows', 'Staircase', 'Tiles', 'Carpenter', ' Accessories fitting ', 'Roofing', 'Insulation ',
        'Shower screens and balustrades ', 'Doors and architraves ', 'Looks', ' hinges and stoper', 'Eaves',
        'Waterproof',
    ];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selected, setSelected] = React.useState([]);
    const [allselect, setAllselect] = React.useState(false);
    const menuopen = Boolean(anchorEl);

    useEffect(() => {
        if (!loading) setLoading(true);
        dispatch(setSidebarVisibility(false));
        dataProvider.getContractors('list-contractors', { filter: {}, pagination: {}, sort: { field: 'id', order: 'asc' } })
            .then(({ data }) => {
                setLoading(false)
                setContractor(data)
            })
            .catch(error => {
                setLoading(false)
            });
        dataProvider.getInspectionData('get-qa-sublist', { type: qaId }).then(({ data }) => {
            console.log(data);
            setInspection(data);
            setInspectionbackup(data);
            setLoading(false);
        })
            .catch(error => {
                setLoading(false);
            });
        return () => {
            dispatch(setSidebarVisibility(true));
        };
    }, [load]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleClose = (value) => {
        setOpen({ index: -1, visibility: false });
        setSelectedValue(value);
        setNewopen(false)
    }
    const handleCloseContractor = (value) => {
        setContractorAssign(false)
    }
    const handleCloseViewAssigned = (value) => {
        setRetrivedcontractor(false)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = (item) => {
        setAnchorEl(null);
    };
    const onFilter = (item) => {
        setSelected([])
        setAnchorEl(null);
        if (item === 'None') {
            setInspection(inspectionbackup)
        }
        else {
            const newArr = inspectionbackup.data.map((maincat) => {
                const subArr = maincat.checklists.filter((subcat) => {

                    if (subcat.remark_section === item) {

                        return subcat
                    }
                    else {
                        return null
                    }
                })

                if (subArr && subArr.length > 0) {
                    return ({
                        category: maincat.category,
                        checklists: subArr,
                        created_at: maincat.created_at,
                        project: maincat.project,
                        qa_id: maincat.qa_id,
                        title: maincat.title,
                        _id: maincat._id,
                    })
                }
                else {
                    return
                }
            })
            const finalArr = newArr.filter((item) => {
                if (item !== 'undefined') {
                    return (item)
                }
            }
            )
            setInspection({ data: finalArr })
        }

    }
    const contractorsubmit = (values) => {
        const data = { contractor: values.Contractor, inspectionIds: selected }
        dataProvider.updateinspectionContractor('inspections/contractors', { filter: {}, data, sort: { field: 'id', order: 'asc' } })
            .then(({ data }) => {
                setLoading(false)
                if (data.data === 'successfully') {
                    handleCloseContractor();
                    setLoad(!load);
                }
            })
            .catch(error => {
                setLoading(false)
                handleCloseContractor()
            });
    }
    const viewassigned = (value) => {
        dataProvider.getContractors('list-contractors', { filter: { _id: value.assigned_to }, pagination: {}, sort: { field: 'id', order: 'asc' } })
            .then(({ data }) => {
                setLoading(false);
                setAssignedcontractor(data[0]);
                setRetrivedcontractor(true);
            })
            .catch(error => {
                setLoading(false)
            });
    }
    return (
        <>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <SectionHeading label="QA Inspection List" classProp={classes.heading} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20, marginBottom: 20 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: 10 }}
                        startIcon={<AddBoxIcon />}
                        onClick={() => {
                            setContractorAssign(true);
                        }}
                    >
                        Assign
                    </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={menuopen}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {area_sections.map((item) => {
                            return (
                                <MenuItem onClick={() => onFilter(item)}>{item}</MenuItem>
                            )
                        })
                        }
                    </Menu>
                </div>
                <Table className={classes.table} size="small" aria-label="material-table">
                    <TableHead>
                        <TableRow >
                            <TableCell align="center" ></TableCell>
                            <TableCell align="left" ><b>Inspection Area</b></TableCell>
                            <TableCell align="left" ><b>Remarks</b></TableCell>
                            <TableCell align="center" ><b>Images</b></TableCell>
                            <TableCell align="center">
                                <Checkbox
                                    checked={allselect}
                                    color="primary"
                                    onChange={(e) => {
                                        if (e.target.checked === true) {
                                            console.log(e.target.checked);
                                            setAllselect(true);
                                            var allselectArr = [];
                                            inspection && inspection.data && inspection.data.length > 0 && inspection.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                                                allselectArr.push(item._id);
                                            })
                                            setSelected(allselectArr);
                                        }
                                        else {
                                            setAllselect(false);
                                            setSelected([]);
                                        }
                                    }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inspection?.data?.length > 0 && inspection?.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                            item && (
                                <TableRow key={item?.id} style={{ marginTop: 20 }}>
                                    <TableCell align="center" style={{ width: '10%' }} ><DoubleArrowIcon size={12} /></TableCell>
                                    <TableCell style={{ width: '20%' }} align="left">{item?.remark_section}</TableCell>
                                    <TableCell style={{ width: '40%' }} align="left">{item.remarks}</TableCell>
                                    <TableCell style={{ width: '20%', cursor: 'pointer' }} align="center">
                                        <Badge badgeContent={item?.attachments?.length} color="primary">
                                            {item?.attachments[0] ?
                                                <img
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                    }}
                                                    src={item?.attachments[0]?.url}
                                                    onClick={() => {
                                                        setAttachment(item?.attachments);
                                                        setNewopen(true);
                                                    }}
                                                    alt=""
                                                />
                                                :
                                                <BrokenImageIcon className={classes.iconHolder} />
                                            }
                                        </Badge>
                                    </TableCell>
                                    <TableCell align={"center"} style={{ width: '10%' }}>
                                        {item.assigned_to ?
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                style={{
                                                    marginRight: 10,
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                }}
                                                onClick={() => {
                                                    viewassigned(item)
                                                }}
                                            > View Assignee
                                            </Button>
                                            :
                                            <Checkbox
                                                checked={selected.includes(item._id)}
                                                color="primary"
                                                onChange={(e) => {
                                                    e.target.checked === true ?
                                                        setSelected([...selected, item._id])
                                                        :
                                                        setSelected(selected.filter((val) => (val !== item._id)))
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                    </TableCell>
                                </TableRow>
                            )
                        ))}
                    </TableBody>
                </Table >
                <Dialog onClose={handleClose} open={newopen}>
                    <DialogTitle>
                        <Grid container direction="row" alignItems="center" >
                            <div className={classes.heading} >
                                <Typography variant="h6" gutterBottom>
                                    Inspection Images
                                </Typography>
                            </div>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.modalContent}>
                        <div>
                            {attachment && attachment.length > 0 ? attachment.map((item, i) => (
                                <div key={i}>
                                    <img
                                        width="300px"
                                        height="300px"
                                        src={`${item.url}?auto=format`}
                                        srcSet={`${item.url}?auto=format&dpr=2 2x`}
                                        alt={item.title}
                                    />
                                </div>
                            ))
                                :
                                <div className={classes.modaltitle} >
                                    <Typography className={classes.modaltext} variant="h6" gutterBottom>
                                        No Images !!!
                                    </Typography>
                                    <BrokenImageIcon className={classes.iconHolder} />
                                </div>

                            }
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog onClose={handleCloseContractor} open={contractorassign}>
                    <DialogContent className={classes.contractorassignmodalContent}>
                        <Form
                            onSubmit={contractorsubmit}
                            render={({ handleSubmit, form }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container direction="row" alignItems="center" >
                                            <div className={classes.heading} >
                                                <Typography variant="h6" gutterBottom>
                                                    Assign Contractor
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <SelectInput
                                                    allowEmpty={true}
                                                    emptyText="--Select Contractor--"
                                                    variant="outlined"
                                                    label="Select Contractor"
                                                    source="Contractor"
                                                    fullWidth={true}
                                                    choices={contractor && contractor}
                                                />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom>
                                                    ** Once Assigned to a contractor cannot be change
                                                </Typography>
                                            </Grid>
                                            <div className={classes.saveBtnHolder}>
                                                <Button className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> Assign</Button>
                                            </div>
                                        </Grid>
                                    </form>
                                )
                            }} />
                    </DialogContent>
                </Dialog>
                <Dialog onClose={handleCloseViewAssigned} open={retrivedcontractor}>
                    <DialogContent className={classes.contractorviewmodalContent}>
                        <Grid container direction="row" alignItems="left" >
                            <div className={classes.heading} >
                                <Typography variant="h6" gutterBottom>
                                    Assigned Contractor
                                </Typography>
                            </div>
                        </Grid>
                        <Typography className={classes.contractordetails} variant="subtitle1" gutterBottom>
                            {`Name : ${assignedcontractor?.fullname}`}
                        </Typography>
                        <Typography className={classes.contractordetails} variant="subtitle1" gutterBottom>
                            {`Company : ${assignedcontractor?.company}`}
                        </Typography>
                        <Typography className={classes.contractordetails} variant="subtitle1" gutterBottom>
                            {`Email : ${assignedcontractor?.email}`}
                        </Typography>
                        <Typography className={classes.contractordetails} variant="subtitle1" gutterBottom>
                            {`Contact : ${assignedcontractor?.contact}`}
                        </Typography>
                    </DialogContent>
                </Dialog>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={inspection?.data?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer >
        </>
    )
}

const SectionHeading = ({ label, classProp }) => {
    return (
        <Grid container direction="row" alignItems="center" className={classProp}>
            <ListIcon />
            <Typography variant="h6" gutterBottom >
                {label}
            </Typography>
        </Grid>
    );
};

const useStyles = makeStyles({
    tableContainer: {
        marginTop: 15,
        paddingBottom: 15,
        boxShadow: 'none',
        border: '1px solid #ebebeb',
        width: 'auto',
        marginLeft: 10,
    },
    heading: {
        padding: 10,
        '& h6': {
            fontSize: 14,
            paddingLeft: 5,
            fontWeight: 'bold',
            letterSpacing: 1,
            marginTop: 5,
            color: '#e58f0a',
            textTransform: 'uppercase',
        }
    },
    table: {
        minWidth: 650,
    },
    deleteButton: {
        border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
    },
    modalContent: {
        minWidth: 300,
        paddingBottom: 30,
        '&::-webkit-scrollbar': {
            width: '0.5em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ddd',
            borderRadius: 15,
            outline: '1px solid #ddd'
        }
    },
    contractorassignmodalContent: {
        minWidth: 300,
        paddingTop: 30,
        paddingBottom: 30,
        '&::-webkit-scrollbar': {
            width: '0.5em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ddd',
            borderRadius: 15,
            outline: '1px solid #ddd'
        }
    },
    contractorviewmodalContent: {
        minWidth: 300,
        paddingTop: 30,
        paddingBottom: 30,
        '&::-webkit-scrollbar': {
            width: '0.5em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ddd',
            borderRadius: 15,
            outline: '1px solid #ddd'
        }
    },
    modaltitle: {
        minWidth: 500,
        textAlign: 'center'
    },
    modaltext: {
        marginTop: 30,
    },
    contractordetails: {

    },
    iconHolder: {
        width: 100,
        textAlign: 'center',
        alignSelf: 'center',
        color: '#000',
        minHeight: 60,
    },
    saveBtnHolder: {
        marginTop: 20,
        padding: 10,
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    saveBtn: {
        background: '#e5aa13',
        color: '#000',
        fontWeight: 'bold',
        '&:hover': {
            background: '#e5aa13',
        }
    },
});


export default InspectionList;
