
const ApiConfig = {

   REST_API_URL: 'https://www.nexaplan.com.au:3000/api',
   REST_API_AUTH_URL: 'https://www.nexaplan.com.au:3000/auth',

  // REST_API_URL: 'http://localhost:5000/api',
  // REST_API_AUTH_URL: 'http://localhost:5000/auth',
  
  SECRET_KEY: "nexabuilder-au-key",

  awss3: {
    version: 'latest',
    region: 'us-east-1',
    key: 'AKIAYAYIEEOCTF7ZNJ7S',
    secret: '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',
    bucket: 'nexaplan-aws/'
  },
};

export default ApiConfig;