import * as React from 'react';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import {Box,Typography,Card,IconButton} from '@material-ui/core';
import {
  linkToRecord,
  useListContext,
  usePermissions,
  DeleteButton,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import { Link } from 'react-router-dom';
import PieChart from './PieChart';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloneDIalog from './cloneProject';
import UpdateProject from './UpdateProject';
import PinDropIcon from '@material-ui/icons/PinDrop';

const getColsForWidth = (width) => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 3;
    if (width === 'lg') return 5;
    return 6;
};

const times = (nbChildren, fn) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({
    width,
    nbItems = 10,
}) => {
    const classes = useStyles();
    return (
        <MuiGridList
          cellHeight={180}
          cols={getColsForWidth(width)}
          className={classes.gridList}
        >
          {' '}
          {times(nbItems, key => (
            <GridListTile key={key}>
              <div className={classes.placeholder} />
            </GridListTile>
          ))}
        </MuiGridList>
    );
};

const LoadedGridList = ({ width }) => {
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openClone, setOpenClone] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState();
  const { ids, data, basePath, resource } = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();

  const openCloneDIalog = (project) => {
    setSelectedProject(project);
    setOpenClone(true);
  }
  const closeCloneDialog = (project) => {
    setOpenClone(false);
  }
  const cloneProject = (projectInfo) => {
    closeCloneDialog();
    projectInfo['projectId'] = selectedProject.id;
    dataProvider.create('clone-project',{data: projectInfo}).then(({data}) => {
      refresh();
    })
    .catch(err => {
      console.log(err);
    });
  }

  const openUpdateDialog = (project) => {
    setSelectedProject(project);
    setOpenUpdate(true);
  }

  const closeUpdateDialog = (project)=> {
    setOpenUpdate(false);
  }

  const updateProject = (data) => {
    closeUpdateDialog();
    dataProvider
      .updateProject('projects',data)
      .then(({ data }) => {
        refresh();
      })
      .catch(error => {
      })
  }

  if (!ids || !data) return null;

  return (
    <>
      <MuiGridList
        cellHeight={180}
        cols={getColsForWidth(width)}
        className={classes.gridList}
      >
        {ids.map((id) => (
          data[id] && (
            <ProjectGrid
              project={data[id]}
              key={id}
              basePath={basePath}
              resource={resource}
              openCloneDIalog={openCloneDIalog}
              openUpdateDialog={openUpdateDialog}
            />
          )
        ))}
      </MuiGridList>
      {(selectedProject && (
        <CloneDIalog
          open={openClone}
          close={closeCloneDialog}
          project={selectedProject}
          cloneProject={cloneProject}
        />
      ))}
      <UpdateProject
        open={openUpdate}
        close={closeUpdateDialog}
        project={selectedProject}
        updateProject={updateProject}
      />
    </>
  );
};

const ProjectGrid = ({project,basePath,resource,openCloneDIalog,openUpdateDialog}) => {

  const { permissions } = usePermissions();
  const classes = useStyles();
  return (
      <Box
        p={1}
        m={2}
        alignItems="center"
        className={classes.projectBox}
      > 
        <div className={classes.boxHeader}>
          {project.location && project.location.mapLocation && (
            <a
              href={project.location.mapLocation}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PinDropIcon />
            </a>
          )}
          {permissions && permissions.role && permissions.role === 'admin' && (
          
            <>
              <IconButton
                size="small"
                aria-label="edit"
                className={classes.editButton}
                onClick={() => openUpdateDialog(project)}
              >
                <EditIcon />
              </IconButton>

              {project.type && project.type !== 'construction' && (
                <IconButton
                  size="small"
                  aria-label="clone"
                  className={classes.cloneButton}
                  onClick={() => openCloneDIalog(project)}
                >
                  <FileCopyIcon />
                </IconButton>
              )}
  
              <DeleteButton
                size="small"
                basePath={basePath}
                undoable={false}
                resource={resource}
                label=""
                record={project}
                confirmTitle={`Delete ${project.title} ${project.description}`}
                confirmContent={`Are you sure want to delete project ${project.title} ${project.description}`}
              />
          </>
        )}
      </div>
        <Link to={linkToRecord(basePath, project.id)} className ={classes.anchor}>
          <Typography variant="body2" component="p" className={classes.title}>
            {project.title}
            <span className={classes.subtitle} >{project.description}</span>
          </Typography>

          {project.address && project.address.street && (
            <Typography variant="body2" component="p" className={classes.subText}>
              {`${project.address.street} ${project.address.suburb} ${project.address.state} ${project.address.zip}`}
            </Typography> 
          )}
          {project?.address?.map_address && (
            <Typography variant="body2" component="p" className={classes.subText}>
              {project?.address?.map_address}
            </Typography> 
          )}
          <Typography variant="body2" component="p" className={classes.subText}>
            {`Date Of Commencement ${project.startDate}`}
          </Typography>

          <Typography variant="body2" component="p" className={classes.subText}>
            Project Process Stage: <span className={classes.processStage}>
              {project.type === 'design_approval' ? 'Design And Approval' : project.type === 'pre_contract' ? 'Pre Contract' : 'Construction'}
            </span>
          </Typography>

          {permissions && permissions.role && (permissions.role === 'admin' || permissions.role === 'manager') && (
            <PieChart projectId={project.id}/>
          )}

          <div className={classes.imageBarContainer}>
            <div className={classes.imageContainer}>
              <img
                src={project.facade ? project.facade : process.env.PUBLIC_URL + '/facade-placeholder.png'}
                alt="facade"
                className={classes.facade}
              />
            </div>
            {permissions && permissions.role && (permissions.role === 'admin'|| permissions.role === 'manager') && (
              <div className={classes.clientContainer}>
                <ClientCard clientInfo={project.client}/>
              </div>
            )}
          </div>
        </Link>
      </Box>
  )
}

const ClientCard = ({clientInfo}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <img
        src={clientInfo && clientInfo.avatar ? clientInfo.avatar : process.env.PUBLIC_URL + '/avatar.png'}
        alt="facade"
        className={classes.cover}
      />
      {clientInfo?._client && clientInfo?._client.name && clientInfo?._client.name.first_name &&  (
        <div className={classes.details}>
          <div className={classes.name}>
            {`${clientInfo?._client.name.first_name}`}
          </div>
          <div>{clientInfo?.email}</div>
          <div>{clientInfo?._client.contact}</div>
        </div>
      )}
    </Card>
  )
}

const GridList = ({ width }) => {
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedGridList width={width} />
  ) : (
    <LoadingGridList width={width} />
  );
};

const useStyles = makeStyles(theme => ({
  gridList: {
    margin: 0,
  },
  tileBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  link: {
    color: '#fff',
  },
  projectBox: {
    boxShadow: '0px 0px 15px rgb(73 78 92 / 15%)',
    borderRadius: 8,
    textDecoration: 'none',
    color: '#000',
    minWidth: 300,
    //padding: 0,
  },
  anchor: {
    textDecoration: 'none',
    color: '#000',
    //padding: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#483939',
    paddingLeft: 10,
    textTransform: 'capitalize',
  },
  subText: {
    fontSize: 13,
    paddingTop: 8,
  },
  imageBarContainer: {
    width: '100%',
    display: 'flex',
    paddingTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
  },
  imageContainer: {
    flex: 2,
  },
  clientContainer: {
    flex:3,
  },
  facade: {
    width: 140,
    height: 'auto',
  },
  root: {
    //display: 'flex',
    //justifyContent: 'space-between',
    //flexDirection: 'row',
    //alignItems: 'center',
    //flexWrap: 'wrap',
    boxShadow: 'none',
    padding: 5,
    textAlign: 'center',
    border: '1px solid #e5aa13',
    margin: '0px 5px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 13,
    color: '#655f5f',
  },
  name: {
    color: '#000',
    paddingBottom: 5,
    fontWeight: 'bold',
    fontSize: 12,
  },
  cover: {
    width: 30,
    height: 30,
    borderRadius: '50%',
  },
  boxHeader: {
    display: 'flex',
    padding: 5,
    justifyContent: 'flex-end',
    '& a': {
      color: '#e65446',
      padding: '0px 10px',
    }
  },
  editButton: {
    color: '#e19c3a',
  },
  cloneButton: {
    color: '#e5772d',
  },
  processStage: {
    color: '#e7a602',
    textTransform: 'uppercase',
    /* font-weight: bold; */
    fontSize: 13,
  },
}));

export default withWidth()(GridList);
