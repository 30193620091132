import React, { useState,useEffect } from 'react';
import {
  SimpleForm,
  FormDataConsumer,
  FileInput,
  FileField,
  required,
  useDataProvider,
  usePermissions,
  Loading
} from 'react-admin';
import { Field } from 'react-final-form'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import AddFile from './AddFile';
import { Grid,Button } from '@material-ui/core';

function ProjectCertificates({project}) {

  const dataProvider = useDataProvider();
  const [drawer, openDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [initialValues, setInitialValues] = useState();
  const classes = useStyles();
  const { permissions } = usePermissions();

  const addNewFile = (value) => {
    value['project'] = project;
    value['assigned_to'] = 'contractor';
   // setLoading(true);
    dataProvider
      .createCertificate('project-certificates',{data: value})
      .then(({ data }) => {
        console.log(data);
        setFileList(prevList => [...prevList, data]);
        openDrawer(false);
       // setLoading(false);
      })
      .catch(error => {
        openDrawer(false);
       // setLoading(false);
      });

    // setFileList(prevList => [...prevList, {FieldName: `${value.name}`, label: value.name}]);
    // openDrawer(false);
  };
  
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    openDrawer(false);
  };


  const onSave = (values) => {
    console.log(values);
    ///setLoading(true);
    dataProvider
    .updateCertificates('project-certificates',{data: values})
    .then(({ data }) => {
      console.log(data);
      
     // setLoading(false);
    })
    .catch(error => {
      console.log(error);
      //setLoading(false);
    });
  }

  useEffect(() => {
    let certificateInitialValue = {};
    dataProvider
      .getProjectCertificates('project-certificates',{filter: {project: project}})
      .then(({ data }) => {
        console.log(data);
        if(data && data.length > 0) {
          data.map(certificate => {
            if(certificate.documents && certificate.documents.length > 0) {
              certificateInitialValue[certificate.id] = certificate.documents;
            }
          });
          setInitialValues(certificateInitialValue);
        }
        setFileList(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if(loading) return (
  //   <div className={classes.main}>
  //     <Loading />
  //   </div>
  // )

  return (
    <div className={classes.main}>
      {(permissions && (permissions.role ==="admin" || permissions.role ==="manager")) && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.addButton}
          onClick={() => openDrawer(true)}
        >
          Add New Certificate
        </Button>
      )}

      <SimpleForm save={onSave} initialValues={initialValues} >
        <Grid container direction="row" className={classes.gridMain}>
          {fileList.map((fileInfo,index) => {
            return (
              <Grid item xs={12} sm={6} key={index} className={classes.gridField}>
                <Field
                  name={fileInfo.id}
                  component={CheckBoxField}
                  type="checkbox"
                  label={fileInfo.label}
                  // validate={customrequired}
                />
                <ConditionFileField
                  fileType={fileInfo.id}
                  label={fileInfo.label}
                  initialValue={fileInfo.documents}
                />
              </Grid>
            )
          })}
        </Grid>
      </SimpleForm>
      <AddFile open={drawer} toggleDrawer={toggleDrawer} addnewFile={addNewFile} project={project} />
    </div>
  )
};


const ConditionFileField = ({fileType,label,initialValue}) => {
  const classes = useStyles();
  const form = useForm();
  const dataProvider = useDataProvider();

  const uploadNewFiles = (certificateId,label) => {

    let files = form.getFieldState(`file_${certificateId}`).value;
    let uploadedFiles = [], uploadingFiles = [];

    if(files && files.length > 0) {
      files.map(file => {
        if(file && file.rawFile){
          uploadingFiles.push(file);
        } else {
          uploadedFiles.push(file);
        }
      });

      dataProvider
        .uploadCertificates({files: uploadingFiles, type: label, certId: certificateId})
        .then(({ data }) => {
          if(data && data.length > 0) {
            uploadingFiles = data.map(fileInfo => {
              return fileInfo;
            });
            form.change(
              `file_${certificateId}`,
              uploadingFiles.concat(uploadedFiles)
            );
          }
        })
        .catch(error => {

        });
    }
  }

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        return (
          formData[fileType] && (
            <FileInput
              source={`file_${fileType}`}
              label={label}
              accept="application/pdf"
              //placeholder="Upload front page of contract of sales"
              validate={required()}
              multiple={true}
              classes={{dropZone: classes.dropzoneContainer}}
              //onChange={onChange}
              onChange={(files) => uploadNewFiles(fileType,label, files)}
              initialValue={initialValue}
            >
              <FileField source="url" title="name" target="_blank" />
            </FileInput>
          )
        )
      }}
    </FormDataConsumer>
  )
}

const CheckBoxField = ({
  input: { checked, onChange, ...restInput },
  meta,
  ...rest
}) => (
  <FormControlLabel
    control={
      <Checkbox
        {...rest}
        inputProps={restInput}
        onChange={onChange}
        checked={Boolean(checked)}
        color="primary"
        style={{ width: 'auto' }}
      />
    }
    style={{textTransform: 'capitalize'}}
    label={rest.label}
  />
)

const useStyles = makeStyles((theme) =>({
  dropzoneContainer: {
    border: '2px dotted #000',
    padding: 0,
    background: '#ddd',
    borderRadius: 5,
    fontSize: 12,
  },
  main: {
    margin: 10,
    width: '100%',
  },
  addButton: {
    margin: '20px 0px',
  },
  gridMain: {
    width: '100%',
  },
  gridField: {
    padding: '0px 10px',
  },
}));

export default ProjectCertificates;
