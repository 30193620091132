/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { setSidebarVisibility, useDataProvider, Loading, usePermissions, useAuthProvider, TextField, Datagrid, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Paper, Box, Tab, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Category from '@material-ui/icons/Category';
import GavelIcon from '@material-ui/icons/Gavel';
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import {
  FcGenealogy,
  FcMindMap
} from "react-icons/fc";
const queryString = require('query-string');

export default function ProjectTaskChart({ id, match, location, history }) {
  const routeHistory = useHistory();
  const redirect = useRedirect();
  const parsedQuery = queryString.parse(location.search);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [data, setdata] = useState();
  const [loading, setLoading] = useState(true);

  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  useEffect(() => {
    setdata([]);
    setLoading(false);
  }, [id]);

  if (loading || !permissions) return <Loading loadingPrimary="" loadingSecondary="" />;
  if (!data) return null;

  return (
    <>
      {(data && (
        <Paper square className={classes.titlePaper}>
          <Box
            display="flex"
            flexWrap="wrap"
            bgcolor="background.paper"
          >
            {(permissions && permissions.role === 'admin'|| permissions.role === 'manager') ? (
              <Box p={1} m={2} alignItems="center">
                <Link to={`/maincategories`} className={classes.anchor}>
                  <div className={classes.iconHolder}>
                    <FcGenealogy className={classes.reportIcon} />
                    <Typography>MainCategory</Typography>
                  </div>
                </Link>
              </Box>
            ) : null}
                        {(permissions && permissions.role === 'admin'|| permissions.role === 'manager') ? (
              <Box p={1} m={2} alignItems="center">
                <Link to={`/subcategories`} className={classes.anchor}>
                  <div className={classes.iconHolder}>
                    <FcMindMap className={classes.reportIcon} />
                    <Typography>SubCategory</Typography>
                  </div>
                </Link>
              </Box>
            ) : null}
          </Box>
        </Paper>
      ))}
    </>
  )
}
const useStyles = makeStyles((theme) => ({
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  iconHolder: {
    width: 115,
    boxShadow: '0px 0px 10px rgba(73, 78, 92, 0.15)',
    cursor: 'pointer',
    display: 'block',
    padding: '28px 10px 10px 10px',
    textAlign: 'center',
    color: '#000',
    minHeight: 70,
    '& p': {
      paddingTop: 5,
      fontSize: 14,
      textTransform: 'uppercase',
    },
  },
  tab: {
    minWidth: 130,
    width: 130,
    background: '#fff',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: ' #555cb1',
    color: ' #fff !important',
  },
  anchor: {
    textDecoration: 'none',
    color: '#dadbe6',
    '&:active': {
      color: '#fff',
    }
  },
  reportIcon: {
    color: '#726708',
  },
  materialIcon: {
    color: '#850d88',
  },
  tabIndicator: {
    // background:' #555cb1',
    // color:' #fff !important',
    // height: '100%',
  },
}));


