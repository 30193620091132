import * as React from "react";
import { List, Datagrid, TextField,EditButton,CreateButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import { Grid,Typography } from '@material-ui/core';

const useStyles = makeStyles({
  heading: {
    padding: '20px 16px',
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  createButton: {
    margin: 30,
  },
});

const SectionAction = ({ label, classes,basePath }) => {
  return (
    <>
      <Grid container direction="row" alignItems="center" className={classes.heading}>
        <ListIcon />
        <Typography variant="h6" gutterBottom >
          {label}
        </Typography>
      </Grid>
      <CreateButton basePath={basePath} className={classes.createButton} />
    </>
  );
};

const StatusField = ({record,source}) => {
  let status = (record && record[source] === 1) ? 'Enabled' : 'Disabled';
  return(
    <span >{status}</span>
  );
}

const PhaseList = props => {
  const classes = useStyles();
  return(
    <List {...props}
      exporter={false}
      actions={<SectionAction {...props} label="Phases" classes={classes} />} 
    >
      <Datagrid>
        <TextField source="title" />
        <StatusField source="status" />
        <EditButton source="Action"/>
      </Datagrid>
    </List>
  );
}

export default PhaseList;

