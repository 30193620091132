import * as React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    // CreateButton,
    // ExportButton,
    Filter,
    //FilterProps,
    //InputProps,
    ListBase,
    //ListProps,
    Pagination,
    SearchInput,
    Title,
    TopToolbar,
    useListContext,
} from 'react-admin';
import TypeFilterButton from './TypeFilterButton';
import GridList from './GridList';
//import Aside from './Aside';

// const useQuickFilterStyles = makeStyles(theme => ({
//   root: {
//     marginBottom: theme.spacing(3),
//   },
// }));


// const QuickFilter = ({ label }) => {
//   // const translate = useTranslate(); //translate(label)
//   const classes = useQuickFilterStyles();
//   return <Chip className={classes.root} label={label} />;
// };

export const ProjectFilter = props => (
    <Filter {...props}>
       {/* <TextInput label="Title" variant="outlined" source="title" alwaysOn />
      <SearchInput variant="outlined" source="title" alwaysOn /> */}
      <SearchInput source="q" variant="outlined" alwaysOn />
      {/*<ReferenceInput
          source="category_id"
          reference="categories"
          sort={{ field: 'id', order: 'ASC' }}
      >
        <SelectInput source="name" />
      </ReferenceInput>
      <NumberInput source="width_gte" />
      <NumberInput source="width_lte" />
      <NumberInput source="height_gte" />
      <NumberInput source="height_lte" />
      <QuickFilter
        label="resources.products.fields.stock_lte"
        source="stock_lte"
        defaultValue={10}
      /> */}
    </Filter>
);

const ListActions= ({ isSmall,classes }) => (
    <TopToolbar className={classes.toolBar}>
      {/* {isSmall && <ProjectFilter context="button" />} */}
      <ProjectFilter />
      <TypeFilterButton fields={[
        {type:'pre contract',value: 'pre_contract'},
        {type:'design and approval',value: 'design_approval'},
        {type:'construction',value: 'construction'}
      ]} />
      {/* <CreateButton basePath="/projectsnew" />
      <ExportButton /> */}
    </TopToolbar>
);

const ProjectList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
      <ListBase
        perPage={10}
        sort={{ field: '_id', order: 'DESC' }}
        filterDefaultValues={{type: 'pre_contract'}}
        {...props}
      >
        <ProductListView isSmall={isSmall} />
      </ListBase>
    );
};

const ProductListView = ({ isSmall }) => {
    const { defaultTitle } = useListContext();
    const pclasses = useStyles();
    return (
      <>
        <Title defaultTitle={defaultTitle} />
        <ListActions isSmall={isSmall} classes={pclasses} />
        {isSmall && (
          <Box m={1}>
            <ProjectFilter context="form" />
          </Box>
        )}
        <Box display="flex">
          {/* <Aside /> */}{/* calc(100% - 16em */}
          <Box width={isSmall ? 'auto' : '100%'}>
            <Pagination rowsPerPageOptions={[5, 10, 20, 40]} />
            <GridList />
          </Box>
        </Box>
      </>
    );
};


const useStyles = makeStyles(theme => ({
 toolBar: {
  justifyContent: 'space-between',
 },
}));

export default ProjectList;

