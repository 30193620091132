import * as React from "react";
import { List, Datagrid, TextField,CreateButton,DateField,ImageField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import { Grid,Typography } from '@material-ui/core';

const useStyles = makeStyles({
  heading: {
    padding: '20px 16px',
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  createButton: {
    margin: 30,
  },
});

const SectionAction = ({ label, classes,basePath }) => {
  return (
    <>
      <Grid container direction="row" alignItems="center" className={classes.heading}>
        <ListIcon />
        <Typography variant="h6" gutterBottom >
          {label}
        </Typography>
      </Grid>
      <CreateButton basePath={basePath} className={classes.createButton} />
    </>
  );
};



const PopupList = props => {
  const classes = useStyles();
  return(
    <List {...props}
      exporter={false}
      actions={<SectionAction {...props} label="Popups" classes={classes} />} 
    >
      <Datagrid rowClick="edit">
        <ImageField source="popupImage.url" title="popupImage.name" label="Ad Image" />
        <TextField source="projectPhase" label="Project Phase"/>
        <DateField source="start_date" label="Start Date" />
        <DateField source="end_date" label="End Date" />
        <TextField source="dateAdded" />
      </Datagrid>
    </List>
  );
}

export default PopupList;

