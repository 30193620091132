/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useDataProvider,useAuthProvider,Loading } from 'react-admin';
import { GiftedChat } from 'react-web-gifted-chat';
import UploadFiles from './UploadFiles';
import { RenderActions,renderSend } from './InputToolbar';
import { renderBubble,renderMessageText } from './MessageContainer';
import uuid from 'uuid';
import EmptyRoom from './EmptyRoom';

function ChatRoom(props) {

  const { room, socket } = props;

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();

  const [open, setOpen] = useState(false);

  const openFileUpload = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRoomMessages = useCallback(() => {
    if(!loading) {setLoading(true);}
    setMessages([]);
    dataProvider.getChatmessages({filter: {room: room}})
     .then(({data}) => {
       // console.log(data)
       if(data) {
        setMessages(previousMessages => GiftedChat.append(previousMessages, data));
       }
       setLoading(false);
     })
     .catch(error => {
      setLoading(false);
     })
  }, [room]);

  const handleSend = useCallback((sendMessages = []) => {
    if (sendMessages.length > 0) {
      sendMessages[0].pending = true;
      sendMessages[0].sent = false;
    }

    setMessages(previousMessages => GiftedChat.append(previousMessages, sendMessages));
    let messageInfo = sendMessages[0];
    messageInfo.room = room;
    messageInfo.user._id = user.id;
    messageInfo._id = messageInfo.id;
    socket.emit('chat-message-web-room', messageInfo);

  }, [room]);
 
  const saveFiles = (data) => {
    handleClose();
    if(data.files && data.files.length > 0) {
      let messages = data.files.map(file => {
        if(file && file.rawFile) {
          let messageInfo = {
            id: uuid.v4(),
            createdAt: new Date(),
            pending: true,
            sent: false,
            user :{id: user.id,name: user.username},
          }
          if (file.rawFile.type.search('image') >= 0) {
            messageInfo.image = file.src;
            messageInfo.name = file.title;
          } else {
            messageInfo.text = file.title;
            messageInfo.document = file.src;
            messageInfo.name = file.title;
          }
          return messageInfo;
        } else {
          return null;
        }
      });
      messages = messages.filter(message => message !== null);
      if(messages && messages.length > 0) {
        setMessages(previousMessages => GiftedChat.append(previousMessages, messages));
        dataProvider.uploadChatFiles({data: {files: data.files}}).then(({data}) => {
          data.files.map(file => {
            let messageDetail = messages.find(message => message.name === file.name);
            if (messageDetail && messageDetail.id) {
              messageDetail.room = room;
              messageDetail.user._id = user.id;
              messageDetail._id = messageDetail.id;
              if (messageDetail.document) {messageDetail.document = file.url;}
              if (messageDetail.image) {messageDetail.image = file.url;}
              socket.emit('chat-message-web-room', messageDetail);
            }
          });
        })
        .catch(error => {
          console.log(error)
        })
      } 
    }
  };

  const MarkMessageRead = (messageInfo) => {
    if (messageInfo.message.user.id === user.id) {
      setMessages(previousMessages => {
        let isMessageHere = previousMessages.find(message => message.id === messageInfo.messageId);
        if (isMessageHere) {
          previousMessages = previousMessages.filter(message => message.id !== messageInfo.messageId);
        }
        messageInfo.message.pending = false;
        messageInfo.message.sent = true;
        return GiftedChat.append(previousMessages, [messageInfo.message]);
      });
    } else {
      if (messageInfo.message.user){
        messageInfo.message.user = {
          id: messageInfo.message.user._id,
          name: messageInfo.message.user.username,
          avatar: messageInfo.message.user.avatar,
        };
      }
      setMessages(previousMessages => {
        let isMessageHere = previousMessages.find(message => message.id === messageInfo.messageId);
        if (!isMessageHere) {
          return GiftedChat.append(previousMessages, [messageInfo.message]);
        } else {
          return previousMessages;
        }
      });
      //setMessages(previousMessages => GiftedChat.append(previousMessages, messageInfo.message));
    }
  }

  useEffect(() => {
    if(room) {
      getRoomMessages();
    }
    if(socket) {
      socket.emit('set-chat-room-user', {id: user.id, name : user.username, room: room});
      socket.emit('join-room-web', {roomId: room, userId: user.id});
      socket.on('new-message-added', (data) => {
        MarkMessageRead(data);
      });
    }

  },[room]);

  if(!room || !socket) {return <EmptyRoom />}
  if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;

  return (
    <>
      <GiftedChat
        messages={messages}
        onSend={(messages) => handleSend(messages)}
        user={{
          id: user.id,
          name: user.username,
        }}
        scrollToBottom={true}
        // alwaysShowSend={true}
        renderActions={RenderActions}
        renderSend={renderSend}
        openFileUpload={openFileUpload}
        renderMessageText={renderMessageText}
        renderBubble={renderBubble}
      />
      <UploadFiles open={open} handleClose={handleClose} saveFiles={saveFiles} />
    </>
  )
}

export default ChatRoom;
