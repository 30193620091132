/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { setSidebarVisibility, useDataProvider, Loading, usePermissions, useAuthProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Paper, Tab, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import EventEditDialog from './EventDialog';
import FullCalender from './Fullcalender';
import Gantt from '../components/Gantt';
import SortButton from './GhantSortButton';
import TaskSortButton from './GuantTaskSort'
import TaskSearchButton from './GuantTaskSearch'
import ContractorTaskInfo from './Schedule_Modal';
import TaskList from './ListTasks';
import { useHistory } from 'react-router-dom';

import ProjectTaskList from '../projecttask/ProjectTaskList';

const queryString = require('query-string');

export default function ProjectTaskChart({ id, match, location, history }) {

  const routeHistory = useHistory();

  const parsedQuery = queryString.parse(location.search);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [data, setdata] = useState();
  const [filteredTasks, setfilteredTasks] = useState();
  const [currentSort, setCurrentSort] = React.useState('None');
  const [currentTaskSort, setCurrentTaskSort] = React.useState('None');
  const [searchQuery, setSearchQuery] = React.useState();
  const [links, setLinks] = useState();
  const [project, setProject] = useState();
  const [fields, setFields] = useState();
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();

  const handleDialogOpen = (event, isGhant = false) => {
    if (isGhant) { event['isGhant'] = true }
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const openDialogFromList = (id) => {
    let selectedTask = data.find(task => task.id === id);
    if (selectedTask) {
      setSelectedEvent(selectedTask);
      setOpenDialog(true);
    }
  };

  const handleClose = (value) => {
    setOpenDialog(false);
  };

  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateTaskEvent = (item, index) => {
    let TaskPost = {
      projectId: id,
      start_date: item.start,
      end_date: (item.end) ? item.end : item.start,
      complete: false,
    };
    //console.log(TaskPost);
    updateTaskDetails(item.id, { data: TaskPost });
  }

  const editProjectTask = (taskId, data, isGhant = false) => {
    let updatedEndDate = new Date(data.end_date);
    // updatedEndDate.setHours(0);
    // updatedEndDate.setMinutes(0);
    // updatedEndDate.setSeconds(0);
    updatedEndDate.setDate(updatedEndDate.getDate() + 1);
    let TaskPost = { ...data, projectId: id, start_date: formatDate(data.start_date), end_date: formatDate(updatedEndDate) };
    updateTaskDetails(taskId, { data: TaskPost }, isGhant);
  }

  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const TaskDataUpdate = (type, action, item, itemId) => {
    if (action === 'update' && type === 'task') {
      let TaskPost = {
        projectId: id,
        start_date: item.start_date,
        end_date: item.end_date,
        complete: false,
      };
      // console.log(TaskPost);
      updateTaskDetails(itemId, { data: TaskPost }, true);
    }
    if (action === 'delete' && type === 'link') {
      let LinkPost = {
        projectId: id,
        source: item.source,
        target: item.target,
      };
      deleteProjectLinks(itemId, { data: LinkPost });
    }
    if (action === 'create' && type === 'task') {
      let parentTask;
      if (item.parent !== 0) {
        parentTask = data.find(_task => { return _task.id === item.parent });
        if (parentTask) {
          parentTask = {
            id: parentTask.id,
            title: parentTask.title,
            sort: parentTask.sort,
            start_date: parentTask.start_date,
            end_date: parentTask._end_date,
          }
        }
      }
      let taskInfo = {
        project: id,
        title: item.text,
        start_date: item.start_date,
        duration: item.duration,
        parent: parentTask,
      };
      createProjectTask(taskInfo)
    }
    if (action === 'create' && type === 'link') {
      let LinkPost = {
        projectId: id,
        source: item.source,
        target: item.target,
        type: item.type,
      };
      if (item.type === "0" || item.type === "1") {
        createProjectLink({ data: LinkPost });
      }
    }
  }

  const getdateInFormat = (date) => {
    ;
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = ('0' + month);
    }
    return `${date.getFullYear()}-${month}-${date.getDate()}`;
  };

  const setCalenderData = (tasks) => {
    let taskList = tasks.map((task, index) => {
      let startDate = new Date(task.CalenderStartDate);
      let actualEndDate = new Date(task.CalenderEndDate);
      let endDate = new Date(task.CalenderEndDate);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      endDate.setDate(endDate.getDate() + 1);


      let task_assignees_contractors = [];
      if (task.contractors && task.contractors.length > 0) {
        task_assignees_contractors = task.contractors.map(_con => {
          if (_con.contractor) {
            let nameInfo = (_con.contractor._contractor) ? _con.contractor._contractor.name : 'sd';
            return {
              id: _con.contractor.id,
              name: (nameInfo.last_name) ? `${nameInfo.first_name} ${nameInfo.last_name}` : nameInfo.first_name,
            }
          }
        }).filter(contractor => contractor !== undefined);

      }


      // let task_assignees_contractors = (task.contractors && task.contractors.length > 0) ? (
      //   task.contractors.map(_con => {
      //     let nameInfo = _con.contractor._contractor.name;
      //     return {
      //       id: _con.contractor.id, 
      //       name:(nameInfo.last_name) ? `${nameInfo.first_name} ${nameInfo.last_name}` : nameInfo.first_name,
      //     }})
      // ) : [];


      let task_assignees_managers = (task.assignees && task.assignees.length > 0) ? (
        task.assignees.map(__assignee => {
          let nameInfo = __assignee._manager.name;
          return {
            id: __assignee._id.toString(),
            name: (nameInfo.last_name) ? `${nameInfo.first_name} ${nameInfo.last_name}` : nameInfo.first_name,
          }
        })
      ) : [];


      let task_assigned_vendors = (task.contractors_temp && task.contractors_temp.length > 0) ? (
        task.contractors_temp.map(contractor => {
          let nameInfo = contractor._contractor.name;
          return {
            id: contractor._id.toString(),
            name: (nameInfo.last_name) ? `${nameInfo.first_name} ${nameInfo.last_name}` : nameInfo.first_name,
          }
        })
      ) : [];

      let task_assignees_all = task_assignees_contractors.concat(task_assignees_managers);

      //console.log(task_assignees_all);

      let task_assigned_vendors_id_lists = task_assigned_vendors.map(_con => _con.id);

      let task_assigned_contractor_id_list = task_assignees_contractors.map(_con => _con.id);

      let task_assigned_manager_id_list = task_assignees_managers.map(_man => _man.id);

      let ContractorInfo = task.contractors.filter(contractorObj => {
        return (contractorObj.contractor) && (contractorObj.contractor._id.toString() === user.id);
      });

      let confirmationStatus = (ContractorInfo.length > 0) ? ContractorInfo[0].confirmed_at : 'NA';


      if (confirmationStatus !== 'NA') {
        confirmationStatus = getdateInFormat(new Date(confirmationStatus)) || 'NA';
      }

      let TaskColor = task.color ? task.color : (task.task && task.task.color) ? task.task.color : '#8bc34a';
      // console.log(task.title)
      return {
        id: task.id,
        demoId: index + 1,
        project: id,
        title: (task.title) ? task.title : task.task.title,
        start: startDate,
        read_start_date: task.startDate,
        start_date: task.CalenderStartDate,
        _end_date: task.CalenderEndDate,
        end: endDate,
        actual_end_date: actualEndDate,
        calender_end_date: task.endDate,
        backgroundColor: (task.status === 2) ? '#e5aa13' : TaskColor,
        name: (task.title) ? task.title : task.task.title,
        text: (task.title) ? task.title : task.task.title,
        duration: task.duration,
        work_days: task.work_days,
        calender_duration: task.duration,
        category: (task.task) ? task.task.phase : '',
        color: (task.status === 2) ? '#e5aa13' : TaskColor,
        colorField: TaskColor,
        complete: (task.status === 2) ? true : false,
        // progress: (task.status === 2) ? 1 : 0.2,
        progress: (task.progress) ? task.progress : 0,
        predecessor: task.predecessor,
        owner_notes: task.owner_notes,
        sub_notes: task.sub_notes,
        internal_notes: task.internal_notes,
        all_notes: task.all_notes,

        contractors: task_assigned_vendors_id_lists,
        contractorNames: task_assignees_contractors.map(_con => _con.name),

        assignees: task_assigned_manager_id_list,
        assigneesNames: task_assignees_managers.map(_man => _man.name),

        allDay: true,
        taskId: (task.task) ? task.task.id : '',
        phase: (task.task) ? task.task.phase : '',
        startReadable: task.startReadable,
        endReadable: task.endReadable,

        isAssigned: (user.id && (task_assigned_vendors_id_lists.includes(user.id) || task_assigned_manager_id_list.includes(user.id))),
        taskMine: (user.id && task_assigned_contractor_id_list.includes(user.id)),

        isTaskManager: (user.id && (task_assigned_manager_id_list.includes(user.id))),

        userId: user.id,
        taskAssignees: task_assignees_contractors,
        confirmationStatus: confirmationStatus,
        files: (task.attachments && task.attachments.length > 0) ? task.attachments : [],
        uploadedFiles: (task.attachments && task.attachments.length > 0) ? task.attachments : [],
        sort: task.sort,

        completedBy: (task.completedBy) ? task.completedBy : [],
        all_assignees: task_assignees_all,
        contractor_assignees: task_assignees_contractors,
        manager_assignees: task_assignees_managers,
        isOverrun: task.isOverrun,
      }

    });
    return taskList;
  }

  const setLinksData = (linkList) => {
    let linkListData = linkList.map((link) => {
      return {
        id: link.id,
        source: link.source,
        target: link.target,
        type: (link.type === '') ? '0' : link.type,
        //color: 
      }
    });
    return linkListData;
  }

  const createProjectTask = (taskInfo) => {
    setLoading(true);
    setValue(0);
    dataProvider.createProjectTask('projecttask', { data: taskInfo }).then(({ data }) => {
      setdata(setCalenderData(data.tasks));
      setfilteredTasks(setCalenderData(data.tasks));
      setLinks(setLinksData(data.links));
      setValue(1);
      setCurrentSort('None');
      setCurrentTaskSort('None');
      setLoading(false);
    })
      .catch(err => {
        setLoading(false);
        setValue(1);
      });
  }

  const updateTaskDetails = (taskId, data, isGhant = false) => {
    setLoading(true);
    if (isGhant) { setValue(0); }
    dataProvider.updateProjectTask(taskId, data).then(({ data }) => {
      setLoading(false);
      setdata(setCalenderData(data.tasks));
      setfilteredTasks(setCalenderData(data.tasks));
      setLinks(setLinksData(data.links));
      if (isGhant) { setValue(1); }
      setCurrentSort('None');
      setCurrentTaskSort('None');
    })
      .catch(error => {
        setLoading(false);
      });
  }
  const createProjectLink = (data) => {
    setLoading(true);
    setValue(0);
    dataProvider.createProjectLink(data).then(({ data }) => {
      setLoading(false);
      setdata(setCalenderData(data.tasks));
      setfilteredTasks(setCalenderData(data.tasks));
      setLinks(setLinksData(data.links));
      setValue(1);
      setCurrentSort('None');
      setCurrentTaskSort('None');
    })
      .catch(error => {
        setValue(1);
        setLoading(false);
      });
  }
  const deleteProjectLinks = (linkId, data) => {
    setLoading(true);
    setValue(0);
    dataProvider.deleteProjectLink(linkId, data,).then(({ data }) => {
      setLoading(false);
      setdata(setCalenderData(data.tasks));
      setfilteredTasks(setCalenderData(data.tasks));
      setLinks(setLinksData(data.links));
      setValue(1);
      setCurrentSort('None');
      setCurrentTaskSort('None');
    })
      .catch(error => {
        setLoading(false);
        setValue(1);
      });
  }
  const sortGhantData = (phaseId, sortTitle) => {
    setLoading(true);
    setValue(0);
    setCurrentSort(sortTitle);
    let filteredTasks;
    if (phaseId === 'none') {
      filteredTasks = data;
    } else {
      filteredTasks = data.filter((task) => {
        return task.phase === phaseId.toString();
      });

    }
    setCurrentTaskSort('None');
    setSearchQuery('');
    setTimeout(
      () => {
        setfilteredTasks(filteredTasks);
        setValue(1);
        setLoading(false);
      },
      1000
    );
  }
  const sortByTask = (taskId, taskTitle) => {
    setLoading(true);
    setValue(0);
    setCurrentTaskSort(taskTitle);
    let filteredTasks;
    if (taskId === 'none') {
      filteredTasks = data;
    } else {
      filteredTasks = data.filter((task) => {
        return task.id === taskId.toString();
      });
    }
    setCurrentSort('None');
    setSearchQuery('');
    setTimeout(
      () => {
        setfilteredTasks(filteredTasks);
        setValue(1);
        setLoading(false);
      },
      1000
    );
  }
  const serachTask = (taskQuery) => {
    setLoading(true);
    setValue(0);
    let filteredTasks;
    if (!taskQuery) {
      setSearchQuery('');
      filteredTasks = data;
    } else {
      setSearchQuery(taskQuery);
      filteredTasks = data.filter((task) => {
        return task.title.toLowerCase().indexOf(taskQuery.toLowerCase()) !== -1;
      });
    }
    setCurrentSort('None');
    setCurrentTaskSort('None');
    setTimeout(
      () => {
        setfilteredTasks(filteredTasks);
        setValue(1);
        setLoading(false);
      },
      1000
    );
  }
  const refreshTaskData = () => {
    setLoading(true);
    dataProvider.getProjectDetailInstance(id).then(({ data }) => {
      let formattedTasks = setCalenderData(data.tasks);
      setProject(data.project);
      setdata(formattedTasks);
      setfilteredTasks(formattedTasks);
      setLinks(setLinksData(data.links));
      setFields(data.phaseList);
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      });
  }
  const deleteProjectTask = (taskId) => {
    handleClose();
    setLoading(true);
    dataProvider.removeProjectTask({ data: { task: taskId, project: id } }).then(({ data }) => {
      refreshTaskData();
    })
      .catch(error => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setdata([]);
    if (!loading) setLoading(true);
    dispatch(setSidebarVisibility(false));
    dataProvider.getProjectDetailInstance(id).then(({ data }) => {
      let formattedTasks = setCalenderData(data.tasks);
      setProject(data.project);
      setdata(formattedTasks);
      setfilteredTasks(formattedTasks);
      setLinks(setLinksData(data.links));
      setFields(data.phaseList);
      if (parsedQuery && parsedQuery.taskId) {
        let selectedTask = formattedTasks.find(_task => _task.id === parsedQuery.taskId);
        if (selectedTask && selectedTask !== undefined) {
          handleDialogOpen(selectedTask);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
      .catch(error => {
        setLoading(false);
        // console.log(error);
      });

    return () => {
      dispatch(setSidebarVisibility(true));
    };
  }, [id]);

  if (loading || !permissions) return <Loading loadingPrimary="" loadingSecondary="" />;
  if (!data) return null;

  return (
    <>
      {(project && (
        <Paper square className={classes.titlePaper}>
          <Typography variant="h5" color="inherit" display="inline">
            {`${project.title}, ${project.description}`}
          </Typography>
          <Typography variant="body2" color="inherit" display="inline">
            {` Date of commencement: ${project.startDate}.`}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={refreshTaskData}
          >
            refresh
          </Button>
          {/* <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={() => {
              console.log(routeHistory)
              //routeHistory.pop(`/projects/${id}`);
              //routeHistory.replace(`/projects/${id}`);
            }}
          >
            goback
          </Button> */}
        </Paper>
      ))}

      <Paper square className={classes.titlePaper}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Show Calender" classes={{ root: classes.tab, selected: classes.selectedTab }} />

          {(permissions && (permissions.role === 'admin' || (permissions.role === 'manager' && project.isAssigned))) && (
            <Tab label="Show Gantt" classes={{ root: classes.tab, selected: classes.selectedTab }} />
          )}

          <Tab label="Show List" classes={{ root: classes.tab, selected: classes.selectedTab }} />

        </Tabs>
      </Paper>
      {(value === 0) ? (
        <FullCalender data={data} updateTask={updateTaskEvent} dialogOpen={handleDialogOpen}
          isEdit={(permissions && ((permissions.role === 'admin') || (permissions.role === 'manager' && project.isAssigned))) ? true : false}
        />
      ) : (
        null
      )}

      {(value && value === 1 && permissions && (permissions.role === 'admin' || (permissions.role === 'manager' && project.isAssigned))) ? (
        // <GhantChart data={data} updateTask={updateTaskEvent} dialogOpen={handleDialogOpen} />
        <>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}>
            <SortButton fields={fields} sortGhantData={sortGhantData} currentSort={currentSort} />
            <TaskSortButton tasks={data} sortByTask={sortByTask} currentTaskSort={currentTaskSort} />
            <TaskSearchButton searchQuery={searchQuery} serachTask={serachTask} />
          </div>
          <div className="gantt-container">
            <Gantt
              tasks={
                {
                  data: filteredTasks,//data
                  links: links, // { id: 1, source: 1, target: 2, type: '0' }
                }
              }
              onDataUpdated={TaskDataUpdate}
              onClick={handleDialogOpen}
              isDraw={(permissions && ((permissions.role === 'admin') || (permissions.role === 'manager' && project.isAssigned))) ? true : false}
            //taskDataClick
            //zoom={'Months'}
            />
          </div>
        </>
      ) : (
        null
      )}

      {(value && ((permissions.role === 'admin' && value === 2) || (permissions.role === 'contractor' && value === 1) ||
        ((permissions.role === 'manager' && value === 2 && project.isAssigned) || (permissions.role === 'manager' && value === 1 && !project.isAssigned)))) ? (
        //<TaskList data={data} dialogOpen={handleDialogOpen} role={permissions.role} projectAsssigned={project.isAssigned} />
        (permissions.role === 'admin' || project.isAssigned) ? (
          <ProjectTaskList dialogOpen={openDialogFromList} {...{ id, match, location, history }} role={permissions.role} projectAsssigned={project && project.isAssigned} />
        ) : (<TaskList data={data} dialogOpen={handleDialogOpen} role={permissions.role} projectAsssigned={project.isAssigned} />)

      ) : (
        null
      )}

      {permissions && selectedEvent && (permissions.role === 'admin' || permissions.role === 'manager') && (
        <EventEditDialog
          open={openDialog}
          event={selectedEvent}
          onClose={handleClose}
          editProjectTask={editProjectTask}
          role={permissions.role}
          projectAssigned={project.isAssigned}
          deleteTask={deleteProjectTask}
        />
      )}

      {selectedEvent && selectedEvent.id && permissions && (permissions.role === 'contractor' || (permissions.role === 'manager' && !project.isAssigned)) && (
        <>
          <ContractorTaskInfo
            open={openDialog}
            taskId={selectedEvent.id}
            onClose={handleClose}
            editProjectTask={editProjectTask}
            role={permissions.role}
            isTaskManager={(selectedEvent.extendedProps) ? selectedEvent.extendedProps.isTaskManager : selectedEvent.isTaskManager}
          />
        </>
      )}
    </>
  )
}
const useStyles = makeStyles((theme) => ({
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  tab: {
    minWidth: 130,
    width: 130,
    background: '#fff',
    fontSize: 13,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: ' #555cb1',
    color: ' #fff !important',
  },
  tabIndicator: {
    // background:' #555cb1',
    // color:' #fff !important',
    // height: '100%',
  },
}));


