import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  NumberInput,
  DateInput,
  useDataProvider,
  useRedirect,
  Loading
} 
from 'react-admin';
import CreateIcon from '@material-ui/icons/Create';
import { InputAdornment,Typography,Grid } from '@material-ui/core';

function CostEdit({id}) {
  const [ costDetail, setCostDetail] = useState();
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const classes = useStyles();

  const onSave = (data) => {
    data = {...data, projectId:costDetail.project};
    dataProvider.update('projectcost',{data,id:id})
    .then(({ data }) => {
      redirect(`/project-costs/${costDetail.project}`);
    })
    .catch(error => {
    });
  }

  useEffect(() => {
    dataProvider.getOne('projectcost',{ id: id })
      .then(({ data }) => {
        setCostDetail(data);
        setLoading(false);
      })
      .catch(error => {
        // console.log(error);
        setLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading loadingPrimary="" loadingSecondary="" />;
  if(!costDetail) return null;

  return(
    <SimpleForm save={onSave}>
      <SectionHeading label="Edit Expence" classProp={classes.heading}/>
      <SelectInput
        source="type"
        variant="outlined"
        label="Select Expense Type"
        choices={[
          { id: 'labour', name: 'Labour' },
          { id: 'travel', name: 'Travel' },
          { id: 'purchase', name: 'Purchase' },
          { id: 'other', name: 'Other' },
        ]}
        initialValue={costDetail.type}
        validate={required()}
        fullWidth={true}
      />
      <NumberInput
        source="cost"
        validate={required()}
        variant="outlined"
        label="Cost"
        fullWidth={true}
        initialValue={costDetail.cost}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              AUD
            </InputAdornment>
          ),
        }}
      />
      <DateInput
        source="cost_date"
        label="Date"
        fullWidth={true}
        variant="outlined"
        validate={required()}
        initialValue={costDetail.cost_date}
      />
      <TextInput initialValue={costDetail.remarks} source="remarks" fullWidth={true} variant="outlined" multiline />
             
    </SimpleForm>
  )
}
const SectionHeading = ({ label, classProp }) => {
  return (
    <Grid container direction="row" alignItems="center" className={classProp}>
      <CreateIcon />
      <Typography variant="h6" gutterBottom >
        {label}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles({
  heading: {
    padding: 10,
    '& h6': {
      fontSize: 14,
      paddingLeft: 5,
      fontWeight: 'bold',
      letterSpacing: 1,
      color:'#e58f0a',
      textTransform: 'uppercase',
    }
  },
});

export default CostEdit;
