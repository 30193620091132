import * as React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
// import SortIcon from '@material-ui/icons/Sort';
import ListAltSharpIcon from '@material-ui/icons/ListAltSharp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

const TaskSortButton = ({tasks,sortByTask,currentTaskSort}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();
  
  const handleChangeTask = (event) => {
    sortByTask(event.currentTarget.dataset.sort,event.currentTarget.dataset.sorttitle);
    setAnchorEl(null);
  };
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if(!tasks) return null;

  return (
    <div className={classes.container}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
        startIcon={<ListAltSharpIcon />}
        endIcon={<ArrowDropDownIcon />}

      >
       {`TASK ${currentTaskSort}`}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
            onClick={handleChangeTask}
            data-sort={'none'}
            data-sorttitle={'None'}
            key={0}
          >
            {'None'}
        </MenuItem>
        {tasks.map(field => (
          <MenuItem
            onClick={handleChangeTask}
            data-sort={field.id}
            data-sorttitle={field.title}
            key={field.id}
          >
            {field.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles((theme) =>({
  container: {
    margin:'15px 10px',
    textAlign: 'right',
  }
}));


export default TaskSortButton;
