/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect,useState} from 'react';
import Chart from "react-google-charts";
import { useDataProvider } from 'react-admin';
import ChartLoader from './ChartLoader';

export default function PieChart({projectId}) {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState();

  useEffect(() => {
    dataProvider.getProjectTasksStatus({filter: {project: projectId}})
    .then(({ data }) => {
      setChartData(data);
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
    });

  },[projectId]);

  if(loading) {return <ChartLoader /> ;}
  if(!chartData) {return null;}

  return (
    <Chart
      //width={'150px'}
      height={'150px'}
      chartType="PieChart"
      loader={<ChartLoader />}
      data={chartData}
      options={{
        // title: 'Project Task Status:',
        // Just add this option
        is3D: true,
        colors: ['#05b30d', '#ff0000','#37a1f9', '#ff6700'],
      }}
      rootProps={{ 'data-testid': '2' }}
    />
  );
}
