import * as React from 'react';
import { useState } from 'react';

import { Field, withTypes } from 'react-final-form';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';

import {Notification, useNotify, useRedirect, useAuthProvider,Loading } from 'react-admin';

import InputAdornment from "@material-ui/core/InputAdornment";

import { lightTheme } from '../layout/themes';

import Logo from '../layout/Logo';

const queryString = require('query-string');

const { Form } = withTypes();

const ResetPassword = (props) => {

  const parsedQuery = queryString.parse(props.location.search);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState();
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const authProvider = useAuthProvider();

  const handleSubmit = (data) => {
    setLoading(true);
    authProvider.postResetPasssword({userId: userId,password: data.password}).then((response) => {
      setLoading(false);
      notify('Please Login With Created Passsword','info');
      redirect('/login');
    })
    .catch(err => {
      setLoading(false);
      notify(err.message,'warning');
    })
  };

  const validate = (values) => {
      const errors = {};
      if (!values.password) {
        errors.password = "required";
      }
      if (values.password && values.password.length < 5) {
        errors.password = "Should Contain 5 characters";
      }
      if (!values.confirmpassword) {
        errors.confirmpassword = "required";
      }
      if (values.password !== values.confirmpassword) {
        errors.confirmpassword = "Password doesn't match";
      }
      return errors;
  };

  React.useEffect(() => {
    if(parsedQuery && parsedQuery.token) {
      authProvider.getResetPasssword({token: parsedQuery.token}).then((response) => {
        setLoading(false);
        setUserId(response.userId);
      })
      .catch(err => {
        setLoading(false);
        redirect('/');
      })
    } else {
      setLoading(false);
      redirect('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  if(loading) return <Loading />;
  if(!userId) return null;

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Logo />
              </div>
              <div className={classes.form}>
                <Field
                  autoFocus
                  name="password"
                  type="password"
                  component={renderInput}
                  label={"Password"}
                  disabled={loading}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <LockIcon className={classes.icon}/>
                      </InputAdornment>
                    ),
                    className: classes.multilineColor
                  }}
                />
              </div>
              <div className={classes.form}>
                <Field
                  name="confirmpassword"
                  type="password"
                  component={renderInput}
                  label={"Confirm Password"}
                  disabled={loading}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <LockIcon className={classes.icon}/>
                      </InputAdornment>
                    ),
                    className: classes.multilineColor
                  }}
                />
              </div>      
              {(loading) ? (
                <div className={classes.loader} >
                  <CircularProgress
                    size={25}
                    thickness={5}
                  />
                </div>
              ) : (
                <CardActions className={classes.actions}>
                
                  <Button
                    className={classes.button}
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    Reset Your Password
                  </Button>
                </CardActions>
              )}
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (

<TextField
  error={!!(touched && error)}
  helperText={touched && error}
  {...inputProps}
  {...props}
  fullWidth
/>
);

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(2376627.jpg)',
    // background: 'rgb(186, 149, 56)',
    // background: '#27424b',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    backgroundColor: '#fff',
  },
  card: {
    minWidth: 310,
    marginTop: '6em',
    borderRadius: 15,
    // background: '#20353c',
    background: '#282a3c',
    border: '1px solid #ebbd17',
    // background: 'rgb(157 116 14)',
  },
  avatar: {
    marginTop: '2em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    root: {
      background: 'red',
    }
    // backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    marginBottom: '1em',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    color:'#dadbe6',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  button: {
    margin: '20px 0',
    background: '#ffbe2e',
    borderRadius: 0,
    '&:hover':{
      background: '#ebbd17',
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 0',
  },
  multilineColor:{
    color:'#dadbe6',
  }
}));


const ResetPasswordWithTheme = (props) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <ResetPassword {...props} />
  </ThemeProvider>
);

export default ResetPasswordWithTheme;
