/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect, useState} from 'react';
import { 
  TextInput,
  required,
  SimpleForm,
  RadioButtonGroupInput,
  NumberInput,
  FormDataConsumer,
  FileInput,
  FileField,
  SelectInput,
  PasswordInput,
  DateInput,
  Loading,
  SaveButton,
  Toolbar,
  useDataProvider,
  useNotify,
  email
} from 'react-admin';

import {Grid,Button,InputAdornment,Paper,Tabs,Tab } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ExtraTheme } from '../layout/themes';
import clsx from 'clsx';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SuccessDialog from './SuccessDialog';

const queryString = require('query-string');

function ProjectForm(props) {

  let parsedQuery = queryString.parse(props.location.search);
  const classes = useStyles();
  const [Tabvalue, setTabValue] = useState(0);
  const [personalData, setPersonalData] = useState({
    email: '',
    first_name: '',
    surname: ''
  });
  const [inviteId, setInviteId] = useState()
  const [activatedTabs, setActivatedTabs] = useState([0]);
  const [formValues, setFormValues] = useState();
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  };

  const changeTabView = (value) => {
    setTabValue(value);
  };

  const handleChange = (event, tabValue) => {
    setTabValue(tabValue);
  };

  const onSubmit = (values) => {
    //console.log(Object.assign(formValues, values));
    setLoading(true);
    if(inviteId) {
      values['invite_id'] = inviteId;
    }

    dataProvider.createProjectFromForm(Object.assign(formValues, values))
    .then(response => {
      setLoading(false);
      setTabValue(0);
      setOpenSuccess(true);
      setFormValues();
      setActivatedTabs([0]);
      setPersonalData({
        email: '',
        first_name: '',
        surname: ''
      });
    })
    .catch(error => {
      //console.log(error);
      alert(error.message);
      setLoading(false);
      notify(`${error.message}`, 'warning');
    });

  };

  const onNextSubmit = (values) => {
    setFormValues(prevValues => {
      if(prevValues) { return Object.assign(prevValues, values)}
      else {return Object.assign({}, values)};
     
    });
    setTabValue(Tabvalue + 1);
    setActivatedTabs(oldArray => [...oldArray,Tabvalue + 1] );
  };
  
  useEffect(() => {
    if(parsedQuery && parsedQuery.id) {
      dataProvider.getInvitedInfo('invite-client', { id: parsedQuery.id })
      .then(({ data }) => {
        setInviteId(parsedQuery.id);
        setPersonalData(data);
      })
      .catch(error => {
      })
    }
  }, []);
  
  if(loading) {return <Loading />}

  return (

    <div className={classes.main}>

      <div className={classes.imageHolder}>
        <img src={process.env.PUBLIC_URL + '/logo-small.png'} className={classes.AdminLogo} alt="logo" />
        <div className={classes.headingTitle}>Welcome to nexaHomes</div>
      </div>

      <div className={classes.container}>
        <Paper square className={classes.paper}>
            <Tabs
              value={Tabvalue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab 
                label="personal Details"
                classes={{ root: classes.tab,selected: classes.selectedTab }}
                disabled={!activatedTabs.includes(0)}
              />
              <Tab
                label="Site Details"
                classes={{ root: classes.tab,selected: classes.selectedTab }}
                disabled={!activatedTabs.includes(1)}
              />
              <Tab
                label="Building Details"
                classes={{ root: classes.tab,selected: classes.selectedTab }}
                disabled={!activatedTabs.includes(2)}
              />
              <Tab
                label="Floor Plan"
                classes={{ root: classes.tab,selected: classes.selectedTab }}
                disabled={!activatedTabs.includes(3)}
              />
              <Tab
                label="Facade"
                classes={{ root: classes.tab,selected: classes.selectedTab }}
                disabled={!activatedTabs.includes(4)}
              /> 
            </Tabs>
          </Paper>

        <TabContainer id={0} active={Tabvalue === 0}>
          <SimpleForm
            save={onNextSubmit}
            initialValues={personalData}
            validate={validateUserCreation}
            toolbar={
              <NavigationToolBar
                tabIndex={Tabvalue}
                changeTabBar={changeTabView}
                activatedTabs={activatedTabs}
              />
            }
          >
            <Grid container spacing={1} fullWidth={true}>
              <Grid item xs={12} sm={6}>
                <TextInput 
                  source="first_name"
                  label="First Name"
                  fullWidth={true}
                  variant="outlined"
                  validate={required()}
                  helperText="Your firstname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput 
                  source="surname"
                  label="Surname"
                  fullWidth={true}
                  variant="outlined"
                  // validate={required()}
                  helperText="Your lastname/Surname"
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} fullWidth={true}>
              <Grid item xs={12} sm={4}>
                <TextInput
                  source="email"
                  label="Email"
                  fullWidth={true}
                  variant="outlined"
                  // validate={required()}
                  validate={validateEmail}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  source="contact"
                  label="Phone Number"
                  fullWidth={true}
                  variant="outlined"
                  validate={required()}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PasswordField classes={classes} />
              </Grid>
            </Grid>
            <AddressField classes={classes} />
            {/* <ButtonToolbar classes={classes} tabIndex={Tabvalue} changeTabBar={changeTabView} /> */}
          </SimpleForm>
        </TabContainer>
          
        <TabContainer id={1} active={Tabvalue === 1}>
          <SimpleForm
            save={onNextSubmit}
            toolbar={
              <NavigationToolBar
                tabIndex={Tabvalue}
                changeTabBar={changeTabView}
                activatedTabs={activatedTabs}
              />
            }
          >
            <PropertyAddressField classes={classes} />
            <TextInput
              source="council"
              label="Council"
              //fullWidth={true}
              variant="outlined" 
              validate={required()}
            />
            <div className={classes.filedTitle}> Size of land </div>
            <LandSize />
            <RadioButtonGroupInput
              label="Type"
              source="site_block"
              fullWidth={true}
              validate={required()}
              choices={[
                { id: 'normal block', name: 'Normal block'},
                { id: 'corner block', name: 'Corner Block' },
              ]}
              optionText="name" optionValue="id"
            />
            <RadioButtonGroupInput
              label="Type"
              source="site_type"
              fullWidth={true}
              validate={required()}
              choices={[
                { id: 'new land', name: 'New Land'},
                { id: 'knocked down', name: 'Knocked down/rebuild' },
              ]}
              optionText="name" optionValue="id"
            />
            {/* <div className={classes.filedTitle}>Has the client completed the survey?</div> */}
            {/* <div className={classes.filedTitle}>Is land registered?</div> */}
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  formData.site_type && formData.site_type === 'new land' && (
                    <RegisterationField classes={classes} />
                  )
                )
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  formData.site_type && formData.site_type !== 'new land' && (
                    <SurveyField />
                  )
                )
              }}
            </FormDataConsumer>
            <TextInput
              source="survey_comment"
              label="Add Comments"
              fullWidth={true}
              variant="outlined"
              helperText="Add comments about survey plan if any."
              multiline
            />
          </SimpleForm>
        </TabContainer>
        
        <TabContainer id={2} active={Tabvalue === 2}>
          <SimpleForm
            save={onNextSubmit}
            toolbar={
              <NavigationToolBar
                tabIndex={Tabvalue}
                changeTabBar={changeTabView}
                activatedTabs={activatedTabs}
              />
            }
          >
            {/* <SurveyField /> */}
            <div className={classes.filedTitle}>How many squares are you planning to build?</div>
            {/* <NumberInput
              source="squares"
              label="Squares"
              //validate={required()}
              variant="outlined"
              fullWidth={true}
              step={1}
              min={1}          
            /> */}
        
            <BuildingInfo classes={classes} />
          </SimpleForm>
        </TabContainer>
        
        <TabContainer id={3} active={Tabvalue === 3}>
          <SimpleForm
            save={onNextSubmit}
            toolbar={
              <NavigationToolBar
                tabIndex={Tabvalue}
                changeTabBar={changeTabView}
                activatedTabs={activatedTabs}
              />
            }
          >
            <div className={classes.filedTitle}>ground floor</div>
            <Grid spacing={1} container direction="row" alignItems="center" fullWidth={true}>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  label="Bedrooms"  
                  source="bedrooms_ground"
                  //validate={required()}
                  variant="outlined"
                  fullWidth={true}
                  helperText="How many berdrooms Needed for your ground floor?"
                  step={1}
                  min={1}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  label="media Room"  
                  source="mediarooms_ground"
                  // validate={required()}
                  variant="outlined"
                  fullWidth={true}
                  step={1}
                  min={1}
                />
              </Grid>
              
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                return (
                  // formValues && formValues.storey && (formValues.storey === 'single') && (
                  formValues && formValues.storey && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Butler Pantry"  
                          source="butlerpantry_ground"
                          //validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Bathroom"  
                          source="bathroom_ground"
                          // validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Bathroom With Shower"  
                          source="bathroom_shower_ground"
                          // validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Informal Lounge"  
                          source="informallounge_ground"
                          //validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Laundry"  
                          source="laundry_ground"
                        // validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Dining"  
                          source="dining_ground"
                          //validate={required()}
                          variant="outlined"
                          step={1}
                          min={1}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Alfersco"  
                          source="alfersco_ground"
                        // validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Formal Lounge"  
                          source="formal_lounge_ground"
                          //validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Kitchen"  
                          source="kitchen_ground"
                        // validate={required()}
                          variant="outlined"
                          step={1}
                          min={1}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Powder Room"  
                          source="powderroom_ground"
                          // validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="Butler kitchen"  
                          source="butlerkitchen_ground"
                          //validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumberInput
                          label="BBQ"  
                          source="bbq_ground"
                          //validate={required()}
                          variant="outlined"
                          fullWidth={true}
                          step={1}
                          min={1}
                        />
                      </Grid>
                    </>
                  )
                )}}
              </FormDataConsumer>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  label="Study"  
                  source="study_ground"
                  //validate={required()}
                  variant="outlined"
                  fullWidth={true}
                  step={1}
                  min={1}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  label="Pantry"  
                  source="pantry_ground"
                  //validate={required()}
                  variant="outlined"
                  fullWidth={true}
                  step={1}
                  min={1}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumberInput
                  label="Garage"  
                  source="garage_ground"
                  // validate={required()}
                  variant="outlined"
                  fullWidth={true}
                  step={1}
                  min={1}
                />
              </Grid>
              <TextInput
                source="ground_plan_comment"
                label="Add Comments"
                fullWidth={true}
                variant="outlined"
                helperText="Add your Comments for Ground FLoor."
                multiline
              />
            </Grid>
            
            
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  formValues && formValues.storey && (formValues.storey === 'double' || formValues.storey === 'triple') && (
                    <>
                      <div className={classes.filedTitle}>first floor</div>
                      <Grid spacing={1} container direction="row" alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Bedrooms"  
                            source="bedrooms_first"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Study"  
                            source="study_first"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Rumpus"  
                            source="rumpus_first"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Bathroom"  
                            source="bathroom_first"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Bathroom With Shower"  
                            source="bathroom_shower_first"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Media Room"  
                            source="media_room_first"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                      </Grid>
                      <TextInput
                        source="first_plan_comment"
                        label="Add Comments"
                        fullWidth={true}
                        variant="outlined"
                        helperText="Add your Comments for First Floor."
                        multiline
                      />
                    </>
                  )
                )
              }}
            </FormDataConsumer>
            
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  formValues && formValues.storey && formValues.storey === 'triple' && (
                    <>
                      <div className={classes.filedTitle}>Second floor</div>
                      <Grid spacing={1} container direction="row" alignItems="center">
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Bedrooms"  
                            source="bedrooms_second"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Study"  
                            source="study_second"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Rumpus"  
                            source="rumpus_second"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Bathroom"  
                            source="bathroom_second"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Bathroom With Shower"  
                            source="bathroom_shower_second"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberInput
                            label="Media Room"  
                            source="media_room_second"
                            //validate={required()}
                            variant="outlined"
                            fullWidth={true}
                            step={1}
                            min={1}
                          />
                        </Grid>
                        
                      </Grid>
                      <TextInput
                        source="second_plan_comment"
                        label="Add Comments"
                        fullWidth={true}
                        variant="outlined"
                        helperText="Add your Comments for Second Floor."
                        multiline
                      />
                    </>
                  )
                )
              }}
            </FormDataConsumer>
          </SimpleForm>
        </TabContainer>
        
        <TabContainer id={4} active={Tabvalue === 4}>
          <SimpleForm
            save={onSubmit}
            toolbar={<FormToolbar tabIndex={Tabvalue} />} 
          >
            <RadioButtonGroupInput
              source="facade_type"
              fullWidth={true}
              label="Choose facade"
              validate={required()}
              choices={[
                { id: 'hip roof', name: 'Hip Roof'},
                { id: 'flat roof', name: 'Flat Roof'},
                { id: 'skillion roof', name: 'skillion Roof'},
                { id: 'skillion and flat roof', name: 'Skillion and Flat Roof'},
                { id: 'hip roof and skillion', name: 'Hip Roof and Skillion'},
                { id: 'hip and flat roof', name: 'Hip And Flat Roof'},
                { id: 'hampton facade', name: 'Hampton Facade'},
                { id: 'other', name: 'Other'},
              ]}
              optionText="name" optionValue="id"
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  formData.facade_type && (formData.facade_type !== '' && formData.facade_type !== 'other') && (
                    <div className={classes.imageContainer}>
                      <img alt=""className={classes.imgStyle} src={process.env.PUBLIC_URL + '/' + formData.facade_type + '.jpg'} />
                    </div>  
                  )
                )
              }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    formData.facade_type && formData.facade_type === 'other' && (
                      <TextInput
                        source="facade_comment"
                        label="Add Comments"
                        fullWidth={true}
                        variant="outlined"
                        helperText="Add your Comments for facade."
                        multiline
                      />
                    )
                  )
                }}
              </FormDataConsumer>

          </SimpleForm>
        </TabContainer>
        
        <SuccessDialog open={openSuccess} handleClose={handleSuccessClose} />
      </div>
  
    </div>
    );
}

const validateEmail = [required(),email()];

const validateUserCreation = async (values) => {
  const errors = {};
  try {
    if(values.email && values.email !== '') {
      const isEmailUnique = await checkEmailIsUnique(values.email);
      if(isEmailUnique && isEmailUnique.status) {

      } else {
        errors.email = ['Email is already registered Please use another one'];
      }
    }
    return errors
  } catch(err) {
    return errors
  }

};

const checkEmailIsUnique = async (email) => {

  const response = (await fetch('https://www.nexaplan.com.au:3000/auth/verify-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email : email})
  })).json();
  return response;
}

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    //float: 'right',
  },
});

const NavigationToolBar = props => {
  const toolbarStyles = useToolbarStyles();
  return (
    <Toolbar {...props} classes={toolbarStyles} >
       <Button
        variant="contained"
        color="primary"
        disabled={!props.activatedTabs.includes(props.tabIndex-1)}
        //className={classes.previousButton}
        onClick={() => props.changeTabBar(props.tabIndex - 1)}
      >
        Previous
      </Button>
      <SaveButton
        label="Next"
        icon={<NavigateNextIcon />}
        //variant="outlined"
        //className={}
        //redirect="show"
        //submitOnEnter={true}
      />
    </Toolbar>
  )
};

const FormToolbar = props => {
  const toolbarStyles = useToolbarStyles();
  return(
    <>
      {props.tabIndex &&  props.tabIndex === 4 ? (
        <Toolbar {...props} classes={toolbarStyles} >
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.changeTabBar(props.tabIndex - 1)}
          >
            Previous
          </Button>
          <SaveButton
            label="Submit"
            //icon={<NavigateNextIcon />}
            //variant="outlined"
            //className={}
            //redirect="show"
            //submitOnEnter={true}
          />
        </Toolbar>
      ) : null}
    </>
  );
}

const LandSize = () => (
  <Grid container spacing={1}>
     <Grid item xs={12} sm={4}>
      <NumberInput
        source="landsize"
        label="Land Size"
        validate={required()}
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              SQM
            </InputAdornment>
          ),
        }}
        step={1}
        min={1}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <NumberInput
        source="frontage"
        validate={required()}
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              SQM
            </InputAdornment>
          ),
        }}
        step={1}
        min={1}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <NumberInput
        source="length"
        validate={required()}
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              SQM
            </InputAdornment>
          ),
        }}
        step={1}
        min={1}
      />
    </Grid>
  </Grid>
);

const RegisterationField = ({classes}) => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={12}>
      <RadioButtonGroupInput
        source="land_registered"
        label="Is land registered?"
        validate={required()}
        choices={[
          { id: 'yes', name: 'Yes'},
          { id: 'no', name: 'No' },
        ]}
        optionText="name" optionValue="id"
      />
    </Grid>
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        return (
          formData.land_registered && formData.land_registered === 'yes' && (
           <SurveyField />
          )
        )
      }}
    </FormDataConsumer>
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        return (
          formData.land_registered && formData.land_registered === 'no' && (
            <>
              <div className={classes.filedTitle}>What is the estimated registration date?</div>
              <DateInput
                label="Registeration Date"
                source="land_registeration_date"
                variant="outlined"
                //fullWidth={true}
                //validate={required()}
              />
              <FileInput
                source="no_land_registered_contract_files"
                label=""
                accept="application/pdf"
                placeholder="Upload front page of contract of sales"
                //validate={required()}
                multiple={true}
              >
                <FileField source="src" title="title" />
              </FileInput>
            </>
          )
        )
      }}
    </FormDataConsumer>
  </Grid>
);

const SurveyField = () => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={12}>
      <RadioButtonGroupInput
        source="survey_completed"
        // label="Do you have a survey or contour plan ?"
        label="Are survey details available?"
        choices={[
          { id: 'yes', name: 'Yes'},
          { id: 'no', name: 'No' },
        ]}
        validate={required()}
        optionText="name" optionValue="id"
      />
    </Grid>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.survey_completed && formData.survey_completed === 'yes' && (
              <>
                <FileInput source="survey_files"
                  label="upload survey/contour plan"
                  accept="application/pdf"
                  placeholder="Click here to upload survey/contour plan"
                  //validate={required()}
                  multiple={true}
                >
                  <FileField source="src" title="title" />
                </FileInput>
                <FileInput source="survey_dwg_files"
                  label="upload survey/contour plan"
                  accept="application/pdf"
                  placeholder="Click here to upload DWG FIle"
                  //validate={required()}
                  multiple={true}
                >
                < FileField source="src" title="title" />
                </FileInput>
              </>
            )
          )
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.survey_completed && formData.survey_completed === 'no' && (
              <>
                {(formData.site_type && formData.site_type === 'new land') ? (
                  <FileInput
                    source="contract_files"
                    label="Upload council rates or front page of contract of sales"
                    accept="application/pdf"
                    placeholder="Click here to upload council rates"
                    // validate={required()}
                    multiple={true}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                ) : (
                  <FileInput
                    source="contract_files"
                    label="Upload council rates or front page of contract of sales"
                    accept="application/pdf"
                    placeholder="Click here to upload council rates/contract of sale"
                    // validate={required()}
                    multiple={true}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                )}
             
              </>
            )
          )
        }}
      </FormDataConsumer>
  </Grid>
);

const BuildingInfo = () => (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={4}>
      <NumberInput
        source="squares"
        label="Squares"
        //validate={required()}
        variant="outlined"
        fullWidth={true}
        step={1}
        min={1}          
      />
    </Grid>
   
    <Grid item xs={12} sm={4}>
      <SelectInput source="building_type"
        label="Building Type"
        variant="outlined"
        options={{ fullWidth: true }}
        choices={[
          { id: 'house', name: 'House' },
          { id: 'duplex', name: 'Duplex' },
          { id: 'house with granny', name: 'House with granny'},
          { id: 'split-level home', name: 'Split-Level Home'},
          { id: 'townhouse', name: 'TownHouse'}
        ]}
        validate={required()}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <SelectInput source="storey"
        variant="outlined"
        options={{ fullWidth: true }}
        choices={[
          { id: 'single', name: 'Single' },
          { id: 'double', name: 'Double' },
          { id: 'triple', name: 'Triple' },
        ]}
        validate={required()}
      />
    </Grid>
  </Grid>
);

const PasswordField = ({classes}) => {
  return (
    <PasswordInput
      fullWidth={true}
      source="password"
      variant="outlined"
      size="small"
      validate={required()}
      helperText="Create password and use this password to login."
    />
  );
};

const AddressField = ({classes}) => (
  <>
    <div className={classes.filedTitle}>Address</div>
    <Grid container spacing={1} >
      <Grid item xs={12} sm={6}>
        <TextInput
          source="street_number"
          label="Unit/Street Number"
          fullWidth={true}
          variant="outlined" 
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="street_name"
          label="Street Name"
          fullWidth={true}
          variant="outlined" 
          validate={required()}
        />
      </Grid>
    </Grid>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="suburb"
          label="Suburb"
          fullWidth={true}
          variant="outlined" 
          validate={required()}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextInput
          source="state"
          label="State"
          fullWidth={true}
          variant="outlined" 
          validate={required()}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextInput
          source="post_code"
          label="Post Code"
          fullWidth={true}
          variant="outlined" 
          validate={required()}
        />
      </Grid>
    </Grid>
  </>
);

const PropertyAddressField = ({classes}) => (
  <>
    <div className={classes.filedTitle}>Property Address</div>
    <Grid spacing={1} container direction="row" alignItems="center">
      <Grid item xs={12} sm={6}>
        <TextInput
          source="property_street_number"
          label="Lot/Street Number"
          fullWidth={true}
          variant="outlined" 
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="property_street_name"
          label="Street Name"
          fullWidth={true}
          variant="outlined" 
          validate={required()}
        />
      </Grid>
    </Grid>
    <Grid spacing={1} container direction="row" alignItems="center">
      <Grid item xs={12} sm={6}>
        <TextInput
          source="property_suburb"
          label="Suburb"
          fullWidth={true}
          variant="outlined" 
          validate={required()}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextInput
          source="property_state"
          label="State"
          fullWidth={true}
          variant="outlined" 
          //validate={required()}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextInput
          source="property_post_code"
          label="Post Code"
          fullWidth={true}
          variant="outlined" 
          //validate={required()}
        />
      </Grid>
    </Grid>
  </>
);

function TabContainer(props) {
  const Tabclasses = useStyles();
  return (
    <div
      className={clsx({
        [Tabclasses.tabcontainerInActive]: !props.active,
      })}
    >
      {props.children}
    </div>
  );
}

const useStyles = makeStyles((theme) =>({
  container: {
    border: '1px solid #f3f3f3',
    margin: '0 40px',
  },
  paper: {  
    boxShadow:'none',
  },
  tab: {
    //width: 140,
    background: '#f3f3f3',
    fontSize: 14,
    textTransform: 'capitalize',
    border: '0.2px solid #ebebeb',
  },
  selectedTab: {
    background: '#fff',
  },
  tabcontainerInActive: {
    display: "none"
  },
  filedTitle: {
    width: '100%',
    paddingBottom: 10,
    textTransform: 'capitalize',
    fontWeight: '500',
    fontSize: 15,
  },
  imageContainer: {
    margin: 'auto',
    textAlign: 'center',
  },
  imgStyle: {
    width: 400,
    height: 'auto',
  },
  passwordTitle: {
    fontSize: 12,
    textTransform: 'capitalize',
    paddingBottom: 10,
    color: '#FF9800',
  },
  passwordButton: {
    margin: 10,
  },
  buttonToolbar: {
    width: '100%',
    //justifyContent: 'space-between',
  },
  previousButton: {
    //float: 'left',
  },
  nextButton: {
    float: 'right',
  },
  AdminLogo: {
    margin: '0 20px',
    width: 120,
    height: 'auto',
    padding: '0px 10px',
  },
  imageHolder: {
    width: '100%',
    padding: '10px 0px',
    background: '#000',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headingTitle: {
    color: '#dcaf3a',
    padding: '0px 20px',
    textTransform: 'capitalize',
  },
}));

const ProjectFormtWithTheme = (props) => (
  <ThemeProvider theme={createMuiTheme(ExtraTheme)}>
    <ProjectForm {...props} />
  </ThemeProvider>
);

export default ProjectFormtWithTheme;
