import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {useEditController} from 'react-admin';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  profileButton: {
    margin: 10,
  },
}));

const Profile = ({ className, ...rest }) => {
  const { record } = useEditController(rest);
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          {/* <Box mt={2} mb={3}>
          </Box> */}
          <Typography
            color="textPrimary"
            variant="h6"
          >
            {record && `${record.company}`}
          </Typography>
          <Typography>
            {record && `${record.email}`}
          </Typography>
          <Typography>
            {record && `${record.tradingName}`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
