import React  from 'react';
import {TableHead,TableRow,TableCell} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default ({titles}) => (
  <TableHead>
    <TableRow>
      {titles.map((title, index) => (
        <StyledTableCell key={index}>{title}</StyledTableCell>
      ))}
    </TableRow>
  </TableHead>
)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#9f9150',
    color: theme.palette.common.white,
    //padding:5,
    fontSize: 13,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);


