import React, {useState} from 'react';
import {
  TextInput,
  SimpleForm,
  required,
  SelectInput,
} from 'react-admin';

import {Typography, Tab} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem',marginLeft: 200, },
});


function General() {
  const classes = useStyles();
  return (
    <div style={{marginTop: 10}}>
      <TextInput
        source="title"
        label="Site Title"
        fullWidth={true}
        variant="outlined"
        validate={required()}
      />
      <SelectInput
        source="country"
        label="Choose Country"
        choices={[
          { id: 0, name: 'USA' },
          { id: 1, name: 'AU' },
          { id: 2, name: 'UK' },
        ]}
        defaultValue={1}
        fullWidth={true}
        variant="outlined"
      />
       <TextInput
        source="region"
        label="Region"
        fullWidth={true}
        variant="outlined"
        validate={required()}
      />
      <SelectInput
        source="language"
        label="Language"
        choices={[
          { id: 1, name: 'English' },
          { id: 2, name: 'Arabic' },
        ]}
        defaultValue={1}
        fullWidth={true}
        variant="outlined"
      />
       <SelectInput
        source="currency"
        label="Choose Currency"
        choices={[
          { id: 1, name: 'USD' },
          { id: 2, name: 'EURO' },
          { id: 3, name: 'Au' },
        ]}
        defaultValue={1}
        fullWidth={true}
        variant="outlined"
      />
    </div>
  )
}

export default General;
