import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery,LinearProgress,Typography } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink, getResources,useQuery, usePermissions } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import SubMenu from './SubMenu';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ComputerIcon from '@material-ui/icons/Computer';
import { useLocation,Link } from 'react-router-dom';
import { matchPath } from "react-router";

const useMenuItemStyles = makeStyles({
  root: {
    color: '#dadbe6',
    // paddingTop: 15,
    fontSize: 13,
    '&:hover': {
      background:' rgba(50, 56, 80,0.7)',
      // background: '#f0be40',
      // color: '#000',
      // '& svg': {
      //   color: '#000',
      // },
    },
    
  },

  // '&:active': {
  //   borderLeft: '3px solid #4f3cc9',
  // },
  icon: {
    // color: '#c4beaa',
    color: '#dadbe6',
  },
});

const CustomMenu = ({ onMenuClick, logout, dense }) => {
  const [state, setState] = useState({
    menuSystem: false,
  });
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  // console.log(useSelector(state => state));
  const resources = useSelector(getResources);
  const classes = useMenuItemStyles();
  const handleToggle = (menu) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  const location = useLocation();
  const id = (location.pathname) ? location.pathname.split('/').pop() : null;
  const isprojectDetailPathActive = !!matchPath(
    location.pathname, 
    '/projects/:id',
  );
  const isprojectChartPathActive = !!matchPath(
    location.pathname,
    '/project-schedule-chart/:id',
  );
  const { permissions } = usePermissions();

  return (
    <>
      <DashboardMenuItem  classes={classes} onClick={onMenuClick} sidebarIsOpen={open} />
      {/* {resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
          classes={classes}
        />
      ))} */}
      {permissions && permissions.role === 'admin' &&
        <SubMenu
          handleToggle={() => handleToggle('menuSystem')}
          isOpen={state.menuSystem}
          sidebarIsOpen={open}
          name="System"
          icon={<ComputerIcon />}
          dense={dense}
          classes={classes}
        >
          <MenuItemLink
            to={`/settings`}
            primaryText={"Settings"}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            classes={classes}
          />
          <MenuItemLink
            to={`/managers`}
            primaryText={`Managers`}
            leftIcon={<PeopleAltIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
            classes={classes}
          />
        </SubMenu>
      }


      {((isprojectDetailPathActive || isprojectChartPathActive) && open && permissions) ? (
        <ProjectListmenu onMenuClick={onMenuClick} isChartPage={isprojectChartPathActive} id={id}/>
      ) : null}

      {isXSmall && logout}
      {open ? (<div className="sidebar-footer">Copy Rights © 2020 All Rights Reserved</div>) : ''}
    </>
  );
};


const ProjectListmenu = ({ onMenuClick,isChartPage,id }) => {
  const classes = useProjectListStyles();
  const { data, loading, error } = useQuery({ 
    type: 'getList',
    resource: 'projects',
   
    payload: { 
      pagination: {page: 1 , perPage: 100},
      sort: { field: '_id',order: 'DESC' },
      filter: {all: true}
    }
  });

  if (loading) return (
    <div className={classes.menuItemHolder}>
      <LinearProgress />
    </div>
  );
  if (error) return null;
  if (!data) return null;
  return (
    <div className={classes.menuItemHolder}>
      {data.map(project => (
        <div className={classes.menuItem} key={project.id}
          style={{borderLeft: (id === project.id) ? '5px solid #efcc08' : '5px solid #4CAF50'}}
        >
          <Link to={(isChartPage) ? `/project-schedule-chart/${project.id}` : `/projects/${project.id}`}>
            <Typography>{project.title}</Typography>
            <Typography className={classes.desc}>{project.description || ''}</Typography>
          </Link>
        </div>
      ))}
    </div>
  )
};

const useProjectListStyles = makeStyles({
  menuItemHolder: {
    margin: '20px 0',
    backgroundColor: '#fff',
    width: '95%',
    maxHeight: 460,
    overflowY: 'scroll',
  },
  menuItem: {
    padding: 10,
    borderLeft: '5px solid green',
    borderBottom: '1px solid #cac6c6',
    '& a': {
      textDecoration: 'none',
      color: '#000',
      fontSize: 10,
    },
    '& p': {
      fontSize: 13,
    }
  },
  desc: {
    fontSize: 10,
  }
});

export default CustomMenu;
