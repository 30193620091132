import * as React from "react";
import { useDataProvider, Loading, usePermissions, useAuthProvider, TextInput, DateInput, } from 'react-admin';
import { useEffect, useState } from 'react';
import { Paper, Box, Typography, Dialog, Button, DialogTitle, DialogContent, List, ListItem, ListItemText, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import BarChartIcon from '@material-ui/icons/BarChart';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Schedules from './Schedules';
import ComingSchedules from './ComingSchedules';
import ProjectStatus from '../components/ProjectStatus';
import {
  FcGenealogy, FcInspection,FcContacts
} from "react-icons/fc";
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from 'react-router-dom';
import GridOnIcon from '@material-ui/icons/GridOn';
function SimpleDialog(props) {
  const { onClose, selectedValue, open, revisions, id } = props;
  const routeHistory = useHistory();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();
  const classes = useStyles();
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value, index) => {
    // onClose(value);
    routeHistory.push(`/project-estimate/${id}/${value.id}`)
  };
  const CreateGroup = (values) => {
    values['project'] = id;
    values['prepared_by'] = user.username;
    dataProvider.createCategory(values, 'project-estimate-init-rev').then(({ data }) => {
      routeHistory.push(`/project-estimate/${id}/${data.id}`)
    }).catch(error => {

    });
  }
  return (
    <>

      <Dialog onClose={handleClose} open={open}>
        {revisions && revisions.length > 0 ?
          <>
            <DialogTitle>
              <Grid container direction="row" alignItems="center" >
                <div className={classes.heading} >
                  <Typography variant="h6" gutterBottom>
                    CHOOSE REVISION
                  </Typography>
                </div>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.modalContent}>
              <List sx={{ pt: 0 }}>
                {revisions?.map((email, index) => (
                  <ListItem button onClick={() => handleListItemClick(email, index)} key={email}>
                    <ListItemText primary={email?.rev_no} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </>
          :
          <>
            <DialogTitle id="form-dialog-title">
              <Grid container direction="row" alignItems="center" >
                <div className={classes.heading} >
                  <FcGenealogy />
                  <Typography variant="h6" gutterBottom>
                    Create Revision
                  </Typography>
                </div>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.modalContent}>
              <Form
                onSubmit={CreateGroup}
                render={({ handleSubmit, form }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          <TextInput
                            source="rev_no"
                            label="Rev No"
                            fullWidth={true}
                            variant="outlined"
                          // validate={required()}
                          // defaultValue={initialval?.rev_no}

                          />
                        </Grid>
                        <div className={classes.saveBtnHolder}>
                          <Button className={classes.saveBtn} type="submit"><SaveIcon style={{ fontSize: 17, marginRight: 2 }} /> SAVE</Button>
                        </div>
                      </Grid>
                    </form>
                  )
                }} />
            </DialogContent>
          </>
        }
      </Dialog>

    </>
  );
}

function ProjectEdit(props) {
  const [project, setProject] = useState();
  const [loading, setLoading] = useState(true);
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const classes = useStyles();
  const [data, setdata] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loading) setLoading(true);
    dataProvider.getOne('projects', { id: props.id })
      .then(({ data }) => {
        setProject(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      })
    dataProvider.getList('project-estimate-rev', { filter: { project: props.id }, pagination: {}, sort: { field: 'id', order: 'asc' } }).then(({ data }) => {
      if (data && data.length > 0) {
        setdata(data);
        setLoading(false);
      }
      else {
        setLoading(false);
      }

    })
      .catch(error => {
        setLoading(false);
      });
    // dataProvider.getOne('managers', { id: props.id })
    // .then(({ data }) => {
    //   console.log(data);
    //   setLoading(false);
    // })
    // .catch(error => {
    //   setLoading(false);
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([1]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  }

  if (loading) return <Loading />;
  if (!project) return null;

  return (
    <>
      <Paper square className={classes.titlePaper}>
        <Typography variant="h5" className={classes.projectTitle}>
          {`${project.title}, ${project.description}`}
          {(permissions && (permissions.role === 'admin' || permissions.role === 'manager')) && (
            <ProjectStatus id={props.id} onlineStatus={project.onlineStatus} />
          )}
        </Typography>
      </Paper>

      <Box
        display="flex"
        flexWrap="wrap"
        bgcolor="background.paper"
      >
        <Box p={1} m={2} alignItems="center">
          <Link to={`/project-schedule-chart/${props.id}`} className={classes.anchor}>
            <div className={classes.iconHolder}>
              <BarChartIcon className={classes.chartIcon} />
              <Typography>View Schedule Chart</Typography>
            </div>
          </Link>
        </Box>
        {(permissions && (permissions.role === 'admin' || permissions.role === 'manager')) ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-todo-chart/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <AssessmentIcon className={classes.todoChartIcon} />
                <Typography>View Todo Chart</Typography>
              </div>
            </Link>
          </Box>
        ) : null}

        {(permissions && (permissions.role === 'admin' || permissions.role === 'manager')) ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-punch-list/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <ListAltIcon className={classes.punchListIcon} />
                <Typography>View Punch List</Typography>
              </div>
            </Link>
          </Box>
        ) : null}

        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-materials/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <AddShoppingCartIcon className={classes.materialIcon} />
                <Typography>Add Material</Typography>
              </div>
            </Link>
          </Box>
        ) : null}

        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-costs/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <AttachMoneyIcon className={classes.moneyIcon} />
                <Typography>Project Cost</Typography>
              </div>
            </Link>
          </Box>
        ) : null}


        <Box p={1} m={2} alignItems="center">
          <Link to={`/project-gallery/${props.id}`} className={classes.anchor}>
            <div className={classes.iconHolder}>
              <PermMediaIcon className={classes.galleryIcon} />
              <Typography>Project Files</Typography>
            </div>
          </Link>
        </Box>

        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/projects/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <AssignmentIcon className={classes.reportIcon} />
                <Typography>Show Report</Typography>
              </div>
            </Link>
          </Box>
        ) : null}

        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/projects/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <AssignmentIcon className={classes.reportIcon} />
                <Typography>Show Material Requests</Typography>
              </div>
            </Link>
          </Box>
        ) : null}
        {(permissions?.role === 'admin' || permissions?.role === 'manager') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-legaldocuments/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <AssignmentIcon className={classes.reportIcon} />
                <Typography>Legal Documents</Typography>
              </div>
            </Link>
          </Box>
        ) : null}
        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center" onClick={handleClickOpen}>
            {/* <Link to={`/project-estimate/${props.id}`} className={classes.anchor}> */}
            <div className={classes.iconHolder}>
              <CardTravelIcon className={classes.estimate} />
              <Typography>Estimate</Typography>
            </div>
            {/* </Link> */}
          </Box>
        ) : null}
        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-floorplan/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <GridOnIcon className={classes.floorplan} />
                <Typography>Floor plan</Typography>
              </div>
            </Link>
          </Box>

        ) : null}
        {(permissions && permissions.role === 'admin') ? (
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-qa-Inspections/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <FcInspection size={26} className={classes.floorplan} />
                <Typography>QA Inspections</Typography>
              </div>
            </Link>
          </Box>
        ) : null}
        {/* {(permissions && permissions.role === 'admin') ? ( */}
          <Box p={1} m={2} alignItems="center">
            <Link to={`/project-contacts/${props.id}`} className={classes.anchor}>
              <div className={classes.iconHolder}>
                <FcContacts size={26} className={classes.floorplan} />
                <Typography>Contacts</Typography>
              </div>
            </Link>
          </Box>
        {/* ) : null} */}
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          id={props.id}
          revisions={data || []}
          onClose={handleClose}
        />
      </Box>

      <Schedules projectId={props.id} permission={permissions.role} />
      <ComingSchedules projectId={props.id} permission={permissions.role} />
    </>
  )
};

const useStyles = makeStyles((theme) => ({
  iconHolder: {
    width: 115,
    boxShadow: '0px 0px 10px rgba(73, 78, 92, 0.15)',
    cursor: 'pointer',
    display: 'block',
    padding: '28px 10px 10px 10px',
    textAlign: 'center',
    color: '#000',
    minHeight: 70,
    '& p': {
      paddingTop: 5,
      fontSize: 14,
      textTransform: 'uppercase',
    },
  },
  galleryIcon: {
    color: '#046b08',
  },
  chartIcon: {
    color: '#df3703',
  },
  reportIcon: {
    color: '#726708',
  },
  estimate: {
    color: '#040e3f',
  },
  materialIcon: {
    color: '#850d88',
  },
  moneyIcon: {
    color: '#0b1a6b',
  },
  todoChartIcon: {
    color: '#930a1e',
  },
  punchListIcon: {
    color: '#ca7a03',
  },
  anchor: {
    textDecoration: 'none',
    color: '#dadbe6',
    '&:active': {
      color: '#fff',
    }
  },
  titlePaper: {
    background: '#f3f3f3',
    marginTop: 20,
    boxShadow: 'none',
  },
  projectTitle: {
    background: '#282a3c',
    color: '#fff',
    padding: 10,
    fontSize: 15,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
  },
  saveBtnHolder: {
    marginTop: 20,
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  saveBtn: {
    background: '#e5aa13',
    color: '#000',
    fontWeight: 'bold',
    '&:hover': {
      background: '#e5aa13',
    }
  },

}));

export default ProjectEdit;

