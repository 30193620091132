import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import { Badge } from '@material-ui/core';
import {
    TableContainer,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Button,
    Grid, Typography,
} from '@material-ui/core';

function FloorplanList(props) {
    const { data, onEditClick, onDeleteClick } = props;
    const classes = useStyles();

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <SectionHeading label="Floor Plan List" classProp={classes.heading} />
            <Table className={classes.table} size="small" aria-label="material-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((projectMaterial) => (
                        <TableRow key={projectMaterial?.id}>
                            <TableCell>{projectMaterial?.checklistcategory?.name}</TableCell>
                            <TableCell>
                                <Badge badgeContent={projectMaterial?.files && projectMaterial?.files.length ? projectMaterial?.files?.length : []} color='primary'>
                                    <DescriptionIcon />
                                </Badge>
                            </TableCell>
                            < TableCell align="right" >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    onClick={() => onEditClick(projectMaterial)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    className={classes.deleteButton}
                                    onClick={() => onDeleteClick(projectMaterial.id)}
                                >
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table >
        </TableContainer >
    )
}

const SectionHeading = ({ label, classProp }) => {
    return (
        <Grid container direction="row" alignItems="center" className={classProp}>
            <ListIcon />
            <Typography variant="h6" gutterBottom >
                {label}
            </Typography>
        </Grid>
    );
};

const useStyles = makeStyles({
    tableContainer: {
        marginTop: 15,
        paddingBottom: 15,
        boxShadow: 'none',
        border: '1px solid #ebebeb',
        width: 'auto',
        marginLeft: 10,
    },
    heading: {
        padding: 10,
        '& h6': {
            fontSize: 14,
            paddingLeft: 5,
            fontWeight: 'bold',
            letterSpacing: 1,
            color: '#e58f0a',
            textTransform: 'uppercase',
        }
    },
    table: {
        minWidth: 650,
    },
    deleteButton: {
        border: '1px solid #ff0000', color: '#ff0000', marginLeft: 15,
    }
});


export default FloorplanList;
