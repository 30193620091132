import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {TextField,Button,Typography,CircularProgress } from '@material-ui/core';
import { useDataProvider,useAuthProvider } from 'react-admin';


export default function TaskMessenger(props) {

  const [messageText,setMessageText] = useState('');
  const [messages,setMessages] = useState();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const user = authProvider.getIdentity();

  const handleMessage = (event) => {
    setMessageText(event.target.value);
  }
  const setMessagesData = (messagesData) => {
    setMessages(
      messagesData.map(message => {
        return {
          id: message.id,
          message: message.message,
          user:(message.user.username) ? message.user.username : message.user.email,
          createdAt: message.createdAt,
          isMe: (message.user.id && message.user.id === user.id),
        }
      })
    )
  }
  const sendMessage = () => {
    if(messageText && messageText !== '') {
      setLoading(true);
      dataProvider.saveMessage('create-message', { data:{task: props.taskId,message: messageText,}})
      .then(response => {
        setMessagesData(response.data);
        setMessageText('');
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
    } else {
      return
    }
  }
  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    dataProvider.getMessages({id: props.taskId})
    .then(response => {
      if (isMounted) {
        setLoading(false);
        // console.log(response.data)
        setMessagesData(response.data);
      }
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
    
    return () => {isMounted = false;};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return(
    <div className={classes.main} >
      <Typography className={classes.message_label}>Comments</Typography>
      <TextField
        variant="outlined"
        label=""
        multiline
        //rowsMax={3}
        value={messageText}
        fullWidth={true}
        onChange={handleMessage}
        className={classes.textField}
      />
      <Button
        variant="outlined"
        color="primary"
        className={classes.sendButton}
        onClick={sendMessage}
        disabled={loading}
      >
        {loading && <CircularProgress size={14} />}
        {!loading && 'Send'}
        
      </Button>
      <div>
        {(messages && messages.length > 0) && (
          <div>
            {messages.map(message => (
              <div className={classes.messageHolder} key={message.id}>
                <div className={classes.createdAt} >
                  {message.createdAt}
                </div>
                <div style={{textAlign: (message.isMe) ? 'right': 'left'}} >
                  {message.message}
                </div>
                <p className={classes.username} style={{textAlign: (message.isMe) ? 'right': 'left'}}>{message.user}</p>
              </div>
            ))}
            
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>({
  main: {
    width: '100%',
    marginTop: 12,
  },
  textField: {
    fontSize: 10,
  },
  sendButton: {
    marginTop: 10,
  },
  messageHolder: {
    padding: 8,
    background: '#efefef',
    border: '1px solid #ebeaea',
    margin: '10px 0',
    borderRadius: 16,
  },
  username: {
    padding: '5px 0',
    margin: 0,
    fontSize: 13,
    fontWeight: 'bold',
    color: '#826510',
  },
  message_label: {
    fontSize: 14,
    padding: '10px 0',
    color: '#8a8080',
  },
  createdAt: {
    width: '100%',
    textAlign: 'center',
  }
}));