import React, { useEffect } from 'react';
import { Table , TableHead, TableBody, TableCell, TableContainer, TableRow,Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import { useDataProvider } from 'react-admin';
const useStyles = makeStyles({
  heading: {
    padding: 16,
    '& h6': {
      fontSize: 15,
      padding: 10,
      fontWeight: 'bold',
      color:'#e7b02c',
    }
  },
  statusField: {
    color: '#ffff',
    padding: 5,
    fontSize: 13,
    textTransform: 'capitalize',
    borderRadius: 5,
    display: 'block',
    textAlign: 'center',
  },
  activated: {
    background: '#1e911e',
  },
  notActivated: {
    background: '#ed3636',
  },
  ListManagers: {
    background: '#ddd',
    padding: '5px 10px',
    borderRadius: 15,
    margin: 2,
  }
});

const InvitedList = props => {
  const classes = useStyles(); 
  const [rows, setRows] = React.useState();
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider.getInvitedList('invited-clients')
      .then(({ data }) => {
        if (data?.clients && data?.clients.length > 0) {
          console.log(data?.clients);
          setRows(data?.clients);
        }
      })
      .catch(error => {
      })
      console.log(rows);
  },[]);
  const listManagers = (manager) => {
    return (
      <div className={classes.ListManagers}>{manager?.username}</div>
    )
  }
  return (
          <div>
            <Grid container direction="row" alignItems="center" className={classes.heading}>
            <ListIcon />
            <Typography variant="h6" gutterBottom>
              Invited Clients
            </Typography>
            </Grid>
            <TableContainer >
              <Table className={classes.table} aria-label="custom table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Contact</TableCell>
                  <TableCell align="center">Project</TableCell>
                  <TableCell align="center">Project type</TableCell>
                  <TableCell align="center">Managers</TableCell>
                </TableRow>
              </TableHead>
                <TableBody>
                { rows && rows.length > 0 && (
                  <>
                  {rows.map( row => {
                    return(
                    <TableRow >
                      <TableCell >{`${row.first_name} ${row.last_name}`}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.contact}</TableCell>
                      <TableCell align="center">{row.title}</TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell align="center">{row.managers.map(listManagers)}</TableCell>
                    </TableRow>
                    );
                  })}
                  </>
                )}                  
                </TableBody>
              </Table>
            </TableContainer>
          </div>
  )
};

export default InvitedList;
