import React from 'react';
import {
  TextInput,
  SimpleForm,
  required,
  email,
} from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles({
  main: {
    width: '100%',
  },
  container: {
    background: '#fff',
    margin: 'auto',
    width: '80%',
    marginTop: 15,
    maxHeight: 600,
    overflowY: 'scroll',
  },
  heading: {
    color: '#e58f0a',
    textTransform: 'capitalize',
  },
  passwordTitle: {
    fontSize: 12,
    textTransform: 'capitalize',
    paddingBottom: 10,
    color: '#FF9800',
  },
  passwordButton: {
    margin: 10,
  }
});


export default ({ save, record }) => {

  const clientAddressRef = React.useRef(null);
  const classes = useStyles();
  let clinetAddrressAuto;
  const [clientAddress, setClientAddress] = React.useState(record?.map_address || null);

  React.useEffect(() => {
    hadleInitialLoad(setClientAddress, clientAddressRef);
  }, []);

  const onSave = (data) => {
    data.map_address = clientAddress;
    save(data);
  }
  function hadleInitialLoad(updateClientAddress, clientAddressRef) {
    clinetAddrressAuto = new window.google.maps.places.Autocomplete(
      clientAddressRef.current,
      { types: ["address"], componentRestrictions: { country: "aus" } }
    );
    clinetAddrressAuto.setFields(["address_components", "formatted_address"]);
    clinetAddrressAuto.addListener("place_changed", () =>
      handleAddressSelect(updateClientAddress)
    );
  }

  const handleAddressSelect = async (updateClientAddress) => {
    const addressObject = await clinetAddrressAuto.getPlace();
    const s = addressObject.formatted_address;
    updateClientAddress(s);
    setClientAddress(s);
  }
  return (
    <div className={classes.container}>
      <SimpleForm save={onSave} record={record || {}}  >
        <FormTitle headingStyle={classes.heading} />
        <FormFields
          clientAddress={clientAddress}
          setClientAddress={setClientAddress}
          clientAddressRef={clientAddressRef}
          gridStyle={classes.main}
        />


      </SimpleForm>
    </div>
  )
}

const FormTitle = ({ headingStyle }) => {
  return (
    <Typography variant="h6" gutterBottom className={headingStyle}>
      Secondary client details
    </Typography>
  )
}

const FormFields = (props) => {
  const { record } = props;
  const { clientAddress, setClientAddress, clientAddressRef } = props;
  return (
    <Grid container spacing={1} className={props.gridStyle}>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="first_name"
          validate={required()}
          fullWidth={true}
          label="First Name"
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="last_name"
          validate={required()}
          fullWidth={true}
          label="Last Name"
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="email"
          fullWidth={true}
          label="Email Address"
          variant="outlined"
          //validate={[required(),validateEmail]}
          validate={[required(), email()]}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          source="contact"
          validate={required()}
          fullWidth={true}
          label="Contact Number"
          variant="outlined"
          size="small"
        />
      </Grid>
      {record?.street ?
        <>
          <Grid item xs={12} sm={6}>
            <TextInput
              source="street"
              validate={required()}
              multiline
              fullWidth={true}
              label="Street"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInput
              source="suburb"
              validate={required()}
              fullWidth={true}
              label="Suburb"
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInput
              source="zip"
              validate={required()}
              fullWidth={true}
              label="Postal code"
              variant="outlined"
              size="small"
            />
          </Grid>
        </> :
        <Grid item xs={12} sm={6}>
          <TextInput
            source="map_address"
            value={clientAddress || null}
            label="Address"
            placeholder='Address'
            variant="outlined"
            style={{ width: '100%' }}
            autocomplete={'on'}
            inputRef={clientAddressRef}
            validate={required()}
            onChange={(e) => { setClientAddress(e.target.value); }}
          />
        </Grid>
      }
    </Grid>
  );
}
